import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-patient-info-outsourced-data',
  templateUrl: './patient-info-outsourced-data.component.html',
  styleUrls: ['./patient-info-outsourced-data.component.scss'],
})
export class PatientInfoOutsourcedDataComponent implements OnInit {
  @Input() public formData: FormGroup;
  constructor() {}

  ngOnInit() {}
}
