import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-help-center-topics-result',
  templateUrl: './help-center-topics-result.component.html',
  styleUrls: ['./help-center-topics-result.component.scss'],
})
export class HelpCenterTopicsResultComponent {
  @Input() original: string;

  constructor() {}
}
