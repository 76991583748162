import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

const BUTTON_HOST_ATTRIBUTES = [
  'app-vale-button',
  'app-vale-flat-button',
  'app-vale-icon-button',
  'app-vale-raised-button',
  'app-vale-stroked-button',
  'app-vale-mini-fab',
  'app-vale-fab-button',
];

@Component({
  selector: `button[app-vale-button],
             button[app-vale-flat-button],
             button[app-vale-icon-button],
             button[app-vale-raised-button],
             button[app-vale-stroked-button],
             button[app-vale-mini-fab],
             button[app-vale-fab-button]`,
  templateUrl: './vale-button.component.html',
  styleUrls: ['./vale-button.component.scss'],
  host: {
    '[attr.disabled]': 'disabled || null',
    class: 'app-vale-button-base',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ['disabled', 'color'],
  exportAs: 'appValeButton',
})
export class ValeButtonComponent implements OnInit, OnDestroy, OnChanges {
  readonly isRoundButton: boolean = this._hasHostAttributes(
    'app-vale-fab-button',
    'app-vale-mini-fab'
  );

  // readonly isIconButton: boolean = this._hasHostAttributes('app-vale-icon-button');

  @Input() public disabled = false;
  @Input() public color = 'primary';
  @Input('icon') public sideIconName = 'right';
  @Input('src') public srcIcon: string = null;
  @Input('prefixIcon') public preIcon: string = null;
  @Input('suffixIcon') public posIcon: string = null;

  public sideIcon = false;

  private _buttonColors = ['primary', 'success', 'gray', 'danger'];

  constructor(private elementRef: ElementRef) {
    elementRef.nativeElement.classList.add('app-vale-button-base');

    for (const attr of BUTTON_HOST_ATTRIBUTES) {
      if (this._hasHostAttributes(attr)) {
        (this._getHostElement() as HTMLElement).classList.add(attr);
      }
    }
  }

  ngOnChanges(): void {
    this.setClassColor();
  }

  public ngOnInit(): void {
    this.sideIcon = this.sideIconName === 'right';
    this.setClassColor();
  }

  public _getHostElement() {
    return this.elementRef.nativeElement;
  }

  public _hasHostAttributes(...attributes: string[]) {
    return attributes.some((attribute) =>
      this._getHostElement().hasAttribute(attribute)
    );
  }

  public ngOnDestroy(): void {
    // this.elementRef.nativeElement.removeEventListener('', this._haltDisabledEvents);
  }

  private setClassColor() {
    this.removeClassColor();
    if (this.color) {
      this._getHostElement().classList.add(`app-vale-button-${this.color}`);
    }
  }

  private removeClassColor() {
    this._buttonColors.forEach((color: string) => {
      this._getHostElement().classList.remove(`app-vale-button-${color}`);
    });
  }
}
