import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-result-quick-exam',
  templateUrl: './result-quick-exam.component.html',
  styleUrls: ['./result-quick-exam.component.scss'],
})
export class ResultQuickExamComponent implements OnInit, OnChanges, OnDestroy {
  public value: any;
  public onChange: any;
  public onTouched: any;
  public isPosition = false;
  public resultQuickExamForm: FormGroup;
  public isResultReactiveWithoutDistiction = false;

  @Input() public disabled = false;
  @Input() public position = 'vertical';
  @Input() public examResult: number;
  @Input() public hasReactiveWithoutDistiction = false;
  @Input() public clearResult$: BehaviorSubject<boolean>;
  @Output('quickExamResultChange')
  private _changeQuickExamResult: EventEmitter<any> = new EventEmitter<any>();
  private _subscriptions = new Subscription();

  constructor() {}
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnChanges(): void {
    this.isResultReactiveWithoutDistiction = this.examResult == 4;
    this._enableForm();
    if (this.isResultReactiveWithoutDistiction) {
      this.hasReactiveWithoutDistiction = true;
      this._disableForm();
    }

    if (this.resultQuickExamForm) {
      this.resultQuickExamForm.setValue(this._quickExamResultMapperToForm());
      this.resultQuickExamForm.updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.isPosition = this.position === 'vertical';

    this.resultQuickExamForm = new FormGroup({
      igmResult: new FormControl('', [Validators.required]),
      iggResult: new FormControl('', [Validators.required]),
    });

    this.isResultReactiveWithoutDistiction = this.examResult == 4;
    if (this.isResultReactiveWithoutDistiction) {
      this.hasReactiveWithoutDistiction = true;
      this._disableForm();
    }
    if (this.examResult) {
      this.resultQuickExamForm.setValue(this._quickExamResultMapperToForm());
      this.resultQuickExamForm.updateValueAndValidity();
    }

    this.resultQuickExamForm.valueChanges.subscribe(() => {
      this._formChangeEmitter();
    });

    if (this.clearResult$) {
      this._subscriptions.add(
        this.clearResult$.subscribe((value) => {
          if (value && this.resultQuickExamForm) {
            this.resultQuickExamForm.reset();
          }
        })
      );
    }
  }

  public disableAndSetForm(event: MatCheckboxChange): void {
    this._enableForm();
    if (event && event.checked) {
      this._disableForm();
    }
  }

  private _enableForm() {
    if (this.resultQuickExamForm) {
      this.resultQuickExamForm.enable();
      this.resultQuickExamForm
        .get('igmResult')
        .setValidators([Validators.required]);
      this.resultQuickExamForm
        .get('iggResult')
        .setValidators([Validators.required]);
      this.resultQuickExamForm.enable();
      this.resultQuickExamForm.updateValueAndValidity();
    }
  }

  private _disableForm() {
    if (this.resultQuickExamForm) {
      this.resultQuickExamForm.setValue({
        igmResult: '2',
        iggResult: '2',
      });
      this.resultQuickExamForm.disable();
      this.resultQuickExamForm.updateValueAndValidity();
    }
  }

  private _quickExamResultMapperToForm() {
    const result = this.resultQuickExamForm
      ? this.resultQuickExamForm.value
      : this._defaultResult();
    switch (this.examResult) {
      case 4:
        (result.igmResult = '2'), (result.iggResult = '2');
        return result;
      case 5:
        (result.igmResult = '1'), (result.iggResult = '1');
        return result;
      case 6:
        (result.igmResult = '0'), (result.iggResult = '1');
        return result;
      case 7:
        (result.igmResult = '1'), (result.iggResult = '0');
        return result;
      case 8:
        (result.igmResult = '0'), (result.iggResult = '0');
        return result;
      default:
        return result;
    }
  }

  private _quickExamFormMapperToResult() {
    const igmResult = this.resultQuickExamForm.get('igmResult').value;
    const iggResult = this.resultQuickExamForm.get('iggResult').value;

    if (igmResult == '1' && iggResult == '1') {
      return 5;
    }
    if (igmResult == '0' && iggResult == '1') {
      return 6;
    }
    if (igmResult == '1' && iggResult == '0') {
      return 7;
    }
    if (igmResult == '0' && iggResult == '0') {
      return 8;
    }

    if (igmResult == '2' && iggResult == '2') {
      return 4;
    }

    return null;
  }

  private _formChangeEmitter(): void {
    this._changeQuickExamResult.emit({
      value: this._quickExamFormMapperToResult(),
      valid: this.resultQuickExamForm.valid,
      invalid: this.resultQuickExamForm.invalid,
    });
  }

  private _defaultResult = () => {
    return {
      igmResult: '',
      iggResult: '',
    };
  };
}
