import { LayoutModule } from '@angular/cdk/layout';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgxMaskModule } from 'ngx-mask';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ApiInterceptor } from './core/middleware/api-interceptor';
import { AbsenceControlService } from './core/services/absence-control.service';
import { ActionDialogService } from './core/services/action-dialog.service';
import { AuthenticationService } from './core/services/authentication.service';
import { BaseAuthenticationService } from './core/services/base-authentication.service';
import { CardManagementService } from './core/services/card-management.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { HelpCenterService } from './core/services/help-center.service';
import { MedicalRecordService } from './core/services/medical-record.service';
import { MonitoringCenterService } from './core/services/monitoring-center.service';
import { PatientRegisterMdmService } from './core/services/patient-register-mdm.service';
import { PatientService } from './core/services/patient.service';
import { RegionService } from './core/services/region.service';
import { RegisterService } from './core/services/register.service';
import { UraAccessCardService } from './core/services/ura-access-card.service';
import { BypassLoginModule } from './modules/bypass-login/bypass-login.module';
import { HomeModule } from './modules/home/home.module';
import { HomeService } from './modules/home/home.service';
import { UserBusiness } from './shared/business/user-business.service';
import { BreakpointObserverService } from './shared/services/breakpoint-observer.service';
import { SharedModule } from './shared/shared.module';
import { Storage } from './shared/util/storage';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.api],
        sendAccessToken: true,
      },
    }),
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    NgHttpLoaderModule.forRoot(),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LayoutModule,
    HomeModule,
    BypassLoginModule,
    AppRoutingModule,
  ],
  entryComponents: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    Title,
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    MedicalRecordService,
    RegisterService,
    RegionService,
    MonitoringCenterService,
    AbsenceControlService,
    BaseAuthenticationService,
    AuthenticationService,
    PatientService,
    ActionDialogService,
    Storage,
    GoogleAnalyticsService,
    UserBusiness,
    HelpCenterService,
    CardManagementService,
    BreakpointObserverService,
    PatientRegisterMdmService,
    UraAccessCardService,
    HomeService,
    { provide: OAuthStorage, useFactory: storageFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
