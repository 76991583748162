<div class="container-dialog">
  <div class="container-body">
    <div class="header">
      <button app-vale-fab-button color="primary">
        <mat-icon svgIcon="filter-list-24px"></mat-icon>
      </button>
      <span class="title">{{ 'FILTER_DIALOG.TITLE' | translate }}</span>
      <div class="actions">
        <mat-icon
          [matTooltip]="'FILTER_DIALOG.CLOSE' | translate"
          (click)="close()"
          class="close-icon"
          >close</mat-icon
        >
      </div>
    </div>
    <div class="body-dialog">
      <div class="body-content">
        <ng-container [ngSwitch]="contentType">
          <ng-container *ngSwitchCase="'string'">
            <div class="box">
              <div [innerHTML]="content"></div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'template'">
            <ng-container
              *ngTemplateOutlet="content; context: context"
            ></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'component'">
            <ng-container *ngComponentOutlet="content"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="buttons-container-confirm">
      <button
        app-vale-flat-button
        [disabled]="isDisabledConfirmButton()"
        color="primary"
        (click)="confirm()"
      >
        {{ 'FILTER_DIALOG.BUTTON' | translate }}
      </button>
    </div>
  </div>
</div>
