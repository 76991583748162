import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-not-found',
  templateUrl: './search-not-found.component.html',
  styleUrls: ['./search-not-found.component.scss'],
})
export class SearchNotFoundComponent implements OnInit {
  @Input('searchBy') public searchBy;
  @Input('tips') public tips = [];
  constructor() {}

  public ngOnInit(): void {
    this.tips = this.tips || [];
    if (!this.tips.length) {
      this.tips = ['SEARCH_NOT_FOUND.TIP'];
    }
  }
}
