import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { PatientBusiness } from '../../../../modules/patient/patient-business.service';
import { ChecklistModel } from '../../../models/checklist.model';
import { PatientInfoChecklistBusiness } from './patient-info-checklist-business.service';

@Component({
  selector: 'app-patient-info-cheklist',
  templateUrl: './patient-info-cheklist.component.html',
  styleUrls: ['./patient-info-cheklist.component.scss'],
})
export class PatientInfoCheklistComponent implements OnInit, OnDestroy {
  public checklists$: Observable<ChecklistModel[]>;
  private _patient;
  private _subscriptions = new Subscription();
  constructor(
    private _router: Router,
    private _patientBusiness: PatientBusiness,
    private _checklistBusiness: PatientInfoChecklistBusiness
  ) {}

  ngOnInit(): void {
    this._subscriptions.add(
      this._patientBusiness.patient.subscribe((patient) => {
        this._patient = patient;
        if (patient && patient.gcmid) {
          this.checklists$ = this._checklistBusiness.getCheckListsByPatientId$(
            patient.gcmid
          );
        }
      })
    );
  }
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
