import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { UserBusiness } from '../../../../shared/business/user-business.service';
import { CountryEnum } from '../../../../shared/enums/country.enum';
import { HomeService } from '../../home.service';

@Component({
  selector: 'app-release-notes-dialog',
  templateUrl: './release-notes-dialog.component.html',
  styleUrls: ['./release-notes-dialog.component.scss'],
})
export class ReleaseNotesDialogComponent implements OnInit {
  private _subscriptions = new Subscription();
  public countryId = this._userBusiness.getUserCountryId();
  public countryEnum = CountryEnum;
  public imageRoute: string;

  constructor(
    private _homeService: HomeService,
    public dialogRef: MatDialogRef<ReleaseNotesDialogComponent>,
    private _userBusiness: UserBusiness,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.imageRoute = this.data;
    }
  }

  public closeModal(): void {
    this.dialogRef.close();
    if (!this.data) {
      const sub = this._homeService
        .getRelease()
        .subscribe((response: any) => {});
      this._subscriptions.add(sub);
    }
  }
}
