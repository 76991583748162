import { Highlightable } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';

import { ValeGenericSearchDropdownService } from './vale-generic-search-dropdown.service';
import { ValeGenericSearchComponent } from './vale-generic-search.component';

@Component({
  selector: 'vale-generic-search-option',
  template:
    "<div class=\"container-vale-generic-search-option {{ isDisabled ? 'disabled' : '' }}\"><ng-content></ng-content></div>",
  styleUrls: ['./_vale-generic-search-option.scss'],
})
export class ValeGenericSearchOptionComponent
  implements Highlightable, AfterViewInit {
  @Input()
  public value: string;

  @Input()
  public disabled: boolean;

  @HostBinding('class.selected')
  public get selected(): boolean {
    return this.select.selectedOption === this;
  }

  @HostBinding('class.active')
  public active = false;

  @HostBinding('class.disabled')
  public get isDisabled(): boolean {
    return this.disabled;
  }

  private select: ValeGenericSearchComponent;

  constructor(private dropdownService: ValeGenericSearchDropdownService) {
    this.select = this.dropdownService.getSelect();
  }

  ngAfterViewInit(): void {}

  public getLabel(): string {
    return this.value;
  }

  public setActiveStyles(): void {
    this.active = true;
  }

  public setInactiveStyles(): void {
    this.active = false;
  }

  @HostListener('click', ['$event'])
  public onClick(event: UIEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.disabled) {
      this.select.selectOption(this);
    }
  }
}
