import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss'],
})
export class ScoreComponent implements OnInit {
  @Output('save') public saveEmitter = new EventEmitter<FormGroup>();
  public scores = [
    { option: 'terrible', value: 1 },
    { option: 'bad', value: 2 },
    { option: 'regular', value: 3 },
    { option: 'good', value: 4 },
    { option: 'great', value: 5 },
  ];
  public scoreForm = new FormGroup({
    score: new FormControl(null, [Validators.required]),
    note: new FormControl(null),
  });
  public selectedOption;

  public MAX_LENGTH_TEXTAREA = 250;

  constructor() {}

  ngOnInit(): void {}

  public getMessageScoreTitle(option: string): string {
    return `HELP_CENTER.CUSTOMER_SATISFACTION.SCORE.${option.toUpperCase()}.TITLE`;
  }

  public getMessageScoreDescription(): string {
    if (this.selectedOption) {
      return `HELP_CENTER.CUSTOMER_SATISFACTION.SCORE.${this.selectedOption.option.toUpperCase()}.DESCRIPTION`;
    }
  }

  public selectOption(score: any): void {
    this.scoreForm.get('score').setValue(score.value);
    this.selectedOption = score;
  }

  public save(): void {
    this.saveEmitter.emit(this.scoreForm);
  }

  public getNumbersCharacteres(): number {
    const note = this.scoreForm.get('note');
    if (note && note.value) {
      return this.MAX_LENGTH_TEXTAREA - note.value.length;
    }
    return this.MAX_LENGTH_TEXTAREA;
  }
}
