<div class="container">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="title">
        {{ topics?.title }}
      </mat-expansion-panel-header>
      <app-help-center-topics-child
        class="children"
        *ngFor="let topic of topics.children"
        [topics]="topic"
      ></app-help-center-topics-child>
    </mat-expansion-panel>
  </mat-accordion>
</div>
