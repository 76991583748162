import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ValeUploadComponent } from './vale-upload.component';

@NgModule({
  declarations: [ValeUploadComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [ValeUploadComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeUploadModule {}
