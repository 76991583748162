<div class="vale-theme" #containerValeTheme>
  <div class="header" *ngIf="(isLoggedIn || isDenied) && !isMobile && !isIE">
    <app-header></app-header>
  </div>
  <div class="body">
    <div style="height: 85vh" (click)="clickOutsideModal()">
      <router-outlet
        *ngIf="(isLoggedIn && !isIE) || isBypassLogin"
      ></router-outlet>
      <ng-http-loader
        [filteredUrlPatterns]="getFiltered()"
        backgroundColor="#007E7A"
        spinner="sk-cube-grid"
      >
      </ng-http-loader>
      <app-profile-denied *ngIf="isDenied"></app-profile-denied>
      <app-incompatibility *ngIf="isIE"></app-incompatibility>
    </div>
    <div style="display: flex" *ngIf="!hasOnlyPermissionUraAccessCard">
      <button
        mat-fab
        class="help-button"
        *ngIf="
          helpCenterIsOpened === false && !isMobile && (isLoggedIn || isDenied)
        "
        (click)="openHelpCenter()"
      >
        <div>
          <mat-icon class="help-icon">help_outline</mat-icon>
        </div>
        <div class="help-text">{{ 'HELP_CENTER.BUTTON' | translate }}</div>
      </button>
    </div>
    <app-help-center-home
      *ngIf="
        helpCenterIsOpened === true &&
        !isMobile &&
        !hasOnlyPermissionUraAccessCard
      "
      (helpCenterState)="openHelpCenter()"
    ></app-help-center-home>
  </div>
</div>
