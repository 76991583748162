<ng-container *ngIf="!show; else result">
  <div
    (click)="details(result)"
    style="margin-bottom: 18px"
    *ngFor="let result of results"
  >
    <div class="title-quote">
      <span>{{ result.father.title }}</span>
      <img
        src="../../../../../../assets/icons/keyboard_arrow_right_24px.svg"
        alt="ícone de arrow right"
      />
      <span>{{ result.children.title }}</span>
    </div>
    <div (click)="details(result)" class="quote">
      <span class="ellipsis-start">(...)</span>
      <span><div [innerHTML]="result.quote | safeHtml"></div></span>
      <span class="ellipsis-end">(...)</span>
    </div>
  </div>
</ng-container>

<ng-template #result>
  <app-help-center-topics-result [original]="original">
  </app-help-center-topics-result>
</ng-template>
