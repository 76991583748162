<div class="header">
  <div class="back-button" (click)="back()">
    <mat-icon class="return-icon"> navigate_before </mat-icon>
    <span class="title">{{ 'HELP_CENTER.ABOUT_SYSTEM' | translate }}</span>
  </div>
</div>
<div class="content">
  <div class="container">
    <div
      *ngFor="let releaseNote of releaseNotesHistory"
      class="button"
      (click)="OpenReleaseNotes(releaseNote.releaseNotesRoute)"
    >
      <span class="title">{{ releaseNote.releaseName }}</span>
    </div>
  </div>
</div>
<div class="footer">
  <div class="description">
    <span> Versão: {{ version }} (Atualizado em {{ dateVersion }}) </span>
  </div>
  <div class="description">
    <span>
      GCM - {{ 'HELP_CENTER.GCM_TEXT' | translate }} | Ano
      {{ Date.getFullYear() }}
    </span>
  </div>
</div>
