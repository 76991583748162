<div class="container-dialog">
  <div class="container-body">
    <div class="header">
      <span class="title">{{ data?.title | translate }}</span>
      <span class="subtitle subtitle-first-part">{{
        data?.subtitle | translate
      }}</span>
    </div>
    <div class="body-dialog">
      <div class="image-section">
        <img
          class="card"
          [src]="data?.src"
          alt="card que representa um cartão da Vale"
        />
      </div>
      <div class="body-content">
        <div class="modal-content">
          <ng-container [ngSwitch]="contentType">
            <ng-container *ngSwitchCase="'string'">
              <div class="box">
                <div [innerHTML]="content"></div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'template'">
              <ng-container
                *ngTemplateOutlet="content; context: context"
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'component'">
              <ng-container *ngComponentOutlet="content"></ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="buttons-container" *ngIf="!data?.confirmButton">
      <button app-vale-button (click)="close()">
        {{ 'RELEASE_ACCESS.CANCEL' | translate }}
      </button>
      <button
        app-vale-flat-button
        color="primary"
        (click)="confirm()"
        [disabled]="isDisabledConfirmButton()"
      >
        {{ 'RELEASE_ACCESS.CONFIRM' | translate }}
      </button>
    </div>
    <div class="buttons-container-confirm" *ngIf="data?.confirmButton">
      <button app-vale-flat-button color="primary" (click)="close()">
        {{ 'RELEASE_ACCESS.CLOSE' | translate }}
      </button>
    </div>
  </div>
</div>
