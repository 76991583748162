import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Extensions } from '../../shared/extensions';
import { EmployeeProtocol } from '../../shared/models/employee-protocol.model';
import { MonitoringCenterFilter } from '../../shared/models/monitoring-center-filter.model';
import { MonitoringCenterProtocols } from '../../shared/models/monitoring-center-protocols.model';
import { PatientInfoMonitoringCenter } from '../../shared/models/patient-info-monitoring-center.model';
import { SsoLocationModel } from '../../shared/models/sso-location.model';
import { BaseService } from './base.service';

@Injectable()
export class MonitoringCenterService extends BaseService {
  public uri: string = environment.api;

  private readonly baseUrl = 'monitoring-center';
  private readonly idiom;

  constructor(httpClient: HttpClient) {
    super(httpClient, null, environment.api);
    this.idiom = localStorage
      .getItem(Extensions.STORAGE_KEYS.CULTURE)
      .replace(/"/g, '');
    if (this.idiom === 'pt' || this.idiom === 'pt-MZ') {
      this.idiom = 'pt-BR';
    }
  }

  public getProtocols(locations: SsoLocationModel[]): Observable<any> {
    const ssoLocations = [];
    if (locations != null) {
      locations.forEach((l) => ssoLocations.push(l.id.toString()));
    }
    return this.get<any>(`${this.baseUrl}/protocols`, {
      SsoLocations: ssoLocations,
    });
  }

  public getEmployeesByProtocol(protocol: number, locations): Observable<any> {
    const ssoLocations = [];
    if (locations != null) {
      locations.forEach((l) => ssoLocations.push(l.id.toString()));
    }
    return this.get<any>(`${this.baseUrl}/employees`, {
      SsoLocations: ssoLocations,
      MonitoringCenterProtocol: protocol.toString(),
    });
  }

  public getProtocolsByFilter(
    monitoringCenterFilter: MonitoringCenterFilter
  ): Observable<MonitoringCenterProtocols> {
    return this.get<MonitoringCenterProtocols>(
      `${this.baseUrl}/monitoring-center-protocols`,
      monitoringCenterFilter
    );
  }

  public getHeadersByFilter(
    monitoringCenterFilter: MonitoringCenterFilter,
    protocolId: number
  ): Observable<EmployeeProtocol> {
    return this.get<EmployeeProtocol>(
      `${this.baseUrl}/monitoring-center-info/protocol/${protocolId}`,
      monitoringCenterFilter
    );
  }

  public getPatientInfoByEmployeeId(
    employeeId: number,
    monitoringCenterFilter: MonitoringCenterFilter
  ): Observable<PatientInfoMonitoringCenter> {
    return this.get<PatientInfoMonitoringCenter>(
      `${this.baseUrl}/patient-info/${employeeId}/${this.idiom}`,
      monitoringCenterFilter
    );
  }

  public getPatientHospitalized(employeeId: number): Observable<string> {
    return this.get<string>(
      `${this.baseUrl}/patient-info/hospitalized/${employeeId}/${this.idiom}`
    );
  }

  public getHistoryByEmployeeId(
    employeeId: number,
    monitoringCenterFilter: MonitoringCenterFilter
  ): Observable<any> {
    return this.get<any>(
      `${this.baseUrl}/employee/${employeeId}/monitoring-center-history`,
      monitoringCenterFilter
    );
  }
}
