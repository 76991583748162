import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { UserBusiness } from '../business/user-business.service';
import { ReturnDialogButton } from '../components/return-dialog/return-dialog-button.model';
import { ReturnDialogComponent } from '../components/return-dialog/return-dialog.component';
import { ReturnDialog } from '../components/return-dialog/return-dialog.model';
import { HomeOptionsEnum } from '../enums/home-options.enum';

export class ButtonConfig {
  text: string;
  action?: any;
  result?: any;
}

export class DialogConfig {
  title: string;
  message: string;
  actionType?: string;
  mainButton?: ButtonConfig;
  secondButton?: ButtonConfig;
  closeButton?: ButtonConfig;
}

@Injectable()
export class DialogService {
  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _translate: TranslateService,
    private _userBusiness: UserBusiness
  ) {}

  public callErrorDialog(
    title: string,
    message: string,
    homeOptionType: HomeOptionsEnum
  ): MatDialogRef<any> {
    const url = this._selectRouter();
    const buttons = new Array<ReturnDialogButton>(
      new ReturnDialogButton({
        name: this._translate.instant('CLOSE'),
        className:
          'button-classic button-classic--complete button-classic--background-red',
        action: () => {
          this._navigateByUrlAndHomeOptionType(url, homeOptionType);
        },
      })
    );

    return this._dialog.open(ReturnDialogComponent, {
      disableClose: true,
      data: new ReturnDialog({
        actionType: 'error',
        title: this._translate.instant(title),
        message: this._translate.instant(message),
        path: './assets/images/error.svg',
        buttons: buttons,
        closeActionButton: () => {
          this._navigateByUrlAndHomeOptionType(url, homeOptionType);
        },
      }),
    });
  }

  public callSuccessDialogWithAction$(
    dialogConfig: DialogConfig
  ): MatDialogRef<any> {
    const buttons = this._getButtonsSuccessConfig(dialogConfig);
    return this._dialog.open(ReturnDialogComponent, {
      disableClose: true,
      data: new ReturnDialog({
        actionType: 'success',
        title: this._translate.instant(dialogConfig.title),
        message: this._translate.instant(dialogConfig.message),
        path: './assets/images/success.svg',
        buttons: buttons,
        closeActionButton: () => {
          this._closeDialogAndExecuteActionButtonClose(dialogConfig);
        },
      }),
    });
  }

  public callSuccessDialogWithAction(
    title: string,
    message: string,
    cb1,
    cb2
  ): MatDialogRef<any> {
    const buttons = new Array<ReturnDialogButton>(
      new ReturnDialogButton({
        name: this._translate.instant('DIALOG.BUTTON_RETURN_HOME'),
        className: 'button-classic button-classic--background-white-blueText',
        action: () => {
          cb1();
          this._dialog.closeAll();
        },
      }),
      new ReturnDialogButton({
        name: this._translate.instant('DIALOG.BUTTON_CONTINUE_MEDICAL_RECORD'),
        className:
          'button-classic button-classic--complete button-classic--background-blue',
        action: () => {
          cb2();
          this._dialog.closeAll();
        },
      })
    );

    return this._dialog.open(ReturnDialogComponent, {
      disableClose: true,
      data: new ReturnDialog({
        actionType: 'success',
        title: this._translate.instant(title),
        message: this._translate.instant(message),
        path: './assets/images/success.svg',
        buttons: buttons,
        closeActionButton: () => {
          cb2();
          this._dialog.closeAll();
        },
      }),
    });
  }

  public callSuccessDialog(title: string, message: string): MatDialogRef<any> {
    const buttons = new Array<ReturnDialogButton>(
      new ReturnDialogButton({
        name: this._translate.instant('DIALOG.BUTTON_RETURN_HOME'),
        className: 'button-classic button-classic--background-white-blueText',
        action: () => {
          this._router.navigate(['/']);
          this._dialog.closeAll();
        },
      }),
      new ReturnDialogButton({
        name: this._translate.instant('DIALOG.BUTTON_CONTINUE_MEDICAL_RECORD'),
        className:
          'button-classic button-classic--complete button-classic--background-blue',
        action: () => {
          this._dialog.closeAll();
        },
      })
    );

    return this._dialog.open(ReturnDialogComponent, {
      disableClose: true,
      data: new ReturnDialog({
        actionType: 'success',
        title: this._translate.instant(title),
        message: this._translate.instant(message),
        path: './assets/images/success.svg',
        buttons: buttons,
        closeActionButton: () => {
          this._dialog.closeAll();
        },
      }),
    });
  }

  public callSuccessDialogRedirectToHomeOption(
    title: string,
    message: string,
    homeOptionType: HomeOptionsEnum
  ): MatDialogRef<any> {
    const url = this._selectRouter();
    const buttons = new Array<ReturnDialogButton>(
      new ReturnDialogButton({
        name: 'Fechar', // [TO DO] - TRADUZIR
        className:
          'button-classic button-classic--complete button-classic--background-blue',
        action: () => {
          this._navigateByUrlAndHomeOptionType(url, homeOptionType);
        },
      })
    );

    return this._dialog.open(ReturnDialogComponent, {
      disableClose: true,
      data: new ReturnDialog({
        actionType: 'success',
        title: this._translate.instant(title),
        message: this._translate.instant(message),
        path: './assets/images/success.svg',
        buttons: buttons,
        closeActionButton: () => {
          this._navigateByUrlAndHomeOptionType(url, homeOptionType);
        },
      }),
    });
  }

  public callSuccessDialog$(dialogConfig: DialogConfig): MatDialogRef<any> {
    const buttons = this._getButtonsSuccessConfig(dialogConfig);

    return this._dialog.open(ReturnDialogComponent, {
      disableClose: true,
      data: new ReturnDialog({
        actionType: dialogConfig.actionType || 'success',
        title: this._translate.instant(dialogConfig.title),
        message: this._translate.instant(dialogConfig.message),
        path: './assets/images/success.svg',
        buttons: buttons,
        closeActionButton: () => {
          this._closeDialogAndExecuteActionButtonClose(dialogConfig);
        },
      }),
    });
  }

  public callErrorDialog$(dialogConfig: DialogConfig): MatDialogRef<any> {
    const classNameMainButton =
      'button-classic button-classic--complete button-classic--background-red';
    const classNameSecondButton =
      'button-classic button-classic--complete button-classic--background-white-red';
    const buttons = this._getButtonsSuccessConfig(
      dialogConfig,
      classNameMainButton,
      classNameSecondButton
    );

    return this._dialog.open(ReturnDialogComponent, {
      disableClose: true,
      data: new ReturnDialog({
        actionType: 'error',
        title: this._translate.instant(dialogConfig.title),
        message: this._translate.instant(dialogConfig.message),
        path: './assets/images/error.svg',
        buttons: buttons,
        closeActionButton: () => {
          this._closeDialogAndExecuteActionButtonClose(dialogConfig);
        },
      }),
    });
  }

  public callWarningDialog(dialogConfig: DialogConfig): MatDialogRef<any> {
    const buttons = this._getButtonsWarningConfig(dialogConfig);

    return this._dialog.open(ReturnDialogComponent, {
      disableClose: true,
      data: new ReturnDialog({
        actionType: 'error',
        title: this._translate.instant(dialogConfig.title),
        message: this._translate.instant(dialogConfig.message),
        path: './assets/images/remove.svg',
        buttons: buttons,
        closeActionButton: () => {
          this._closeDialogAndExecuteActionButtonClose(dialogConfig);
        },
      }),
    });
  }

  public callAttentionDialog$(dialogConfig: DialogConfig): MatDialogRef<any> {
    const buttons = this._getButtonsSuccessConfig(dialogConfig);

    return this._dialog.open(ReturnDialogComponent, {
      disableClose: true,
      data: new ReturnDialog({
        actionType: 'rectification',
        title: this._translate.instant(dialogConfig.title),
        message: this._translate.instant(dialogConfig.message),
        path: './assets/images/attention.svg',
        buttons: buttons,
        closeActionButton: () => {
          this._closeDialogAndExecuteActionButtonClose(dialogConfig);
        },
      }),
    });
  }

  public openGoBackDialog(): MatDialogRef<any> {
    const buttons = this._getGoBackButtonsSuccessConfig();

    return this._dialog.open(ReturnDialogComponent, {
      disableClose: true,
      data: new ReturnDialog({
        actionType: 'success',
        title: this._translate.instant('DIALOG.GO_BACK.TITLE'),
        message: this._translate.instant('DIALOG.GO_BACK.MESSAGE'),
        path: './assets/images/attention.svg',
        buttons: buttons,
        closeActionButton: () => {
          this._dialog.closeAll();
        },
      }),
    });
  }

  public openSnackBar(message: string): void {
    this._snackBar.open(this._translate.instant(message), 'FECHAR', {
      duration: 4000,
    });
  }

  private _navigateByUrlAndHomeOptionType(
    url: string,
    homeOptionType: HomeOptionsEnum
  ) {
    this._router.navigate([url], {
      state: {
        selectedOption: this._getHomeOption() || homeOptionType,
      },
    });
    this._dialog.closeAll();
  }

  private _closeDialogAndExecuteActionButtonClose(dialogConfig: DialogConfig) {
    if (dialogConfig.closeButton) {
      dialogConfig.closeButton.action();
    }
    this._dialog.closeAll();
  }

  private _selectRouter() {
    let url = 'search';
    if (this._getHomeOption() === HomeOptionsEnum.MEDICAL_RECORD) {
      url = 'search';
    }
    if (this._getHomeOption() === HomeOptionsEnum.MONITORING_CENTER) {
      url = 'new-monitoring-center';
    }
    return url;
  }

  private _getHomeOption(): HomeOptionsEnum {
    return this._userBusiness.getHomeOption();
  }

  private _getButtonsSuccessConfig(
    dialogConfig: DialogConfig,
    classNameMainButton?: string,
    classNameSecondButton?: string
  ): Array<ReturnDialogButton> {
    const buttons = new Array<ReturnDialogButton>();
    if (dialogConfig.secondButton) {
      const b = new ReturnDialogButton({
        name: this._translate.instant(
          dialogConfig.secondButton.text || 'DIALOG.BUTTON_CLOSE'
        ),
        className: classNameSecondButton
          ? classNameSecondButton
          : 'button-classic button-classic--background-white-blueText',
        action: () => {
          dialogConfig.secondButton.action();
        },
        result: dialogConfig.secondButton.result,
      });
      buttons.push(b);
    }

    if (dialogConfig.mainButton) {
      const b = new ReturnDialogButton({
        name: this._translate.instant(
          dialogConfig.mainButton.text || 'DIALOG.BUTTON_CONFIRM'
        ),
        className: classNameMainButton
          ? classNameMainButton
          : 'button-classic button-classic--complete button-classic--background-blue',
        action: () => {
          dialogConfig.mainButton.action();
        },
        result: dialogConfig.mainButton.result,
      });
      buttons.push(b);
    }
    return buttons;
  }

  private _getButtonsWarningConfig(
    dialogConfig: DialogConfig
  ): Array<ReturnDialogButton> {
    const buttons = new Array<ReturnDialogButton>();
    if (dialogConfig.secondButton) {
      const b = new ReturnDialogButton({
        name: this._translate.instant(
          dialogConfig.secondButton.text || 'DIALOG.BUTTON_CLOSE'
        ),
        className: 'button-classic button-classic--background-white-red',
        action: () => {
          dialogConfig.secondButton.action();
        },
        result: dialogConfig.secondButton.result,
      });
      buttons.push(b);
    }

    if (dialogConfig.mainButton) {
      const b = new ReturnDialogButton({
        name: this._translate.instant(
          dialogConfig.mainButton.text || 'DIALOG.BUTTON_CONFIRM'
        ),
        className:
          'button-classic button-classic--complete button-classic--background-red',
        action: () => {
          dialogConfig.mainButton.action();
        },
        result: dialogConfig.mainButton.result,
      });
      buttons.push(b);
    }
    return buttons;
  }

  private _getGoBackButtonsSuccessConfig(): Array<ReturnDialogButton> {
    const buttons = new Array<ReturnDialogButton>();
    const b = new ReturnDialogButton({
      name: this._translate.instant('DIALOG.GO_BACK.CANCEL'),
      className: 'button-classic button-classic--background-white-blueText',
      action: () => {},
      result: false,
    });
    buttons.push(b);

    const b1 = new ReturnDialogButton({
      name: this._translate.instant('DIALOG.GO_BACK.CONFIRM'),
      className:
        'button-classic button-classic--complete button-classic--background-blue',
      action: () => {},
      result: true,
    });
    buttons.push(b1);
    return buttons;
  }
}
