<label>
  <ng-content></ng-content>
</label>
<div class="wrapper-field">
  <ng-content select="[valePrefix]"></ng-content>
  <ng-content select="input,textarea"></ng-content>
  <ng-content select="[valeSuffix]"></ng-content>
</div>
<ng-container *ngIf="invalid">
  <div class="error-message">
    {{ invalidText }}
  </div>
</ng-container>
<ng-container *ngIf="!invalid && hintText">
  <div class="hint-message">
    {{ hintText }}
  </div>
</ng-container>
