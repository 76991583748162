import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { PatientService } from '../../../core/services/patient.service';
import { UserBusiness } from '../../../shared/business/user-business.service';
import { PatientReleaseModel } from '../../../shared/models/patient-release.model';
@Component({
  selector: 'app-patient-release-access',
  templateUrl: './patient-release-access.component.html',
  styleUrls: ['./patient-release-access.component.scss'],
})
export class PatientReleaseAccessComponent implements OnInit, OnDestroy {
  public periodIsSelected: boolean;
  public optionOtherIsSelected: boolean;
  public hasAccess: boolean;
  public dateNow = new Date();
  public minInitialDate = new Date(
    this.dateNow.setDate(this.dateNow.getDate() + 1)
  );
  public maxInitialDate = new FormControl();
  public initialDateValue = new FormControl();
  public finalDateValue = new FormControl();
  public patientReleaseData = new PatientReleaseModel();
  public userFullName: string;
  public initialDate: any;
  public finalDate: any;
  public buttonForm = new FormControl(null);
  public maxFinalDate: any;
  public equalDates: boolean;
  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PatientReleaseAccessComponent>,
    public patientService: PatientService,
    private userBusiness: UserBusiness,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit() {
    if (this.data.releaseResponse) {
      this.userFullName = this.data.releaseResponse.userFullName;
      this.initialDate = this.data.releaseResponse.initialDate;
      this.finalDate = this.data.releaseResponse.finalDate;
      this.equalDates = this.compareDates();
    }
    this.periodIsSelected = false;
    this.optionOtherIsSelected = false;
    const dateNowToManipulateInitial = new Date();
    const initial = new Date(
      dateNowToManipulateInitial.setDate(
        dateNowToManipulateInitial.getDate() + 1
      )
    );
    this.initialDateValue.setValue(initial);
    this.buttonForm.valueChanges.subscribe((value) => {
      this.isButtonActive(value);
    });
  }

  public compareDates() {
    return this.initialDate.substring(0, 10) == this.finalDate.substring(0, 10);
  }

  public clickButtonOneDay() {
    this.periodIsSelected = true;
    this.optionOtherIsSelected = false;
    const dateNowToManipulateFinal = new Date();
    const final = new Date(
      dateNowToManipulateFinal.setDate(dateNowToManipulateFinal.getDate() + 1)
    );
    this.finalDateValue.setValue(final, { emitEvent: false });
  }

  public clickButtonTwoDays() {
    this.periodIsSelected = true;
    this.optionOtherIsSelected = false;
    const dateNowToManipulateFinal = new Date();
    const final = new Date(
      dateNowToManipulateFinal.setDate(dateNowToManipulateFinal.getDate() + 2)
    );
    this.finalDateValue.setValue(final, { emitEvent: false });
  }

  public clickButtonOtherDay() {
    const dateNowToManipulateInitial = cloneDeep(this.initialDateValue.value);
    let final;
    if (moment.isMoment(dateNowToManipulateInitial)) {
      final = new Date(
        dateNowToManipulateInitial
          .toDate()
          .setDate(dateNowToManipulateInitial.toDate().getDate() + 2)
      );
      this.maxFinalDate = final;
    } else {
      final = new Date(
        dateNowToManipulateInitial.setDate(
          dateNowToManipulateInitial.getDate() + 2
        )
      );
      this.maxFinalDate = final;
    }
    this.finalDateValue.setValue(final, { emitEvent: false });
    this.subscriptions.add(
      this.initialDateValue.valueChanges.subscribe((value) => {
        if (value) {
          const _value = new Date(value);
          _value.setDate(value._d.getDate() + 2);
          this.finalDateValue.setValue(_value, { emitEvent: false });
          this.maxFinalDate = _value;
        }
      })
    );
    this.periodIsSelected = true;
    this.optionOtherIsSelected = true;
  }

  public closeDialog() {
    this.dialogRef.close(true);
  }

  public isButtonActive(typeButton: string): void {
    switch (typeButton) {
      case 'one':
        this.clickButtonOneDay();
        break;
      case 'two':
        this.clickButtonTwoDays();
        break;
      case 'other':
        this.clickButtonOtherDay();
        break;
      default:
        break;
    }
  }

  public savePatientRelease() {
    this.patientReleaseData.initialDate = this.initialDateValue.value;
    this.patientReleaseData.finalDate = this.finalDateValue.value;
    this.patientReleaseData.employeeId = this.data.employeeId;
    this.getLoggedUserInfo();
    this.subscriptions.add(
      this.patientService.saveRelease(this.patientReleaseData).subscribe(
        (response) => {
          this.snackBar.open('Liberação salva com sucesso', 'Fechar', {
            duration: 5000,
          });
          this.closeDialog();
        },
        (err) => {
          this.snackBar.open(
            'Não foi possível salvar. Tente novamente',
            'Fechar',
            {
              duration: 5000,
            }
          );
        }
      )
    );
  }

  private getLoggedUserInfo() {
    this.subscriptions.add(
      this.userBusiness.getLoggedUser$.subscribe((result: any) => {
        this.patientReleaseData.user = result.userIamId;
        this.patientReleaseData.userFullName = result.userName;
      })
    );
  }

  public isDate(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
