import { Injectable } from '@angular/core';

@Injectable()
export class GoogleAnalyticsService {
  constructor() {}

  public eventEmitter(
    eventCategory: string,
    eventAction: string,
    eventLabel: string,
    eventValue: number,
    fieldsObject: object = null
  ): void {
    (window as any).ga(
      'send',
      'event',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      fieldsObject
    );
  }
}
