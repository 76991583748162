export class PatientTypeConstant {
  public static VALE = 1;
  public static OUTSOURCED = 2;
  public static DEPENDENT = 3;
  public static RETIRED = 4;
}

export enum PatientTypeEnum {
  VALE = 1,
  OUTSOURCED = 2,
  DEPENDENT = 3,
  RETIRED = 4,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PatientTypeEnum {
  export function toString(mode: PatientTypeEnum): string {
    return PatientTypeEnum[mode];
  }

  export function parse(mode: string): PatientTypeEnum {
    return PatientTypeEnum[mode];
  }
}

export enum PatientCategoryEnum {
  CONTRIBUTORS = 1,
  OTHER_BENEFICIARIES = 2,
}
