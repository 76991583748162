import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-patient-info-retired-data',
  templateUrl: './patient-info-retired-data.component.html',
  styleUrls: ['./patient-info-retired-data.component.scss'],
})
export class PatientInfoRetiredDataComponent implements OnInit {
  @Input() public formData: FormGroup;
  constructor() {}

  ngOnInit(): void {}
}
