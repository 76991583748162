import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-profile-denied',
  templateUrl: './profile-denied.component.html',
  styleUrls: ['./profile-denied.component.scss'],
})
export class ProfileDeniedComponent implements OnInit {
  private language =
    // eslint-disable-next-line dot-notation
    window.navigator['userLanguage'] || window.navigator.language;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private authenticationService: AuthenticationService
  ) {
    this.translate.use(this.language);
    this.translate.setDefaultLang('pt-BR');
  }

  public ngOnInit() {}

  public redirectButton() {
    window.open('https://portal-iam-prod.valeglobal.net/IDMProv/');
    this.authenticationService.doLogout();
    localStorage.clear();
  }
}
