export class AppConstant {
  public static INACTIVE_TIME_USER_IN_SECONDS = 20;
  public static INTERVAL_SECONDS_INACTIVE_TIME_USER = 1000;
  public static FILTER_OPERATIONS = {
    ADMIN_CENTER: 'admin-center',
    MEDICAL_RECORD: 'medical-record',
    QUICK_EXAM: 'quick-exam',
  };

  public static iconNamesSvg: string[] = [
    '255-brazil',
    'access-denied-door',
    'accessibility',
    'accessibility-red',
    'accessibility-green',
    'accessibility-blue',
    'add',
    'add-absence',
    'add-illustration',
    'anchor',
    'angleRight',
    'arrow-down',
    'arrow-up',
    'attention-gray',
    'badge',
    'bg-card-repass',
    'bg-card-team',
    'bg-card-user',
    'bg-card-utilities',
    'black-warning',
    'blue-check',
    'calendar',
    'calendar2',
    'calendarIcon',
    'centralDeMonitoramento',
    'checkbox-gray',
    'checkbox-green',
    'checkbox-red',
    'checkbox-white',
    'chrome-icon',
    'clear',
    'clickedListIcon',
    'close',
    'close-round',
    'comments',
    'config',
    'configIcon',
    'confirm_death_medical_record',
    'confirm_save_medical_record',
    'contact-activated',
    'contact-disabled',
    'cross',
    'delete',
    'dependent',
    'dependent_click',
    'dependent-icon',
    'dependent-icon-click',
    'edit',
    'edit-pencil',
    'employee',
    'employee_not_found',
    'employee-click',
    'EmptyRegister',
    'error',
    'error_save_medical_record',
    'error-register',
    'error-test',
    'filter-list-24px',
    'fire',
    'fireDisabled',
    'gGroup5',
    'green-check',
    'history-activated',
    'history-disabled',
    'Iconcontainer',
    'info',
    'inherit',
    'left-arrow',
    'left-arrow-blue',
    'listChecked',
    'listChildren',
    'logout',
    'lock',
    'Logotipo_Vale',
    'logo-vale',
    'option-absence-control',
    'Outline',
    'outsourced',
    'outsourced_click',
    'outsourced-icon',
    'outsourced-icon-click',
    'patient-activated',
    'patient-disabled',
    'phone',
    'play',
    'pombas',
    'prontuarioOnline',
    'protocol1',
    'protocol2',
    'protocol3',
    'protocol4',
    'protocoloDois',
    'protocoloTres',
    'protocoloUm',
    'recordImg',
    'red-warning',
    'Remove',
    'retired',
    'retired_click',
    'retired-icon',
    'retired-icon-click',
    'right-arrow',
    'rootList',
    'search',
    'settings',
    'search-tool-online-record',
    'search-tool-quick-exam',
    'success',
    'success-register',
    'success-test',
    'Support',
    'system-manager',
    'testeRapido',
    'time',
    'unclickedListIcon',
    'upload',
    'yellow-warning',
    'trash',
    'ura-access-card-doodle',
    'monitoring',
    'system-manager-man',
    'heart-plus',
    'clipboard',
    'download',
  ];

  public static STATES = {
    PATIENT_HEALTH_INFO: 'PATIENT_HEALTH_INFO',
    PATIENT_HEALTH_INFO_UPDATE_VACCINE: 'PATIENT_HEALTH_INFO_UPDATE_VACCINE',
    PATIENT_HEALTH_INFO_CARD_SITUATION: 'PATIENT_HEALTH_INFO_CARD_SITUATION',
    PATIENT_HEALTH_INFO_TIMELINE: 'PATIENT_HEALTH_INFO_TIMELINE',
  };
}
