import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-patient-info-vale-data',
  templateUrl: './patient-info-vale-data.component.html',
  styleUrls: ['./patient-info-vale-data.component.scss'],
})
export class PatientInfoValeDataComponent implements OnInit {
  @Input() public formData: FormGroup;
  constructor() {}

  ngOnInit(): void {}
}
