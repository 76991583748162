<div class="home-container">
  <div class="header-container">
    <span class="title">{{ 'HELP_CENTER.HELP_CENTER' | translate }}</span>
    <mat-icon class="close-icon" (click)="closeHelpCenter()">close</mat-icon>
    <app-vale-label class="search-bar">
      <app-vale-icon valePrefix iconSrc="search_lupe.svg"></app-vale-icon>
      <input
        valeInput
        [formControl]="search"
        placeholder="{{ 'HELP_CENTER.CAN_WE_HELP' | translate }}"
      />
    </app-vale-label>
  </div>

  <ng-container [ngSwitch]="optionPage">
    <app-help-center-menu
      (page)="selectPage($event)"
      *ngSwitchCase="'menu'"
      class="body-container"
    ></app-help-center-menu>
    <app-help-center-topics
      class="topics"
      *ngSwitchCase="'topics'"
      [topics]="topics"
      [quotes]="quotes"
      [term]="term"
      [showAllTopics]="showAllTopics"
      (returnMenu)="returnMenu()"
    ></app-help-center-topics>
    <app-help-center-about *ngSwitchCase="'about'" (returnMenu)="returnMenu()">
    </app-help-center-about>
    <app-helper-center-customer-satisfaction
      *ngSwitchCase="'csat'"
      (returnMenu)="returnMenu()"
    >
    </app-helper-center-customer-satisfaction>
  </ng-container>
</div>
