import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export class BaseService {
  public prefixoApi: string;

  constructor(
    private readonly http: HttpClient,
    private readonly area?: string,
    prefixoApi?: string
  ) {
    this.prefixoApi = prefixoApi;
  }

  protected get<T>(route: string | any[], parameters?: object): Observable<T> {
    const httpParams = this.getHttpParams(parameters);
    const url = this.getRoute(route);

    return this.http.get(url, { params: httpParams }) as Observable<T>;
  }

  protected post<T>(
    route: string | any[],
    body?: object,
    parameters?: object,
    headers?: object
  ): Observable<T> {
    const httpParams = this.getHttpParams(parameters);
    const httpHeaders = this.getHttpHeaders(headers);
    const url = this.getRoute(route);

    return this.http.post(url, body, {
      params: httpParams,
      headers: httpHeaders,
    }) as Observable<T>;
  }

  protected patch<T>(
    route: string | any[],
    body?: object,
    parameters?: object,
    headers?: object
  ): Observable<T> {
    const httpParams = this.getHttpParams(parameters);
    const httpHeaders = this.getHttpHeaders(headers);
    const url = this.getRoute(route);

    return this.http.patch(url, body, {
      params: httpParams,
      headers: httpHeaders,
    }) as Observable<T>;
  }

  protected put<T>(
    route: string | any[],
    body?: object,
    parameters?: object
  ): Observable<T> {
    const httpParams = this.getHttpParams(parameters);
    const url = this.getRoute(route);

    return this.http.put(url, body, { params: httpParams }) as Observable<T>;
  }

  protected delete<T>(
    route: string | any[],
    parameters?: object
  ): Observable<T> {
    const httpParams = this.getHttpParams(parameters);
    const url = this.getRoute(route);

    return this.http.delete(url, { params: httpParams }) as Observable<T>;
  }

  private getRoute(route: string | any[]): string {
    if (!Array.isArray(route)) {
      route = [route];
    }

    if (this.area) {
      route.splice(0, 0, this.area);
    }

    return this.prefixoApi + route.join('/');
  }

  private getHttpParams(parameters: object): HttpParams {
    let params = new HttpParams();

    if (parameters) {
      Object.keys(parameters).forEach((key) => {
        if (Array.isArray(parameters[key])) {
          parameters[key].forEach((param: { toString: () => string }) => {
            if (param) {
              params = params.append(key, param.toString());
            }
          });
        } else if (parameters[key]) {
          params = params.append(key, parameters[key].toString());
        }
      });
    }
    return params;
  }

  private getHttpHeaders(parameters: object): HttpHeaders {
    let headers = new HttpHeaders();

    if (parameters) {
      Object.keys(parameters).forEach((key) => {
        if (Array.isArray(parameters[key])) {
          parameters[key].forEach((param: { toString: () => string }) => {
            if (param) {
              headers = headers.append(key, param.toString());
            }
          });
        } else if (parameters[key]) {
          headers = headers.append(key, parameters[key].toString());
        }
      });
    }
    return headers;
  }
}
