import * as moment from 'moment';

import { TimeRangeModel } from '../models/time-range.model';

export class DateTimeUtil {
  public static getStringDateTimeNow(): string {
    return moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
  }

  public static getStringDateNow(): string {
    return moment(new Date()).format('YYYY-MM-DD');
  }

  public static getStringDateFromDate(d: Date): string {
    return moment(d).format('YYYY-MM-DD');
  }

  public static getDateStringFromDate(date: Date): string {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  }

  public static getDateFromString(date: string): Date {
    return moment(date, 'YYYY-MM-DDTHH:mm:ss').toDate();
  }

  public static formatDate(date: any, format: string): string {
    return moment(date).format(format);
  }

  public static diff(date1: string, date2?: string): number {
    return moment(date1).diff(date2 || this.getStringDateTimeNow());
  }

  public static isBetween(
    dateA: string,
    dateB: string,
    date?: string
  ): boolean {
    return moment(date || this.getStringDateTimeNow()).isBetween(dateA, dateB);
  }

  public static subtractDaysInDate(dateString: string, days: number): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() - days);
    return DateTimeUtil.getStringDateFromDate(date);
  }

  public static setMinutesInDate(dateString: string, minutes: number): string {
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + minutes);
    return DateTimeUtil.getDateStringFromDate(date);
  }

  public static addHours(date: string, hours: number): string {
    return moment(date).add(hours).format();
  }

  public static getTimeRanges(): TimeRangeModel[] {
    const dateTimeRanges: TimeRangeModel[] = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        const startMinutes = j * 15;
        let endMinutes = startMinutes + 15;
        let endHour = i;
        if (endMinutes === 60) {
          endMinutes = 0;
          endHour = (endHour + 1) % 24;
        }
        const startTime =
          i.toString().padStart(2, '0') +
          ':' +
          startMinutes.toString().padStart(2, '0');
        const endTime =
          endHour.toString().padStart(2, '0') +
          ':' +
          endMinutes.toString().padStart(2, '0');
        const timeRange = startTime + ' - ' + endTime;

        const date = new Date();
        date.setHours(i);
        date.setMinutes(startMinutes);
        date.setSeconds(0);

        const timeRangeObject = new TimeRangeModel();
        timeRangeObject.time = date;
        timeRangeObject.timeRange = timeRange;

        dateTimeRanges.push(timeRangeObject);
      }
    }
    return dateTimeRanges;
  }

  public static formatDayOfMonth(
    lastSyncDate: Date | string | number | null | undefined
  ): string {
    if (!lastSyncDate) {
      return '';
    }
    lastSyncDate = new Date(lastSyncDate);
    const monthNames = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ];

    return `${lastSyncDate.getDate()} de ${
      monthNames[lastSyncDate.getMonth()]
    }`;
  }
}
