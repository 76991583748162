import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { CsatDialogComponent } from './components/csat-dialog/csat-dialog.component';
import { ReleaseNotesDialogComponent } from './components/release-notes-dialog/release-notes-dialog.component';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { HomeService } from './home.service';

@NgModule({
  imports: [SharedModule, HomeRoutingModule],
  declarations: [
    HomeComponent,
    ReleaseNotesDialogComponent,
    CsatDialogComponent,
  ],
  providers: [{ provide: 'HomeService', useClass: HomeService }],
})
export class HomeModule {}
