<div
  [class]="
    inUse
      ? 'custom-input-wrapper custom-input-wrapper-disabled'
      : 'custom-input-wrapper'
  "
  multiple
  [class.required]="required"
  #dropreference
>
  <div
    class="custom-input-wrapper-padding-left"
    (click)="disabled ? false : showDropdown()"
  ></div>
  <img
    *ngIf="withIcon"
    style="position: absolute; margin-left: 24px;"
    [src]="icon"
    alt=""
  />
  <img
    (click)="disabled ? false : showDropdown()"
    *ngIf="icon && !withIcon"
    class="custom-icon-input"
    [src]="icon"
    alt=""
  />
  <input
    #input
    placeholder="{{ placeholder }}"
    [disabled]="disabled"
    readonly
    [value]="displayText"
    (click)="showDropdown()"
    (keydown)="onKeyDown($event)"
    (blur)="onTouched()"
    autocomplete="off"
    [class]="inUse ? 'input input-in-use' : 'input'"
  />
  <div
    class="container-dropdown-arrow"
    (click)="disabled ? false : showDropdown()"
  >
    <div class="dropdown-arrow" (click)="onDropMenuIconClick($event)">
      <app-custom-dropdown
        markAll
        checkAll
        [reference]="dropreference"
        #dropdownComp
      >
        <div class="dropdown-options-container">
          <ng-content select="app-custom-select-option"></ng-content>
        </div>
      </app-custom-dropdown>
    </div>
  </div>
  <label class="label-text">{{ label }}</label>
</div>
