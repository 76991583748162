import { Injectable } from '@angular/core';
@Injectable()
export class Storage {
  public getSession<T>(key: string) {
    const sStorage = sessionStorage.getItem(key);
    if (typeof sStorage === 'undefined') {
      return null;
    }
    if (typeof sStorage === 'undefined') {
      return null;
    }

    return JSON.parse(sStorage);
  }

  public setSession<T>(key: string, value: T) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  public delSession(key: string) {
    sessionStorage.removeItem(key);
  }

  public clearSesion() {
    sessionStorage.clear();
  }

  public clearLocal() {
    localStorage.clear();
  }

  public getLocal<T>(key: string) {
    const lStorage = localStorage.getItem(key);
    if (typeof lStorage === 'undefined') {
      return null;
    }
    if (typeof lStorage === 'undefined') {
      return null;
    }

    return JSON.parse(lStorage);
  }

  public setLocal<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public delLocal(key: string) {
    localStorage.removeItem(key);
  }
}
