import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

import { PatientReleaseAccessComponent } from '../modules/patient/patient-release-access/patient-release-access.component';
import { PatientRevokeAccessComponent } from '../modules/patient/patient-revoke-access/patient-revoke-access.component';
import { SendEmailManagerComponent } from '../modules/patient/send-email-manager/send-email-manager.component';
import { ActionDialogComponent } from './components/action-dialog/action-dialog.component';
import { AppValeTabGroupComponent } from './components/app-vale-tab-group/app-vale-tab-group.component';
import { CustomDropdownModule } from './components/custom-dropdown/custom-dropdown.module';
import { HeaderComponent } from './components/header/header.component';
import { IncompatibilityComponent } from './components/incompatibility/incompatibility.component';
import { ProfileDeniedComponent } from './components/profile-denied/profile-denied.component';
import { ResultQuickExamComponent } from './components/result-quick-exam/result-quick-exam.component';
import { ReturnDialogComponent } from './components/return-dialog/return-dialog.component';
import { SearchGenericComponent } from './components/search-generic/search-generic.component';
import { SearchNotFoundComponent } from './components/search-not-found/search-not-found.component';
import { ValePatientSearchComponent } from './components/vale-patient-search/vale-patient-search.component';
import { MaterialComponentsModule } from './material.module';
import { HelpCenterModule } from './modules/help-center/help-center.module';
import { PatientInfoModule } from './modules/patient-info/patient-info.module';
import { UpdateEmployeeDataComponent } from './modules/update-employee-data/update-employee-data.component';
import { AnalyticsService } from './services/analytics.service';
import { DialogService } from './services/dialog.service';
import { GenericFilterDialogComponent } from './vale-lib/vale-expansion-filter/components/generic-filter-dialog/generic-filter-dialog.component';
import { ValeLibModule } from './vale-lib/vale-lib.module';

@NgModule({
  declarations: [
    ReturnDialogComponent,
    HeaderComponent,
    ProfileDeniedComponent,
    IncompatibilityComponent,
    AppValeTabGroupComponent,
    PatientReleaseAccessComponent,
    PatientRevokeAccessComponent,
    SearchGenericComponent,
    ActionDialogComponent,
    SearchNotFoundComponent,
    UpdateEmployeeDataComponent,
    ResultQuickExamComponent,
    ValePatientSearchComponent,
    SendEmailManagerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomDropdownModule,
    ValeLibModule,
    MaterialComponentsModule,
    TranslateModule.forChild({
      extend: true,
    }),
    NgxMaskModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialComponentsModule,
    TranslateModule,
    CustomDropdownModule,
    HeaderComponent,
    ProfileDeniedComponent,
    IncompatibilityComponent,
    AppValeTabGroupComponent,
    SearchGenericComponent,
    ActionDialogComponent,
    SearchNotFoundComponent,
    ValeLibModule,
    PatientInfoModule,
    HelpCenterModule,
    ResultQuickExamComponent,
    ValePatientSearchComponent,
  ],
  entryComponents: [
    ReturnDialogComponent,
    PatientReleaseAccessComponent,
    PatientRevokeAccessComponent,
    ActionDialogComponent,
    GenericFilterDialogComponent,
    UpdateEmployeeDataComponent,
    SendEmailManagerComponent,
  ],
  providers: [DialogService, AnalyticsService],
})
export class SharedModule {}
