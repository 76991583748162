<div class="container-dialog">
  <div class="body-dialog">
    <span class="title">{{ 'RELEASE_ACCESS.REVOKE_ACCESS' | translate }}</span>

    <div class="revoke-content-section">
      <div class="image-section">
        <img
          class="card"
          src="../../../../assets/images/patient-red-card-release.svg"
          alt="card que representa que não há liberação de acesso pro paciente"
        />
      </div>
      <div class="text-section">
        <div class="subtitle">
          <span>{{ 'REVOKE_ACCESS.REVOKE_ACCESS_TO_VALE' | translate }}</span>
        </div>
        <div class="released-access-label">
          <span *ngIf="!equalDates">{{
            'RELEASE_ACCESS.RELEASE_LABEL' | translate
          }}</span>
          <span *ngIf="!equalDates"
            >{{ initialDate | date: 'dd/MM/yyyy' }} e
            {{ finalDate | date: 'dd/MM/yyyy' }}.</span
          >
          <span *ngIf="equalDates">{{
            'RELEASE_ACCESS.RELEASE_LABEL_SAME_DATA' | translate
          }}</span>
          <span *ngIf="equalDates">{{ initialDate | date: 'dd/MM/yyyy' }}</span>
        </div>
        <div class="text">
          <span>{{ 'REVOKE_ACCESS.CANCEL_VALE_VIST' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons-container">
    <button [class]="'option-buttons half-cancel'" [mat-dialog-close]="false">
      {{ 'RELEASE_ACCESS.CANCEL' | translate }}
    </button>
    <button class="option-buttons revoke" (click)="revokeAccess()">
      {{ 'REVOKE_ACCESS.REVOKE' | translate }}
    </button>
  </div>
</div>
