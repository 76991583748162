import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { UserPermissionConstant } from '../../shared/constants/user-permission.constant';
import { environment } from './../../../environments/environment';
import { BypassLoginAuthService } from './bypass-login-auth.service';

@Component({
  selector: 'app-bypass-login',
  templateUrl: './bypass-login.component.html',
  styleUrls: ['./bypass-login.component.scss'],
})
export class BypassLoginComponent implements OnInit {
  public bypassLoginForm: FormGroup;
  public userForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    code: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    roles: new FormControl([]),
    country: new FormControl('', [Validators.required]),
    groups: new FormArray([]),
  });

  public groups = this.userForm.get('groups') as FormArray;

  public countries = [
    { name: 'Brasil', code: 'BR' },
    { name: 'Canadá', code: 'CA' },
    { name: 'Malawi', code: 'ML' },
    { name: 'Moçambique', code: 'MZ' },
  ];

  public tools = [
    {
      name: 'Prontuário (Prontuário, Teste rápido e Central de Monitoramento)',
      code: 'MedicalRecord',
    },
    { name: 'Exame rápido', code: 'QuickTest' },
  ];

  public userCountries = [
    { code: 'Brazil', name: 'Brasil' },
    { code: 'Canada', name: 'Canadá' },
    { code: 'Malawi', name: 'Malawi' },
    { code: 'Mozambique', name: 'Moçambique' },
  ];

  public roles = [
    {
      name: 'Administrador',
      code: `${UserPermissionConstant.ADMIN_CENTER_AM}`,
    },
    {
      name: 'Suporte',
      code: `${UserPermissionConstant.SUPPORT_ADMIN_CENTER}`,
    },
    {
      name: 'Gerente',
      code: `${UserPermissionConstant.MANAGER_AM}`,
    },
    {
      name: 'Gestor de cards',
      code: `${UserPermissionConstant.CARD_MANAGEMENT}`,
    },
    {
      name: 'Representante',
      code: `${UserPermissionConstant.REPRESENTATIVE_AM}`,
    },
    {
      name: 'Cards Acesso URA',
      code: `${UserPermissionConstant.URA_ACCESS_CARD}`,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private bypassLoginAuthService: BypassLoginAuthService,
    private router: Router
  ) {}

  public ngOnInit() {
    this.userForm.get('code').setValue('81007304');
    this.userForm.get('name').setValue('RONIE CURTO DA SILVA');
    this.userForm.get('email').setValue('ronie.silva@vale.com');
    this.userForm.get('country').setValue('Brazil');
    this.selectAll();
    this.groups.push(
      this.fb.group({
        tool: new FormControl('MedicalRecord', [Validators.required]),
        country: new FormControl('BR', [Validators.required]),
      })
    );
  }

  public addGroup() {
    this.groups.push(
      this.fb.group({
        tool: new FormControl('', [Validators.required]),
        country: new FormControl('', [Validators.required]),
      })
    );
  }

  public removeGroup(index: number) {
    this.groups.removeAt(index);
  }

  public async doLogin() {
    if (environment.production) {
      this.router.navigate(['/']);
      return false;
    }
    const { name, code, email, country, roles, groups } = this.userForm.value;
    const userGroups = [];
    groups.forEach((element) => {
      const { tool, country } = element;
      if (tool && country) {
        userGroups.push(
          `cn=GCM${element.tool}${element.country},ou=GCM,ou=Groups,o=vale`
        );
      }
    });
    roles.forEach((role) => {
      userGroups.push(`cn=${role},ou=GCM,ou=Groups,o=vale`);
    });
    const user = {
      'result.Country': country,
      'result.Email': email,
      'result.Groups': userGroups,
      'result.Name': name,
      'result.User': code,
      sub: '',
    };
    localStorage.setItem('bypass_login_user', JSON.stringify(user));
    localStorage.setItem('access_token', 'com alguma coisa');
    const threeDays = 259200000;
    const expiresAt = new Date().getTime() + threeDays;
    localStorage.setItem('expires_at', expiresAt.toString());
    await this.bypassLoginAuthService.updateLoggedBypassLoginUser(user);
    this.router.navigate(['/']);
  }

  public getGroup() {
    return this.userForm.get('groups') as FormArray;
  }
  public selectAll() {
    const teste = [];
    this.roles.forEach(function (role: any) {
      teste.push(role.code);
    });
    this.userForm.get('roles').setValue(teste);
  }
}
