export const environment = {
  GAcontainer: 'UA-168983926-3',
  clarity: '71r741sm1w',
  production: false,
  api: '##{apiUrl}##',
  configuration: 'Development',
  version: '20230816.1',
  applicationInsights: {
    isActive: true,
    instrumentationKey: '##{instrumentationKey}##',
  },
};

export const auth = {
  issuer: '##{iamUrl}##/v2.0/',
  loginUrl: '##{iamUrl}##/oauth2/v2.0/authorize',
  tokenEndpoint: '##{apiUrl}##/Authorization/token',
  userinfoEndpoint: 'https://graph.microsoft.com/oidc/userinfo',
  logoutUrl:
    '##{iamLogoutUrl}##?post_logout_redirect_uri=' +
    window.location.origin +
    '/',
  redirectUri: window.location.origin + '/',
  clientId: '##{iamClientId}##',
  responseType: 'code',
  oidc: true,
  skipIssuerCheck: true,
  scope: 'user.read openid profile offline_access',
  showDebugInformation: true,
};
