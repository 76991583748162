<app-vale-loading *ngIf="updatingTimeline"></app-vale-loading>
<div class="container-patient-info-history">
  <div class="container-form">
    <form>
      <div class="filter">
        <app-custom-select
          [ngClass]="fieldName ? '' : 'no-field-name'"
          icon="./assets/icons/filter-list-24px.svg"
          [fieldName]="fieldName | translate"
          [multiple]="true"
          [markAll]="false"
          [formControl]="filteredForm"
        >
          <app-custom-select-option
            [checkAll]="true"
            [key]="0"
            [value]="'Todos'"
          ></app-custom-select-option>
          <app-custom-select-option
            *ngFor="let option of options"
            [key]="option"
            [value]="option.name | translate"
          ></app-custom-select-option>
        </app-custom-select>
      </div>
      <vale-removable-tag
        [outline]="true"
        *ngFor="let field of filteredForm?.value"
        (close)="removableFilterTag(field.id)"
        >{{ field.name | translate }}</vale-removable-tag
      >
      <div class="clear-filetered-form">
        <button
          (click)="clearfilteredForm()"
          app-vale-button
          color="primary"
          *ngIf="filteredForm?.value?.length"
        >
          {{ 'GENERAL.CLEAR' | translate }}
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="!updatingTimeline" class="container-timeline">
    <app-vale-timeline [data]="filteredHistories"></app-vale-timeline>
  </div>
</div>
