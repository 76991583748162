import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

import { BaseAuthenticationService } from '../../core/services/base-authentication.service';
import { UserBusiness } from '../../shared/business/user-business.service';
import { Storage } from '../../shared/util/storage';
import { environment } from './../../../environments/environment';

@Injectable()
export class BypassLoginAuthService extends BaseAuthenticationService {
  public _storage: Storage = new Storage();

  constructor(
    oauthService: OAuthService,
    userBusiness: UserBusiness,
    router: Router
  ) {
    super(oauthService, userBusiness, router);
  }

  public async updateLoggedBypassLoginUser(claim: any) {
    if (claim && !environment.production) {
      try {
        if (!claim['result.Groups']) {
          this.userBusiness.setUser(false);
          throw new Error('Not Allowed');
        }

        const groupMembershipSplitted = this.getGroupMembership(
          claim['result.Groups']
        );

        if (groupMembershipSplitted) {
          const groupArray = groupMembershipSplitted.filter((group) =>
            group.ou.includes('GCM')
          );
          if (groupArray.length === 0) {
            this.userBusiness.setUser(false);
            throw new Error('Not Allowed');
          }
        }

        // obtém os dados do userinfo retornados pelo IAM e transforma as keys do objeto todas em lowercase
        let userObj = {
          userName: claim['result.Name'],
          userIamId: claim['result.User'],
          userEmail: claim['result.Email'],
          userCountry: claim['result.Country'],
          userGroups: groupMembershipSplitted,
          sub: claim.sub,
          isBypassLogin: true,
        };

        userObj = this.getUserCountry(userObj);
        this.userBusiness.setUser(userObj);
      } catch (error) {
        console.log(error);
      }
    }
  }
}
