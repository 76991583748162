import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ReturnDialog } from './return-dialog.model';

@Component({
  selector: 'app-return-dialog',
  templateUrl: './return-dialog.component.html',
  styleUrls: ['./return-dialog.component.scss'],
})
export class ReturnDialogComponent implements OnInit {
  public type: string;
  public title: string;
  public message: string;
  public caminho: string;
  public imgType = 'bigImg';
  public bold: number[];

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ReturnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReturnDialog
  ) {}

  public ngOnInit(): void {
    this.type = this.data.actionType;
    this.title = this.data.title;
    this.caminho = this.data.path;
    this.message = this.data.message;
    this.imgType = this.data.imgType;
  }

  public getClassbyImgType(propertie: string): string {
    return propertie === 'message' ? 'message-bold' : 'mreturn-dialog-image';
  }
}
