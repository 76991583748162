import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PatientTypeEnum } from '../../constants/patient-type.constant';

@Component({
  selector: 'app-vale-dot-profile',
  templateUrl: './vale-dot-profile.component.html',
  styleUrls: ['./vale-dot-profile.component.scss'],
})
export class ValeDotProfileComponent implements OnInit {
  @Input('profile')
  public employeeType: PatientTypeEnum = PatientTypeEnum.VALE;

  constructor(private _translateService: TranslateService) {}

  ngOnInit(): void {}

  public getMessageByEmployeeType(): string {
    return this._translateService.instant(
      `PATIENT_INFO.PATIENT_TYPE.${PatientTypeEnum.toString(
        this.employeeType
      ).toUpperCase()}`
    );
  }

  public getCssClassByEmployeeType(): string {
    return PatientTypeEnum.toString(this.employeeType).toLowerCase();
  }
}
