export class UserPermissionConstant {
  public static MEDICAL_RECORD = 'GCMMedicalRecord';
  public static MEDICAL_RECORD_BR = 'GCMMedicalRecordBR';
  public static QUICK_TEST = 'GCMQuickTest';
  public static ABSENCE_CONTROL = '_AM';
  public static REPRESENTATIVE_AM = 'Representative_AM';
  public static MANAGER_AM = 'Manager_AM';
  public static ADMIN_CENTER_AM = 'Administrator_GCM';
  public static CARD_MANAGEMENT = 'WorkFromHome_GC';
  public static URA_ACCESS_CARD = 'GCMAccessCardURA';
  public static SUPPORT_ADMIN_CENTER = 'GCMSupport';
}
