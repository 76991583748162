import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbsenteeismModel } from '../../modules/absence-control/models/absenteeism.model';
import { Extensions } from '../../shared/extensions';
import { environment } from './../../../environments/environment';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class AbsenceControlService extends BaseService {
  private readonly _baseUrlAbsenceControl = 'absence-control';
  private readonly _idiom;

  constructor(httpClient: HttpClient) {
    super(httpClient, null, environment.api);
    this._idiom = localStorage.getItem(Extensions.STORAGE_KEYS.CULTURE);
    if (this._idiom === 'pt' || this._idiom === 'pt-MZ') {
      this._idiom = 'pt-BR';
    }
  }

  public getEmployeesByFilter(filter: any): Observable<any> {
    return this.get<any>(
      `${this._baseUrlAbsenceControl}/employees-mdm`,
      filter
    );
  }

  public getAbsencesControl(code: string): Observable<any> {
    return this.get<any>(`${this._baseUrlAbsenceControl}`, { code: code });
  }

  public getEmployeesUnderManagement(filter: any): Observable<any> {
    return this.get<any>(
      `${this._baseUrlAbsenceControl}/employees-under-management`,
      filter
    );
  }

  public getWorkShift(): Observable<any> {
    return this.get<any>(`${this._baseUrlAbsenceControl}/work-shift`, {
      idiom: this._idiom,
    });
  }

  public getReasonForAbsence(): Observable<any> {
    return this.get<any>(`${this._baseUrlAbsenceControl}/reasons-absence`, {
      idiom: this._idiom,
    });
  }

  public saveRegisterAbsenceEmployee(
    register: AbsenteeismModel
  ): Observable<any> {
    return this.post<AbsenteeismModel>(
      `${this._baseUrlAbsenceControl}`,
      register
    );
  }

  public editAbsenceEmployee(
    id: number,
    register: AbsenteeismModel
  ): Observable<any> {
    return this.put<AbsenteeismModel>(
      `${this._baseUrlAbsenceControl}/${id}`,
      register
    );
  }

  public editEarlyEmployeeReturnDates(
    id: number,
    register: any
  ): Observable<any> {
    return this.put<any>(
      `${this._baseUrlAbsenceControl}/${id}/early-return-date`,
      register
    );
  }

  public getCountries(): Observable<any> {
    return this.get<any>(`location/countries`, { idiom: this._idiom });
  }

  public getDelegation(code: string): Observable<any> {
    return this.get<any>(`${this._baseUrlAbsenceControl}/delegation/${code}`);
  }

  public patchDelegationsToggleStatus(
    managerCode: string,
    representatives: any
  ): Observable<any> {
    return this.patch<any>(
      `${this._baseUrlAbsenceControl}/delegation/${managerCode}/representatives/status`,
      representatives
    );
  }

  public postSaveDelegations(
    managerCode: string,
    representatives: any
  ): Observable<any> {
    return this.post<any>(
      `${this._baseUrlAbsenceControl}/delegation/${managerCode}/representatives`,
      representatives
    );
  }

  public getRepresentativeByManager(managerCode: string): Observable<any> {
    return this.get<any>(
      `${this._baseUrlAbsenceControl}/delegation/${managerCode}/representatives`
    );
  }

  public disableAbsencebyCycle(
    id: number,
    managerCode: number,
    representatives?: any
  ): Observable<any> {
    return this.patch<any>(
      `${this._baseUrlAbsenceControl}/${id}/manager/${managerCode}/disable-absence-cycle`,
      representatives
    );
  }
}
