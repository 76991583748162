import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  Type,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ValeExpansionFilterDialogService } from '../../vale-expansion-filter-dialog.service';
import { FilterDialogRef } from './generic-filter-dialog-ref';

export interface DataModel {
  title: '';
  disabledClose?: false;
  confirmButton?: false;
}

@Component({
  selector: 'app-generic-filter-dialog',
  templateUrl: './generic-filter-dialog.component.html',
  styleUrls: ['./generic-filter-dialog.component.scss'],
})
export class GenericFilterDialogComponent
  implements OnInit, AfterViewChecked, OnDestroy {
  public data: DataModel;
  public contentType: 'template' | 'string' | 'component';
  public content: string | TemplateRef<any> | Type<any>;
  public context;
  private _subscriptions = new Subscription();
  private _responseDataChild: any;
  constructor(
    private ref: FilterDialogRef,
    private cdref: ChangeDetectorRef,
    private _filterDialogService: ValeExpansionFilterDialogService
  ) {}

  ngAfterViewChecked(): void {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.content = this.ref.content;
    if (typeof this.content === 'string') {
      this.contentType = 'string';
    } else if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
      this.context = {
        close: this.ref.close.bind(this.ref),
      };
    } else {
      this.contentType = 'component';
    }
    this.data = this.ref.data;
    this._subscriptions.add(
      this._filterDialogService.responseDataChild$.subscribe((response) => {
        this._responseDataChild = response;
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public confirm(): void {
    this._configureClose(this._responseDataChild);
  }

  public close(): void {
    this.ref.close(false);
  }

  public isDisabledConfirmButton(): boolean {
    return this.ref.isDisabledConfirmButton;
  }

  private _configureClose(answer: any) {
    if (this.data && this.data.disabledClose) {
      this.ref.sendAnswerWithoutCloseDialog(answer);
    } else {
      this.ref.close(answer);
    }
  }
}
