<div class="container-vale-access-card">
  <div class="card">
    <img [src]="card?.src" alt="{{ card?.alt ? card?.alt : '' }}" />
  </div>
  <div class="info" *ngIf="showInfo">
    <div>
      <span class="card-name">{{ card?.name }}</span>
    </div>
    <div class="reason" *ngIf="card?.message">
      <span class="message">Motivo: {{ card?.message }}</span>
    </div>
  </div>
</div>
