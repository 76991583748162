import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/middleware/auth-guard';
import { IncompatibilityComponent } from './shared/components/incompatibility/incompatibility.component';
import { ProfileDeniedComponent } from './shared/components/profile-denied/profile-denied.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quick-exam',
    loadChildren: () =>
      import('./modules/quick-exam/quick-exam.module').then(
        (m) => m.QuickExamModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'patient',
    loadChildren: () =>
      import('./modules/patient/patient.module').then((m) => m.PatientModule),
    canActivate: [AuthGuard],
  },
  /*{
    path: 'new-monitoring-center',
    loadChildren: () =>
      import(
        './modules/new-monitoring-center/new-monitoring-center.module'
      ).then((m) => m.NewMonitoringCenterModule),
    canActivate: [AuthGuard],
  },*/
  {
    path: 'absence-control',
    loadChildren: () =>
      import('./modules/absence-control/absence-control.module').then(
        (m) => m.AbsenceControlModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile-denied',
    component: ProfileDeniedComponent,
  },
  {
    path: 'incompatibility',
    component: IncompatibilityComponent,
  },
  {
    path: 'bypass-login',
    loadChildren: () =>
      import('./modules/bypass-login/bypass-login.module').then(
        (m) => m.BypassLoginModule
      ),
  },
  {
    path: 'admin-center',
    loadChildren: () =>
      import('./modules/admin-center/admin-center.module').then(
        (m) => m.AdminCenterModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'card-management',
    loadChildren: () =>
      import('./modules/card-management/card-management.module').then(
        (m) => m.CardManagementModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'patient-register',
    loadChildren: () =>
      import('./modules/patient-register/patient-register.module').then(
        (m) => m.PatientRegisterModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'ura-access-card',
    loadChildren: () =>
      import('./modules/ura-access-card/ura-access-card.module').then(
        (m) => m.UraAccessCardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'vale-forms-vaccines',
    loadChildren: () =>
      import('./modules/vale-forms-vaccines/vale-forms-vaccines.module').then(
        (m) => m.ValeFormsVaccinesModule
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
