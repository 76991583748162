import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-incompatibility',
  templateUrl: './incompatibility.component.html',
  styleUrls: ['./incompatibility.component.scss'],
})
export class IncompatibilityComponent implements OnInit {
  private language =
    // eslint-disable-next-line dot-notation
    window.navigator['userLanguage'] || window.navigator.language;

  constructor(private translate: TranslateService) {
    this.translate.use(this.language);
    this.translate.setDefaultLang('pt-BR');
  }

  public ngOnInit(): void {}
}
