<div class="container-dialog">
  <div class="body-dialog">
    <span class="title" *ngIf="!data.releaseResponse">{{
      'RELEASE_ACCESS.TITLE' | translate
    }}</span>
    <span
      class="subtitle subtitle-first-part"
      *ngIf="!data.releaseResponse && !data.releaseResponse"
      >{{ 'RELEASE_ACCESS.SUBTITLE_FIRST_PART' | translate }}</span
    >
    <span
      class="subtitle subtitle-second-part"
      *ngIf="!data.releaseResponse && !data.releaseResponse"
      >{{ 'RELEASE_ACCESS.SUBTITLE_SECOND_PART' | translate }}</span
    >

    <span class="title title-released" *ngIf="data.releaseResponse">{{
      'RELEASE_ACCESS.TITLE_PATIENT_RELEASED' | translate
    }}</span>

    <div
      [ngClass]="
        data.releaseResponse
          ? 'access-section-released access-section'
          : 'access-section'
      "
    >
      <div class="image-section">
        <img
          class="card"
          *ngIf="!periodIsSelected && !data.releaseResponse"
          src="../../../../assets/images/patient-red-card.svg"
          alt="card que representa que não há liberação de acesso pro paciente"
        />
        <img
          class="card"
          *ngIf="periodIsSelected || data.releaseResponse"
          src="../../../../assets/images/patient-green-card.svg"
          alt="card que representa que não há liberação de acesso pro paciente"
        />
      </div>
      <div
        class="action-section"
        *ngIf="
          (!data.releaseResponse || periodIsSelected) && !data.releaseResponse
        "
      >
        <app-vale-radio-group
          [formControl]="buttonForm"
          position="horizontal"
          label="{{ 'RELEASE_ACCESS.LABEL' | translate }}"
        >
          <app-vale-radio-button class="exam-radio-button" value="one">
            {{ 'RELEASE_ACCESS.ONE_DAY' | translate }}
          </app-vale-radio-button>
          <app-vale-radio-button class="exam-radio-button" value="two">
            {{ 'RELEASE_ACCESS.TWO_DAYS' | translate }}
          </app-vale-radio-button>
          <app-vale-radio-button class="exam-radio-button" value="other">
            {{ 'RELEASE_ACCESS.OTHER' | translate }}
          </app-vale-radio-button>
        </app-vale-radio-group>
        <span class="period-alert">{{
          'RELEASE_ACCESS.PERIOD_ALERT' | translate
        }}</span>
        <div class="other-period-text" *ngIf="optionOtherIsSelected">
          {{ 'RELEASE_ACCESS.OTHER_PERIOD_TEXT' | translate }}
        </div>
        <div class="other-period-date" *ngIf="optionOtherIsSelected">
          <app-vale-label class="initial-date-interval">
            <app-vale-icon
              valePrefix
              iconSrc="event_note.svg"
              (click)="initialDate.open()"
            ></app-vale-icon>
            <input
              valeInput
              [min]="minInitialDate"
              [matDatepicker]="initialDate"
              [formControl]="initialDateValue"
              (keypress)="isDate($event)"
            />
            <mat-datepicker #initialDate></mat-datepicker>
          </app-vale-label>
          <span class="text-interval-dates">a</span>
          <app-vale-label class="final-date-interval">
            <app-vale-icon
              valePrefix
              iconSrc="event_note.svg"
              (click)="finalDate.open()"
            ></app-vale-icon>
            <input
              valeInput
              [min]="initialDateValue.value"
              [max]="maxFinalDate"
              [matDatepicker]="finalDate"
              [formControl]="finalDateValue"
            />
            <mat-datepicker #finalDate></mat-datepicker>
          </app-vale-label>
        </div>
      </div>
      <div class="action-section-released" *ngIf="data.releaseResponse">
        <div class="released-access-label">
          <span *ngIf="!equalDates">{{
            'RELEASE_ACCESS.RELEASE_LABEL' | translate
          }}</span>
          <span *ngIf="!equalDates"
            >{{ initialDate | date: 'dd/MM/yyyy' }} e
            {{ finalDate | date: 'dd/MM/yyyy' }}.</span
          >
          <span *ngIf="equalDates">{{
            'RELEASE_ACCESS.RELEASE_LABEL_SAME_DATA' | translate
          }}</span>
          <span *ngIf="equalDates">{{ initialDate | date: 'dd/MM/yyyy' }}</span>
        </div>
        <div class="released-access-manager">
          <span>{{ 'RELEASE_ACCESS.RELEASE_MANAGER' | translate }}</span>
          <span>{{ userFullName }}</span>
        </div>
        <div class="action-text">
          <span>{{ 'RELEASE_ACCESS.ACTION_TEXT_PART_ONE' | translate }}</span>
          <span>{{ 'RELEASE_ACCESS.ACTION_TEXT_PART_TWO' | translate }}</span>
          <span>{{ 'RELEASE_ACCESS.ACTION_TEXT_PART_THREE' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons-container">
    <button
      *ngIf="!data.releaseResponse"
      [class]="
        periodIsSelected
          ? 'option-buttons half-cancel'
          : 'option-buttons cancel'
      "
      [mat-dialog-close]="false"
    >
      {{ 'RELEASE_ACCESS.CANCEL' | translate }}
    </button>
    <button
      *ngIf="data.releaseResponse"
      class="option-buttons close"
      [mat-dialog-close]="false"
    >
      {{ 'RELEASE_ACCESS.CLOSE' | translate }}
    </button>
    <button
      class="option-buttons confirm"
      *ngIf="periodIsSelected"
      (click)="savePatientRelease()"
    >
      {{ 'RELEASE_ACCESS.CONFIRM' | translate }}
    </button>
  </div>
</div>
