import { Injectable } from '@angular/core';

import { ValeGenericSearchComponent } from './vale-generic-search.component';

@Injectable()
export class ValeGenericSearchDropdownService {
  private select: ValeGenericSearchComponent;

  public register(select: ValeGenericSearchComponent): void {
    this.select = select;
  }

  public getSelect(): ValeGenericSearchComponent {
    return this.select;
  }

  public resetSearch(): void {
    if (this.select) {
      this.select.resetSearch();
    }
  }
}
