export enum ValeTimelineEnum {
  COMORBIDITY = 1,
  SYMPTOM = 2,
  HOSPITALIZATION = 3,
  NOTE = 4,
  EXAM_PCR = 5,
  EXAM_SEROLOGICAL = 6,
  EXAM_TRAG = 7,
  VACCINES = 8,
  COMPULSORY_NOTIFICATION = 9,
  TRAG_TEST_EXTERNAL = 10,
  DISCHARGE = 11,
  DEATH = 12,
}

export enum TypeTestEnum {
  SOROLOGIC = 1,
  PCR = 2,
  TRAG = 3,
}
export enum ExternalTest {
  ExternalTest = 1,
}
