import { Input, OnInit } from '@angular/core';
import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { PatientService } from '../../../core/services/patient.service';
import { AppConstant } from '../../constants/app.constant';
import { NameGcmToolsConstant } from '../../constants/name-gcm-tools.constant';
import { PatientTypeEnum } from '../../constants/patient-type.constant';
import { HomeOptionsEnum } from '../../enums/home-options.enum';
import { PatientProtocolModel } from '../../models/patient-protocol.model';
import { ReturnDialogButton } from '../return-dialog/return-dialog-button.model';
import { ReturnDialogComponent } from '../return-dialog/return-dialog.component';
import { ReturnDialog } from '../return-dialog/return-dialog.model';
import { MedicalRecordService } from './../../../core/services/medical-record.service';
import { UserBusiness } from './../../business/user-business.service';
import { AnalyticsService } from './../../services/analytics.service';

@Component({
  selector: 'app-vale-patient-search',
  templateUrl: './vale-patient-search.component.html',
  styleUrls: ['./vale-patient-search.component.scss'],
})
export class ValePatientSearchComponent implements OnInit, OnDestroy {
  @Input()
  public color = 'primary';

  @Input()
  public focus = false;

  public selectedOption;
  public selectedPatient;

  private _subscriptions = new Subscription();
  public _userCountry = 'BRAZIL';

  constructor(
    private _patientService: PatientService,
    private _userBusiness: UserBusiness,
    private _dialog: MatDialog,
    private _medicalRecordService: MedicalRecordService,
    private _router: Router,
    private _analyticsService: AnalyticsService
  ) {
    this._subscriptions.add(
      this._userBusiness.homeOption$.subscribe((selectedOption) => {
        this.selectedOption = selectedOption;
      })
    );
  }
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this._userCountry = this._userBusiness.getUserCountry();
  }

  public notFound = false;
  public selectedDestination: string;
  public hasDataSearch = false;
  public openModal = false;
  public options = [];

  public changeSearch(event): void {
    this.options = [];
    this.notFound = false;
    if (event) {
      this.searchUser(event.searchBy);
    }
  }

  public redirect(option): void {
    this.notFound = false;
    this.options = [];
  }

  public resetSearch(event): void {
    this.options = [];
    this.notFound = false;
  }

  public isValidPatientToClick(patientType: number): boolean {
    return (
      (HomeOptionsEnum.QUICK_TEST == this.selectedOption &&
        (patientType == PatientTypeEnum.VALE ||
          patientType == PatientTypeEnum.OUTSOURCED)) ||
      HomeOptionsEnum.MEDICAL_RECORD == this.selectedOption
    );
  }

  public onPessoaSelectionChanged(input: any): void {
    this.selectedPatient = input;
    if (
      !this.selectedPatient.gcmId ||
      this.selectedPatient.gcmId === undefined ||
      this.selectedOption === HomeOptionsEnum.MEDICAL_RECORD
    ) {
      this.goToNextScreen();
    } else {
      if (this._userCountry === 'BRAZIL') {
        this.verifyIfShouldTest();
      } else {
        this.goToNextScreen();
      }
    }
  }

  public getDescriptionSearch(patient: any): string {
    const description = [];

    if (patient && patient.gcmId) {
      description.push(`@${patient.gcmId}`);
    }

    if (patient && patient.iamId) {
      description.push(patient.iamId);
    }

    if (patient && patient.name) {
      description.push(patient.name);
    }
    return description.join(' - ');
  }

  public openNewRegister(): void {
    this.sendClickNewRegisterToGA();
    this._router.navigate(['patient-register'], {
      state: {
        selectedOption: this.selectedOption,
      },
    });
  }

  public searchUser(searchBy: string): void {
    this._sendSearchToGA(searchBy);
    if (searchBy !== null && searchBy.length > 0) {
      const operation = this._getOperationFromSelectedOperation();
      const filterValue = searchBy.toLowerCase();
      const sub = this._getPatientsByFilterAndOperation(
        filterValue,
        operation
      ).subscribe((response) => {
        this._searchResponseHandler(response);
      });
      this._subscriptions.add(sub);
    }
  }

  public sendClickNewRegisterToGA(): void {
    if (this.selectedOption === HomeOptionsEnum.MEDICAL_RECORD) {
      this._analyticsService.sendEventToGA(
        NameGcmToolsConstant.MEDICAL_RECORD,
        'Novo cadastro iniciado',
        'click_new_patient_mRec'
      );
    }
    if (this.selectedOption === HomeOptionsEnum.QUICK_TEST) {
      this._analyticsService.sendEventToGA(
        NameGcmToolsConstant.QUICK_TEST,
        'Novo cadastro iniciado',
        'click_new_patient_test'
      );
    }
  }

  private _getOperationFromSelectedOperation() {
    return this.selectedOption === HomeOptionsEnum.MEDICAL_RECORD
      ? AppConstant.FILTER_OPERATIONS.MEDICAL_RECORD
      : AppConstant.FILTER_OPERATIONS.QUICK_EXAM;
  }

  private async _sendSearchToGA(text) {
    if (this.selectedOption === HomeOptionsEnum.MEDICAL_RECORD) {
      if (text.charAt(0) == '@') {
        this._analyticsService.sendEventToGA(
          NameGcmToolsConstant.MEDICAL_RECORD,
          'Pesquisar no prontuário com o @',
          'click_search_mRec@'
        );
      } else if (/^[a-zA-Z\s]*$/.test(text)) {
        this._analyticsService.sendEventToGA(
          NameGcmToolsConstant.MEDICAL_RECORD,
          'Pesquisar nome no prontuário',
          'click_search_mRec'
        );
      } else {
        this._analyticsService.sendEventToGA(
          NameGcmToolsConstant.MEDICAL_RECORD,
          'Pesquisar código no prontuário',
          'click_search_mRec_code'
        );
      }
    }
    if (this.selectedOption === HomeOptionsEnum.QUICK_TEST) {
      if (text.charAt(0) == '@') {
        this._analyticsService.sendEventToGA(
          NameGcmToolsConstant.QUICK_TEST,
          'Pesquisar no teste rápido com o @',
          'click_search_test@'
        );
      } else if (/^[a-zA-Z\s]*$/.test(text)) {
        this._analyticsService.sendEventToGA(
          NameGcmToolsConstant.QUICK_TEST,
          'Pesquisar nome no teste rápido',
          'click_search_test'
        );
      } else {
        this._analyticsService.sendEventToGA(
          NameGcmToolsConstant.QUICK_TEST,
          'Pesquisar código no teste rápido',
          'click_search_test_code'
        );
      }
    }
  }

  private _getPatientsByFilterAndOperation(
    filterValue: any,
    operation: string
  ) {
    return this._patientService.getPatients(filterValue, operation).pipe(
      map((response: any) => {
        return response
          ? response.filter(() => {
              return true;
            })
          : false;
      })
    );
  }

  private _searchResponseHandler(response) {
    if (response && response.length) {
      this.options = response.map((e) => {
        return { ...e, disabled: !this.isValidPatientToClick(e.typeEmployee) };
      });
      this.notFound = false;
    } else {
      this.options = [];
      this.notFound = true;
    }
  }

  private goToNextScreen() {
    if (this.selectedPatient) {
      let url = 'patient';
      if (this.selectedOption === HomeOptionsEnum.MEDICAL_RECORD) {
        url = `patient/${
          this.selectedPatient.gcmId || this.selectedPatient.iamId
        }`;
      }
      if (this.selectedOption === HomeOptionsEnum.QUICK_TEST) {
        url = `quick-exam/patient/${
          this.selectedPatient.gcmId || this.selectedPatient.iamId
        }`;
      }
      this._router.navigate([url], {
        state: {
          shortEmployee: this.selectedPatient,
          selectedOption: this.selectedOption,
        },
      });
    }
  }

  private verifyIfShouldTest() {
    if (this.selectedPatient.gcmId) {
      const sub = this._medicalRecordService
        .getPatientProtocolSituation(this.selectedPatient.gcmId)
        .subscribe(
          (response: any) => {
            if (response) {
              const model = new PatientProtocolModel();
              model.id = response.id;
              model.protocol = response.protocol;
              model.shouldRetest = response.shouldRetest;

              switch (model.protocol) {
                case 1:
                  this.callProtocolAlert(1);
                  return;
                case 2:
                  this.callProtocolAlert(2);
                  return;
                case 3:
                  if (model.shouldRetest != null) {
                    if (model.shouldRetest === true) {
                      this.goToNextScreen();
                    } else {
                      this.callProtocolAlert(3);
                    }
                  } else {
                    this.callProtocolAlert(3, false);
                  }
                  return;
                default:
                  this.goToNextScreen();
              }
            } else {
              this.goToNextScreen();
            }
          },
          (err) => {
            console.log('error: ', err);
          }
        );
      this._subscriptions.add(sub);
    }
  }

  private callProtocolAlert(protocol: number, examined = true) {
    let image: string;
    let title: string;
    let message: string;
    const buttonLeftTitle = 'Não desejo prosseguir';
    let buttonRightTitle: string;

    const text1 = 'Este paciente se encaixa no ';
    const text2 = 'protocolo de não retestar'.bold();
    const text3 = ', confirme para prosseguir mesmo assim.';
    const defaultMessage = text1 + text2 + text3;

    switch (protocol) {
      case 1:
        image = './assets/images/protocoloUm.svg';
        title = 'Não é necessário retestagem';
        message = defaultMessage;
        buttonRightTitle = 'Prosseguir com retestagem';
        break;
      case 2:
        image = './assets/images/protocoloDois.svg';
        title = 'Não é necessário retestagem';
        message = defaultMessage;
        buttonRightTitle = 'Prosseguir mesmo assim';
        break;
      case 3:
        image = './assets/images/protocoloTres.svg';
        title = examined
          ? 'Não é necessário retestagem'
          : 'Encaminhar para avaliação médica';
        message = examined
          ? defaultMessage
          : 'Este paciente precisa realizar uma avaliação médica antes de prosseguir.';
        buttonRightTitle = 'Prosseguir mesmo assim';
        break;
      default:
        break;
    }

    const dialogRef = this._dialog;
    // eslint-disable-next-line no-array-constructor
    const buttons = new Array<ReturnDialogButton>(
      new ReturnDialogButton({
        name: buttonLeftTitle,
        className: 'button-classic button-classic--background-white-redText',
        action: () => {
          dialogRef.closeAll();
        },
      }),
      new ReturnDialogButton({
        name: buttonRightTitle,
        className: 'button-classic button-classic--background-red',
        action: () => {
          this.goToNextScreen();
          dialogRef.closeAll();
        },
      })
    );

    this._dialog.open(ReturnDialogComponent, {
      data: new ReturnDialog({
        actionType: 'error',
        title: title,
        message: message,
        path: image,
        buttons: buttons,
        closeActionButton: () => {
          dialogRef.closeAll();
        },
      }),
      disableClose: true,
    });
  }
}
