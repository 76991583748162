<div class="container-search-generic">
  <form class="container-input">
    <div class="wrapper-input">
      <mat-icon
        svgIcon="search"
        class="icone-filtro-lupa"
        style="color: blue"
      ></mat-icon>
      <input
        placeholder="{{ placeholder | translate }}"
        autofocus
        #inputSearch
        id="inputSearch"
        autocomplete="off"
        [formControl]="searchForm"
        focus="true"
      />
      <div
        class="container-circle"
        *ngIf="searchForm?.value"
        (click)="onReset()"
      >
        <div class="circle" [matTooltip]="'GENERAL.CLEAR' | translate">
          <img class="image" src="./assets/images/cross.svg" alt="icon close" />
        </div>
      </div>
    </div>
    <button
      *ngIf="hasSearchButton"
      app-vale-flat-button
      color="primary"
      matSuffix
      class="button-search"
      (click)="search()"
    >
      <span class="text">{{ 'GENERAL.SEARCH' | translate }}</span>
    </button>
  </form>
</div>
