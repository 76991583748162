<div class="container">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header class="title">
        {{ topics?.title }}
      </mat-expansion-panel-header>
      <div
        class="description"
        [innerHTML]="topics?.description | safeHtml"
      ></div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
