import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { HelpCenterService } from '../../../../core/services/help-center.service';
import {
  DialogConfig,
  DialogService,
} from '../../../../shared/services/dialog.service';
import { HomeService } from '../../home.service';

@Component({
  selector: 'app-csat-dialog',
  templateUrl: './csat-dialog.component.html',
  styleUrls: ['./csat-dialog.component.scss'],
})
export class CsatDialogComponent implements OnInit, OnDestroy {
  public scoreForm = new FormGroup({
    score: new FormControl(null, [Validators.required]),
    note: new FormControl(null),
  });

  private _subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<CsatDialogComponent>,
    private _helperCenterService: HelpCenterService,
    private _dialogService: DialogService,
    private _homeService: HomeService,
    public dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit(): void {}

  public save(form: FormGroup): void {
    this.scoreForm = form;

    if (this.scoreForm.valid) {
      this._subscriptions.add(
        this._helperCenterService
          .postSaveCustomerSatisfactionScore(this.scoreForm.value)
          .subscribe(
            (response: any) => {
              this._homeService.updateCsatFlag().subscribe((r) => {
                this.dialogRef.close();
                this._openSuccessDialog();
              });
            },
            (error) => {
              this.dialogRef.close();
              this._openErrorDialog();
            }
          )
      );
    }
  }

  private _openSuccessDialog(): MatDialogRef<any> {
    const config: DialogConfig = {
      title: 'CSAT_DIALOG.SUCCESS_DIALOG.TITLE',
      message: 'CSAT_DIALOG.SUCCESS_DIALOG.MESSAGE',
      mainButton: {
        text: 'GENERAL.CLOSE',
        action: () => {},
        result: true,
      },
    };
    return this._dialogService.callSuccessDialog$(config);
  }

  private _openErrorDialog() {
    const config: DialogConfig = {
      title: 'CSAT_DIALOG.ERROR_DIALOG.TITLE',
      message: 'CSAT_DIALOG.ERROR_DIALOG.MESSAGE',
      mainButton: {
        text: 'CSAT_DIALOG.ERROR_DIALOG.BUTTON_TRY_AGAIN',
        action: () => {
          this.dialog.open(CsatDialogComponent, {
            maxWidth: '750px',
            disableClose: true,
          });
        },
        result: true,
      },
      secondButton: {
        text: 'CSAT_DIALOG.ERROR_DIALOG.BUTTON_EVALUATE_LATER',
        action: () => {
          this.dialogRef.close();
        },
        result: false,
      },
    };
    this._dialogService.callErrorDialog$(config);
  }
}
