import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-generic',
  templateUrl: './search-generic.component.html',
  styleUrls: ['./search-generic.component.scss'],
})
export class SearchGenericComponent implements OnInit, OnDestroy {
  public searchForm = new FormControl();
  public showReset = false;
  @Input('placeholder') public placeholder;
  @Input('hasSearchButton') public hasSearchButton = false;
  @Input('clear') public clear = false;
  @Output('search') private _searchEmitter = new EventEmitter(null);
  @Output('filterChange') private _changeEmitter = new EventEmitter(null);

  private _clear = false;
  private _subscriptions = new Subscription();
  constructor() {}
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this._subscriptions.add(
      this.searchForm.valueChanges.subscribe((values) => {
        this._changeEmitter.emit(values);
      })
    );
  }
  ngOnChanges(): void {
    if (this.clear != this._clear) {
      this.onReset();
      this._clear = this.clear;
    }
  }

  public search(): void {
    this._searchEmitter.emit(this.searchForm.value);
    this.onReset();
  }

  public onReset(): void {
    this.searchForm.setValue(null);
    this.searchForm.reset();
  }
}
