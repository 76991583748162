import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { ValeDotProfileComponent } from './vale-dot-profile.component';

@NgModule({
  declarations: [ValeDotProfileComponent],
  imports: [CommonModule],
  exports: [ValeDotProfileComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeDotProfileModule {}
