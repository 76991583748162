import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { PatientTypeEnum } from '../../shared/constants/patient-type.constant';
import { IDependentPatientCreate } from '../../shared/interfaces/dependent-patient-create.interface';
import { IOutsourcedPatientCreate } from '../../shared/interfaces/outsourced-patient-create.interface';
import { IPatientCreate } from '../../shared/interfaces/patient-create.interface';
import { IResultPatientRegister } from '../../shared/interfaces/result-patient-register.interface';
import { IRetiredPatientCreate } from '../../shared/interfaces/retired-patient-create.interface';
import { DemobilizeEmployeeModel } from '../../shared/models/demobilize-employee.model';
import { DependentModel } from '../../shared/models/dependent.model';
import { OutsourcedModel } from '../../shared/models/outsourced.model';
import { RegisterModel } from '../../shared/models/register.model';
import { RetiredModel } from '../../shared/models/retired.model';
import { BaseService } from './base.service';

@Injectable()
export class RegisterService extends BaseService {
  private readonly baseUrl = 'Employee';
  constructor(http: HttpClient) {
    super(http, null, environment.api);
  }

  public saveRegister(register: any, type: PatientTypeEnum): Observable<any> {
    const registerSave: RegisterModel = {
      name: register.name,
      code: register.code,
      cpf: register.cpf ? register.cpf.replace(/[^\d]/gm, '') : register.cpf,
      passport: register.passport,
      phone: register.phone,
      backupPhone: register.backupPhone,
      backupPhone_2: register.backupPhone_2,
      backupPhone_3: register.backupPhone_3,
      ssoId: register.ssoId,
      typeEmployee: type,
    };

    if (type === PatientTypeEnum.OUTSOURCED) {
      const outsourced: OutsourcedModel = {
        address: register.address,
        company: register.company,
        descriptionSsoLocal: register.descriptionSsoLocal,
        manager: register.manager,
        managerEmail: register.managerEmail,
        managerPhone: register.managerPhone,
        local: register.local,
      };
      registerSave.outsourced = outsourced;
    } else if (type === PatientTypeEnum.DEPENDENT) {
      const dependent: DependentModel = {
        holderPhone: register.holderPhone,
        idCard: register.idCard,
        nameHolder: register.nameHolder,
        registrationHolder: register.registrationHolder,
      };
      registerSave.dependent = dependent;
    } else if (type === PatientTypeEnum.RETIRED) {
      const retired: RetiredModel = {
        idCard: register.idCard,
        state: register.state,
      };
      registerSave.retired = retired;
    }
    return this.postRegister(registerSave);
  }

  public editEmployeeRegister(id: any, employee: any): Observable<any> {
    return this.patch<any>(`${this.baseUrl}/${id}`, employee);
  }

  public editRetiredRegister(id: any, employee: any): Observable<any> {
    return this.patch<any>(`${this.baseUrl}/${id}/retired`, employee);
  }

  public retiredToVale(id: any, employee: any): Observable<any> {
    return this.patch<any>(`${this.baseUrl}/${id}/retired-to-vale`, employee);
  }

  public valeToRetired(id: any, employee: any): Observable<any> {
    return this.patch<any>(`${this.baseUrl}/${id}/vale-to-retired`, employee);
  }

  public dependentToVale(id: any, employee: any): Observable<any> {
    return this.patch<any>(`${this.baseUrl}/${id}/dependent-to-vale`, employee);
  }

  public outsourcedToVale(id: any, employee: any): Observable<any> {
    return this.patch<any>(
      `${this.baseUrl}/${id}/outsourced-to-vale`,
      employee
    );
  }

  public valeToDependent(id: any, employee: any): Observable<any> {
    return this.patch<any>(`${this.baseUrl}/${id}/vale-to-dependent`, employee);
  }

  public outsourcedToDependent(id: any, employee: any): Observable<any> {
    return this.patch<any>(
      `${this.baseUrl}/${id}/outsourced-to-dependent`,
      employee
    );
  }

  public dependentToOutsourced(id: any, employee: any): Observable<any> {
    return this.patch<any>(
      `${this.baseUrl}/${id}/dependent-to-outsourced`,
      employee
    );
  }

  public valeToOutsourced(id: any, employee: any): Observable<any> {
    return this.patch<any>(
      `${this.baseUrl}/${id}/vale-to-outsourced`,
      employee
    );
  }

  public editDependentRegister(id: any, employee: any): Observable<any> {
    return this.patch<any>(`${this.baseUrl}/${id}/dependent`, employee);
  }

  public editOutsourcedRegister(id: any, employee: any): Observable<any> {
    return this.patch<any>(`${this.baseUrl}/${id}/outsourced`, employee);
  }

  private postRegister(register: RegisterModel): Observable<any> {
    return this.post<any>(`${this.baseUrl}`, register);
  }

  public postDemobilize(data: DemobilizeEmployeeModel): Observable<any> {
    return this.post<any>(`${this.baseUrl}/demobilize`, data);
  }

  public postOutsourced(
    data: IOutsourcedPatientCreate
  ): Observable<IResultPatientRegister> {
    return this.post<any>(`${this.baseUrl}/outsourced`, data);
  }

  public postDependent(
    data: IDependentPatientCreate
  ): Observable<IResultPatientRegister> {
    return this.post<any>(`${this.baseUrl}/dependent`, data);
  }

  public postRetired(
    data: IRetiredPatientCreate
  ): Observable<IResultPatientRegister> {
    return this.post<any>(`${this.baseUrl}/retired`, data);
  }

  public getOutsourced(id: number): Observable<any> {
    return this.get<any>(`${this.baseUrl}/${id}/outsourced`);
  }

  public getDependent(id: number): Observable<any> {
    return this.get<any>(`${this.baseUrl}/${id}/dependent`);
  }

  public getRetired(id: number): Observable<any> {
    return this.get<any>(`${this.baseUrl}/${id}/retired`);
  }

  public getVale(id: number): Observable<any> {
    return this.get<any>(`${this.baseUrl}/${id}/vale`);
  }

  public patchPatient(id: number, properties: any): Observable<any> {
    return this.patch<any>(`${this.baseUrl}/${id}`, properties);
  }

  public patchPatientRetired(id: number, properties: any): Observable<any> {
    return this.patch<any>(`${this.baseUrl}/${id}/retired`, properties);
  }

  public patchPatientDependent(id: number, properties: any): Observable<any> {
    return this.patch<any>(`${this.baseUrl}/${id}/dependent`, properties);
  }

  public patchPatientOutsourced(id: number, properties: any): Observable<any> {
    return this.patch<any>(`${this.baseUrl}/${id}/outsourced`, properties);
  }

  public changePatientType(
    id: number,
    properties: IPatientCreate
  ): Observable<any> {
    return this.put<any>(`${this.baseUrl}/${id}/change-to-vale`, properties);
  }

  public changePatientTypeRetired(
    id: number,
    properties: IRetiredPatientCreate
  ): Observable<any> {
    return this.put<any>(`${this.baseUrl}/${id}/change-to-retired`, properties);
  }

  public changePatientTypeDependent(
    id: number,
    properties: IDependentPatientCreate
  ): Observable<any> {
    return this.put<any>(
      `${this.baseUrl}/${id}/change-to-dependent`,
      properties
    );
  }

  public changePatientTypeOutsourced(
    id: number,
    properties: IOutsourcedPatientCreate
  ): Observable<any> {
    return this.put<any>(
      `${this.baseUrl}/${id}/change-to-outsourced`,
      properties
    );
  }

  public getPatientProfileFromMdm(code: string): Observable<any> {
    return this.get<any>(`${this.baseUrl}/profile-mdm`, {
      IamId: code,
    });
  }

  public getEmployeeGCMProfile(code: string): Observable<any> {
    return this.get<any>(`${this.baseUrl}/profile-data-pasa`, {
      IamId: code,
    });
  }

  public getEmployeeAudit(employeeId: number): Observable<any> {
    return this.get<any>(`${this.baseUrl}/${employeeId}/employee-audit`);
  }

  public postValidateExistingDocument(document: any): Observable<any> {
    return this.post<any>(`${this.baseUrl}/validate-existing-document`, {
      document: document,
    });
  }
}
