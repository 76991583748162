import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ActionDialogService } from '../../../core/services/action-dialog.service';
import { RegionService } from '../../../core/services/region.service';
import { RegisterService } from '../../../core/services/register.service';
import { AutoCompleteValidator } from '../../util/auto-complete-validator';
import { FormFieldsValidator } from '../../util/form-fields-validator';
import { UserBusiness } from './../../business/user-business.service';

@Component({
  selector: 'app-update-employee-data',
  templateUrl: './update-employee-data.component.html',
  styleUrls: ['./update-employee-data.component.scss'],
})
export class UpdateEmployeeDataComponent implements OnInit {
  public dataForm: FormGroup;
  public regions: any[];
  public filteredRegions: any;
  public managerField: boolean;
  public managerEmailField: boolean;
  public managerPhoneField: boolean;
  public cpfField: boolean;
  public passportField: boolean;
  public ssoLocationField: boolean;

  private patchEmployee: any[] = [];
  private regionsDescriptions: any[];
  private _subscriptions = new Subscription();
  private _userCountry;

  constructor(
    public registerService: RegisterService,
    public regionService: RegionService,
    private _actionDialogService: ActionDialogService,
    private _userBusiness: UserBusiness
  ) {}

  ngOnInit(): void {
    this._actionDialogService.setDisabledConfirmButton(true);
    this._actionDialogService.dataChild$.subscribe((res) => {
      this.managerField = res.managerField;
      this.managerEmailField = res.managerEmailField;
      this.managerPhoneField = res.managerPhoneField;
      this.ssoLocationField = res.ssoLocationField;
      this.cpfField = res.cpfField;
      this.passportField = res.passportField;
      this._userCountry = this._userBusiness.getUserCountry();
    });
    this.getRegion();
    this._createModalFormFields();
    this._watchDocumentChangeAndExecuteDisableConditions();
    this._watchManagerChangeAndExecuteDisableConditions();
    this._watchManagerEmailChangeAndExecuteDisableConditions();
    this._watchManagerPhoneChangeAndExecuteDisableConditions();
    this._watchSsoLocationChangeAndExecuteDisableConditions();
    this._watchRegionChangeAndFilterAccordingWithSsoLocationInput();
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public hasError(controlName: string, errorName: string) {
    return (
      !this.dataForm.controls[controlName].untouched &&
      this.dataForm.controls[controlName].hasError(errorName)
    );
  }

  public displayFn(ssoId: number): string | undefined {
    let display = '';
    if (ssoId != undefined && ssoId != null && ssoId > 0) {
      if (this.filteredRegions) {
        const sub = this.filteredRegions.subscribe((regions) => {
          if (regions) {
            const region = regions.find((x) => x.id == ssoId);
            if (region) {
              display = `${region.descriptionSsoLocal} - ${region.description}`;
              this.onChangeProperty('ssoId', 'ssoid');
            }
          }
        });
        this._subscriptions.add(sub);
      }
    }
    return display;
  }

  public onChangeProperty(formControlName: any, name: any) {
    let _value;
    if (name && formControlName) {
      _value = this.dataForm.get(formControlName).value;
    }
    if (this.patchEmployee) {
      this.patchEmployee = this.patchEmployee
        .filter((property: any) => property.name !== name)
        .filter((p) => p.value);
      this._actionDialogService.setDisabledConfirmButton(
        this.patchEmployee.length == 0
      );
    }
    if (_value) {
      this._actionDialogService.setDisabledConfirmButton(false);
      this.patchEmployee.push({
        name: name,
        value: _value,
      });
    }
    this._actionDialogService.setResponseDataChild(this.patchEmployee);
  }

  public getErrorMessage(field: string): string {
    const errors = this.dataForm.get(field).errors;

    if (!errors) {
      return '';
    }

    if (errors.validateTestLocation) {
      return 'FORM_ERROR_INPUT.FORM_AUTOCOMPLETE_INVALID';
    }

    if (errors.cpfNotValid) {
      return 'Documento inválido';
    }
  }

  public trackByFn(index, item) {
    return index; // or item.id
  }

  public async getRegion() {
    const sub = this.regionService.getRegionVale().subscribe((regions: any) => {
      this.regions = regions;
      this._initiateLocationValidator();
    });

    this._subscriptions.add(sub);
    if (this.dataForm) {
      const id = this.dataForm.get('ssoId').value;
      if (id) {
        this.displayFn(id);
      }
    }
  }

  private _createModalFormFields() {
    const ssoId = new FormControl('', []);
    const cpf = new FormControl('');
    const passport = new FormControl('', []);
    const manager = new FormControl('', []);
    const managerEmail = new FormControl('', []);
    const managerPhone = new FormControl('', []);

    if (this._userCountry === 'BRAZIL') {
      cpf.setValidators([FormFieldsValidator.isValidCpf]);
    } else {
      cpf.setValidators([FormFieldsValidator.isValidNuit]);
    }
    cpf.updateValueAndValidity();
    this.dataForm = new FormGroup({
      ssoId: ssoId,
      cpf: cpf,
      passport: passport,
      manager: manager,
      managerEmail: managerEmail,
      managerPhone: managerPhone,
    });
  }

  private _watchRegionChangeAndFilterAccordingWithSsoLocationInput() {
    this.filteredRegions = this.dataForm.get('ssoId').valueChanges.pipe(
      startWith(''),
      map((value) =>
        typeof value === 'string' ? value : value ? value.description : ''
      ),
      map((name) =>
        name ? this._filter(name) : this.regions ? this.regions.slice() : []
      )
    );
  }

  private _watchSsoLocationChangeAndExecuteDisableConditions() {
    this.dataForm.get('passport').valueChanges.subscribe((passport) => {
      this.dataForm.get('cpf').enable({
        emitEvent: false,
      });
      this._actionDialogService.setDisabledConfirmButton(true);
      if (passport) {
        this._actionDialogService.setDisabledConfirmButton(
          this.dataForm.invalid
        );
        this.dataForm.get('cpf').disable({
          emitEvent: false,
        });
      }
    });
  }

  private _watchManagerChangeAndExecuteDisableConditions() {
    this.dataForm.get('manager').valueChanges.subscribe((manager) => {
      this._actionDialogService.setDisabledConfirmButton(!manager);
    });
  }

  private _watchManagerEmailChangeAndExecuteDisableConditions() {
    this.dataForm.get('managerEmail').valueChanges.subscribe((managerEmail) => {
      this._actionDialogService.setDisabledConfirmButton(!managerEmail);
    });
  }

  private _watchManagerPhoneChangeAndExecuteDisableConditions() {
    this.dataForm.get('managerPhone').valueChanges.subscribe((managerPhone) => {
      this._actionDialogService.setDisabledConfirmButton(!managerPhone);
    });
  }

  private _watchDocumentChangeAndExecuteDisableConditions() {
    this.dataForm.get('cpf').valueChanges.subscribe((cpf) => {
      this.dataForm.get('passport').enable({
        emitEvent: false,
      });
      this._actionDialogService.setDisabledConfirmButton(true);
      if (cpf) {
        this._actionDialogService.setDisabledConfirmButton(
          this.dataForm.invalid
        );
        this.dataForm.get('passport').disable({
          emitEvent: false,
        });
      }
    });
  }

  private _initiateLocationValidator() {
    this.regionsDescriptions = [];
    this.regions.forEach((r) => {
      const description = r.descriptionSsoLocal + ' - ' + r.description;
      this.regionsDescriptions.push(description);
    });
    this.dataForm
      .get('ssoId')
      .setValidators([
        Validators.required,
        AutoCompleteValidator.validateSsoLocation(this.regionsDescriptions),
      ]);
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.regions.filter((option: any) => {
      if (option) {
        const label = `${option.descriptionSsoLocal} - ${option.description}`;
        return label.toLowerCase().includes(filterValue);
      }
      return false;
    });
  }
}
