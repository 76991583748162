<div class="container-form-result">
  <div class="form-title">
    {{
      'PATIENT_RECORD.MEDICAL_RECORD.EXAMS.SEROLOGICAL_TEST.QUICK_EXAM_RESULT.TITLE'
        | translate
    }}
  </div>
  <div class="result-reactive" *ngIf="hasReactiveWithoutDistiction">
    <mat-checkbox
      (change)="disableAndSetForm($event)"
      [checked]="isResultReactiveWithoutDistiction"
      >{{
        'PATIENT_RECORD.MEDICAL_RECORD.EXAMS.SEROLOGICAL_TEST.QUICK_EXAM_RESULT.REACTIVE_WITHOUT_DISTICTION'
          | translate
      }}
    </mat-checkbox>
  </div>
  <form
    [formGroup]="resultQuickExamForm"
    class="container-form container-form{{ isPosition ? '' : '--horizontal' }}"
  >
    <div class="form-result">
      <div class="label-result">
        {{
          'PATIENT_RECORD.MEDICAL_RECORD.EXAMS.SEROLOGICAL_TEST.QUICK_EXAM_RESULT.RESULT_IGM'
            | translate
        }}
      </div>
      <mat-form-field
        appearance="outline"
        floatLabel="never"
        [ngClass]="
          resultQuickExamForm?.disabled
            ? 'has-background-gray'
            : 'has-background-white'
        "
      >
        <mat-select formControlName="igmResult" placeholder="Selecionar">
          <mat-option value="1">
            {{
              'PATIENT_RECORD.MEDICAL_RECORD.EXAMS.SEROLOGICAL_TEST.QUICK_EXAM_RESULT.REAGENT_RESULT'
                | translate
            }}
          </mat-option>
          <mat-option value="0">
            {{
              'PATIENT_RECORD.MEDICAL_RECORD.EXAMS.SEROLOGICAL_TEST.QUICK_EXAM_RESULT.NOT_REAGENT_RESULT'
                | translate
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-result">
      <div class="label-result">
        {{
          'PATIENT_RECORD.MEDICAL_RECORD.EXAMS.SEROLOGICAL_TEST.QUICK_EXAM_RESULT.RESULT_IGG'
            | translate
        }}
      </div>
      <mat-form-field
        appearance="outline"
        floatLabel="never"
        [ngClass]="
          resultQuickExamForm?.disabled
            ? 'has-background-gray'
            : 'has-background-white'
        "
      >
        <mat-select formControlName="iggResult" placeholder="Selecionar">
          <mat-option value="1">
            {{
              'PATIENT_RECORD.MEDICAL_RECORD.EXAMS.SEROLOGICAL_TEST.QUICK_EXAM_RESULT.REAGENT_RESULT'
                | translate
            }}
          </mat-option>
          <mat-option value="0">
            {{
              'PATIENT_RECORD.MEDICAL_RECORD.EXAMS.SEROLOGICAL_TEST.QUICK_EXAM_RESULT.NOT_REAGENT_RESULT'
                | translate
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>
