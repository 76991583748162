import { AbstractControl, FormGroup, Validators } from '@angular/forms';

import { UtilHelper } from './util-helper';

export class FormFieldsValidator {
  public static get isValidCpf() {
    return (control: AbstractControl): Validators => {
      const cpf = control.value;
      if (cpf) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return { cpfNotValid: true };
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }

  public static get isValidNuit() {
    return (control: AbstractControl): Validators => {
      const nuit = control.value;
      const regex = new RegExp(/^[0-9]{9}/);
      if (nuit) {
        if (regex.test(nuit)) {
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }

  public static get isOnlyDigit() {
    return (control: AbstractControl): Validators => {
      const nuit = control.value;
      const regex = new RegExp(/\d+/);
      if (nuit) {
        if (regex.test(nuit)) {
          return null;
        } else {
          return { isNotOnlyDigit: true };
        }
      }
      return null;
    };
  }

  public static get onlyLetters() {
    return (control: AbstractControl): Validators => {
      const _value = control.value;
      const onlyLettersRegex = new RegExp(
        /^[A-ZÀ-Ÿa-zà-ÿ]+( [A-ZÀ-Ÿa-zà-ÿ]+)*$/
      );
      if (_value) {
        if (onlyLettersRegex.test(_value)) {
          return null;
        } else {
          return { isNotOnlyLetters: true };
        }
      }
      return null;
    };
  }

  public static get isNotOnlyWhiteSpace() {
    return (control: AbstractControl): Validators => {
      const _value = control.value;
      const onlyWhiteSpaceRegex = new RegExp(/^\s*$/);
      if (_value && onlyWhiteSpaceRegex.test(_value)) {
        return { isOnlyWhiteSpace: true };
      }
      return null;
    };
  }

  public static get isValidIamId() {
    return (control: AbstractControl): Validators => {
      const _value = control.value;
      if (_value && !UtilHelper.isValidIamId(_value)) {
        return { isInvalidIamId: true };
      }
      return null;
    };
  }

  public static get isValidIamIdAndCode() {
    return (control: AbstractControl): Validators => {
      const _value = control.value;
      if (_value && !UtilHelper.isValidIamIdAndCode(_value)) {
        return { isInvalidIamId: true };
      }
      return null;
    };
  }

  public static getErrorFormFieldMessage(
    form: FormGroup,
    field: string
  ): string {
    if (!form && !field) {
      throw new Error('Required Argument');
    }

    const errors = form.get(field).errors;

    if (!errors) {
      return '';
    }

    if (errors.matDatepickerParse) {
      return 'FORM_ERROR_INPUT.FORM_INVALID_DATE';
    }

    if (errors.matDatepickerMin) {
      return 'FORM_ERROR_INPUT.FORM_DATE_MIN_ERROR';
    }

    if (errors.matDatepickerMax) {
      return 'FORM_ERROR_INPUT.FORM_DATE_MAX_ERROR';
    }

    if (errors.required) {
      return 'FORM_ERROR_INPUT.FORM_MANDATORY_FIELD';
    }

    if (errors.validateTestLocation) {
      return 'FORM_ERROR_INPUT.FORM_AUTOCOMPLETE_INVALID';
    }

    if (errors.exactSize) {
      return 'FORM_ERROR_INPUT.FORM_COMPULSORY_NOTIFICATION';
    }

    if (errors.isNotOnlyLetters) {
      return 'FORM_ERROR_INPUT.FORM_TEXT_ONLY_LETTERS_AND_WHITE_SPACE';
    }

    if (errors.isOnlyWhiteSpace) {
      return 'FORM_ERROR_INPUT.FORM_TEXT_ONLY_WHITE_SPACE';
    }

    if (errors.isNotOnlyDigit) {
      return 'FORM_ERROR_INPUT.FORM_INVALID_DIGIT';
    }
  }

  public static get isValidCid() {
    return (control: AbstractControl): Validators => {
      const _value = control.value;
      const regex = /^[aA-zZ]{1}\d{2}(\.\d{1})?$/;

      if (_value && !regex.test(_value)) {
        return { isInvalidCid: true };
      }

      return null;
    };
  }

  public static get isExistDocument() {
    return (control: AbstractControl): Validators => {
      const document = control.value;
      if (document) {
        return { existDocument: true };
      }

      return null;
    };
  }
}
