<div
  (click)="
    openPage(
      'https://vale.service-now.com/sp?id=sc_cat_item&sys_id=eaf3df931ba120103a8185dde54bcb42&vc_par=1'
    )
  "
  class="card support-contact-card"
>
  <img
    class="image"
    src="./assets/images/support-contact.png"
    alt="card to represent support contact"
  />
  <span class="title-card">{{
    'HELP_CENTER.SUPPORT_CONTACT' | translate
  }}</span>
  <span class="subtitle-card">{{
    'HELP_CENTER.TALK_ATTENDANT' | translate
  }}</span>
</div>
<div (click)="selectPage('topics')" class="card popular-topics-card">
  <img
    class="image"
    src="./assets/images/popular-topics.png"
    alt="card to represent popular topics"
  />
  <span class="title-card">
    {{ 'HELP_CENTER.POPULAR_TOPICS' | translate }}
  </span>
  <span class="subtitle-card">{{ 'HELP_CENTER.FAQ' | translate }}</span>
</div>
<div (click)="selectPage('csat')" class="card evaluate-gcm-card">
  <img
    class="image"
    src="./assets/images/evaluation-gcm.png"
    alt="card to represent evaluation of GCM"
  />
  <span class="title-card">{{ 'HELP_CENTER.EVALUATE_GCM' | translate }}</span>
  <span class="subtitle-card">{{
    'HELP_CENTER.GIVE_FEEDBACK' | translate
  }}</span>
</div>
<div (click)="selectPage('about')" class="card about-system-card">
  <img
    class="image"
    src="./assets/images/about-system.png"
    alt="card to represent informations about the system"
  />
  <span class="title-card">{{ 'HELP_CENTER.ABOUT_SYSTEM' | translate }}</span>
  <span class="subtitle-card">{{
    'HELP_CENTER.VERSION_AND_UPDATE' | translate
  }}</span>
</div>
