export enum VaccinesEnum {
  COVISHIELD_ASTRAZENECA_OXFORD = 1,
  CORONAVAC_SINOVAC = 2,
  PFIZER_BIONTECH = 3,
  JANSSEN_JOHNSON_JOHNSON = 4,
  SPUTNIK = 5,
  COVAXIN = 6,
  VEROCELL_SINOPHARM = 7,
  MODERNA = 8,
  RESTRICAO_MEDICA = 9,
  OTHER_VACCINE = 10,
}
