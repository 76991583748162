import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Extensions } from '../../shared/extensions';
import { EmployeeModel } from '../../shared/models';
import { MapperHelper } from '../mapper/mapper-helper';
import { BaseService } from './base.service';

@Injectable()
export class MedicalRecordService extends BaseService {
  public opts = [];
  private readonly _idiom;
  private readonly _baseUrlEmployee = 'employee';
  private readonly _baseUrlPatient = 'patient';
  private readonly _baseUrlMedicalRecord = 'medical-record';
  private readonly _baseUrlQuickExamCycle = 'quick-exam-cycle';
  private readonly _baseUrlEvents = 'events';

  constructor(httpClient: HttpClient) {
    super(httpClient, null, environment.api);
    this._idiom = localStorage.getItem(Extensions.STORAGE_KEYS.CULTURE);
    if (this._idiom === 'pt' || this._idiom === 'pt-MZ') {
      this._idiom = 'pt-BR';
    }
  }

  public getEmployees(text: string): Observable<any> {
    return this.get<any>(`${this._baseUrlEmployee}/filter`, { text: text });
  }

  public getQuickExamEmployees(text: string): Observable<any> {
    return this.get<any>(`${this._baseUrlPatient}/quick-exam`, { text: text });
  }

  public getEmployeesWithLocation(
    text: string,
    ssoLocation: string
  ): Observable<any> {
    return this.get<any>(`${this._baseUrlEmployee}/filter`, {
      text: text,
      sso: ssoLocation,
    });
  }

  public getRegion(): Observable<any> {
    return this.getRegionApi().pipe(
      map((result) => {
        return result.map((region) => {
          return {
            key: region.ssoLocal,
            value: region.descriptionSsoLocal,
          };
        });
      })
    );
  }

  public saveMedicalRecord(employee: EmployeeModel): Observable<any> {
    const employeeSave: any = employee.medicalRecord;

    employeeSave.employee = {
      id: employee.id,
      typeEmployee: employee.typeEmployee,
      cpf: employee.document
        ? employee.document.replace(/[^\d]/gm, '')
        : employee.document,
      name: employee.name,
      code: employee.code,
      director: employee.director,
      role: employee.role,
      manager: employee.manager,
      description: employee.description,
      ssoLocation: employee.ssoLocation,
      checklist: [],
      comorbidities: employee.comorbidities,
      dependent: employee.dependent,
      outsourced: employee.outsourced,
    };

    return this.post<any>(`${this._baseUrlMedicalRecord}`, employeeSave);
  }

  public getEmployeeMedicalRecords(employeeId: number): Observable<any> {
    return this.get<any>(
      `${this._baseUrlMedicalRecord}/employee/${employeeId}`
    );
  }

  public getEmployeeMedicalRecordById(recordId: number): Observable<any> {
    return this.getEmployeeConsolidateMedicalRecordById(recordId).pipe(
      map((medicalRecordApi: any) =>
        MapperHelper.onMedicalRecordMapper(medicalRecordApi)
      )
    );
  }

  public saveRectification(
    employee: EmployeeModel,
    recordId: number
  ): Observable<any> {
    const medicalRecord: any = employee.medicalRecord;
    medicalRecord.employee = {
      typeEmployee: employee.typeEmployee,
      cpf: employee.document
        ? employee.document.replace(/[^\d]/gm, '')
        : employee.document,
      name: employee.name,
      code: employee.code,
      director: employee.director,
      role: employee.role,
      manager: employee.manager,
      description: employee.description,
      ssoLocation: employee.ssoLocation,
      checklist: [],
      comorbidities: employee.comorbidities,
    };

    return this.post<any>(
      `${this._baseUrlMedicalRecord}/${recordId}/rectification`,
      medicalRecord
    );
  }

  public getPatientProtocolSituation(gcmId: number): Observable<any> {
    return this.get<any>(
      `${this._baseUrlQuickExamCycle}/employee/${gcmId}/second-phase-analysis`
    );
  }

  public getPatientInfoEvolutionByEmployeeId(
    patientId: number,
    filterList?: number[]
  ): Observable<any> {
    return this.get<any>(
      `${this._baseUrlEvents}/employee/${patientId}/evolution-event`,
      {
        FilterList: filterList,
      }
    );
  }

  public getEmployeeProfile(idiom: string, iamId: number): Observable<any> {
    return this.get<any>(`${this._baseUrlEmployee}/profile/${idiom}`, {
      IamId: iamId,
    });
  }

  public getEmployeeProfileFromMdm(iamId: number): Observable<any> {
    return this.get<any>(`${this._baseUrlEmployee}/profile-mdm`, {
      IamId: iamId,
    });
  }

  private getEmployeeConsolidateMedicalRecordById(
    recordId: number
  ): Observable<any> {
    let idiom = this._idiom;
    if (idiom === 'pt' || idiom === 'pt-MZ') {
      idiom = 'pt-BR';
    }

    return this.get<any>(`${this._baseUrlMedicalRecord}/${recordId}/${idiom}`);
  }

  private getRegionApi(): Observable<any> {
    return this.get<any>(`sso-location`);
  }
}
