import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { ValeContainerComponent } from './vale-container.component';

@NgModule({
  declarations: [ValeContainerComponent],
  imports: [CommonModule],
  exports: [ValeContainerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeContainerModule {}
