<app-vale-container>
  <div class="container-all">
    <div class="checklist-container">
      <div class="container" *ngIf="checklists$ | async as checklists">
        <div *ngFor="let checklist of checklists">
          <mat-expansion-panel [disabled]="!checklist.answered">
            <mat-expansion-panel-header
              class="header-accordion"
              [ngClass]="{ 'header-accordion-disable': !checklist.answered }"
            >
              <mat-panel-title
                class="datetime-answered"
                *ngIf="checklist.date && checklist.answered; else name"
              >
                <span>
                  {{ checklist.date | date: 'dd/MM/yy - HH:mm' }}
                </span>
                <span>
                  <span
                    *ngIf="checklist.symptoms.length > 0; else withoutSymptoms"
                    class="with-symptoms"
                  >
                    {{ 'PATIENT_RECORD.CHECKLIST.WITH_SYMPTOMS' | translate }}
                  </span>
                  <ng-template #withoutSymptoms>
                    <span class="without-symptoms">
                      {{
                        'PATIENT_RECORD.CHECKLIST.WITHOUT_SYMPTOMS' | translate
                      }}
                    </span>
                  </ng-template>
                </span>
                <span
                  [ngClass]="
                    checklist.origin == 'URA'
                      ? 'origin-ura'
                      : 'origin-valeforms'
                  "
                >
                  {{ checklist.origin }}
                </span>
              </mat-panel-title>
              <ng-template #name>
                <mat-panel-title class="datetime">
                  {{
                    checklist.date
                      ? (checklist.date | date: 'dd/MM/yy')
                      : checklist.name
                  }}
                  <span *ngIf="checklist.numberOfDaysWithoutResponse">
                    - {{ checklist.numberOfDaysWithoutResponse }}
                    {{
                      'PATIENT_RECORD.CHECKLIST.DAYS_WITHOUT_RESPONSE'
                        | translate
                    }}
                    {{ 'PATIENT_RECORD.CHECKLIST.FROM' | translate }}
                    {{ checklist.firstDayWhitoutResponse | date: 'dd/MM' }}
                    {{ 'PATIENT_RECORD.CHECKLIST.TO' | translate }}
                    {{ checklist.date | date: 'dd/MM' }}
                  </span>
                  <span *ngIf="!checklist.numberOfDaysWithoutResponse">
                    -
                    {{
                      'PATIENT_RECORD.CHECKLIST.THERE_IS_NO_RECORDED_RESPONSE'
                        | translate
                    }}
                  </span>
                </mat-panel-title>
              </ng-template>
            </mat-expansion-panel-header>

            <div class="content">
              <div
                class="symptom-group"
                *ngIf="checklist.symptoms.length; else notSymptoms"
              >
                <div class="question">
                  {{ 'PATIENT_RECORD.CHECKLIST.SYMPTOMS' | translate }}
                </div>
                <div
                  class="symptom-container"
                  *ngFor="let symptom of checklist.symptoms"
                >
                  <div class="symptom-text">{{ symptom.description }}</div>
                </div>
              </div>
              <ng-template #notSymptoms>
                <div class="symptom-group">
                  <div class="question">
                    {{ 'PATIENT_RECORD.CHECKLIST.SYMPTOMS' | translate }}
                  </div>
                  <div class="symptom-container">
                    <div class="without-symptom-text">
                      {{
                        'PATIENT_RECORD.CHECKLIST.NO_REPORTED_SYMPTOMS'
                          | translate
                      }}
                    </div>
                  </div>
                </div>
              </ng-template>

              <div class="answers-group">
                <div class="item">
                  <div class="question">
                    {{
                      'PATIENT_RECORD.CHECKLIST.CONTACT_INFECTED' | translate
                    }}
                  </div>
                  <div class="answers">
                    <p>
                      {{
                        (checklist?.contact ? 'GENERAL.YES' : 'GENERAL.NO')
                          | translate
                      }}
                    </p>
                  </div>
                </div>

                <div class="item">
                  <div class="question">
                    {{
                      'PATIENT_RECORD.CHECKLIST.QUARANTINED_ISOLATED'
                        | translate
                    }}
                  </div>
                  <div class="answers">
                    <p>
                      {{
                        (checklist?.quarantine ? 'GENERAL.YES' : 'GENERAL.NO')
                          | translate
                      }}
                    </p>
                  </div>
                </div>

                <div class="item">
                  <div class="question">
                    {{
                      'PATIENT_RECORD.CHECKLIST.MEDICAL_INDICATION' | translate
                    }}
                  </div>
                  <div class="answers">
                    <p>
                      {{
                        (checklist?.labExam ? 'GENERAL.YES' : 'GENERAL.NO')
                          | translate
                      }}
                    </p>
                  </div>
                </div>

                <div class="item">
                  <div class="question">
                    {{
                      'PATIENT_RECORD.CHECKLIST.EMOTIONAL_SUPPORT' | translate
                    }}
                  </div>
                  <div class="answers">
                    <p>
                      {{
                        (checklist?.agreeContact ? 'GENERAL.YES' : 'GENERAL.NO')
                          | translate
                      }}
                    </p>
                  </div>
                </div>

                <div class="item">
                  <div class="question">
                    {{ 'PATIENT_RECORD.CHECKLIST.WORK_MODE' | translate }}
                  </div>
                  <div class="answers">
                    <p>
                      {{ checklist?.workMode ? checklist.workMode : '-' }}
                    </p>
                  </div>
                </div>

                <div class="item">
                  <div class="question">
                    {{ 'PATIENT_RECORD.CHECKLIST.COMPANY' | translate }}
                  </div>
                  <div class="answers">
                    <p>
                      {{ checklist?.company ? checklist.company : '-' }}
                    </p>
                  </div>
                </div>

                <div class="item">
                  <div class="question">
                    {{ 'PATIENT_RECORD.CHECKLIST.INFORM_COMPANY' | translate }}
                  </div>
                  <div class="answers">
                    <p>
                      {{
                        checklist?.contractorCompany
                          ? checklist.contractorCompany
                          : '-'
                      }}
                    </p>
                  </div>
                </div>

                <div class="item">
                  <div class="question">
                    {{
                      'PATIENT_RECORD.CHECKLIST.IDENTIFICATION_NUMBER'
                        | translate
                    }}
                  </div>
                  <div class="answers">
                    <p>
                      {{
                        checklist?.contractorIdDoc
                          ? checklist.contractorIdDoc
                          : '-'
                      }}
                    </p>
                  </div>
                </div>

                <div class="item">
                  <div class="question">
                    {{
                      'PATIENT_RECORD.CHECKLIST.LOCATE_SUPERVISOR' | translate
                    }}
                  </div>
                  <div class="answers">
                    <p>
                      {{
                        checklist?.contractorSupervisor
                          ? checklist.contractorSupervisor
                          : '-'
                      }}
                    </p>
                  </div>
                </div>

                <div class="item">
                  <div class="question">
                    {{ 'PATIENT_RECORD.CHECKLIST.PHONE' | translate }}
                  </div>
                  <div class="answers">
                    <p>
                      {{
                        checklist?.contractorPhone
                          ? checklist.contractorPhone
                          : checklist.employeePhone
                          ? checklist.employeePhone
                          : '-'
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
          <div
            class="container-small-image"
            *ngIf="checklist.numberOfDaysWithoutResponse"
          >
            <img
              src="./assets/images/checklist-hourglass.svg"
              alt="imagem de uma ampulheta"
            />
          </div>
        </div>
        <div *ngIf="!checklists.length" class="checklist-not-found">
          <div class="container-image">
            <img
              class="empty-image"
              src="./assets/images/employee_not_found.svg"
              alt="imagem de uma lupa indicando que não foi possível encontrar checklist"
            />
          </div>
          <div class="title">
            <span class="empty-state-text">{{
              'PATIENT_RECORD.CHECKLIST.NOT_FOUND' | translate
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-vale-container>
