import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-patient-info-dependent-contact',
  templateUrl: './patient-info-dependent-contact.component.html',
  styleUrls: ['./patient-info-dependent-contact.component.scss'],
})
export class PatientInfoDependentContactComponent implements OnInit {
  @Input() public formContact: FormGroup;

  constructor() {}

  public ngOnInit(): void {}
}
