import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ValeTimelineComponent } from './vale-timeline.component';

@NgModule({
  declarations: [ValeTimelineComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [ValeTimelineComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeTimelineModule {}
