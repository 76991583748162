<div class="container-customer-satisfaction-score">
  <div class="content centered">
    <form [formGroup]="scoreForm" class="centered">
      <div class="container-scores">
        <div
          *ngFor="let score of scores"
          class="score-option"
          (click)="selectOption(score)"
        >
          <img
            style="width: 50px; height: 50px"
            src="./assets/icons/{{
              score?.option == selectedOption?.option
                ? score.option + '-on.svg'
                : score.option + '-off.svg'
            }}"
            alt="card to represent score"
          />
          <div class="centered">
            <span
              class="title {{
                score?.option == selectedOption?.option
                  ? 'selected'
                  : 'not-selected'
              }}"
              [innerHtml]="getMessageScoreTitle(score.option) | translate"
            ></span>
          </div>
        </div>
      </div>
      <div *ngIf="scoreForm.valid" class="container-notes centered">
        <div class="wapper-description">
          <span
            class="score-description"
            [innerHtml]="getMessageScoreDescription() | translate"
          ></span>
        </div>
        <div class="text-area-note">
          <textarea
            rows="4"
            cols="46"
            [maxlength]="MAX_LENGTH_TEXTAREA"
            formControlName="note"
            placeholder="{{
              'HELP_CENTER.CUSTOMER_SATISFACTION.TEXT_AREA.PLACEHOLDER'
                | translate
            }}"
          ></textarea>
          <div>
            <span class="title"
              >{{ getNumbersCharacteres() }}
              {{
                'HELP_CENTER.CUSTOMER_SATISFACTION.TEXT_AREA.CHARACTERES_LEFT'
                  | translate
              }}</span
            >
          </div>
        </div>
        <div>
          <button app-vale-flat-button color="primary" (click)="save()">
            {{ 'HELP_CENTER.CUSTOMER_SATISFACTION.BUTTONS.SEND' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
