import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ValeRemovableTagModule } from '../vale-removable-tag/vale-removable-tag.module';
import { ValeButtonModule } from './../vale-button/vale-button.module';
import { GenericFilterDialogComponent } from './components/generic-filter-dialog/generic-filter-dialog.component';
import { ValeExpansionFilterComponent } from './vale-expansion-filter.component';

@NgModule({
  declarations: [ValeExpansionFilterComponent, GenericFilterDialogComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    ValeButtonModule,
    MatIconModule,
    ValeRemovableTagModule,
    TranslateModule,
    MatTooltipModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ValeExpansionFilterComponent, GenericFilterDialogComponent],
})
export class ValeExpansionFilterModule {}
