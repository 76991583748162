<div class="container-send-email">
  <div class="header-modal">
    <div class="close-button">
      <mat-icon (click)="closeModal()" class="close-icon">close</mat-icon>
    </div>
  </div>
  <div class="container-body">
    <div class="icon">
      <img src="./assets/images/attention.svg" alt="attention image" />
    </div>
    <div class="title">
      <span>{{ 'DIALOG_CONFIRM_EMAIL.TITLE' | translate }}</span>
    </div>
    <div class="description">
      <span>
        {{ 'DIALOG_CONFIRM_EMAIL.DESCRIPTION' | translate }}
      </span>
    </div>
    <form [formGroup]="emailForm">
      <app-vale-label class="input">
        <span>{{ 'DIALOG_CONFIRM_EMAIL.TITLE_INPUT' | translate }}</span>
        <input
          valeInput
          formControlName="managerEmail"
          placeholder="{{
            'DIALOG_CONFIRM_EMAIL.PLACEHOLDER_INPUT' | translate
          }}"
        />
      </app-vale-label>
    </form>
  </div>
  <div
    [ngClass]="[
      !emailForm.valid
        ? 'buttons-container buttons-container--disabled'
        : 'buttons-container buttons-container--enabled'
    ]"
  >
    <button
      app-vale-button
      color="gray"
      [mat-dialog-close]="false"
      class="option-buttons half-cancel"
    >
      {{ 'DIALOG.BUTTON_CANCEL' | translate }}
    </button>
    <button
      app-vale-flat-button
      color="primary"
      [mat-dialog-close]="emailForm?.get('managerEmail').value"
      [disabled]="!emailForm.valid"
    >
      {{ 'DIALOG.BUTTON_SEND' | translate }}
    </button>
  </div>
</div>
