<div class="container-form">
  <form [formGroup]="formContact">
    <div class="form-input form-input--padding-bottom-12">
      <app-vale-label>
        <div class="title">
          {{ 'PATIENT_INFO.PATIENT_INFO_CONTACT.PHONE' | translate }}
        </div>
        <input
          valeInput
          disabled="true"
          outlineNone="true"
          type="text"
          formControlName="personalPhone"
          placeholder="-"
        />
      </app-vale-label>
    </div>
    <div class="form-input form-input--padding-bottom-12">
      <app-vale-label>
        <div class="title">
          {{ 'PATIENT_INFO.PATIENT_INFO_CONTACT.BACKUP_PHONE' | translate }}
        </div>
        <input
          valeInput
          disabled="true"
          outlineNone="true"
          type="text"
          formControlName="alternativePhone"
          placeholder="-"
        />
      </app-vale-label>
    </div>
    <div class="form-input form-input--padding-bottom-12">
      <app-vale-label>
        <div class="title">
          {{ 'PATIENT_INFO.PATIENT_INFO_CONTACT.CHECKLIST' | translate }}
        </div>
        <input
          valeInput
          disabled="true"
          outlineNone="true"
          type="text"
          formControlName="checklistPhone"
          placeholder="-"
        />
      </app-vale-label>
    </div>
    <div class="form-input">
      <app-vale-label>
        <div class="title">
          {{ 'PATIENT_INFO.PATIENT_INFO_CONTACT.MANAGER' | translate }}
        </div>
        <input
          valeInput
          disabled="true"
          outlineNone="true"
          type="text"
          formControlName="managerPhone"
          placeholder="-"
        />
      </app-vale-label>
    </div>
  </form>
</div>
