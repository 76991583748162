import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Extensions } from '../../shared/extensions';
import { AccessCardData } from '../../shared/models/access-card-data';
import { BlueAccessCardFunctionLog } from '../../shared/models/blue-access-card-function-log';
import { Card } from '../../shared/models/card';
import { EmployeeMdmModel } from '../../shared/models/employee-mdm.model';
import { MdmSearchModel } from '../../shared/models/mdm-search.model';
import { PatientCardSituationModel } from '../../shared/models/patient-card-situation.model';
import { BaseService } from './base.service';

@Injectable()
export class CardManagementService extends BaseService {
  private readonly _idiom;
  private readonly _baseUrl = 'card-management';

  constructor(httpClient: HttpClient) {
    super(httpClient, null, environment.api);
    this._idiom = localStorage.getItem(Extensions.STORAGE_KEYS.CULTURE);
    if (this._idiom === 'pt' || this._idiom === 'pt-MZ') {
      this._idiom = 'pt-BR';
    }
  }

  /**
   * Get employee mdm
   * @param {Object} search  MdmSearchModel
   * @returns {EmployeeMdmModel } EmployeeMdmModel
   * @returns {ApiErrorModel} exception business exception
   * @usage
   *   getEmployee(search);
   */
  public getEmployee(search: MdmSearchModel): Observable<EmployeeMdmModel> {
    return this.get<any>(`${this._baseUrl}/employee/mdm`, search);
  }

  public getCards(): Observable<Card[]> {
    return this.get<Card[]>(`${this._baseUrl}/access-cards`);
  }

  public getLastSyncStatus(): Observable<BlueAccessCardFunctionLog[]> {
    return this.get<BlueAccessCardFunctionLog[]>(
      `${this._baseUrl}/last-sync-status`
    );
  }

  public saveStatusCard(employee: AccessCardData): Observable<any> {
    return this.post<any>(
      `${this._baseUrl}/employee-access-card-situation`,
      employee
    );
  }

  /**
   * Get patient card situation
   * @param {number} gcmId
   * @param {number} employeeTypeId PatientTypeEnum
   * @returns {PatientCardSituationModel } PatientCardSituationModel
   * @returns {ApiErrorModel} exception business exception
   * @usage
   *   patientCardSituation(1, PatientTypeEnum.VALE);
   */
  public patientCardSituation(
    gcmId: number,
    employeeTypeId: number
  ): Observable<PatientCardSituationModel> {
    const search = {
      idiom: this._idiom,
    };
    return this.get<PatientCardSituationModel>(
      `${this._baseUrl}/patient/${gcmId}/profile/${employeeTypeId}/card-situation`,
      search
    );
  }
}
