import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';

import { UserBusiness } from '../../shared/business/user-business.service';
import { Extensions } from '../../shared/extensions';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private _userBusiness: UserBusiness
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const cred = parseInt(localStorage.getItem('expires_at'), 10);
    const localToken = localStorage.getItem(Extensions.STORAGE_KEYS.TOKEN);

    if (new Date(cred) > new Date() || localToken) {
      return this._hasPermissionToAccessRoute(route);
    } else {
      this.authenticationService.doLogout();
      return false;
    }
  }

  private _hasPermissionToAccessRoute(route: ActivatedRouteSnapshot): boolean {
    const urlSegments = route.url;
    if (urlSegments.length) {
      const path = urlSegments[0].path;
      if (path === 'admin-center') {
        return (
          this._userBusiness.getUserPermission.hasPermissionAdminCenter ||
          this._userBusiness.getUserPermission.hasPermissionSupport
        );
      }
      if (path === 'card-management') {
        return this._userBusiness.getUserPermission.hasPermissionCardManagement;
      }
      if (path === 'patient') {
        return this._userBusiness.getUserPermission.hasPermissionMedicalRecord;
      }
      if (path === 'monitoring-center') {
        return this._userBusiness.getUserPermission
          .hasPermissionMonitoringCenter;
      }
      if (path === 'quick-exam') {
        return this._userBusiness.getUserPermission.hasPermissionQuickTest;
      }
      if (path === 'absence-control') {
        return this._userBusiness.getUserPermission.hasPermissionAbsenceControl;
      }

      if (path === 'search') {
        return (
          this._userBusiness.getUserPermission.hasPermissionQuickTest ||
          this._userBusiness.getUserPermission.hasPermissionMedicalRecord
        );
      }
      if (path === 'ura-access-card') {
        return this._userBusiness.getUserPermission.hasPermissionUraAccessCard;
      }
      if (path === 'vale-forms-vaccines') {
        return this._userBusiness.getUserPermission.hasPermissionMedicalRecord;
      }
    }
    return true;
  }
}
