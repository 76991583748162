import { OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export interface OverlayCloseEvent<R> {
  type: 'backdropClick' | 'close';
  data: R;
}

// R = Response Data Type, T = Data passed to Modal Type
export class FilterDialogRef<R = any, T = any> {
  afterClosed$ = new Subject<OverlayCloseEvent<R> | any>();

  constructor(
    public overlay: OverlayRef,
    public content: string | TemplateRef<any> | Type<any>,
    public data: T,
    private state: BehaviorSubject<any>
  ) {
    overlay.backdropClick().subscribe(() => {
      this._close('backdropClick', null);
    });
  }

  public close(data?: R): void {
    this._close('close', data);
  }

  public get isDisabledConfirmButton(): boolean {
    return this.state && this.state.value;
  }

  public sendAnswerWithoutCloseDialog(data?: R): void {
    this.afterClosed$.next({
      type: 'backdropClick',
      data,
    });

    this.afterClosed$.complete();
  }

  private _close(type: 'backdropClick' | 'close', data: R) {
    this.overlay.dispose();
    this.afterClosed$.next({
      type,
      data,
    });

    this.afterClosed$.complete();
  }
}
