import { Input } from '@angular/core';
import { Component, EventEmitter, Output } from '@angular/core';

import { TopicsModel } from './../../../models/topics-model';

@Component({
  selector: 'app-help-center-topics',
  templateUrl: './help-center-topics.component.html',
  styleUrls: ['./help-center-topics.component.scss'],
})
export class HelpCenterTopicsComponent {
  @Output() public returnMenu = new EventEmitter();

  @Input() public topics: TopicsModel[];
  @Input() public quotes = [];
  @Input() public term: string;
  @Input() public showAllTopics: boolean;

  public title: string;
  public backResult: any;
  public showResult: boolean;

  constructor() {}

  public back(): void {
    this.returnMenu.emit();
  }

  public backToResult(): void {
    this.backResult = { ...this.backResult };
    this.title = undefined;
  }

  public changeTitle(title: string): void {
    this.title = title;
  }

  public shoeResultSearch(show: boolean): void {
    this.showResult = show;
  }
}
