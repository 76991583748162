import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { HelpCenterService } from '../../../../core/services/help-center.service';
import { Storage } from '../../../../shared/util/storage';
import { NameGcmToolsConstant } from '../../../constants/name-gcm-tools.constant';
import { ReleaseNoteHistoryModel } from '../../../models/ReleaseNoteHistoryModel';
import { AnalyticsService } from '../../../services/analytics.service';
import { TopicsModel } from './../../../models/topics-model';

@Component({
  selector: 'app-help-center-home',
  templateUrl: './help-center-home.component.html',
  styleUrls: ['./help-center-home.component.scss'],
})
export class HelpCenterHomeComponent {
  @Output() public helpCenterState = new EventEmitter<boolean>();

  public search: FormControl;

  public optionPage = 'menu';

  public topics: TopicsModel[];
  public quotes = [];
  public topicsOriginal: TopicsModel[];
  public releaseNotesHistory: ReleaseNoteHistoryModel[];
  public term: string;
  public showAllTopics = false;
  private _storage: Storage = new Storage();

  constructor(
    private eRef: ElementRef,
    private helpCenterService: HelpCenterService,
    private analyticsService: AnalyticsService
  ) {
    this.search = new FormControl('');
    const releaseNotesHistory = this._storage.getSession<any>(
      'release_notes_history'
    );
    this.saveReleaseNotesDataInLocalStorage(releaseNotesHistory);
    const heplCenterItem = this._storage.getSession<any>('help_center_item');
    this.saveHelpCenterDataInLocalStorage(heplCenterItem);
    this.search.valueChanges
      .pipe(debounceTime(500))
      .subscribe((term: string) => {
        term = term.trim();

        if (term.length >= 1) {
          this.selectPage('topics');
          this.quotes = this.filterTopics(term, this.topicsOriginal);
          this.analyticsService.sendEventToGA(
            NameGcmToolsConstant.HELP_CENTER,
            'Pesquisar em Tópicos Populares',
            term
          );

          this.showAllTopics = false;
        } else {
          this.optionPage = 'menu';
        }

        this.term = term;
      });
  }

  private saveHelpCenterDataInLocalStorage(storage: string) {
    if (storage != null && storage != '') {
      this.topicsOriginal = JSON.parse(
        this._storage.getSession<any>('help_center_item')
      );
      this.topics = this.topicsOriginal;
    } else {
      this.helpCenterService.getTopics().subscribe((topics) => {
        this.topics = topics;
        this.topicsOriginal = topics;
        this._storage.setSession<any>(
          'help_center_item',
          JSON.stringify(topics)
        );
      });
    }
  }

  private saveReleaseNotesDataInLocalStorage(storage: any) {
    if (storage != null && storage != '') {
      this.releaseNotesHistory = storage;
    } else {
      this.helpCenterService.getReleaseNotesHistory().subscribe((topics) => {
        this.releaseNotesHistory = topics;
        this._storage.setSession<any>('release_notes_history', topics);
      });
    }
  }

  public highlight(search, text, startHighlight, endHighlight) {
    const regexp = RegExp(search.replace(/([+|-|(|)|{|}])/g, '\\$1'), 'gi');
    const strOriginal = text;
    const strNormalize = strOriginal
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    let highlightText = '';
    let index = 0;
    let match;
    let searchTextOriginal;

    while ((match = regexp.exec(strNormalize)) !== null) {
      const lastIndex = regexp.lastIndex;
      highlightText += strOriginal.substring(index, match.index);
      searchTextOriginal = strOriginal.substring(match.index, lastIndex);
      highlightText += `${startHighlight}${searchTextOriginal}${endHighlight}`;
      index = lastIndex;
    }
    highlightText += strOriginal.substring(index);

    const textWithoutTags = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/<(?:!|\x2f?[a-zA-Z]+).*?\x2f?>/g, '');

    let lengthDisplayText = 50;
    const quotes = [];

    while ((match = regexp.exec(textWithoutTags)) !== null) {
      const lastIndex = regexp.lastIndex;
      lengthDisplayText -= search.length;
      const half = Math.floor(lengthDisplayText / 2);
      const startDescription = textWithoutTags.substring(
        match.index - half,
        match.index
      );
      const term = textWithoutTags.substring(match.index, lastIndex);
      const endDescription = textWithoutTags.substring(
        lastIndex,
        lastIndex + half
      );
      const termWithHighlight = `${startHighlight}${term}${endHighlight}`;

      quotes.push({
        quote: `${startDescription}${termWithHighlight}${endDescription}`,
        original: highlightText,
      });
    }

    return quotes;
  }

  public filterTopics(term: string, topics: TopicsModel[]) {
    const result = [];

    for (const father of topics) {
      for (const children of father.children) {
        const description = children.description
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/<\w+>|<\x2f\w+>/g, '')
          .toLowerCase();

        term = term
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        if (description.includes(term)) {
          const quotes = this.highlight(
            term,
            children.description,
            '<span style="background-color: #ECB11F;">',
            '</span>'
          );

          result.push(
            ...quotes.map((q) => ({
              ...q,
              father,
              children,
            }))
          );
        }
      }
    }

    return result;
  }

  public closeHelpCenter(): void {
    this.helpCenterState.emit(false);
  }

  public returnMenu(): void {
    this.optionPage = 'menu';
    this.search.setValue('');
  }

  public selectPage(page: string): void {
    if (page === 'topics') {
      this.showAllTopics = true;
    }
    this.optionPage = page;
  }
}
