import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-help-center-menu',
  templateUrl: './help-center-menu.component.html',
  styleUrls: ['./help-center-menu.component.scss'],
})
export class HelpCenterMenuComponent implements OnInit {
  @Output() public page = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  public selectPage(page: string): void {
    this.page.emit(page);
  }
  public openPage(url: string): void {
    window.open(url, '_blank');
  }
}
