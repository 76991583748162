<div [ngClass]="{ 'container-checked': checked }" class="container">
  <div class="radio-centered">
    <input
      type="radio"
      [name]="name"
      [disabled]="disabled"
      [checked]="checked"
    />
    <div class="radio-label">
      <ng-content></ng-content>
    </div>
  </div>
</div>
