import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-help-center-topics-search',
  templateUrl: './help-center-topics-search.component.html',
  styleUrls: ['./help-center-topics-search.component.scss'],
})
export class HelpCenterTopicsSearchComponent {
  @Input() public results = [];
  @Output() public shoeResultSearch = new EventEmitter();
  @Output() public title = new EventEmitter();

  public original: string;
  public show = false;

  @Input()
  public set backToResult(value) {
    this.show = false;
    this.shoeResultSearch.emit(this.show);
  }

  public details(result: any): void {
    this.original = result.original;
    this.show = true;
    this.title.emit(result.children.title);
    this.shoeResultSearch.emit(this.show);
  }
}
