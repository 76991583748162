import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { FilterTag } from '../../models/filter-tag.interface';
import { Storage } from '../../util/storage';
import { ValeExpansionFilterDialogService } from './vale-expansion-filter-dialog.service';

@Component({
  selector: 'app-vale-expansion-filter',
  templateUrl: './vale-expansion-filter.component.html',
  styleUrls: ['./vale-expansion-filter.component.scss'],
  providers: [ValeExpansionFilterDialogService],
})
export class ValeExpansionFilterComponent implements OnInit, OnDestroy {
  public panelOpenState = true;
  public form: AbstractControl;
  customCollapsedHeight = '72px';
  customExpandedHeight = '72px';

  @Input() public entryFilterComponent;
  @Input() public entryFilterComponentData;
  @Input() public title: string;
  @Input() public isFilterIconActive: boolean;
  @Input('initialFilterTags') filtersTag: any = [];
  @Input('initialForm') initialForm: any = {};
  @Output('change')
  @Output('openFilter')
  private _openFilterEvent = new EventEmitter();
  @Output('closeFilter')
  private _closeFilterEvent = new EventEmitter();
  @Output('changeFilter')
  private _changeFilterEvent = new EventEmitter();

  private _subscriptions = new Subscription();

  private _storage: Storage = new Storage();

  constructor(private _filterDialogService: ValeExpansionFilterDialogService) {}
  ngOnInit(): void {
    if (this.entryFilterComponent && this.entryFilterComponentData) {
      this._filterDialogService.setDataChild(this.entryFilterComponentData);
    }
    if (!this.form && this.initialForm) {
      this.form = this.initialForm;
    }
  }
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public openFilterAndEmitEvent($event): void {
    if ($event) {
      $event.stopPropagation();
    }
    this._openFilterEvent.emit();
    if (this.entryFilterComponent) {
      const dialogRef = this._filterDialogService.open(
        this.entryFilterComponent
      );
      if (this.form) {
        this._filterDialogService.setDataChild(this.form.value);
      }
      this._subscriptions.add(
        dialogRef.afterClosed$.subscribe((res: any) => {
          if (res && res.data) {
            this.form = res.data.form;
            this._closeFilterEvent.emit(res.data);
            this._changeFilterEvent.emit(res.data);
            this.filtersTag = res.data.filtersTag || [];
            this._storage.setSession<any>('VaccineFormValue', this.form.value);
          }
        })
      );
    }
  }

  public changeTags($event: any): void {
    this.openFilterAndEmitEvent($event);
  }

  public closeTag(index: number, filter: FilterTag): void {
    if (index > -1 && filter.isRemovable) {
      if (this.form) {
        this.form.get(filter.formControlName).setValue(null);
      }
      this._changeFilterEvent.emit({
        form: this.form,
        filtersTag: [],
      });
      this.filtersTag.splice(index, 1);
    }
  }
}
