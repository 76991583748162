export enum AccessCard {
  WITHOUT_CARD = 1,
  GREEN_CARD = 2,
  BLUE_CARD_FREQUENT = 3,
  YELLOW_CARD = 4,
  RED_CARD_LATE_TEST = 5,
  RED_CARD_SYMPTOMATIC = 6,
  RED_CARD_GROUP_RISK = 7,
  RED_CARD_RELEASE = 8,
  BLUE_CARD_RELEASE = 9,
  BLUE_CARD_EVENTUAL = 10,
  GREEN_CARD_RETEST = 11,
  RED_CARD_QUARANTINE = 12,
  RED_CARD_VACCINE = 13,
  BLUE_CARD_AND_GREEN = 72,
  BLUE_CARD_AND_YELLOW = 73,
  BLUE_CARD_AND_RED = 75,
  RED_CARD_TEST_POSITIVE_QUARANTINE = 14,
  RED_CARD_BY_CONTACT_QUARANTINE = 15,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AccessCard {
  export function toString(mode: AccessCard): string {
    return AccessCard[mode];
  }

  export function parse(mode: string): AccessCard {
    return AccessCard[mode];
  }
}
