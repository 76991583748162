import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ValePrefixDirective } from './directive/vale-prefix.directive';
import { ValeSuffixDirective } from './directive/vale-suffix.directive';
import { ValeIconComponent } from './vale-icon.component';

@NgModule({
  declarations: [ValeIconComponent, ValeSuffixDirective, ValePrefixDirective],
  exports: [ValeIconComponent, ValeSuffixDirective, ValePrefixDirective],
  imports: [CommonModule],
})
export class ValeIconModule {}
