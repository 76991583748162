import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-patient-info-vale-contact',
  templateUrl: './patient-info-vale-contact.component.html',
  styleUrls: ['./patient-info-vale-contact.component.scss'],
})
export class PatientInfoValeContactComponent implements OnInit {
  @Input() public formContact: FormGroup;
  constructor() {}

  ngOnInit(): void {}
}
