import { Injectable } from '@angular/core';

import { GoogleAnalyticsService } from '../../core/services/google-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(public googleAnalyticsService: GoogleAnalyticsService) {}

  public sendEventToGA(
    eventCategory: string,
    eventAction: string,
    eventLabel: string
  ) {
    this.googleAnalyticsService.eventEmitter(
      eventCategory,
      eventAction,
      eventLabel,
      0
    );
  }
}
