import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

import { CustomDropdownModule } from '../../components/custom-dropdown/custom-dropdown.module';
import { MaterialComponentsModule } from '../../material.module';
import { ValeLibModule } from '../../vale-lib/vale-lib.module';
import { PatientInfoChecklistBusiness } from './patient-info-cheklist/patient-info-checklist-business.service';
import { PatientInfoCheklistComponent } from './patient-info-cheklist/patient-info-cheklist.component';
import { PatientInfoDependentContactComponent } from './patient-info-dependent/patient-info-dependent-contact/patient-info-dependent-contact.component';
import { PatientInfoDependentDataComponent } from './patient-info-dependent/patient-info-dependent-data/patient-info-dependent-data.component';
import { PatientInfoHistoryComponent } from './patient-info-history/patient-info-history.component';
import { PatientInfoOutsourcedContactComponent } from './patient-info-outsourced/patient-info-outsourced-contact/patient-info-outsourced-contact.component';
import { PatientInfoOutsourcedDataComponent } from './patient-info-outsourced/patient-info-outsourced-data/patient-info-outsourced-data.component';
import { PatientInfoRetiredContactComponent } from './patient-info-retired/patient-info-retired-contact/patient-info-retired-contact.component';
import { PatientInfoRetiredDataComponent } from './patient-info-retired/patient-info-retired-data/patient-info-retired-data.component';
import { PatientInfoUpdate } from './patient-info-update.service';
import { PatientInfoValeContactComponent } from './patient-info-vale/patient-info-vale-contact/patient-info-vale-contact.component';
import { PatientInfoValeDataComponent } from './patient-info-vale/patient-info-vale-data/patient-info-vale-data.component';
import { PatientInfoComponent } from './patient-info.component';

@NgModule({
  declarations: [
    PatientInfoDependentContactComponent,
    PatientInfoDependentDataComponent,
    PatientInfoOutsourcedContactComponent,
    PatientInfoOutsourcedDataComponent,
    PatientInfoRetiredContactComponent,
    PatientInfoRetiredDataComponent,
    PatientInfoValeContactComponent,
    PatientInfoValeDataComponent,
    PatientInfoComponent,
    PatientInfoHistoryComponent,
    PatientInfoCheklistComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ValeLibModule,
    CustomDropdownModule,
    MaterialComponentsModule,
    TranslateModule.forChild({
      extend: true,
    }),
    NgxMaskModule.forRoot(),
  ],
  exports: [
    CustomDropdownModule,
    PatientInfoDependentContactComponent,
    PatientInfoDependentDataComponent,
    PatientInfoOutsourcedContactComponent,
    PatientInfoOutsourcedDataComponent,
    PatientInfoRetiredContactComponent,
    PatientInfoRetiredDataComponent,
    PatientInfoValeContactComponent,
    PatientInfoValeDataComponent,
    PatientInfoComponent,
  ],
  providers: [PatientInfoUpdate, PatientInfoChecklistBusiness],
})
export class PatientInfoModule {}
