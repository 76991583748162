import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Extensions } from '../../shared/extensions';
import { EmployeeChecklistUra } from '../../shared/models/employee-checklist-ura.model';
import { EmployeeUraDto } from './../../shared/models/employee-ura-dto.model';
import { SearchEmployeeUra } from './../../shared/models/search-employee-ura.model';
import { BaseService } from './base.service';

@Injectable()
export class UraAccessCardService extends BaseService {
  private readonly _idiom;
  private readonly _baseUrl = 'access-card-ura';

  constructor(httpClient: HttpClient) {
    super(httpClient, null, environment.api);
    this._idiom = localStorage.getItem(Extensions.STORAGE_KEYS.CULTURE);
    if (this._idiom === 'pt' || this._idiom === 'pt-MZ') {
      this._idiom = 'pt-BR';
    }
  }

  public searchEmployees(
    searchBy: SearchEmployeeUra
  ): Observable<EmployeeUraDto[]> {
    return this.post<EmployeeUraDto[]>(`${this._baseUrl}/employees`, searchBy);
  }

  public getLastChecklistInfoUraByDocument(
    document: string
  ): Observable<EmployeeChecklistUra> {
    return this.post<EmployeeChecklistUra>(
      `${this._baseUrl}/employees/document/last-checklist-info-ura`,
      {
        document: document,
      }
    );
  }
}
