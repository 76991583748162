<div class="container">
  <div class="container-image">
    <mat-icon
      class="button-image button-image--back"
      (click)="setFirstUrl()"
      *ngIf="backImage"
    >
      <img
        src="../../../../assets/images/icons/keyboard-arrow-left.svg"
        alt="icon keyboard-arrow-left"
      />
    </mat-icon>
    <span
      is-over-directive="$event.target"
      class="vale-image"
      id="vale-image"
      #valeimage
    >
      <div
        class="image"
        *ngIf="firstUrlValid && !firstIsImage && firstUrlDownload"
      >
        <img
          src="./../../../../assets/images/vale-img-broken-link.svg"
          alt=""
        />
        <div>
          <span class="title">{{ firstExtension }}</span>
        </div>
      </div>
      <div
        class="image"
        *ngIf="secondUrlValid && !secondIsImage && secondUrlDownload"
      >
        <img
          src="./../../../../assets/images/vale-img-broken-link.svg"
          alt=""
        />
        <div>
          <span class="title">{{ secondExtension }}</span>
        </div>
      </div>
      <div
      class="image"
      *ngIf="boosterUrlValid && !boosterIsImage && boosterUrlDownload"
    >
      <img
        src="./../../../../assets/images/vale-img-broken-link.svg"
        alt=""
      />
      <div>
        <span class="title">{{ boosterExtension }}</span>
      </div>
    </div>
      <div class="image" *ngIf="!firstUrlValid && firstUrlDownload">
        <img
          src="./../../../../assets/images/vale-file-broken-link.svg"
          alt=""
        />
        <div>
          <span class="title">{{ 'VALE_IMAGE.BROKEN' | translate }}</span>
        </div>
      </div>
      <div class="image" *ngIf="!secondUrlValid && secondUrlDownload">
        <img
          src="./../../../../assets/images/vale-file-broken-link.svg"
          alt=""
        />
        <div>
          <span class="title">{{ 'VALE_IMAGE.BROKEN' | translate }}</span>
        </div>
      </div>
    </span>
    <div class="image" *ngIf="!boosterUrlValid && boosterUrlDownload">
      <img
        src="./../../../../assets/images/vale-file-broken-link.svg"
        alt=""
      />
      <div>
        <span class="title">{{ 'VALE_IMAGE.BROKEN' | translate }}</span>
      </div>
    </div>
    <mat-icon
      class="button-image button-image--next"
      (click)="setSecondUrl()"
      *ngIf="nextImage"
    >
      <img
        src="../../../../assets/images/icons/keyboard-arrow-right.svg"
        alt="icon keyboard-arrow-right"
      />
    </mat-icon>
    <mat-icon
    class="button-image button-image--next"
    (click)="setBoosterUrl()"
    *ngIf="nextImage"
  >
    <img
      src="../../../../assets/images/icons/keyboard-arrow-right.svg"
      alt="icon keyboard-arrow-right"
    />
  </mat-icon>
  </div>
  <div *ngIf="firstUrlValid || secondUrlValid || boosterUrlValid" class="button">
    <button
      app-vale-flat-button
      (click)="downloadFirstUrl()"
      [prefixIcon]="'file_download'"
      *ngIf="firstUrlValid && firstUrlDownload"
    >
      {{ 'VALE_IMAGE.DOWNLOAD' | translate }}
    </button>
    <button
      app-vale-flat-button
      (click)="downloadSecondUrl()"
      [prefixIcon]="'file_download'"
      *ngIf="secondUrlValid && secondUrlDownload"
    >
      {{ 'VALE_IMAGE.DOWNLOAD' | translate }}
    </button>
    <button
    app-vale-flat-button
    (click)="downloadBoosterUrl()"
    [prefixIcon]="'file_download'"
    *ngIf="boosterUrlValid && boosterUrlDownload"
  >
    {{ 'VALE_IMAGE.DOWNLOAD' | translate }}
  </button>
  </div>
</div>
