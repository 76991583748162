<ng-container
  *ngIf="overlay; else spinner === 'sk-cube' ? skCube : progressSpinner"
>
  <div class="container-vale-loading-overlay">
    <div class="container-vale-loading-center">
      <ng-template
        [ngTemplateOutlet]="spinner === 'sk-cube' ? skCube : progressSpinner"
      ></ng-template>
    </div>
  </div>
</ng-container>
<ng-template #progressSpinner>
  <mat-progress-spinner
    [diameter]="diameter"
    [mode]="mode"
    [color]="color"
    [strokeWidth]="strokeWidth"
    [value]="value"
  >
  </mat-progress-spinner>
</ng-template>

<ng-template #skCube>
  <div class="background">
    <div class="sk-cube-grid">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>
  </div>
</ng-template>
