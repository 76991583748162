import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ValeRadioButtomSlimComponent } from './vale-radio-buttom-slim/vale-radio-buttom-slim.component';
import { ValeRadioGroupSlimComponent } from './vale-radio-group-slim/vale-radio-group-slim.component';

@NgModule({
  declarations: [ValeRadioButtomSlimComponent, ValeRadioGroupSlimComponent],
  imports: [CommonModule, FormsModule],
  exports: [ValeRadioButtomSlimComponent, ValeRadioGroupSlimComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeRadioButtonSlimModule {}
