import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { IDevice } from '../interfaces/device.interface';

@Injectable({
  providedIn: 'root',
})
export class BreakpointObserverService implements OnDestroy {
  private _isMobile = false;
  private _isTablet = false;
  private _isWeb = false;
  private _deviceBehavior = new BehaviorSubject<IDevice>(
    this.getDeviceConfig()
  );
  private _subscriptionsPoint = new Subscription();
  private _BREAKPOINTS = {
    MAX_WIDTH_480PX: '(max-width: 480px)',
    MMIN_WIDTH_481PX: '(min-width: 481px)',
    MAX_WIDTH_1280PX: '(max-width: 1280px)',
    MIN_WIDTH_1281PX: '(min-width: 1281px)',
  };

  constructor(private _breakObserver: BreakpointObserver) {
    this._subscriptionsPoint.add(
      this._breakObserver
        .observe([
          this._BREAKPOINTS.MAX_WIDTH_480PX,
          this._BREAKPOINTS.MMIN_WIDTH_481PX,
          this._BREAKPOINTS.MAX_WIDTH_1280PX,
          this._BREAKPOINTS.MIN_WIDTH_1281PX,
        ])
        .subscribe((result: BreakpointState) => {
          this._processBreakpointsAndSetDevice(result);
          this._emitDeviceChange();
        })
    );
  }

  private _processBreakpointsAndSetDevice(result: BreakpointState) {
    if (this._isMobileBreakpoint(result)) {
      this._isMobile = true;
      this._isTablet = false;
      this._isWeb = false;
    } else if (this._isTabletBreakpoint(result)) {
      this._isMobile = false;
      this._isTablet = true;
      this._isWeb = false;
    } else if (this._isWebBreakpoint(result)) {
      this._isMobile = false;
      this._isTablet = false;
      this._isWeb = true;
    }
  }

  private _isWebBreakpoint(result: BreakpointState): boolean {
    return result.breakpoints[this._BREAKPOINTS.MIN_WIDTH_1281PX];
  }

  private _isTabletBreakpoint(result: BreakpointState): boolean {
    return (
      result.breakpoints[this._BREAKPOINTS.MMIN_WIDTH_481PX] &&
      result.breakpoints[this._BREAKPOINTS.MAX_WIDTH_1280PX]
    );
  }

  private _isMobileBreakpoint(result: BreakpointState): boolean {
    return result.breakpoints[this._BREAKPOINTS.MAX_WIDTH_480PX];
  }

  ngOnDestroy(): void {
    this._subscriptionsPoint.unsubscribe();
  }

  public get deviceConfig$(): Observable<IDevice> {
    return this._deviceBehavior.asObservable();
  }

  public getDeviceConfig(): IDevice {
    return {
      isMobile: this._isMobile,
      isTablet: this._isTablet,
      isWeb: this._isWeb,
    };
  }

  private _emitDeviceChange(): void {
    this._deviceBehavior.next(this.getDeviceConfig());
  }
}
