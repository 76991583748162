<div class="container-update-employee-data-template">
  <section class="header">
    <span class="title">{{ 'UPLOAD_EMPLOYEE_DATA.TITLE' | translate }}</span>
  </section>
  <section class="body">
    <form [formGroup]="dataForm" class="form">
      <span class="text">{{
        'UPLOAD_EMPLOYEE_DATA.SUBTITLE' | translate
      }}</span>
      <app-vale-label
        class="input-vale-title"
        *ngIf="cpfField"
        [invalidText]="getErrorMessage('cpf') | translate"
      >
        <span>{{ 'UPLOAD_EMPLOYEE_DATA.CPF' | translate }}</span>
        <input
          valeInput
          formControlName="cpf"
          placeholder="{{ 'UPLOAD_EMPLOYEE_DATA.INPUT_CPF' | translate }}"
          (focusout)="onChangeProperty('cpf', 'cpf')"
          mask="{{ 'FORM_REGISTER.MASK_DOCUMENT' | translate }}"
          maxlength="14"
        />
      </app-vale-label>
      <app-vale-label
        class="input-vale-title"
        *ngIf="passportField"
        [invalidText]="getErrorMessage('passport') | translate"
      >
        <span>{{ 'UPLOAD_EMPLOYEE_DATA.PASSPORT' | translate }}</span>
        <input
          valeInput
          formControlName="passport"
          placeholder="{{ 'UPLOAD_EMPLOYEE_DATA.INPUT_PASSPORT' | translate }}"
          (focusout)="onChangeProperty('passport', 'cpf')"
        />
      </app-vale-label>
      <app-vale-label
        class="input-vale-title"
        *ngIf="managerField"
        [invalidText]="getErrorMessage('manager') | translate"
      >
        <span>{{ 'UPLOAD_EMPLOYEE_DATA.MANAGER' | translate }}</span>
        <input
          valeInput
          formControlName="manager"
          placeholder="{{ 'UPLOAD_EMPLOYEE_DATA.INPUT_MANAGER' | translate }}"
          (focusout)="onChangeProperty('manager', 'manager')"
        />
      </app-vale-label>
      <app-vale-label
        class="input-vale-title"
        *ngIf="managerEmailField"
        [invalidText]="getErrorMessage('managerEmail') | translate"
      >
        <span>{{ 'UPLOAD_EMPLOYEE_DATA.MANAGER_EMAIL' | translate }}</span>
        <input
          valeInput
          formControlName="managerEmail"
          placeholder="{{
            'UPLOAD_EMPLOYEE_DATA.INPUT_MANAGER_EMAIL' | translate
          }}"
          (focusout)="onChangeProperty('managerEmail', 'managerEmail')"
        />
      </app-vale-label>
      <app-vale-label
        class="input-vale-title"
        *ngIf="managerPhoneField"
        [invalidText]="getErrorMessage('managerPhone') | translate"
      >
        <span>{{ 'UPLOAD_EMPLOYEE_DATA.MANAGER_PHONE' | translate }}</span>
        <input
          valeInput
          [mask]="'DEFAULT_MASK.PHONE' | translate"
          formControlName="managerPhone"
          placeholder="{{
            'UPLOAD_EMPLOYEE_DATA.INPUT_MANAGER_PHONE' | translate
          }}"
          (focusout)="onChangeProperty('managerPhone', 'managerPhone')"
        />
      </app-vale-label>
      <div class="question" *ngIf="ssoLocationField">
        <span class="input-title">{{
          'SSO_PLACE.SEARCH_INPUT' | translate
        }}</span>
      </div>
      <div class="answer" *ngIf="ssoLocationField">
        <div class="autocomplete">
          <mat-form-field
            class="campo-autocomplete"
            appearance="outline"
            floatLabel="never"
          >
            <input
              type="text"
              placeholder="{{ 'AUTOCOMPLETE_REGION.SEARCH_INPUT' | translate }}"
              aria-label="Number"
              formControlName="ssoId"
              matInput
              [matAutocomplete]="autoRegion"
              class="input"
            />
            <mat-autocomplete
              #autoRegion="matAutocomplete"
              [displayWith]="displayFn.bind(this)"
            >
              <mat-option
                *ngFor="
                  let region of filteredRegions | async;
                  trackBy: trackByFn
                "
                [value]="region.id"
              >
                {{ region.descriptionSsoLocal }} -
                {{ region.description }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="hasError('ssoId', 'validateTestLocation')">{{
              'FORM_ERROR_INPUT.FORM_AUTOCOMPLETE_INVALID' | translate
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </section>
</div>
