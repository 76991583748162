import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { ValeDragNDropDirective } from './vale-drag-n-drop.directive';

@NgModule({
  declarations: [ValeDragNDropDirective],
  imports: [CommonModule],
  exports: [ValeDragNDropDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeDragNDropModule {}
