<div class="header">
  <div class="back-button" *ngIf="!title" (click)="back()">
    <mat-icon class="return-icon"> navigate_before </mat-icon>
    <div class="title">
      {{ 'HELP_CENTER.POPULAR_TOPICS' | translate }}
    </div>
  </div>
  <div class="back-button" *ngIf="title" (click)="backToResult()">
    <mat-icon class="return-icon"> navigate_before </mat-icon>
    <div class="title">
      {{ title }}
    </div>
  </div>
</div>
<div class="content">
  <div class="content-body">
    <div class="result-for" *ngIf="term?.length > 0 && !showResult">
      {{ 'HELP_CENTER.RESULTS_FOR' | translate: { term: term } }}
    </div>

    <app-help-center-topics-search
      *ngIf="!showAllTopics"
      [results]="quotes"
      [backToResult]="backResult"
      (title)="changeTitle($event)"
      (shoeResultSearch)="shoeResultSearch($event)"
    >
    </app-help-center-topics-search>

    <ng-container *ngIf="showAllTopics">
      <app-help-center-topics-list
        *ngFor="let topic of topics"
        [topics]="topic"
      ></app-help-center-topics-list>
    </ng-container>

    <div *ngIf="topics.length === 0">
      <div class="not-found-container">
        <img
          src="../../../../../assets/images/employee_not_found.svg"
          alt="imagem de uma lupa indicando que não foi possível encontrar tópicos"
        />
        <div class="not-found">{{ 'HELP_CENTER.NOT_FOUND' | translate }}</div>
        <div class="term-not-found">
          {{
            'HELP_CENTER.NO_RESULTS_WERE_FOUND_FOR' | translate: { term: term }
          }}
        </div>
        <ul>
          <li>
            {{
              'HELP_CENTER.MAKE_SURE_YOU_SPELLED_ALL_THE_WORDS_CORRECTLY'
                | translate
            }}
          </li>
          <li>{{ 'HELP_CENTER.TRY_DIFFERENT_KEYWORDS' | translate }}</li>
          <li>{{ 'HELP_CENTER.TRY_MORE_GENERAL_KEYWORDS' | translate }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer">
    <span class="phrase first-phrase">{{
      'HELP_CENTER.FOOTER_PHRASE_1' | translate
    }}</span>
    <span class="phrase second-phrase">{{
      'HELP_CENTER.FOOTER_PHRASE_2' | translate
    }}</span>
  </div>
</div>
