import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { HelpCenterService } from './../../../../core/services/help-center.service';

@Component({
  selector: 'app-helper-center-customer-satisfaction',
  templateUrl: './helper-center-customer-satisfaction.component.html',
  styleUrls: ['./helper-center-customer-satisfaction.component.scss'],
})
export class HelperCenterCustomerSatisfactionComponent
  implements OnInit, OnDestroy {
  @Output() public returnMenu = new EventEmitter<string>();
  public scoreForm = new FormGroup({
    score: new FormControl(null, [Validators.required]),
    note: new FormControl(null),
  });

  public savedSuccefully = false;
  public reassess = false;
  public toEvaluate = true;
  private _subscriptions = new Subscription();

  constructor(private _helperCenterService: HelpCenterService) {}

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit(): void {}

  public back(): void {
    this.returnMenu.emit();
  }

  public save(form: FormGroup): void {
    this.scoreForm = form;
    if (this.scoreForm.valid) {
      this._subscriptions.add(
        this._helperCenterService
          .postSaveCustomerSatisfactionScore(this.scoreForm.value)
          .subscribe(
            (response: any) => {
              this.savedSuccefully = response && response.success;
              this.toEvaluate = false;
            },
            () => {
              this.toEvaluate = false;
              this.savedSuccefully = false;
            }
          )
      );
    }
  }

  public trySaveAgain(): void {
    this.save(this.scoreForm);
  }

  public reassessGcm(): void {
    this.toEvaluate = true;
    this.scoreForm.reset();
  }

  public getTitleSavedSuccefully(): string {
    return `HELP_CENTER.CUSTOMER_SATISFACTION.${
      this.savedSuccefully ? 'SUCCESS' : 'ERROR'
    }.TITLE`;
  }

  public getMessageSavedSuccefully(): string {
    return `HELP_CENTER.CUSTOMER_SATISFACTION.${
      this.savedSuccefully ? 'SUCCESS' : 'ERROR'
    }.DESCRIPTION`;
  }
}
