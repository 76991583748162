import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CountryEnum } from '../enums/country.enum';
import { HomeOptionsEnum } from '../enums/home-options.enum';
import { Extensions } from '../extensions';
import { UserPermissionConstant } from './../constants/user-permission.constant';
import { Storage } from './../util/storage';

@Injectable()
export class UserBusiness {
  private _roles: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.defaultRoles
  );
  private _loggedUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _homeOption: BehaviorSubject<HomeOptionsEnum> = new BehaviorSubject<HomeOptionsEnum>(
    null
  );
  public countryId: number;

  private _storage: Storage = new Storage();

  constructor(private translate: TranslateService) {
    this._loggedUser = new BehaviorSubject<any>(null);
  }

  public get homeOption$(): Observable<any> {
    return this._homeOption.asObservable();
  }

  public get userPermission$(): Observable<any> {
    return this._roles.asObservable();
  }

  public get getUserPermission(): any {
    return this._roles.getValue();
  }

  public getHomeOption(): HomeOptionsEnum {
    return this._homeOption.value;
  }

  public setHomeOption(option: HomeOptionsEnum): void {
    this._homeOption.next(option);
  }

  public setUser(user: any): void {
    if (user) {
      this._storage.setSession('_user', {
        name: user.userName,
        iam: user.userIamId,
      });
    }
    this.setUserPermissions(user);
    this._loggedUser.next(user);
  }

  public get getLoggedUser$(): Observable<any> {
    return this._loggedUser.asObservable();
  }

  public get getLoggedUser(): any {
    return this._loggedUser.value;
  }

  public getUserLoggedStorage(): any {
    const user = this._loggedUser.value;
    if (user) {
      return {
        name: user.userName,
        iam: user.userIamId,
      };
    }
  }

  public setUserCountry(): void {
    const user = this._loggedUser.value;
    let language;
    if (user != null && user.userName) {
      const userCountry = user.userCountry;
      if (userCountry) {
        language = this.setLanguageByCountry(userCountry);
      } else {
        language =
          // eslint-disable-next-line dot-notation
          window.navigator['userLanguage'] || window.navigator.language;
        if (language === 'pt') {
          language = 'pt-MZ';
        }
      }
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      this.translate.use(language);
      // this language will be used as a fallback when a translation isn't found in the current language
      this.translate.setDefaultLang('pt-BR');
      localStorage.setItem(Extensions.STORAGE_KEYS.CULTURE, language);
    }
  }

  public getUserCountry(): string {
    const _loggedUser = this._loggedUser.value;
    return this._normalizeCountryUser(_loggedUser.userCountry);
  }

  public getUserCountryId(): number {
    switch (this.getUserCountry()) {
      case 'BRAZIL':
        this.countryId = CountryEnum.BRAZIL;
        break;
      case 'MOZAMBIQUE':
        this.countryId = CountryEnum.MOZAMBIQUE;
        break;
      case 'CANADA':
        this.countryId = CountryEnum.CANADA;
        break;
    }
    return this.countryId;
  }

  public getCountryById(countryId: number): string {
    switch (countryId) {
      case CountryEnum.BRAZIL:
        return 'BRAZIL';
      case CountryEnum.MOZAMBIQUE:
        return 'MOZAMBIQUE';
      case CountryEnum.MALAWI:
        return 'Malawi';
    }
  }

  private _normalizeCountryUser(userCountry: string): string {
    const _userCountry = (userCountry || '').toUpperCase();
    return _userCountry === 'BRASIL' || _userCountry === 'BRAZIL'
      ? 'BRAZIL'
      : _userCountry;
  }

  private setUserPermissions(user) {
    const permissions = { ...this.defaultRoles };
    this._roles.next(permissions);
    if (user) {
      user.userGroups.forEach((group: any) => {
        if (
          group.cn
            .toUpperCase()
            .includes(UserPermissionConstant.MEDICAL_RECORD.toUpperCase())
        ) {
          permissions.hasPermissionMedicalRecord = true;
          permissions.hasPermissionQuickTest = true;
        }
        if (
          group.cn
            .toUpperCase()
            .includes(UserPermissionConstant.MEDICAL_RECORD_BR.toUpperCase())
        ) {
          permissions.hasPermissionMonitoringCenter = true;
        }
        if (
          group.cn
            .toUpperCase()
            .includes(UserPermissionConstant.QUICK_TEST.toUpperCase())
        ) {
          permissions.hasPermissionQuickTest = true;
        }
        if (
          group.cn
            .toUpperCase()
            .includes(UserPermissionConstant.REPRESENTATIVE_AM.toUpperCase()) ||
          group.cn
            .toUpperCase()
            .includes(UserPermissionConstant.MANAGER_AM.toUpperCase())
        ) {
          permissions.hasPermissionAbsenceControl = true;
        }

        if (
          group.cn
            .toUpperCase()
            .includes(UserPermissionConstant.ADMIN_CENTER_AM.toUpperCase())
        ) {
          permissions.hasPermissionAdminCenter = true;
        }

        if (
          group.cn
            .toUpperCase()
            .includes(UserPermissionConstant.CARD_MANAGEMENT.toUpperCase())
        ) {
          permissions.hasPermissionCardManagement = true;
        }

        if (
          group.cn
            .toUpperCase()
            .includes(UserPermissionConstant.URA_ACCESS_CARD.toUpperCase()) ||
          this._hasPermissionWithoutIamRole(user.userIamId)
        ) {
          permissions.hasPermissionUraAccessCard = true;
        }

        if (
          group.cn
            .toUpperCase()
            .includes(UserPermissionConstant.SUPPORT_ADMIN_CENTER.toUpperCase())
        ) {
          permissions.hasPermissionSupport = true;
        }
      });
      this._setHasOnlyPermissionUraAccessCard(permissions);
      this._roles.next(permissions);
    }
  }

  private _setHasOnlyPermissionUraAccessCard(permissions) {
    permissions.hasOnlyPermissionUraAccessCard =
      permissions.hasPermissionUraAccessCard &&
      !permissions.hasPermissionAdminCenter &&
      !permissions.hasPermissionSupport &&
      !permissions.hasPermissionAbsenceControl &&
      !permissions.hasPermissionMedicalRecord &&
      !permissions.hasPermissionMonitoringCenter &&
      !permissions.hasPermissionQuickTest &&
      !permissions.hasPermissionCardManagement;
    return permissions;
  }

  private get defaultRoles() {
    return {
      hasPermissionMedicalRecord: false,
      hasPermissionMonitoringCenter: false,
      hasPermissionQuickTest: false,
      hasPermissionAbsenceControl: false,
      hasPermissionAdminCenter: false,
      hasPermissionCardManagement: false,
      hasPermissionUraAccessCard: false,
      hasOnlyPermissionUraAccessCard: false,
      hasPermissionSupport: false,
    };
  }

  private setLanguageByCountry(userCountry: string) {
    let language;
    switch (userCountry.toUpperCase()) {
      case 'BRAZIL':
      case 'BRASIL':
        language = 'pt-BR';
        break;
      case 'MOZAMBIQUE':
      case 'MOÇAMBIQUE':
        language = 'pt-MZ';
        break;
      case 'MALAWI':
      case 'CANADA':
        language = 'en-US';
        break;
      default:
        language = 'pt-BR';
        break;
    }
    return language;
  }

  private _hasPermissionWithoutIamRole(code: string): boolean {
    switch (code) {
      case '81007304':
        return true;
      default:
        return false;
    }
  }
}
