import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[valePrefix]',
})
export class ValePrefixDirective {
  @HostBinding('style.left') public side = 0;

  @HostBinding('class.icon-field') public size = true;

  constructor() {}
}
