import { ReturnDialogButton } from './return-dialog-button.model';

export class ReturnDialog {
  public actionType: string;
  public title: string;
  public message: string;
  public closeActionButton;
  public path: string;
  public buttons: ReturnDialogButton[];
  public imgType?: string = 'bigImg';

  constructor(config: ReturnDialog) {
    this.actionType = config.actionType;
    this.title = config.title;
    this.message = config.message;
    this.closeActionButton = config.closeActionButton;
    this.path = config.path;
    this.buttons = config.buttons;
    this.imgType = config.imgType;
  }
}
