<div class="container-app-button">
  <mat-icon class="pre-icon" *ngIf="preIcon && !srcIcon" matPrefix>{{
    preIcon
  }}</mat-icon>
  <img
    class="img-left"
    *ngIf="sideIcon && srcIcon"
    src="{{ srcIcon }}"
    alt="img-left"
  />
  <ng-content></ng-content>
  <img
    class="img-right"
    *ngIf="!sideIcon && srcIcon"
    src="{{ srcIcon }}"
    alt="img-right"
  />
  <mat-icon class="pos-icon" *ngIf="posIcon && !srcIcon" matSuffix>{{
    posIcon
  }}</mat-icon>
</div>
