import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector, OnDestroy, Type } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { FilterDialogRef } from './components/generic-filter-dialog/generic-filter-dialog-ref';
import { GenericFilterDialogComponent } from './components/generic-filter-dialog/generic-filter-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ValeExpansionFilterDialogService implements OnDestroy {
  private _isValidSource = new BehaviorSubject(false);
  private _dataChild = new BehaviorSubject(null);
  private _responseDataChild = new BehaviorSubject(null);

  constructor(private overlay: Overlay, private injector: Injector) {}

  ngOnDestroy(): void {
    this._isValidSource.unsubscribe();
  }

  open<R = any, T = any>(content: Type<any>, data?: T): FilterDialogRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal', 'is-active'],
      backdropClass: 'modal-background',
    });

    const overlayRef = this.overlay.create(configs);

    const myOverlayRef = new FilterDialogRef<R, T>(
      overlayRef,
      content,
      data,
      this._isValidSource
    );

    const injector = this.createInjector(myOverlayRef, this.injector);
    overlayRef.attach(
      new ComponentPortal(GenericFilterDialogComponent, null, injector)
    );

    return myOverlayRef;
  }

  createInjector(ref: FilterDialogRef, inj: Injector): PortalInjector {
    const injectorTokens = new WeakMap([[FilterDialogRef, ref]]);
    return new PortalInjector(inj, injectorTokens);
  }

  public setDisabledConfirmButton(state: boolean): void {
    this._isValidSource.next(state);
  }

  public setDataChild(data: any): void {
    this._dataChild.next(data);
  }

  public get dataChild$(): Observable<any> {
    return this._dataChild.asObservable();
  }

  public setResponseDataChild(data: any): void {
    this._responseDataChild.next(data);
  }

  public get responseDataChild$(): Observable<any> {
    return this._responseDataChild.asObservable();
  }

  public get responseDataChild(): any {
    return this._responseDataChild.getValue();
  }
}
