import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Extensions } from '../../shared/extensions';
import { CustomerSatisfactionScoreModel } from '../../shared/models/customer-satisfaction-score.model';
import { ReleaseNoteHistoryModel } from '../../shared/models/ReleaseNoteHistoryModel';
import { TopicsModel } from './../../shared/models/topics-model';
import { BaseService } from './base.service';

@Injectable()
export class HelpCenterService extends BaseService {
  private readonly _idiom;
  private readonly _baseUrl = 'help-center';

  constructor(httpClient: HttpClient) {
    super(httpClient, null, environment.api);
    this._idiom = localStorage.getItem(Extensions.STORAGE_KEYS.CULTURE);
  }

  public getTopics(): Observable<any> {
    return this.get<TopicsModel[]>(`${this._baseUrl}/topics`, {
      idiom: this._idiom,
    });
  }

  public getReleaseNotesHistory(): Observable<any> {
    return this.get<ReleaseNoteHistoryModel[]>(
      `${this._baseUrl}/release-notes`,
      {
        idiom: this._idiom,
      }
    );
  }

  public postSaveCustomerSatisfactionScore(
    customerSatisfaction: CustomerSatisfactionScoreModel
  ): Observable<any> {
    return this.post<any>(
      `${this._baseUrl}/customer-satisfaction-score`,
      customerSatisfaction
    );
  }
}
