import { Component, Input } from '@angular/core';

import { TopicsModel } from './../../../../models/topics-model';

@Component({
  selector: 'app-help-center-topics-list',
  templateUrl: './help-center-topics-list.component.html',
  styleUrls: ['./help-center-topics-list.component.scss'],
})
export class HelpCenterTopicsListComponent {
  @Input() public topics: TopicsModel;
}
