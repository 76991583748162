import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vale-loading',
  templateUrl: './vale-loading.component.html',
  styleUrls: ['./vale-loading.component.scss'],
})
export class ValeLoadingComponent implements OnInit {
  constructor() {}

  @Input() value = 100;
  @Input() diameter = 100;
  @Input() mode = 'indeterminate';
  @Input() strokeWidth = 10;
  @Input() overlay = false;
  @Input() color = 'primary';
  @Input() spinner = 'sk-cube';

  ngOnInit(): void {}
}
