import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValeRadioButtomSlimComponent } from './../vale-radio-buttom-slim/vale-radio-buttom-slim.component';

@Component({
  selector: 'app-vale-radio-group-slim',
  templateUrl: './vale-radio-group-slim.component.html',
  styleUrls: ['./vale-radio-group-slim.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValeRadioGroupSlimComponent),
      multi: true,
    },
  ],
  host: {
    '[attr.disabled]': 'disabled || null',
  },
})
export class ValeRadioGroupSlimComponent
  implements ControlValueAccessor, AfterViewInit, OnInit, OnChanges {
  @ContentChildren(ValeRadioButtomSlimComponent)
  radios: QueryList<ValeRadioButtomSlimComponent>;

  public value: any;
  public onChange: any;
  public onTouched: any;
  public isPosition = false;
  @Input() public disabled = false;
  @Input() public position = 'vertical';
  @Input() public label: string = null;
  @Input() public formControlName: string;
  @Output('radioChange') public radioChange = new EventEmitter(null);

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.disabled) {
      this.setDisabledRadioOptions();
    }
  }
  public ngOnInit(): void {
    this.isPosition = this.position === 'vertical';
    if (this.disabled) {
      this.setDisabledRadioOptions();
    }
  }

  public writeValue(value: any[] | any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public ngAfterViewInit(): void {
    this.setDisabledRadioOptions();
    this._cdr.detectChanges();
  }

  private setDisabledRadioOptions() {
    (this.radios || []).forEach((x) => {
      x.disabled = !!this.disabled;
      x.control = this;
      x.name = this.formControlName;
    });
    this._cdr.detectChanges();
  }

  public setValue(value): void {
    this.value = value;
    this.onChange(value);
    this._radioChangeEventEmitter(value);
  }
  private _radioChangeEventEmitter(value: any) {
    this.radioChange.emit(value);
  }
}
