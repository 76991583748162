import { Directive, HostBinding, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[valeInput]',
})
export class ValeInputDirective {
  @HostBinding('class.input-vale') inputClass = true;

  @HostBinding('class.input-vale-base')
  public get base(): boolean {
    return !this.disabled && this.control.untouched;
  }

  @HostBinding('class.input-vale-valid')
  public get valid(): boolean {
    return this.control.touched && this.control.valid;
  }

  @HostBinding('class.input-vale-invalid')
  public get invalid(): boolean {
    return this.control.invalid;
  }

  @Input() public disabled = false;

  @HostBinding('class.input-vale-disabled')
  public get disabledAttr(): boolean {
    return this.disabled || this.control.disabled;
  }

  @HostBinding('class.input-vale-icon-left')
  public iconLeft = false;

  @HostBinding('class.input-vale-icon-right')
  public iconRight = false;

  @Input()
  @HostBinding('class.input-vale-outline-none')
  public outlineNone = false;

  constructor(private control: NgControl) {}
}
