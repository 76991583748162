import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Extensions } from '../../shared/extensions';
import { MedicalRecordModel } from '../../shared/models';
import { ComorbiditiesReleasedModel } from '../../shared/models/comorbidities-released.model';
import { EmployeeProfileUnify } from '../../shared/models/employee-profile-unify.model';
import { MedicalRecordWithEmployeeModel } from '../../shared/models/medical-record/medical-record-with-employee.model';
import { NoteModel, NotesHistoryModel } from '../../shared/models/note-model';
import { PatientReleaseModel } from '../../shared/models/patient-release.model';
import { PatientVaccineModel } from '../../shared/models/patient-vaccine.model';
import { PatientModel } from '../../shared/models/patient.model';
import { QuickExamLastDateDto } from '../../shared/models/quick-exam-last-date.model';
import { ChecklistModel } from './../../shared/models/checklist.model';
import { BaseService } from './base.service';

@Injectable()
export class PatientService extends BaseService {
  private readonly _idiom;
  private readonly _baseUrlPatients = 'patients';
  private readonly _baseUrlMedicalRecord = 'medical-record';
  private readonly _baseUrlComorbidity = 'comorbidity';
  private readonly _baseUrlEmployee = 'employee';

  constructor(httpClient: HttpClient) {
    super(httpClient, null, environment.api);
    this._idiom = localStorage.getItem(Extensions.STORAGE_KEYS.CULTURE);
    if (this._idiom === 'pt' || this._idiom === 'pt-MZ') {
      this._idiom = 'pt-BR';
    }
  }

  public getPatients(text: string, operation: string): Observable<any> {
    return this.get<any>(`${this._baseUrlPatients}`, {
      text: text,
      operation: operation,
    });
  }

  public getPatientInfo(params: any): Observable<any> {
    return this.get<PatientModel>(
      `${this._baseUrlEmployee}/profile/${this._idiom}`,
      params
    );
  }

  public getRectifications(id: number): Observable<any> {
    return this.get<any>(`${this._baseUrlMedicalRecord}/employee/${id}`);
  }

  public GetCompulsoryNotification(id: number): Observable<any> {
    return this.get<any>(
      `${this._baseUrlEmployee}/${id}/compulsory-notification`
    );
  }
  public getComorbidities(): Observable<any> {
    return this.get<any>(`${this._baseUrlComorbidity}/${this._idiom}`);
  }

  public getComorbiditiesByEmployeeId(id: number): Observable<any> {
    return this.get<any>(`${this._baseUrlComorbidity}/employee/${id}`);
  }

  public getSymptoms(): Observable<any> {
    return this.get<any>(`symptom/${this._idiom}`);
  }

  public getSymptomsByEmployee(params: any): Observable<any> {
    return this.get<any>(`${this._baseUrlEmployee}/current-symptoms`, params);
  }

  public getNotesIncludeTimeline(employeeId: number): Observable<any> {
    return this.get<NoteModel[]>(
      `${this._baseUrlMedicalRecord}/notes-include-timeline/employee/${employeeId}`
    );
  }

  public getEmployeeNotesHistory(employeeId: number): Observable<any> {
    return this.get<NotesHistoryModel>(
      `${this._baseUrlMedicalRecord}/notes/employee/${employeeId}`
    );
  }

  public getMostRecentMedicalRecord(employeeId: number): Observable<any> {
    return this.get<MedicalRecordModel>(
      `${this._baseUrlMedicalRecord}/most-recent/employee/${employeeId}`
    );
  }

  public getEmployeeLastExamDateAsync(
    employeeId: number
  ): Observable<QuickExamLastDateDto> {
    return this.get<QuickExamLastDateDto>(
      `${this._baseUrlMedicalRecord}/employee/last-exam-date/${employeeId}`
    );
  }

  public getMedicalRecordById(
    recordId: number,
    idiom: string
  ): Observable<any> {
    return this.get<MedicalRecordModel>(
      `${this._baseUrlMedicalRecord}/${recordId}/${idiom}`
    );
  }

  public getChecklist(gcmId?: number): Observable<any> {
    return this.get<ChecklistModel[]>(
      `${this._baseUrlEmployee}/screening-records`,
      {
        employeeId: gcmId,
        itemsToTake: 20,
      }
    );
  }

  public saveMedicalRecord(
    medicalRecord: MedicalRecordWithEmployeeModel
  ): Observable<any> {
    return this.post<any>(`${this._baseUrlMedicalRecord}`, medicalRecord);
  }

  public saveRectification(
    medicalRecord: MedicalRecordWithEmployeeModel
  ): Observable<any> {
    return this.post<any>(
      `${this._baseUrlMedicalRecord}/${medicalRecord.id}/rectification`,
      medicalRecord
    );
  }

  public getRelease(employeeId): Observable<PatientReleaseModel> {
    return this.get<PatientReleaseModel>(
      `${this._baseUrlEmployee}/release-access/${employeeId}`,
      employeeId
    );
  }

  public saveRelease(patient): Observable<any> {
    return this.post<any>(`${this._baseUrlEmployee}/release-access`, patient);
  }

  public revoke(id: number): Observable<any> {
    return this.delete<any>(
      `${this._baseUrlEmployee}/release-access/revoke-access/${id}`
    );
  }

  public deleteEmployee(gcmId: number, code: string): Observable<any> {
    return this.delete<any>(`${this._baseUrlEmployee}/${gcmId}/code/${code}`);
  }

  public getVaccinesPatientByGcmId(patientId: number): Observable<any> {
    return this.get<PatientVaccineModel>(
      `${this._baseUrlPatients}/${patientId}/vaccines`
    );
  }

  public saveVaccinated(
    patientId: number,
    vaccinatedPatient: PatientVaccineModel
  ): Observable<any> {
    return this.post<any>(
      `${this._baseUrlPatients}/${patientId}/vaccines`,
      vaccinatedPatient
    );
  }

  public updateVaccinated(
    patientId: number,
    vaccinatedPatient: PatientVaccineModel[]
  ): Observable<any> {
    return this.put<PatientVaccineModel[]>(
      `${this._baseUrlPatients}/${patientId}/vaccines`,
      vaccinatedPatient
    );
  }

  public removeAllVaccinesByPatientId(patientId: number): Observable<any> {
    return this.delete<boolean>(
      `${this._baseUrlPatients}/${patientId}/vaccines`
    );
  }

  public removeReleaseFromVaccination(patientId: number): Observable<any> {
    return this.put<any>(
      `${this._baseUrlPatients}/vaccine-remove/${patientId}`
    );
  }

  public saveComorbidities(
    patientId: number,
    comorbidities: ComorbiditiesReleasedModel
  ): Observable<any> {
    return this.post<any>(
      `${this._baseUrlComorbidity}/patient/${patientId}`,
      comorbidities
    );
  }

  public getTragConsecutivePositives(employeeId: number): Observable<any> {
    return this.get<any>(
      `${this._baseUrlEmployee}/${employeeId}/consecutive-trag-positives`
    );
  }

  public getQuixkExamByEmployeeAndDate(
    employeeid: number,
    date: string
  ): Observable<any> {
    return this.get<any>(
      `${this._baseUrlMedicalRecord}/exist/employee/${employeeid}/${date}`
    );
  }

  public getEmployeeToUnifyAdminCenter(employeeId: any): Observable<any> {
    return this.get<any>(`${this._baseUrlEmployee}/${employeeId}/unification`);
  }

  public saveEmployeeUnification(
    employeeToKeep: EmployeeProfileUnify,
    employeeId: number
  ) {
    return this.post<any>(
      `${this._baseUrlEmployee}/${employeeId}/unification/`,
      employeeToKeep
    );
  }
}
