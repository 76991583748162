import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-patient-info-retired-contact',
  templateUrl: './patient-info-retired-contact.component.html',
  styleUrls: ['./patient-info-retired-contact.component.scss'],
})
export class PatientInfoRetiredContactComponent implements OnInit {
  @Input() public formContact: FormGroup;
  constructor() {}

  ngOnInit() {}
}
