import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { ValeAccessCardComponent } from './vale-access-card.component';

@NgModule({
  declarations: [ValeAccessCardComponent],
  imports: [CommonModule],
  exports: [ValeAccessCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeAccessCardModule {}
