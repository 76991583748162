<div class="incompatibility">
  <div class="chrome-card">
    <img
      class="chrome-icon"
      src="./assets/images/chrome-icon.svg"
      alt="chrome-icon"
    />
    <span class="chrome-title">{{
      'INCOMPATIBILITY.RECOMMENDATION' | translate
    }}</span>
    <span class="chrome-text">{{
      'INCOMPATIBILITY.RECOMMENDATION_TEXT' | translate
    }}</span>
  </div>
  <div class="instruction-card">
    <div class="img">
      <img
        class="vale-icon"
        src="./assets/images/Logotipo_Vale.svg"
        alt="Logotipo_Vale"
      /><br />
    </div>
    <span class="instruction-title">{{
      'INCOMPATIBILITY.INSTRUCTION_TITLE' | translate
    }}</span>
    <span class="instruction-text"
      >{{ 'INCOMPATIBILITY.INSTRUCTION_TEXT' | translate }}
      <strong>{{ 'INCOMPATIBILITY.INSTRUCTION_TEXT_BOLD' | translate }}</strong>
      {{ 'INCOMPATIBILITY.INSTRUCTION_TEXT_END' | translate }}</span
    >
  </div>
</div>
