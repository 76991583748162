import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

import { ApiErrorModel } from '../models/api-error.model';
import { ErrorModel } from './../models/error.model';
import { DateTimeUtil } from './date-time-util';

export class UtilHelper {
  public static onReturnedToWork(endDate: moment.Moment) {
    return moment(new Date()).isAfter(endDate, 'day');
  }

  public static onCalculateAbsenceDays(
    startDate: moment.Moment,
    endDate: moment.Moment
  ) {
    // eslint-disable-next-line import/namespace
    return moment.duration(endDate.diff(startDate)).asDays();
  }

  /**
   * Capitalizes first letters of words in string.
   * @param {string} str String to be modified
   * @param {boolean=false} lower Whether all other letters should be lowercased
   * @return {string}
   * @usage
   *   capitalize('fix this string');     // -> 'Fix This String'
   *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
   *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
   */
  public static capitalize = (str: string, lower = false): string =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
      match.toUpperCase()
    );

  /**
   * Get business exception type error messages
   * @param {ApiErrorModel} exception business exception
   * @param {string} separator Separator of messages
   * @return {string}
   * @usage
   *   getMessagesApiError(exception, '\n'); // default
   *   getMessagesApiError(exception);
   *   getMessagesApiError(exception, ';');
   */
  public static getMessagesApiError = (
    exception: ApiErrorModel,
    separator = '/n'
  ): string => {
    if (exception && exception.error && exception.error.errors) {
      const message = exception.error.errors.map(
        (error: ErrorModel) => error.description
      );
      return message.join(separator);
    }
  };

  /**
   * Clona os erros último formulário para o formulário corrente
   * @param {FormGroup} current formulário atual
   * @param {FormGroup} last formulário atual
   * @usage
   *
   */
  public static cloneFormError(current: FormGroup, last: FormGroup): void {
    if (last && current && last.invalid) {
      Object.keys(last.controls).forEach((element) => {
        const existFieldCurrentForm = current.get(element);
        const existFieldLastForm = last.get(element);
        if (!existFieldCurrentForm && existFieldLastForm) {
          current.addControl(
            element,
            new FormControl(existFieldLastForm.value)
          );
        }
        current.get(element).setErrors(last.get(element).errors);
      });
    }
  }

  public static cleanErrorsDate(form: FormGroup): void {
    if (form) {
      Object.keys(form.controls).forEach((field) => {
        const errors = form.controls[field].errors;

        if (errors && errors.matDatepickerParse) {
          form.get(field).reset('');
          form.get(field).clearValidators();
        }
      });
    }
  }

  public static updateValueAndValidityForm(form: FormGroup): void {
    if (form) {
      Object.keys(form.controls).forEach((field) => {
        const fieldForm = form.controls[field];
        if (fieldForm) {
          fieldForm.updateValueAndValidity();
        }
      });
    }
  }

  /**
   *@param {string} iamId represent the identifier access management
   *@returns {boolean} return a boolean
   *
   * @usage
   *    isValidIamId('123456789');
   **/
  public static isValidIamId(iamId: string): boolean {
    const regex = /^(?=.*[0-9])([a-zA-Z0-9]{1,8})$/;
    return iamId && regex.test(iamId);
  }
  /**
   *@param {string} iamId or code represent the identifier access management
   *@returns {boolean} return a boolean
   *
   * @usage
   *    isValidIamId('12345678900');
   **/
  public static isValidIamIdAndCode(iamId: string): boolean {
    const regex = /^(?=.*[0-9])([a-zA-Z0-9]{1,10})$/;
    return iamId && regex.test(iamId);
  }
  /**
   *@param {string} email email to validate
   *@returns {boolean} return a boolean
   *
   * @usage
   *    isValidEmail('a@abs.com');
   **/
  public static isValidEmail(email: string): boolean {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && regex.test(email);
  }

  public static getAge(date: any): number {
    const dateFormated = this._convertDateToMomentDate(date);
    return moment().diff(dateFormated, 'years', false);
  }

  private static _convertDateToMomentDate(date: any) {
    if (date && moment.isMoment(date)) {
      return date;
    } else if (date && typeof date === 'string') {
      const _birthDate = new Date(date);
      return DateTimeUtil.getDateStringFromDate(_birthDate);
    } else if (date && date instanceof Date) {
      return DateTimeUtil.getDateStringFromDate(date);
    }
  }

  public static isValidCpf(cpf: string): boolean {
    if (!cpf) return false;
    cpf = cpf.replace(/[.-]/g, '');
    let numbers, digits, sum, i, result, equalDigits;
    equalDigits = 1;
    if (cpf.length < 11) {
      return false;
    }

    for (i = 0; i < cpf.length - 1; i++) {
      if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
        equalDigits = 0;
        break;
      }
    }

    if (!equalDigits) {
      numbers = cpf.substring(0, 9);
      digits = cpf.substring(9);
      sum = 0;
      for (i = 10; i > 1; i--) {
        sum += numbers.charAt(10 - i) * i;
      }

      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

      if (result !== Number(digits.charAt(0))) {
        return false;
      }
      numbers = cpf.substring(0, 10);
      sum = 0;

      for (i = 11; i > 1; i--) {
        sum += numbers.charAt(11 - i) * i;
      }
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

      if (result !== Number(digits.charAt(1))) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  public static isValidName(name: string): boolean {
    const regex = /^[A-ZÀ-Ÿa-zà-ÿ]+(([',. -][A-ZÀ-Ÿa-zà-ÿ ])?[A-ZÀ-Ÿa-zà-ÿ]*)*$/;
    return name && regex.test(name);
  }

  public static isValidNuit(nuit: string): boolean {
    const regex = /^[0-9]{9}$/;
    return nuit && regex.test(nuit);
  }
}
