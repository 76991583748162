<div class="return-dialog">
  <div
    type="button"
    (click)="data.closeActionButton()"
    class="close-button"
    [mat-dialog-close]="'DEFAULT_CLOSE'"
  >
    <img
      class="icon-close"
      src="./assets/images/icons/close.svg"
      alt="fechar Pop Up"
    />
  </div>
  <div class="return-dialog-container">
    <div class="return-dialog-image-box">
      <img
        [ngClass]="getClassbyImgType('img')"
        src="{{ caminho }}"
        alt="imagem do Pop Up"
      />
    </div>
    <div class="subtitle subtitle--success" *ngIf="type === 'success'">
      {{ title }}
    </div>
    <div class="subtitle subtitle--error" *ngIf="type === 'error'">
      {{ title }}
    </div>
    <div class="subtitle subtitle--death" *ngIf="type === 'death'">
      {{ title }}
    </div>
    <div
      class="subtitle subtitle--rectification"
      *ngIf="type === 'rectification'"
    >
      {{ title }}
    </div>
    <div [innerHTML]="message" [ngClass]="getClassbyImgType('message')"></div>
  </div>
  <div class="buttons">
    <div *ngFor="let button of data.buttons" class="button-wrap">
      <button
        [ngClass]="button.className"
        (click)="button.action()"
        [mat-dialog-close]="button.result"
      >
        <span>{{ button.name }}</span>
      </button>
    </div>
  </div>
</div>
