import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Action {
  name: string;
  value?: any;
}
@Injectable()
export class PatientInfoUpdate implements OnDestroy {
  private actionUpdatePatientInfo = new BehaviorSubject<Action>(null);
  constructor() {}

  public ngOnDestroy(): void {}

  public get actionUpdatePatientInfo$(): Observable<Action> {
    return this.actionUpdatePatientInfo.asObservable();
  }

  public setActionUpdatePatientInfo(action: Action): void {
    this.actionUpdatePatientInfo.next(action);
  }
}
