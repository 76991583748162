import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vale-icon',
  templateUrl: './vale-icon.component.html',
  styleUrls: ['./vale-icon.component.scss'],
})
export class ValeIconComponent implements OnInit {
  @Input() public iconSrc: string;

  constructor() {}

  ngOnInit() {}
}
