import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialComponentsModule } from '../../material.module';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { ValeLibModule } from '../../vale-lib/vale-lib.module';
import { HelpCenterAboutComponent } from './help-center-about/help-center-about.component';
import { HelpCenterHomeComponent } from './help-center-home/help-center-home.component';
import { HelpCenterMenuComponent } from './help-center-menu/help-center-menu.component';
import { HelpCenterTopicsChildComponent } from './help-center-topics/help-center-topics-list/help-center-topics-child/help-center-topics-child.component';
import { HelpCenterTopicsListComponent } from './help-center-topics/help-center-topics-list/help-center-topics-list.component';
import { HelpCenterTopicsResultComponent } from './help-center-topics/help-center-topics-search/help-center-topics-result/help-center-topics-result.component';
import { HelpCenterTopicsSearchComponent } from './help-center-topics/help-center-topics-search/help-center-topics-search.component';
import { HelpCenterTopicsComponent } from './help-center-topics/help-center-topics.component';
import { ScoreComponent } from './helper-center-customer-satisfaction/components/score/score.component';
import { HelperCenterCustomerSatisfactionComponent } from './helper-center-customer-satisfaction/helper-center-customer-satisfaction.component';

@NgModule({
  declarations: [
    HelpCenterHomeComponent,
    HelpCenterMenuComponent,
    HelpCenterTopicsComponent,
    HelpCenterTopicsListComponent,
    HelpCenterAboutComponent,
    HelpCenterTopicsChildComponent,
    HelperCenterCustomerSatisfactionComponent,
    ScoreComponent,
    SafeHtmlPipe,
    HelpCenterTopicsResultComponent,
    HelpCenterTopicsSearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ValeLibModule,
    MaterialComponentsModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [HelpCenterHomeComponent, ScoreComponent],
})
export class HelpCenterModule {}
