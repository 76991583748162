<div class="container-vale-patient-search">
  <div class="container-search">
    <vale-generic-search
      #model="ngModel"
      [(ngModel)]="selectedDestination"
      (changeSearch)="changeSearch($event)"
      (selectionChange)="redirect($event)"
      [color]="color"
      [placeholder]="'VALE_PATIENT_SEARCH.PLACEHOLDER.MOBILE' | translate"
      [searchTitle]="'VALE_PATIENT_SEARCH.BUTTONS.SEARCH_TITLE' | translate"
      [closeTitle]="'VALE_PATIENT_SEARCH.BUTTONS.RESET_TITLE' | translate"
      [focus]="focus"
    >
      <vale-generic-search-option
        *ngFor="let option of options"
        [value]="option"
        class="search-option"
        [disabled]="option?.disabled"
        (click)="
          isValidPatientToClick(option?.typeEmployee)
            ? onPessoaSelectionChanged(option)
            : null
        "
      >
        <div class="container-search-option">
          <span class="text-search-option">
            {{ getDescriptionSearch(option) }}</span
          >

          <app-vale-dot-profile
            [profile]="option.typeEmployee"
          ></app-vale-dot-profile>
        </div>
      </vale-generic-search-option>

      <vale-generic-search-option *ngIf="notFound" [disabled]="true">
        <div class="patient-not-found">
          <img
            height="40px"
            width="40px"
            src="./../assets/images/employee_not_found.svg"
            alt=""
          />
          <div class="message">
            <div class="title">
              {{ 'VALE_PATIENT_SEARCH.NO_SEARCH_RESULT.TITLE' | translate }}
            </div>
            <div class="content">
              {{ 'VALE_PATIENT_SEARCH.NO_SEARCH_RESULT.CONTENT' | translate }}
            </div>
          </div>
        </div>
      </vale-generic-search-option>
    </vale-generic-search>
  </div>
  <mat-icon
    *ngIf="_userCountry != 'MOZAMBIQUE'"
    [ngClass]="
      color === 'primary'
        ? 'patient-add patient-add--primary'
        : 'patient-add patient-add--gray'
    "
    [matTooltip]="'VALE_PATIENT_SEARCH.NEW_PATIENT' | translate"
    (click)="openNewRegister()"
    >person_add</mat-icon
  >
</div>
