import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vale-container',
  templateUrl: './vale-container.component.html',
  styleUrls: ['./vale-container.component.scss'],
})
export class ValeContainerComponent implements OnInit {
  @Input('class')
  public customClass;

  constructor() {}

  public ngOnInit(): void {}
}
