import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ValeButtonModule } from './../vale-button/vale-button.module';
import { ValeGenericSearchDropdownComponent } from './vale-generic-search-dropdown.component';
import { ValeGenericSearchDropdownService } from './vale-generic-search-dropdown.service';
import { ValeGenericSearchOptionComponent } from './vale-generic-search-option.component';
import { ValeGenericSearchComponent } from './vale-generic-search.component';

@NgModule({
  declarations: [
    ValeGenericSearchDropdownComponent,
    ValeGenericSearchOptionComponent,
    ValeGenericSearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    PortalModule,
    MatIconModule,
    ValeButtonModule,
    MatTooltipModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [
    ValeGenericSearchDropdownComponent,
    ValeGenericSearchOptionComponent,
    ValeGenericSearchComponent,
  ],
  providers: [ValeGenericSearchDropdownService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeGenericSearchDropdownModule {}
