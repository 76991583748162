<div class="container-form">
  <form [formGroup]="formData">
    <div
      *ngIf="formData.get('socialName').value"
      class="form-input form-input--padding-bottom-12"
    >
      <app-vale-label>
        <div class="title">
          {{ 'PATIENT_INFO.PATIENT_INFO_DATA.SOCIAL_NAME' | translate }}
        </div>
        <input
          valeInput
          disabled="true"
          outlineNone="true"
          type="text"
          formControlName="socialName"
          placeholder="-"
        />
      </app-vale-label>
    </div>
    <div class="form-input form-input--padding-bottom-12">
      <div
        class="form-input-divided form-input-divided--padding-right-24"
        [matTooltip]="'TOOLTIPS.DOCUMENT_PATIENT' | translate"
      >
        <app-vale-label>
          <div class="title">
            {{ 'PATIENT_INFO.PATIENT_INFO_DATA.DOCUMENT' | translate }}
          </div>
          <input
            valeInput
            disabled="true"
            outlineNone="true"
            type="text"
            formControlName="id"
            placeholder="-"
          />
        </app-vale-label>
      </div>
      <div
        class="form-input-divided"
        [matTooltip]="'TOOLTIPS.ENROLLMENT_PATIENT' | translate"
      >
        <app-vale-label>
          <div class="title">
            {{ 'PATIENT_INFO.PATIENT_INFO_DATA.CODE_NUMBER' | translate }}
          </div>
          <input
            valeInput
            disabled="true"
            outlineNone="true"
            type="text"
            formControlName="idCard"
            placeholder="-"
          />
        </app-vale-label>
      </div>
    </div>
  </form>
</div>
