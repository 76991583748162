<div class="default-theme">
  <div class="medical-record">
    <header class="medical-record-header">
      <div
        class="brand-logo-vale"
        (click)="redirectToHomePage()"
        [matTooltip]="'TOOLTIPS.HOME_BUTTON' | translate"
      >
        <img src="./assets/images/logo-vale.svg" alt="logo-vale" />
        <div class="app-name">{{ 'HEADER.TITLE' | translate }}</div>
      </div>
      <div
        class="vale-patient-search"
        style="display: flex; align-items: center"
        *ngIf="showingSearch && !hasOnlyPermissionUraAccessCard"
      >
        <app-vale-patient-search color="gray"></app-vale-patient-search>
      </div>
      <div
        class="container-account"
        [matTooltip]="'TOOLTIPS.USER_BUTTON' | translate"
      >
        <div
          class="account"
          [matMenuTriggerFor]="menu"
          *ngIf="this.userBusiness.userPermission$ | async as roles"
        >
          <span class="name">{{ userName }}</span>
          <mat-menu #menu="matMenu" class="custom-menu">
            <div
              mat-menu-item
              (click)="
                onSelectedOptionAndRedirect(homeOptionsEnum.ADMIN_CENTER)
              "
              *ngIf="
                roles.hasPermissionAdminCenter || roles.hasPermissionSupport
              "
            >
              <img
                src="../../../../assets/icons/central-do-administrador.svg"
                alt="central do administrador"
              />
              <span class="title-menu-item">{{
                'HEADER.ACCOUNT.ADMIN_CENTER' | translate
              }}</span>
            </div>
            <div
              *ngIf="roles.hasPermissionMedicalRecord"
              mat-menu-item
              (click)="
                onSelectedOptionAndRedirect(homeOptionsEnum.MEDICAL_RECORD)
              "
            >
              <img
                src="../../../../assets/icons/prontuario.svg"
                alt="ícone do prontuário"
              />
              <span class="title-menu-item">
                {{ 'PATIENT_RECORD.MEDICAL_RECORD.TITLE' | translate }}
              </span>
            </div>
            <div
              *ngIf="roles.hasPermissionMedicalRecord"
              mat-menu-item
              (click)="
                onSelectedOptionAndRedirect(homeOptionsEnum.VALE_FORMS_VACCINES)
              "
            >
              <img
                src="../../../../assets/icons/vacina.svg"
                alt="ícone de vacina"
              />
              <span class="title-menu-item">
                {{ 'VACCINES.TITLE' | translate }}
              </span>
            </div>
            <!--<div
              *ngIf="roles.hasPermissionQuickTest"
              mat-menu-item
              (click)="onSelectedOptionAndRedirect(homeOptionsEnum.QUICK_TEST)"
            >
              <img
                src="../../../../assets/icons/teste-rapido.svg"
                alt="ícone teste rápido"
              />
              <span class="title-menu-item">
                {{ 'QUICK_EXAM.TITLE' | translate }}
              </span>
            </div>-->
            <!--<div
              *ngIf="roles.hasPermissionAbsenceControl"
              mat-menu-item
              (click)="
                onSelectedOptionAndRedirect(homeOptionsEnum.ABSENCE_CONTROL)
              "
            >
              <img
                src="../../../../assets/icons/controle-de-ausencias.svg"
                alt="ícone controle de ausencias"
              />
              <span class="title-menu-item">
                {{ 'ABSENCE_CONTROL.TITLE' | translate }}
              </span>
            </div>-->
            <!--<div
              *ngIf="roles.hasPermissionMonitoringCenter"
              mat-menu-item
              (click)="
                onSelectedOptionAndRedirect(homeOptionsEnum.MONITORING_CENTER)
              "
            >
              <img
                src="../../../../assets/icons/central-de-monitoramento.svg"
                alt="ícone central de monitoramento"
              />
              <span class="title-menu-item">
                {{ 'MONITORING_CENTER_TITLE.SEARCH_INPUT' | translate }}
              </span>
            </div>-->
            <mat-divider></mat-divider>
            <div mat-menu-item (click)="logout()">
              <mat-icon svgIcon="logout"></mat-icon>
              <span class="title-menu-item">{{
                'HEADER.ACCOUNT.LOGOUT' | translate
              }}</span>
            </div>
          </mat-menu>
          <div class="name-initials">
            <span class="initials">{{ userInitialsName }}</span>
          </div>
          <div class="photo"></div>
        </div>
      </div>
    </header>
  </div>
</div>
