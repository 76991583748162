import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { environment } from '../../../../../environments/environment';
import { ReleaseNotesDialogComponent } from '../../../../modules/home/components/release-notes-dialog/release-notes-dialog.component';
import { Storage } from '../../../../shared/util/storage';
import { ReleaseNoteHistoryModel } from '../../../models/ReleaseNoteHistoryModel';

@Component({
  selector: 'app-help-center-about',
  templateUrl: './help-center-about.component.html',
  styleUrls: ['./help-center-about.component.scss'],
})
export class HelpCenterAboutComponent implements OnInit {
  @Output() public returnMenu = new EventEmitter();
  public Date: Date = new Date();
  public version: String = environment.version;
  public dateVersion: String;
  public releaseNotesHistory: ReleaseNoteHistoryModel[];
  private _storage: Storage = new Storage();

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.releaseNotesHistory = this._storage.getSession<any>(
      'release_notes_history'
    );
    this.versionToDate();
  }

  public back(): void {
    this.returnMenu.emit();
  }

  public OpenReleaseNotes(releaseNoteRoute: string): void {
    this.dialog.open(ReleaseNotesDialogComponent, {
      maxWidth: '750px',
      disableClose: true,
      panelClass: 'modal-release-notes',
      data: releaseNoteRoute,
    });
  }

  public versionToDate(): void {
    const versionDate = this.version.substring(0, this.version.indexOf('.'));
    const date = new Date(
      versionDate.substring(0, 4) +
        '/' +
        versionDate.substring(4, 6) +
        '/' +
        versionDate.substring(6, 8)
    );
    this.dateVersion = this.dateToText(date);
  }

  private dateToText(date: Date) {
    const day = date.getDate();
    const monthName = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${day} de ${monthName} de ${year}`;
  }
}
