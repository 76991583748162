import { PatientTypeEnum } from '../constants/patient-type.constant';
import { PatientContactModel } from './patient-contact.model';
import { PatientStatusModel } from './patient-status.model';

export class PatientModel {
  public gcmid: number;
  public name: string;
  public socialName: string;
  public type: PatientTypeEnum;
  public contact: PatientContactModel;
  public status: PatientStatusModel;
  public director?: string;
  public location?: string;
  public manager?: string;
  public managerEmail?: string;
  public registration?: string;
  public role?: string;
  public ssoLocation?: string;
  public id?: string;
  public idCard?: string;
  public company?: string;
  public holderName?: string;
  public isDemobilize?: boolean;
  public demobilizeDate?: Date;
  public birthDate?: Date;
  public lastStatusUpdateDate?: Date;
  public tragTestResult?: boolean;
  public examQuarantineDate?: Date;
}
