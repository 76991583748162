export enum CountryEnum {
  BRAZIL = 1,
  MOZAMBIQUE = 2,
  PERU = 3,
  CHILE = 4,
  PARAGUAY = 5,
  UNITED_STATES = 6,
  CANADA = 7,
  MALAWI = 8,
  INDONESIA = 9,
  NEW_CALEDONIA = 10,
  SINGAPORE = 11,
  UNITED_KINGDOM = 12,
  JAPAN = 13,
  NETHERLANDS = 14,
  SWITZERLAND = 15,
  URUGUAY = 16,
  MALAYSIA = 17,
  AUSTRIA = 18,
  OMAN = 19,
  AUSTRALIA = 20,
  FRANCE = 21,
  CHINA = 22,
  INDIA = 23,
  ARGENTINA = 24,
  VACCINE_RELEASE = 199,
}
