<div class="container-customer-satisfaction">
  <div class="header">
    <div class="back-button" (click)="back()">
      <mat-icon class="return-icon"> navigate_before </mat-icon>
      <span class="title">{{
        'HELP_CENTER.CUSTOMER_SATISFACTION.TITLE_GO_BACK' | translate
      }}</span>
    </div>
  </div>
  <div class="container-title">
    <span class="title">
      {{ 'HELP_CENTER.CUSTOMER_SATISFACTION.TITLE' | translate }}</span
    >
  </div>
  <app-score *ngIf="toEvaluate" (save)="save($event)"></app-score>
  <div class="container-messages centered" *ngIf="!toEvaluate">
    <div class="centered">
      <div class="container-image">
        <img
          class="image"
          src="./assets/images/{{ savedSuccefully ? 'success' : 'error' }}.svg"
          alt="image"
        />
      </div>
      <div class="centered">
        <span class="title"> {{ getTitleSavedSuccefully() | translate }} </span>
      </div>
    </div>
    <div class="content-text centered">
      <span class="content-font">{{
        getMessageSavedSuccefully() | translate
      }}</span>
    </div>
    <div>
      <button
        app-vale-flat-button
        (click)="savedSuccefully ? reassessGcm() : trySaveAgain()"
      >
        {{
          (savedSuccefully
            ? 'HELP_CENTER.CUSTOMER_SATISFACTION.BUTTONS.REASSESS'
            : 'HELP_CENTER.CUSTOMER_SATISFACTION.BUTTONS.TRY_AGAIN'
          ) | translate
        }}
      </button>
    </div>
  </div>
</div>
