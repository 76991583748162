import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { MedicalRecordService } from '../../core/services/medical-record.service';
import { PatientService } from '../../core/services/patient.service';
import { UserBusiness } from '../../shared/business/user-business.service';
import { ReturnDialogButton } from '../../shared/components/return-dialog/return-dialog-button.model';
import { ReturnDialogComponent } from '../../shared/components/return-dialog/return-dialog.component';
import { ReturnDialog } from '../../shared/components/return-dialog/return-dialog.model';
import { HomeOptionsEnum } from '../../shared/enums/home-options.enum';
import { ComorbiditiesModel } from '../../shared/models/comorbidities.model';
import { PatientProtocolModel } from '../../shared/models/patient-protocol.model';
import { PatientModel } from '../../shared/models/patient.model';
import { DialogService } from '../../shared/services/dialog.service';

@Injectable()
export class PatientBusiness implements OnDestroy {
  public patient = new BehaviorSubject<PatientModel>(null);
  public patientProtocol = new BehaviorSubject<PatientProtocolModel>(null);
  public patientComorbidity = new BehaviorSubject<ComorbiditiesModel>(null);

  private userCountry: string;
  private shortPatient: any;
  private _subscriptions: Subscription = new Subscription();
  private _shortPatientSubject = new BehaviorSubject<any>(null);

  constructor(
    public medicalRecordService: MedicalRecordService,
    public patientService: PatientService,
    public dialogService: DialogService,
    private userBusiness: UserBusiness,
    public translateService: TranslateService,
    public dialog: MatDialog
  ) {}

  public ngOnDestroy(): void {
    this._resetData();
  }

  public getUserCountry(): string {
    if (!this.userCountry) {
      const country = this.userBusiness.getLoggedUser$.subscribe(
        (result: any) => {
          if (result) {
            this.userCountry = result.userCountry.toUpperCase();
          }
        }
      );
      this._subscriptions.add(country);
    }
    return this.userCountry;
  }

  public getPatientInformation(shortPatient: any): void {
    this._setShortPatientFromSearch(shortPatient);
    this.shortPatient = shortPatient;
    if (shortPatient) {
      this.getPatientInfoFromApi(shortPatient);
      if (shortPatient.gcmId) {
        this.getEmployeeSecondPhaseProtocol(shortPatient.gcmId);
      }
    }
  }

  public demobilizeReturnDialog(): void {
    const dialogRef = this.dialog;
    const buttons = new Array<ReturnDialogButton>(
      new ReturnDialogButton({
        name: this.translateService.instant('DIALOG.BUTTON_CLOSE'),
        className:
          'button-classic button-classic--complete button-classic--background-blue',
        action: () => {
          dialogRef.closeAll();
        },
      })
    );
    const message = this.translateService.instant('DIALOG.DEMOBILIZE_MESSAGE');
    this.dialog.open(ReturnDialogComponent, {
      data: new ReturnDialog({
        actionType: 'success',
        title: this.translateService.instant('DIALOG.DEMOBILIZE_TITLE'),
        message: message,
        path: './assets/images/demobilize-img.svg',
        buttons: buttons,
        closeActionButton: () => {
          dialogRef.closeAll();
        },
      }),
      disableClose: true,
    });
  }

  public getComorbiditiesByEmployeeId(): void {
    if (this.shortPatient && this.shortPatient.gcmId) {
      const comorbidity = this.patientService
        .getComorbiditiesByEmployeeId(this.shortPatient.gcmId)
        .subscribe(
          (response) => {
            if (response) {
              this.patientComorbidity.next(response);
            }
          },
          (error: any) => {
            if (error) {
              this._callErrorDialog(
                'DIALOG.ERROR_COMORBIDITIES',
                'DIALOG.ERROR_GET_COMORBIDITIES',
                this._userOption
              );
            }
          }
        );

      this._subscriptions.add(comorbidity);
    }
  }

  public get shortPatient$(): Observable<any> {
    return this._shortPatientSubject.asObservable();
  }

  public setComorbidities(comorbidities: ComorbiditiesModel): void {
    this.patientComorbidity.next(comorbidities);
  }

  private _setShortPatientFromSearch(shortPatient: any) {
    this._shortPatientSubject.next(shortPatient);
  }

  private getPatientInfoFromApi(shortPatient: any) {
    const search = {
      gcmId: shortPatient.gcmId,
      id: shortPatient.cpf,
      iamId: shortPatient.iamId,
    };
    this._setShortPatientFromSearch(search);
    const patientInfo = this.patientService.getPatientInfo(search).subscribe(
      (response) => {
        if (response) {
          this.patient.next(response);
        } else {
          this.patient.next(new PatientModel());
        }
      },
      (error: any) => {
        this._callErrorDialog(
          'DIALOG.ERROR_MEDICAL_RECORD',
          'DIALOG.ERROR_GET_MEDICAL_RECORD',
          this._userOption
        );
      }
    );

    this._subscriptions.add(patientInfo);
  }

  private _callErrorDialog(
    title: string,
    message: string,
    userOption: HomeOptionsEnum
  ): void {
    this._resetData();
    this.dialogService.callErrorDialog(
      title,
      message,
      userOption || this._userOption
    );
  }

  private getEmployeeSecondPhaseProtocol(gcmId: any) {
    const protocol = this.medicalRecordService
      .getPatientProtocolSituation(gcmId)
      .subscribe(
        (response: any) => {
          if (response) {
            this.patientProtocol.next(response);
          } else {
            const ProtocolEmpty = new PatientProtocolModel();
            this.patientProtocol.next(ProtocolEmpty);
          }
        },
        (error: any) => {
          if (error) {
            this._callErrorDialog(
              'DIALOG.ERROR_PROTOCOL',
              'DIALOG.ERROR_GET_PROTOCOL',
              this._userOption
            );
          }
        }
      );
    this._subscriptions.add(protocol);
  }

  private _resetData() {
    this.patient = new BehaviorSubject<PatientModel>(null);
    this.patientProtocol = new BehaviorSubject<PatientProtocolModel>(null);
    this.patientComorbidity = new BehaviorSubject<ComorbiditiesModel>(null);
  }

  private get _userOption() {
    return this.userBusiness.getHomeOption();
  }
}
