export enum QuickExamResultEnum {
  UNREACTIVE = 3,
  REACTIVE_WITHOUT_DISTICTION = 4,
  IGM_POSITIVE_IGG_POSITIVE = 5,
  IGM_NEGATIVE_IGG_POSITIVE = 6,
  IGM_POSITIVE_IGG_NEGATIVE = 7,
  IGM_NEGATIVE_IGG_NEGATIVE = 8,
}

export enum LabExamResultEnum {
  NEGATIVE = 0,
  POSITIVE = 1,
  INCONCLUSIVE = 2,
}

export enum TragTestResultEnum {
  NEGATIVE = 0,
  POSITIVE = 1,
}

export enum HealthImprovedEnum {
  IMPROVEMENT = 1,
  WORSENS = 2,
  STABLE = 3,
  RECOVERED = 4,
}
