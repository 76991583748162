import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { PatientBusiness } from '../../../modules/patient/patient-business.service';
import { QuickExamResultEnum } from '../../enums/medical-record.enum';
import { VaccinesEnum } from '../../enums/vaccines.enum';
import { TragTestResultEnum } from './../../enums/medical-record.enum';
import {
  TypeTestEnum,
  ValeTimelineEnum,
} from './../../enums/vale-timeline.enum';
import { DateTimeUtil } from './../../util/date-time-util';

@Component({
  selector: 'app-vale-timeline',
  templateUrl: './vale-timeline.component.html',
  styleUrls: ['./vale-timeline.component.scss'],
})
export class ValeTimelineComponent implements OnInit, OnChanges, OnDestroy {
  public data = [];
  public valeTimelineEnum = ValeTimelineEnum;
  public vaccinesEnum = VaccinesEnum;
  public typeTestEnum = TypeTestEnum;
  public quickExamResultEnum = QuickExamResultEnum;
  public tragTestResultEnum = TragTestResultEnum;
  private _patientId;
  private _subscriptions = new Subscription();
  public integrationSGC = 'Integração SGC';
  @Input('data') private _data = [];

  constructor(
    private _router: Router,
    private _patientBusiness: PatientBusiness
  ) {}

  ngOnChanges(): void {
    this._handleData();
  }

  ngOnInit(): void {
    this._subscriptions.add(
      this._patientBusiness.shortPatient$.subscribe((response) => {
        this._patientId = response?.gcmId;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  public openModule(module: string) {
    const url = `patient/${this._patientId}/${module}`;
    const state = {
      shortEmployee: {
        gcmId: this._patientId,
      },
    };
    this._router.navigate([url], {
      state: state,
    });
  }

  private _handleData() {
    const _dataByYear = this._convertDataFromDataByYear();
    this.data = _dataByYear.map((dataByYear) => {
      const _data = this._sortDataByEventDateDesc(dataByYear.data);
      this._setFirstDate(_data);
      dataByYear.data = _data;
      return dataByYear;
    });
  }

  private _convertDataFromDataByYear() {
    const dataByYearDict = {};
    this._data.forEach((e) => {
      const year = e.eventDate && e.eventDate.getFullYear();
      if (!dataByYearDict[year]) {
        dataByYearDict[year] = [];
      }
      if (e.eventType !== ValeTimelineEnum.VACCINES) {
        this._addEvent(dataByYearDict, year, e);
      } else {
        if (e.vaccineId === VaccinesEnum.RESTRICAO_MEDICA) {
          if (e.eventDate > e.createdDate) {
            this._addEvent(dataByYearDict, year, e);
            this._addReleaseFromVaccinationEvent(dataByYearDict, year, e);
          } else {
            this._addReleaseFromVaccinationEvent(dataByYearDict, year, e);
          }
        } else {
          this._addEvent(dataByYearDict, year, e);
        }
      }
    });
    return Object.keys(dataByYearDict)
      .map((key) => {
        return {
          year: key,
          data: dataByYearDict[key],
        };
      })
      .sort((a, b) => {
        return Number.parseInt(b.year) - Number.parseInt(a.year);
      });
  }

  private _addEvent(dataByYearDict: {}, year: any, e: any) {
    dataByYearDict[year].push({
      ...e,
      isFirst: false,
      createdDateFormated: DateTimeUtil.formatDayOfMonth(e.createdDate),
      eventDateFormated: DateTimeUtil.formatDayOfMonth(e.eventDate),
      labExamResultDateFormated: DateTimeUtil.formatDayOfMonth(
        e.labExamResultDate
      ),
    });
  }

  private _addReleaseFromVaccinationEvent(
    dataByYearDict: {},
    year: any,
    e: any
  ) {
    dataByYearDict[year].push({
      createdDate: e.createdDate,
      eventDate: e.createdDate,
      userFullName: e.userFullName,
      user: e.user,
      eventType: e.eventType,
      stopQuarentinePCR: e.stopQuarentinePCR,
      vaccineName: e.vaccineName,
      doseVaccine: e.doseVaccine,
      numberDoses: e.numberDoses,
      vaccineId: e.vaccineId,
      origin: e.origin,
      isBoosterVaccine: e.isBoosterVaccine,
      vaccineInformationNote: e.vaccineInformationNote,
      isFirst: false,
      createdDateFormated: DateTimeUtil.formatDayOfMonth(e.createdDate),
      eventDateFormated: DateTimeUtil.formatDayOfMonth(e.createdDate),
      labExamResultDateFormated: DateTimeUtil.formatDayOfMonth(
        e.labExamResultDate
      ),
    });
  }

  private _setFirstDate(data: any[]) {
    if (!(data || []).length) {
      return [];
    }

    data[0].isFirst = true;
    for (let i = 1; i < data.length; i++) {
      const previous = data[i - 1];
      const current = data[i];
      current.isFirst = !this._isEqualDate(
        previous.eventDate,
        current.eventDate
      );
    }
  }

  private _formatDate(date: string | number | Date): string {
    const formatDate = new Date(date);
    const day = `${formatDate.getDate()}`.padStart(2, '0');
    const month = `${formatDate.getMonth() + 1}`.padStart(2, '0');
    const year = `${formatDate.getFullYear()}`.padStart(2, '0');
    return `${day}/${month}/${year}`;
  }

  private _isEqualDate(
    date: string | number | Date,
    date2: string | number | Date
  ): boolean {
    const currentDate = new Date(date);
    const previousDate = new Date(date2);
    const currentDateFormated = this._formatDate(currentDate);
    const previousDateFormated = this._formatDate(previousDate);
    return currentDateFormated === previousDateFormated;
  }

  private _sortDataByEventDateDesc(data: any[]): any[] {
    return [...(data || [])].sort((a, b) => {
      return b.eventDate - a.eventDate;
    });
  }
}
