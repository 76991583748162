import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { PatientService } from '../../../../core/services/patient.service';
import { ChecklistModel } from '../../../models/checklist.model';

export interface ChecklistResponseInfo {
  dayDifference: number;
  lastDayWhitoutResponse: Date;
  firstDayWhitoutResponse: Date;
}

@Injectable()
export class PatientInfoChecklistBusiness implements OnDestroy {
  private _patientId;
  private _patientCheckList = new BehaviorSubject<ChecklistModel[]>(null);
  private _subscriptions: Subscription = new Subscription();

  constructor(private _patientService: PatientService) {}

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this._patientCheckList = new BehaviorSubject<ChecklistModel[]>(null);
  }

  public getCheckListsByPatientId$(
    patientId: number
  ): Observable<ChecklistModel[]> {
    if (this._isPatientChange(patientId)) {
      this._patientId = patientId;
      this._patientCheckList.next(null);
      this._getChecklistFromApi(patientId);
    }
    return this._patientCheckList.asObservable();
  }

  private _getChecklistFromApi(patientId: number) {
    const checklist = this._patientService
      .getChecklist(patientId)
      .subscribe((checklists) => {
        const result = this._processChecklistFilled(checklists);
        this._patientCheckList.next(result);
      });
    this._subscriptions.add(checklist);
  }

  private _isPatientChange(patientId: number): boolean {
    return this._patientId !== patientId;
  }

  private _processChecklistFilled(checklists: any) {
    if (checklists && checklists.length == 1) {
      return [
        {
          ...checklists[0],
          answered: true,
        },
      ];
    }
    return this._handleChecklistsResponse(checklists);
  }

  private _handleChecklistsResponse(checklists: any) {
    const result = [];
    if (checklists) {
      for (let i = 1; i < checklists.length; i++) {
        const mostRecentChecklist = checklists[i - 1];
        const previousChecklist = checklists[i];

        result.push(this._getAnsweredChecklist(mostRecentChecklist));

        result.push(
          this._getLastChecklistWhitoutResponse(
            mostRecentChecklist,
            previousChecklist
          )
        );

        result.push(
          this._getFirstChecklistWhitoutResponse(
            mostRecentChecklist,
            previousChecklist
          )
        );
      }
    }
    return result.filter((r) => r);
  }

  private _getAnsweredChecklist(mostRecentChecklist: any): any {
    return {
      ...mostRecentChecklist,
      answered: true,
    };
  }

  private _getFirstChecklistWhitoutResponse(
    mostRecentChecklist: any,
    previousChecklist: any
  ) {
    const {
      dayDifference,
      firstDayWhitoutResponse,
    } = this._getChecklistResponseInfo(mostRecentChecklist, previousChecklist);

    if (dayDifference > 1) {
      return {
        ...previousChecklist,
        date: firstDayWhitoutResponse,
        answered: false,
      };
    }
  }

  private _getLastChecklistWhitoutResponse(
    mostRecentChecklist: any,
    previousChecklist: any
  ) {
    const {
      dayDifference,
      lastDayWhitoutResponse,
      firstDayWhitoutResponse,
    } = this._getChecklistResponseInfo(mostRecentChecklist, previousChecklist);

    if (dayDifference > 2) {
      return {
        ...previousChecklist,
        date: lastDayWhitoutResponse,
        numberOfDaysWithoutResponse: dayDifference - 1,
        firstDayWhitoutResponse: firstDayWhitoutResponse,
        answered: false,
      };
    }
  }

  private _getChecklistResponseInfo(
    mostRecentChecklist: any,
    previousChecklist: any
  ): ChecklistResponseInfo {
    const date1 = moment(mostRecentChecklist.date, 'YYYY-MM-DD');
    const date2 = moment(previousChecklist.date, 'YYYY-MM-DD');
    const dayDifference = date1.diff(date2, 'days');

    const lastDayWhitoutResponse = date1.clone().subtract(1, 'days').toDate();
    const firstDayWhitoutResponse = date2.clone().add(1, 'days').toDate();
    return { dayDifference, lastDayWhitoutResponse, firstDayWhitoutResponse };
  }
}
