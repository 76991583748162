<div class="container-csat-dialog">
  <div class="header">
    <div class="title">{{ 'CSAT_DIALOG.TITLE' | translate }}</div>
  </div>
  <div class="body">
    <div>
      <app-score (save)="save($event)"></app-score>
    </div>
  </div>
</div>
