// eslint-disable-next-line simple-import-sort/sort
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { UserDateAdapter } from '../../../../shared/util/user-date.adapter';
import {
  TypeTestEnum,
  ValeTimelineEnum,
} from '../../../enums/vale-timeline.enum';
import {
  HospitalizationModel,
  ProtocolHistoryModel,
  QuickExamHistoryModel,
} from '../../../models/quick-exam-history.model';
import {
  ComorbidityTimelineModel,
  CompulsoryNotificationTimelineModel,
  DeathTimelineModel,
  ExamsTimelineModel,
  NoteTimelineModel,
  SymptomTimelineModel,
  VaccinesTimelineModel,
  ValeTimelineModel,
} from '../../../models/vale-timeline.model';
import { Extensions } from '../../../extensions';
import * as moment from 'moment';

interface FilterOption {
  id: number;
  name: string;
}

@Component({
  selector: 'app-patient-info-history',
  templateUrl: './patient-info-history.component.html',
  styleUrls: ['./patient-info-history.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: UserDateAdapter },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class PatientInfoHistoryComponent implements OnInit, OnChanges {
  @Input() public patientHistory: QuickExamHistoryModel[];
  @Input() public protocolHistory: ProtocolHistoryModel[];
  @Input('updatingTimeline') public updatingTimeline: false;

  public idiom: string = localStorage.getItem(Extensions.STORAGE_KEYS.CULTURE);
  public fieldName: string = null;
  public filteredForm = new FormControl();
  public filteredHistories: ValeTimelineModel[];
  public _histories: ValeTimelineModel[];
  public fullOptions: FilterOption[] = [
    {
      id: ValeTimelineEnum.NOTE,
      name: 'PATIENT_HISTORY.FILTER.OPTIONS.EVOLUTION',
    },
    {
      id: ValeTimelineEnum.HOSPITALIZATION,
      name: 'PATIENT_HISTORY.FILTER.OPTIONS.HOSPITALIZED',
    },
    {
      id: ValeTimelineEnum.SYMPTOM,
      name: 'PATIENT_HISTORY.FILTER.OPTIONS.SYMPTOMS',
    },
    {
      id: ValeTimelineEnum.EXAM_PCR,
      name: 'PATIENT_HISTORY.FILTER.OPTIONS.PCR_TEST',
    },
    {
      id: ValeTimelineEnum.COMORBIDITY,
      name: 'PATIENT_HISTORY.FILTER.OPTIONS.COMORBIDITIES',
    },
    {
      id: ValeTimelineEnum.VACCINES,
      name: 'PATIENT_HISTORY.FILTER.OPTIONS.VACCINE',
    },
    {
      id: ValeTimelineEnum.COMPULSORY_NOTIFICATION,
      name: 'PATIENT_HISTORY.FILTER.OPTIONS.COMPULSORY_NOTIFICATION',
    },
    {
      id: ValeTimelineEnum.EXAM_TRAG,
      name: 'PATIENT_HISTORY.FILTER.OPTIONS.TRAG_TEST',
    },
    {
      id: ValeTimelineEnum.EXAM_SEROLOGICAL,
      name: 'PATIENT_HISTORY.FILTER.OPTIONS.SEROLOGICAL_TEST',
    },
    {
      id: ValeTimelineEnum.TRAG_TEST_EXTERNAL,
      name: 'PATIENT_HISTORY.FILTER.OPTIONS.TRAG_TEST_EXTERNAL',
    },
    {
      id: ValeTimelineEnum.DISCHARGE,
      name: 'PATIENT_HISTORY.FILTER.OPTIONS.DISCHARGE',
    },
    {
      id: ValeTimelineEnum.DEATH,
      name: 'PATIENT_HISTORY.FILTER.OPTIONS.DEATH',
    },
  ];
  public options: FilterOption[];

  constructor() {}

  public ngOnInit(): void {
    this.fieldName = 'PATIENT_HISTORY.FILTER.NAME';
    this._histories = this._getHistories();
    this.filterTypes();
    this.filteredHistories = this._histories;
    this.filteredForm.valueChanges.subscribe((value: FilterOption[]) => {
      this.filteredHistories = this.filterPatientHistory(value);
      this.fieldName = value.length ? null : 'PATIENT_HISTORY.FILTER.NAME';
    });
  }

  ngOnChanges(): void {
    this._histories = this._getHistories();
    this.filteredHistories = this._histories;
    this.filterTypes();
    if (this.updatingTimeline) {
      this.clearfilteredForm();
    }
  }

  public filterPatientHistory(
    filteredOptions: FilterOption[]
  ): ValeTimelineModel[] {
    if (
      (filteredOptions || []).length === this.options.length ||
      !(filteredOptions || []).length
    ) {
      return this._histories;
    }
    return filteredOptions
      .reduce((acc: any[], option) => {
        const _filtereds = this._histories.filter((his) =>
          this._isFilterByOption(option.id, his)
        );
        return Array.from(new Set([...acc, ..._filtereds]));
      }, [])
      .sort((a, b) => {
        if (b.date && a.date) {
          return b.date.getTime() - a.date.getTime();
        }
        return 0;
      });
  }

  public filterTypes(): void {
    const types: FilterOption[] = [];
    const typeNumbers = new Set();
    this._histories.forEach((e) => {
      typeNumbers.add(e.eventType);
    });
    typeNumbers.forEach((id) => {
      types.push(this.fullOptions.find((option) => option.id == id));
    });
    this.options = types;
  }

  public removableFilterTag(event: number): void {
    if (event) {
      const filtered = this._removeFilterForm(event);
      this.filteredForm.setValue(filtered);
    }
  }

  public clearfilteredForm(): void {
    this.filteredForm.setValue([]);
  }

  private _isFilterByOption(option: number, his): boolean {
    switch (option) {
      case ValeTimelineEnum.NOTE:
        return this._isNotesFilter(his);
      case ValeTimelineEnum.HOSPITALIZATION:
        return this._isHospitalized(his);
      case ValeTimelineEnum.SYMPTOM:
        return this._isSymptoms(his);
      case ValeTimelineEnum.EXAM_PCR:
        return this._isPcrTest(his);
      case ValeTimelineEnum.COMORBIDITY:
        return this._isComorbities(his);
      case ValeTimelineEnum.VACCINES:
        return this._isVaccine(his);
      case ValeTimelineEnum.COMPULSORY_NOTIFICATION:
        return this._isCompulsoryNotification(his);
      case ValeTimelineEnum.EXAM_TRAG:
        return this._isTragTest(his);
      case ValeTimelineEnum.EXAM_SEROLOGICAL:
        return this._isSerologicalTest(his);
      case ValeTimelineEnum.TRAG_TEST_EXTERNAL:
        return this._isTragTestExternal(his);
      case ValeTimelineEnum.DISCHARGE:
        return this._isDischarge(his);
      case ValeTimelineEnum.DEATH:
        return this._isDeath(his);
    }
  }

  private _isNotesFilter(history: NoteTimelineModel): boolean {
    return history.note && history.eventType == ValeTimelineEnum.NOTE;
  }

  private _isHospitalized(history: HospitalizationModel): boolean {
    return history.eventType == ValeTimelineEnum.HOSPITALIZATION;
  }

  private _isSymptoms(history: SymptomTimelineModel): boolean {
    return history.symptomList && history.eventType == ValeTimelineEnum.SYMPTOM;
  }

  private _isPcrTest(history: ExamsTimelineModel): boolean {
    return (
      history.eventType == ValeTimelineEnum.EXAM_PCR &&
      history.typeTest == TypeTestEnum.PCR
    );
  }

  private _isComorbities(history: ComorbidityTimelineModel): boolean {
    return (
      history.comorbidityList &&
      history.eventType == ValeTimelineEnum.COMORBIDITY
    );
  }

  private _isVaccine(history: VaccinesTimelineModel): boolean {
    return (
      history.doseVaccine && history.eventType == ValeTimelineEnum.VACCINES
    );
  }

  private _isCompulsoryNotification(
    history: CompulsoryNotificationTimelineModel
  ): boolean {
    return (
      history.numberCompulsoryNotification &&
      history.eventType == ValeTimelineEnum.COMPULSORY_NOTIFICATION
    );
  }

  private _isTragTest(history: ExamsTimelineModel): boolean {
    return (
      history.eventType == ValeTimelineEnum.EXAM_TRAG &&
      history.typeTest == TypeTestEnum.TRAG
    );
  }

  private _isSerologicalTest(history: ExamsTimelineModel): boolean {
    return (
      history.eventType == ValeTimelineEnum.EXAM_SEROLOGICAL &&
      history.typeTest == TypeTestEnum.SOROLOGIC
    );
  }

  private _isTragTestExternal(history: ExamsTimelineModel): boolean {
    return (
      history.eventType == ValeTimelineEnum.TRAG_TEST_EXTERNAL &&
      history.typeTest == TypeTestEnum.TRAG &&
      history.externalTest == true
    );
  }

  private _isDischarge(history: ExamsTimelineModel): boolean {
    return history.eventType == ValeTimelineEnum.DISCHARGE;
  }

  private _isDeath(history: DeathTimelineModel): boolean {
    return history.eventType == ValeTimelineEnum.DEATH;
  }

  private _getHistories(): any[] {
    const histories = [...this._getHistoriesFromPatientHistory()];
    return histories.sort((a, b) => {
      if (b.eventDate) {
        return b.eventDate.getTime();
      }
      return 0;
    });
  }

  private _getHistoriesFromPatientHistory(): any[] {
    return (this.patientHistory || []).map((p) => {
      const extension = this._mapExtensionByEventType(p);
      return {
        eventDate: p.eventDate
          ? new Date(p.eventDate)
          : new Date(p.createdDate),
        createdDate: new Date(p.createdDate),
        userFullName: p.userFullName,
        user: p.user,
        eventType: p.eventType,
        stopQuarentinePCR: p.stopQuarentinePCR,
        ...extension,
      };
    });
  }

  private _mapExtensionByEventType(event) {
    if (event.eventType === ValeTimelineEnum.COMORBIDITY) {
      return {
        comorbidityList: event.comorbidityList,
        description: event.description,
        medicalNote: event.medicalNote,
      };
    } else if (event.eventType === ValeTimelineEnum.SYMPTOM) {
      return {
        symptomList: event.symptomList,
        description: event.description,
      };
    } else if (event.eventType === ValeTimelineEnum.HOSPITALIZATION) {
      return {
        hospitalName: event.hospitalName,
        transferenceDate: event.transferenceDate
          ? moment(new Date(event.transferenceDate))
              .locale(this.idiom)
              .format('L')
          : null,
        state: event.state,
      };
    } else if (event.eventType === ValeTimelineEnum.DISCHARGE) {
      return {
        hospitalName: event.hospitalName,
      };
    } else if (event.eventType === ValeTimelineEnum.NOTE) {
      return {
        note: event.note,
      };
    } else if (event.eventType === ValeTimelineEnum.EXAM_PCR) {
      return {
        typeTest: event.typeTest,
        resultTest: event.resultTest,
        labExamResultDate: event.labExamResultDate,
        timeTestPCR: event.eventDate.substring(11, 16),
      };
    } else if (
      event.eventType === ValeTimelineEnum.EXAM_SEROLOGICAL ||
      event.eventType === ValeTimelineEnum.EXAM_TRAG
    ) {
      return {
        typeTest: event.typeTest,
        resultTest: event.resultTest,
        labExamResultDate: null,
        note: event.note,
        cycle: event.cycle,
        timeTestTrag: event.eventDate.substring(11, 16),
      };
    } else if (event.eventType === ValeTimelineEnum.VACCINES) {
      return {
        vaccineName: event.vaccineName,
        doseVaccine: event.doseVaccine,
        numberDoses: event.numberDoses,
        vaccineId: event.vaccineId,
        origin: event.origin,
        isBoosterVaccine: event.isBoosterVaccine,
        vaccineInformationNote: event.vaccineInformationNote,
      };
    } else if (event.eventType === ValeTimelineEnum.COMPULSORY_NOTIFICATION) {
      return {
        numberCompulsoryNotification: event.numberCompulsoryNotification,
      };
    } else if (event.eventType === ValeTimelineEnum.TRAG_TEST_EXTERNAL) {
      return {
        typeTest: event.typeTest,
        resultTest: event.resultTest,
        labExamResultDate: null,
        externalText: event.externalText,
        note: event.note,
      };
    } else if (event.eventType == ValeTimelineEnum.DEATH) {
      return {
        cid: event.cid,
      };
    }
  }

  private _removeFilterForm(value: number): number[] {
    return this.filteredForm.value.filter(
      (field: FilterOption) => field.id !== value
    );
  }
}
