import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ValeRadioButtonComponent } from './vale-radio-button/vale-radio-button.component';
import { ValeRadioGroupComponent } from './vale-radio-group/vale-radio-group.component';

@NgModule({
  declarations: [ValeRadioButtonComponent, ValeRadioGroupComponent],
  imports: [CommonModule, FormsModule],
  exports: [ValeRadioButtonComponent, ValeRadioGroupComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeRadioButtonModule {}
