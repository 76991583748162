<mat-accordion class="expansion-filter-container">
  <mat-expansion-panel
    class="expansion-filter-panel"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="panelOpenState"
  >
    <mat-expansion-panel-header
      [collapsedHeight]="customCollapsedHeight"
      [expandedHeight]="customExpandedHeight"
    >
      <mat-panel-title class="expansion-filter-title">
        {{ title }}
        <button
          class="filter-button"
          app-vale-fab-button
          (click)="openFilterAndEmitEvent($event)"
          [matTooltip]="'FILTER_DIALOG.FILTER' | translate"
        >
          <mat-icon svgIcon="filter-list-24px"></mat-icon>
        </button>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="tag-container">
      <ng-container>
        <ng-content></ng-content>
      </ng-container>
      <ng-container>
        <div *ngFor="let filter of filtersTag; let i = index">
          <vale-removable-tag
            *ngIf="filter?.name"
            (change)="changeTags($event)"
            (close)="closeTag(i, filter)"
            [removable]="filter.isRemovable"
            >{{ filter.name | translate }}</vale-removable-tag
          >
        </div>
      </ng-container>
    </div>
  </mat-expansion-panel>
</mat-accordion>
