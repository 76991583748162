import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

import { auth } from '../../../environments/environment';
import { UserBusiness } from '../../shared/business/user-business.service';
import { Storage } from '../../shared/util/storage';

@Injectable()
export class BaseAuthenticationService {
  public _storage: Storage = new Storage();
  public accessToken: string;
  public oauthService: OAuthService;
  public userBusiness: UserBusiness;
  public router: Router;

  constructor(
    _oauthService: OAuthService,
    _userBusiness: UserBusiness,
    _router: Router
  ) {
    this.oauthService = _oauthService;
    this.userBusiness = _userBusiness;
    this.router = _router;
  }

  public doLogout(): void {
    this.oauthService.logOut();
    window.location.href = auth.logoutUrl;
  }

  // o groupMembership vem como o exemplo: cn=GCMMedicalRecordBR,ou=GCM,ou=Groups,o=vale
  public getGroupMembership(groups: [string] | string): any[] {
    const groupsOrganized = [];
    if (Array.isArray(groups)) {
      groups.forEach((group: string) => {
        groupsOrganized.push(this.getGroup(group));
      });
    } else {
      groupsOrganized.push(this.getGroup(groups));
    }
    return groupsOrganized;
  }

  public getGroup(req: string): object {
    const properties = req.split(',');
    let cn: string;
    const ou = [];
    const o = [];
    const other = [];

    properties.forEach((property: string) => {
      const prop = property.split('=');
      switch (prop[0]) {
        case 'cn':
          cn = prop[1];
          break;
        case 'ou':
          ou.push(prop[1]);
          break;
        case 'o':
          o.push(prop[1]);
          break;
        default:
          other.push({
            name: prop[0],
            value: prop[1],
          });
      }
    });
    const group = { cn, ou, o, other };
    return group;
  }

  public getUserCountry(user: any): any {
    if (!user.userCountry) {
      const country = user.userGroups[0].cn.toUpperCase();
      switch (country.slice(-2)) {
        case 'MZ':
          user.userCountry = 'Mozambique';
          break;
        case 'CA':
          user.userCountry = 'Canada';
          break;
        case 'ML':
          user.userCountry = 'Malawi';
          break;
        case 'BR':
        case 'AM':
        default:
          user.userCountry = 'Brazil';
          break;
      }
    }

    return user;
  }
}
