<div class="container-vale-upload">
  <div class="vale-upload" *ngIf="!fileInputForm?.value">
    <div appValeDragNDrop (fileDropped)="onFileDropped($event)">
      <input
        type="file"
        #fileDropRef
        id="fileDropRef"
        (change)="fileBrowseHandler($event.target.files)"
      />
      <div class="container-title">
        <span>{{ 'VACCINE_LOAD.DRAG_DROP' | translate }}</span>
        <span>{{ 'VACCINE_LOAD.OTHER_OPTION' | translate }}</span>
      </div>
      <div class="action">
        <button class="upload-button">
          {{ 'VACCINE_LOAD.PICK_FILE' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div
    class="invalid-format"
    *ngIf="fileInputForm?.invalid && fileInputForm?.value"
  >
    <div class="close-icon">
      <mat-icon (click)="reset()" [matToolTip]="Fechar">close</mat-icon>
    </div>
    <div class="upload-files">
      <div class="image">
        <img src="./../../../../assets/images/csv-error.svg" alt="" />
      </div>
      <div class="title">
        <span>{{ filename }}</span>
      </div>
      <div class="invalid">
        <span>Formato Invalido</span>
      </div>
    </div>
  </div>
  <div
    class="container-upload-files"
    *ngIf="fileInputForm?.value && fileInputForm.valid"
  >
    <div class="close-icon">
      <mat-icon (click)="reset()" [matToolTip]="Fechar">close</mat-icon>
    </div>
    <div class="upload-files">
      <div class="image">
        <img src="./../../../../assets/images/csv-spdreeadsheet.svg" alt="" />
      </div>
      <div class="title">
        <span>{{ filename }}</span>
      </div>
    </div>
  </div>
</div>
