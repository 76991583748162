import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'vale-removable-tag',
  templateUrl: './vale-removable-tag.component.html',
  styleUrls: ['./vale-removable-tag.component.scss'],
})
export class ValeRemovableTagComponent implements OnInit {
  @Output('change')
  private _changeEvent = new EventEmitter();

  @Output('close')
  private _closeEvent = new EventEmitter();

  @Input()
  public outline = false;

  @Input()
  public removable = true;

  constructor() {}

  ngOnInit(): void {}

  public emitChangeEvent(): void {
    this._changeEvent.emit();
  }

  public emitCloseEvent(): void {
    this._closeEvent.emit();
  }
}
