<div>
  <mat-checkbox
    *ngIf="multiple"
    (change)="$event ? toggle() : null"
    [checked]="isSelected()"
    >{{ value }}
  </mat-checkbox>
  <div style="display: flex; align-items: center">
    <img style="margin-right: 12px" [src]="icon" alt="" />
    <span *ngIf="!multiple">{{ value }}</span>
  </div>
</div>
