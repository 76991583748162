import {
  AfterContentInit,
  Component,
  ContentChild,
  HostBinding,
  Input,
} from '@angular/core';

import { ValeIconComponent } from '../vale-icon/vale-icon.component';
import { ValePrefixDirective } from './../vale-icon/directive/vale-prefix.directive';
import { ValeSuffixDirective } from './../vale-icon/directive/vale-suffix.directive';
import { ValeInputDirective } from './directive/vale-input.directive';

@Component({
  selector: 'app-vale-label',
  templateUrl: './vale-label.component.html',
  styleUrls: ['./vale-label.component.scss'],
})
export class ValeLabelComponent implements AfterContentInit {
  @Input() public invalidText: string;
  @Input() public hintText: string;

  @ContentChild(ValeInputDirective)
  public field: ValeInputDirective;

  @ContentChild(ValePrefixDirective)
  public prefixIcon: ValeIconComponent;

  @ContentChild(ValeSuffixDirective)
  public suffixIcon: ValeIconComponent;

  @HostBinding('class.label-vale') labelClass = true;

  @HostBinding('class.label-vale-base')
  public get base(): boolean {
    return this.field ? this.field.base : true;
  }

  @HostBinding('class.label-vale-valid')
  public get valid(): boolean {
    return this.field ? this.field.valid : false;
  }

  @HostBinding('class.label-vale-invalid')
  public get invalid(): boolean {
    return this.field ? this.field.invalid : false;
  }

  @HostBinding('class.label-vale-disabled')
  public get disabled(): boolean {
    return this.field ? this.field.disabled : false;
  }

  @HostBinding('class.label-vale-outline-none')
  public get outlineNone(): boolean {
    return this.field ? this.field.outlineNone : false;
  }

  constructor() {}

  public ngAfterContentInit(): void {
    if (this.prefixIcon) {
      this.field.iconLeft = true;
    }

    if (this.suffixIcon) {
      this.field.iconRight = true;
    }
  }
}
