<div class="container-vale-removable-tag {{ outline ? 'outline' : '' }}">
  <div
    (click)="emitChangeEvent()"
    class="content font-config {{ removable ? 'removable' : '' }}"
  >
    <ng-content></ng-content>
  </div>
  <div *ngIf="removable" class="action">
    <mat-icon
      (click)="emitCloseEvent()"
      aria-hidden="false"
      aria-label="close icon"
      >close</mat-icon
    >
  </div>
</div>
