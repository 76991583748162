<div class="access-denied">
  <img
    class="denied-img"
    src="./assets/images/access-denied-door.svg"
    alt="imagem de acesso negado"
  />
  <div class="denied-text">
    <div class="vale-header">
      <img
        class="vale-img"
        src="./assets/images/logo-vale.svg"
        alt="logotipo da Vale"
      />
      <span class="vale-text">VALE</span>
    </div>
    <span class="denied-title">{{
      'PROFILE_DENIED.ACCESS_DENIED' | translate
    }}</span>
    <span class="denied-description normal-text">{{
      'PROFILE_DENIED.YOU_DONT_ACCESS' | translate
    }}</span>
    <span class="denied-description bold-text">{{
      'PROFILE_DENIED.CONTACT_IAM' | translate
    }}</span>
    <button
      app-vale-flat-button
      (click)="redirectButton()"
      class="redirect-button"
    >
      <span>{{ 'PROFILE_DENIED.REDIRECT_TO_IAM' | translate }}</span>
    </button>
  </div>
</div>
