import { EmployeeModel, MedicalRecordModel } from '../../shared/models';

export class MapperHelper {
  public static onEmployeeMapper(employeeApi: any): EmployeeModel {
    const employee: EmployeeModel = {
      id: employeeApi.id,
      typeEmployee: employeeApi.typeEmployee,
      document: employeeApi.document,
      address: employeeApi.address,
      name: employeeApi.name,
      socialName: employeeApi.socialName,
      code: employeeApi.iamId,
      director: employeeApi.director,
      role: employeeApi.role,
      manager: employeeApi.manager,
      managerEmail: employeeApi.managerEmail,
      description: employeeApi.description,
      ssoLocation: employeeApi.ssoLocation
        ? employeeApi.ssoLocation
        : employeeApi.facilityDescription,
      backupPhone:
        employeeApi.backupPhone != null
          ? this.clearPhone(employeeApi.backupPhone)
          : employeeApi.backupPhone,
      phone:
        employeeApi.phone != null
          ? this.clearPhone(employeeApi.phone)
          : employeeApi.phone,
      isDemobilize: employeeApi.isDemobilize,
      demobilizeDate: employeeApi.demobilizeDate,
      birthDate: employeeApi.birthDate,
      dependent: {
        idCard: employeeApi.dependent ? employeeApi.dependent.idCard : '',
        nameHolder: employeeApi.dependent
          ? employeeApi.dependent.nameHolder
          : '',
        registrationHolder: employeeApi.dependent
          ? employeeApi.dependent.registrationHolder
          : '',
        holderPhone: employeeApi.dependent
          ? employeeApi.dependent.holderPhone != null
            ? this.clearPhone(employeeApi.dependent.holderPhone)
            : employeeApi.holderPhone
          : '',
      },
      outsourced: {
        company: employeeApi.outsourced ? employeeApi.outsourced.company : '',
        descriptionSsoLocal: employeeApi.outsourced
          ? employeeApi.outsourced.descriptionSsoLocal
          : '',
        local: employeeApi.outsourced ? employeeApi.outsourced.local : '',
        manager: employeeApi.outsourced
          ? employeeApi.outsourced.manager != null
            ? employeeApi.outsourced.manager
            : employeeApi.manager !== null
            ? employeeApi.manager
            : ''
          : employeeApi.manager !== null
          ? employeeApi.manager
          : '',
        managerEmail: employeeApi.outsourced
          ? employeeApi.outsourced.managerEmail != null
            ? employeeApi.outsourced.managerEmail
            : employeeApi.managerEmail !== null
            ? employeeApi.managerEmail
            : ''
          : employeeApi.managerEmail !== null
          ? employeeApi.managerEmail
          : '',
        managerPhone: employeeApi.outsourced
          ? employeeApi.outsourced.managerPhone != null
            ? this.clearPhone(employeeApi.outsourced.managerPhone)
            : employeeApi.managerPhone !== null
            ? this.clearPhone(employeeApi.managerPhone)
            : ''
          : employeeApi.managerPhone !== null
          ? this.clearPhone(employeeApi.managerPhone)
          : '',
      },
      retired: {
        idCard: employeeApi.retired ? employeeApi.retired.idCard : '',
        state: employeeApi.retired ? employeeApi.retired.state : '',
      },
      contact: {
        phone:
          employeeApi.phone != null
            ? this.clearPhone(employeeApi.phone)
            : employeeApi.phone,
        backupPhone:
          employeeApi.backupPhone != null
            ? this.clearPhone(employeeApi.backupPhone)
            : employeeApi.backupPhone,
        employeeChecklistPhone:
          employeeApi.employeeChecklistPhone != null
            ? this.clearPhone(employeeApi.employeeChecklistPhone)
            : employeeApi.employeeChecklistPhone,
        managerPhone:
          employeeApi.managerPhone != null
            ? this.clearPhone(employeeApi.managerPhone)
            : employeeApi.managerPhone,
      },
      medicalRecord: {
        lastDayWorked:
          employeeApi.medicalRecord && employeeApi.medicalRecord.lastDayWorked
            ? new Date(employeeApi.medicalRecord.lastDayWorked)
            : employeeApi.lastDayWorkedDate
            ? new Date(employeeApi.lastDayWorkedDate)
            : null,
        noteDate:
          employeeApi.medicalRecord && employeeApi.medicalRecord.NoteDate
            ? new Date(employeeApi.medicalRecord.NoteDate)
            : employeeApi.NoteDate
            ? new Date(employeeApi.NoteDate)
            : null,
        nextContactDate:
          employeeApi.medicalRecord && employeeApi.medicalRecord.nextContactDate
            ? new Date(employeeApi.medicalRecord.nextContactDate)
            : null,
        beginSyntoms:
          employeeApi.medicalRecord && employeeApi.medicalRecord.beginSyntoms
            ? new Date(employeeApi.medicalRecord.beginSyntoms)
            : employeeApi.symptomsDate
            ? new Date(employeeApi.symptomsDate)
            : null,
        quarantineBegin:
          employeeApi.medicalRecord && employeeApi.medicalRecord.quarantineBegin
            ? new Date(employeeApi.medicalRecord.quarantineBegin)
            : employeeApi.quarentineDate
            ? new Date(employeeApi.quarentineDate)
            : null,
        cid:
          employeeApi.medicalRecord && employeeApi.medicalRecord.cid
            ? employeeApi.medicalRecord.cid
            : null,
        deathDate:
          employeeApi.medicalRecord && employeeApi.medicalRecord.deathDate
            ? new Date(employeeApi.medicalRecord.deathDate)
            : null,
        oxygenUseDate:
          employeeApi.medicalRecord && employeeApi.medicalRecord.oxygenUseDate
            ? new Date(employeeApi.medicalRecord.oxygenUseDate)
            : null,
        dischargeDate:
          employeeApi.medicalRecord && employeeApi.medicalRecord.dischargeDate
            ? new Date(employeeApi.medicalRecord.dischargeDate)
            : null,
        healthImproved:
          employeeApi.medicalRecord && employeeApi.medicalRecord.healthImproved,
        healthRecoveredDate:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.healthRecoveredDate
            ? new Date(employeeApi.medicalRecord.healthRecoveredDate)
            : null,

        hospitalized:
          employeeApi.medicalRecord && employeeApi.medicalRecord.hospitalized,
        includeNoteTimeline: false,
        infoValidatedSocialService:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.infoValidatedSocialService,
        intensiveCareHospitalized:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.intensiveCareHospitalized,
        labExamDate:
          employeeApi.medicalRecord && employeeApi.medicalRecord.labExamDate
            ? new Date(employeeApi.medicalRecord.labExamDate)
            : null,
        labExamResultDate:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.labExamResultDate
            ? new Date(employeeApi.medicalRecord.labExamResultDate)
            : null,
        labExam: employeeApi.medicalRecord && employeeApi.medicalRecord.labExam,
        labExamResult:
          employeeApi.medicalRecord && employeeApi.medicalRecord.labExamResult,
        lastContactPASADate:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.lastContactPASADate
            ? new Date(employeeApi.medicalRecord.lastContactPASADate)
            : null,
        medicalDischarge:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.medicalDischarge,
        medication:
          employeeApi.medicalRecord && employeeApi.medicalRecord.medication,
        note: '',
        noteSubject: '',
        oxygenUse:
          employeeApi.medicalRecord && employeeApi.medicalRecord.oxygenUse,
        quarantineEndDate:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.quarantineEndDate != null
            ? new Date(employeeApi.medicalRecord.quarantineEndDate)
            : null,
        quickExamDate:
          employeeApi.medicalRecord && employeeApi.medicalRecord.quickExamDate
            ? new Date(employeeApi.medicalRecord.quickExamDate)
            : null,
        quickExamScheduled:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.quickExamScheduled
            ? new Date(employeeApi.medicalRecord.quickExamScheduled)
            : null,
        quickExamPerformed:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.quickExamPerformed,
        quickExamResult:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.quickExamResult,
        requestDischargeReport:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.requestDischargeReport,
        symptoms:
          employeeApi.medicalRecord && employeeApi.medicalRecord.symptoms,
        testLocation: employeeApi.medicalRecord.testLocation,
        quickExamIndicated:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.quickExamIndicated,
        clinicallyDiscarded:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.clinicallyDiscarded,
        date: employeeApi.medicalRecord && employeeApi.medicalRecord.date,
        clinicalConfirmation:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.clinicalConfirmation
            ? employeeApi.medicalRecord.clinicalConfirmation
            : null,
        clinicalConfirmationDate:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.clinicalConfirmationDate
            ? new Date(employeeApi.medicalRecord.clinicalConfirmationDate)
            : null,
        crm:
          employeeApi.medicalRecord && employeeApi.medicalRecord.crm
            ? employeeApi.medicalRecord.crm
            : '',
        doctorsName:
          employeeApi.medicalRecord && employeeApi.medicalRecord.doctorsName
            ? employeeApi.medicalRecord.doctorsName
            : '',
        medicalDischargeDate:
          employeeApi.medicalRecord &&
          employeeApi.medicalRecord.medicalDischargeDate
            ? new Date(employeeApi.medicalRecord.medicalDischargeDate)
            : null,

        labExamIndicated: employeeApi.medicalRecord
          ? employeeApi.medicalRecord.labExamIndicated
          : null,
        deathConfirmation: employeeApi.medicalRecord
          ? employeeApi.medicalRecord.deathConfirmation
          : null,
        isolationMeasures: employeeApi.medicalRecord
          ? employeeApi.medicalRecord.isolationMeasures
          : null,
        contactWithConfirmedCase: employeeApi.medicalRecord
          ? employeeApi.medicalRecord.contactWithConfirmedCase
          : null,
        contactWithConfirmedCaseDate: employeeApi.medicalRecord
          ? employeeApi.medicalRecord.contactWithConfirmedCaseDate
          : null,
        recentTrip: employeeApi.medicalRecord
          ? employeeApi.medicalRecord.recentTrip
          : null,
        recentTripDate: employeeApi.medicalRecord
          ? employeeApi.medicalRecord.recentTripDate
          : null,
        medicalEvaluation: employeeApi.medicalRecord
          ? employeeApi.medicalRecord.medicalEvaluation
          : null,
        numberCompulsoryNotification:
          employeeApi.medicalRecord.numberCompulsoryNotification,
        dateCompulsoryNotification:
          employeeApi.medicalRecord.dateCompulsoryNotification,
        quickExamTrag: employeeApi.medicalRecord.quickExamTrag,
        noteExam: employeeApi.medicalRecord
          ? employeeApi.medicalRecord.noteExam
          : null,
        disregardQuarantineBecauseOfTragAndPCR:
          employeeApi.medicalRecord.disregardQuarantineBecauseOfTragAndPCR,
        hospitalization: employeeApi.medicalRecord.hospitalization,
      },
      notes: null,
      baseSymptoms: employeeApi.baseSymptoms,
      status: {
        id: employeeApi.status ? employeeApi.status.id : '',
        description: employeeApi.status ? employeeApi.status.description : '',
      },
      lastStatusUpdateDate: employeeApi.lastStatusUpdateDate,
      checklist: null,
      createdOn: new Date(),
      comorbidities:
        employeeApi.medicalRecord &&
        employeeApi.medicalRecord.employee &&
        employeeApi.medicalRecord.employee.comorbidities
          ? employeeApi.medicalRecord.employee.comorbidities
          : [],
      baseComorbidities: employeeApi.baseComorbidities,
      ssoId: employeeApi.ssoId,
      managerPhone:
        employeeApi.managerPhone != null
          ? this.clearPhone(employeeApi.managerPhone)
          : employeeApi.managerPhone,
    };
    return employee;
  }

  public static clearPhone(phone: string) {
    if (phone == undefined) {
      return '';
    }
    const codigoPais = phone.substring(2, 0);
    if (codigoPais == '55') {
      phone = phone.substring(phone.length, 2);
    }
    return phone;
  }

  public static onMedicalRecordMapper(medicalRecordApi: any) {
    const medicalRecord: MedicalRecordModel = {
      lastDayWorked: medicalRecordApi.lastDayWorked
        ? new Date(medicalRecordApi.lastDayWorked)
        : medicalRecordApi.lastDayWorkedDate
        ? new Date(medicalRecordApi.lastDayWorkedDate)
        : null,
      noteDate:
        medicalRecordApi.noteDate != null
          ? new Date(medicalRecordApi.noteDate)
          : null,
      nextContactDate:
        medicalRecordApi.nextContactDate != null
          ? new Date(medicalRecordApi.nextContactDate)
          : null,
      beginSyntoms: medicalRecordApi.beginSyntoms
        ? new Date(medicalRecordApi.beginSyntoms)
        : medicalRecordApi.symptomsDate
        ? new Date(medicalRecordApi.symptomsDate)
        : null,
      quarantineBegin: medicalRecordApi.quarantineBegin
        ? new Date(medicalRecordApi.quarantineBegin)
        : medicalRecordApi.quarentineDate
        ? new Date(medicalRecordApi.quarentineDate)
        : null,
      cid: medicalRecordApi.cid != null ? medicalRecordApi.cid : null,
      deathDate:
        medicalRecordApi.deathDate != null
          ? new Date(medicalRecordApi.deathDate)
          : null,
      oxygenUseDate:
        medicalRecordApi.oxygenUseDate != null
          ? new Date(medicalRecordApi.oxygenUseDate)
          : null,
      dischargeDate:
        medicalRecordApi.dischargeDate != null
          ? new Date(medicalRecordApi.dischargeDate)
          : null,
      healthImproved: medicalRecordApi.healthImproved,
      hospitalized: medicalRecordApi.hospitalized,
      healthRecoveredDate:
        medicalRecordApi.healthRecoveredDate != null
          ? new Date(medicalRecordApi.healthRecoveredDate)
          : null,
      includeNoteTimeline: false,
      infoValidatedSocialService: medicalRecordApi.infoValidatedSocialService,
      intensiveCareHospitalized: medicalRecordApi.intensiveCareHospitalized,
      labExamDate:
        medicalRecordApi.labExamDate != null
          ? new Date(medicalRecordApi.labExamDate)
          : null,
      labExamResultDate:
        medicalRecordApi.labExamResultDate != null
          ? new Date(medicalRecordApi.labExamResultDate)
          : null,
      labExam: medicalRecordApi.labExam,
      labExamResult: medicalRecordApi.labExamResult,
      lastContactPASADate:
        medicalRecordApi.lastContactPASADate != null
          ? new Date(medicalRecordApi.lastContactPASADate)
          : null,
      medicalDischarge: medicalRecordApi.medicalDischarge,
      medication: medicalRecordApi.medication,
      note: medicalRecordApi.note,
      noteSubject: medicalRecordApi.noteSubject,
      oxygenUse: medicalRecordApi.oxygenUse,
      quarantineEndDate:
        medicalRecordApi.quarantineEndDate != null
          ? new Date(medicalRecordApi.quarantineEndDate)
          : null,
      quickExamDate:
        medicalRecordApi.quickExamDate != null
          ? new Date(medicalRecordApi.quickExamDate)
          : null,
      quickExamScheduled:
        medicalRecordApi.quickExamScheduled != null
          ? new Date(medicalRecordApi.quickExamScheduled)
          : null,
      quickExamPerformed: medicalRecordApi.quickExamPerformed,
      quickExamResult: medicalRecordApi.quickExamResult,
      requestDischargeReport: medicalRecordApi.requestDischargeReport,
      symptoms: medicalRecordApi.symptoms,
      testLocation: medicalRecordApi.testLocation,
      quickExamIndicated: medicalRecordApi.quickExamIndicated,
      clinicallyDiscarded: medicalRecordApi.clinicallyDiscarded,
      date: medicalRecordApi.date,
      clinicalConfirmation: medicalRecordApi.clinicalConfirmation
        ? medicalRecordApi.clinicalConfirmation
        : null,
      clinicalConfirmationDate: medicalRecordApi.clinicalConfirmationDate
        ? new Date(medicalRecordApi.clinicalConfirmationDate)
        : null,
      crm: medicalRecordApi && medicalRecordApi.crm ? medicalRecordApi.crm : '',
      doctorsName: medicalRecordApi.doctorsName
        ? medicalRecordApi.doctorsName
        : '',
      medicalDischargeDate: medicalRecordApi.medicalDischargeDate,
      labExamIndicated: medicalRecordApi.labExamIndicated,
      deathConfirmation: medicalRecordApi.deathConfirmation,
      isolationMeasures: medicalRecordApi.isolationMeasures,
      contactWithConfirmedCase: medicalRecordApi.contactWithConfirmedCase,
      contactWithConfirmedCaseDate:
        medicalRecordApi.contactWithConfirmedCaseDate,
      recentTrip: medicalRecordApi.recentTrip,
      recentTripDate: medicalRecordApi.recentTripDate,
      medicalEvaluation: medicalRecordApi.medicalEvaluation,
      numberCompulsoryNotification:
        medicalRecordApi.numberCompulsoryNotification,
      dateCompulsoryNotification: medicalRecordApi.dateCompulsoryNotification,
    };
    return medicalRecord;
  }
}
