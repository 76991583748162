import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-send-email-manager',
  templateUrl: './send-email-manager.component.html',
  styleUrls: ['./send-email-manager.component.scss'],
})
export class SendEmailManagerComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public emailForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SendEmailManagerComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.emailForm = new FormGroup({
      managerEmail: new FormControl('', [Validators.required]),
    });

    if (this.data.managerEmail) {
      this.emailForm.get('managerEmail').setValue(this.data.managerEmail);
    }
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
