export class Extensions {
  public static STORAGE_KEYS = {
    AREA_INFO: '__area_info',
    CONFIG: '__config',
    TOKEN: '.auth',
    CULTURE: '__culture',
    AUDIO: '__audio',
    REFRESH_TOKEN: 'refresh_token',
    ACCESS_TOKEN: 'access_token',
    EXPIRES_IN: 'expires_in',
    EXPIRES_AT: 'expires_at',
  };

  public static TRANSLATION_KEYS = {
    INTERCEPTOR: 'INTERCEPTOR',
    EMPLOYEE: 'EMPLOYEE',
    CREW: 'CREW',
    DEPARTMENT: 'DEPARTMENT',
    EQUIPMENT: 'EQUIPMENT',
    WORKPOSITION: 'WORKPOSITION',
    MAIN: 'MAIN',
    SIDEBAR: 'SIDEBAR',
    BUSINESSUNIT: 'BUSINESSUNIT',
    OPERATIONALAREA: 'OPERATIONALAREA',
    DEFAULTCONFIGURATION: 'DEFAULTCONFIGURATION',
  };

  public static isMobile = {
    Android: () => {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: () => {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: () => {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: () => {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: () => {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: () => {
      return (
        Extensions.isMobile.Android() ||
        Extensions.isMobile.BlackBerry() ||
        Extensions.isMobile.iOS() ||
        Extensions.isMobile.Opera() ||
        Extensions.isMobile.Windows()
      );
    },
  };
}
