import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { MaterialComponentsModule } from '../shared/material.module';
import { AuthGuard } from './middleware/auth-guard';
@NgModule({
  imports: [HttpClientModule, CommonModule, MaterialComponentsModule],
  declarations: [],
  providers: [DatePipe, AuthGuard],
  exports: [],
})
export class CoreModule {}
