import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

export function fileExtensionValidator(extensions: string[]) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let forbidden = !!extensions.length;
    if (control.value) {
      const fileList = Array.from(control.value);
      fileList.forEach((element: File) => {
        const extension = element.name.split('.').pop();
        extensions.forEach((ext) => {
          if (ext.trim().toLowerCase() == extension.toLowerCase()) {
            forbidden = false;
          }
        });
      });
    } else {
      forbidden = false;
    }
    return forbidden ? { inValidExt: true } : null;
  };
}

@Component({
  selector: 'app-vale-upload',
  templateUrl: './vale-upload.component.html',
  styleUrls: ['./vale-upload.component.scss'],
})
export class ValeUploadComponent implements OnInit, OnDestroy {
  @Input('extensions') public extensions = [];
  @Input('required') public required = false;
  @Output('fileChange') private _fileChangeEvent = new EventEmitter(null);
  public fileInputForm = new FormControl(null);
  public filename;

  constructor() {}
  ngOnInit(): void {
    this.fileInputForm = new FormControl(
      null,
      fileExtensionValidator(this.extensions)
    );
  }

  ngOnDestroy(): void {
    this.reset();
  }

  public fileBrowseHandler(event): void {
    this.fileInputForm.setValue(event);
    this._setFileName(this.fileInputForm.value);
    if (this.fileInputForm.valid) {
      this._fileChangeEvent.emit(event);
    }
  }

  public onFileDropped(event): void {
    this.fileInputForm.setValue(event);
    this._setFileName(this.fileInputForm.value);
    if (this.fileInputForm.valid) {
      this._fileChangeEvent.emit(event);
      this._setFileName(this.fileInputForm.value);
    }
  }

  public reset(): void {
    this.fileInputForm.reset();
    this.extensions = [];
    this.required = false;
    this._fileChangeEvent.emit(null);
  }

  private _setFileName(value: any) {
    this.filename = (
      Array.from(value)
        .map((file: File) => file.name)
        .join(', ') || ''
    ).toUpperCase();
  }
}
