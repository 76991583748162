export class ReturnDialogButton {
  public name: string;
  public className: string;
  public action;
  public result?;

  constructor(config: ReturnDialogButton) {
    this.name = config.name;
    this.className = config.className;
    this.action = config.action;
    this.result = config.result;
  }
}
