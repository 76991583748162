<div class="default-theme">
  <div class="panel">
    <div class="title">
      <span class="welcome"
        >{{ 'WELCOME.SEARCH_INPUT' | translate }}{{ userName }}!</span
      >
      <span class="options-title">{{
        'OPTIONS_TITLE.SEARCH_INPUT' | translate
      }}</span>
    </div>
    <div class="alert-mobile">
      <span class="icon">
        <mat-icon svgIcon="attention-gray"> </mat-icon>
      </span>
      <span
        class="message-mobile"
        [innerHTML]="'ALERT_MESSAGE_MOBILE.ALERT-MOBILE' | translate"
      >
      </span>
    </div>
    <div class="options">
      <div
        class="{{
          hasPermissionMedicalRecord
            ? 'hideMobile option option--medicalRecord'
            : 'hideMobile option option--medicalRecord disabled'
        }}"
        (click)="
          hasPermissionMedicalRecord
            ? onSelectedOptionAndRedirect(homeOptionsEnum.MEDICAL_RECORD)
            : false
        "
      >
        <img
          class="image"
          src="{{
            hasPermissionMedicalRecord
              ? './assets/images/prontuarioOnline.svg'
              : './assets/images/medical-record-disabled.png'
          }}"
          alt="card to represent medical record"
        />
        <span
          class="{{
            hasPermissionMedicalRecord
              ? 'option-title'
              : 'option-title disabled'
          }}"
          >{{
            'HOME.MEDICAL_RECORD.OPTION_TITLE.SEARCH_INPUT' | translate
          }}</span
        >
        <span
          class="{{
            hasPermissionMedicalRecord
              ? 'option-description'
              : 'option-description disabled'
          }}"
          >{{
            'HOME.MEDICAL_RECORD.OPTION_DESCRIPTION.SEARCH_INPUT' | translate
          }}</span
        >
      </div>
      <div
        class="{{
          hasPermissionMedicalRecord
            ? 'hideMobile option option--vaccines'
            : 'hideMobile option option--vaccines disabled'
        }}"
        (click)="
          hasPermissionMedicalRecord
            ? onSelectedOptionAndRedirect(homeOptionsEnum.VALE_FORMS_VACCINES)
            : false
        "
      >
        <img
          class="image"
          src="{{
            hasPermissionMedicalRecord
              ? './assets/images/vaccine.svg'
              : './assets/images/vaccine-disabled.svg'
          }}"
          alt="card to represent vaccines"
        />
        <span
          class="{{
            hasPermissionMedicalRecord
              ? 'option-title'
              : 'option-title disabled'
          }}"
          >{{ 'HOME.VACCINES.OPTION_TITLE.SEARCH_INPUT' | translate }}</span
        >
        <span
          class="{{
            hasPermissionMedicalRecord
              ? 'option-description'
              : 'option-description disabled'
          }}"
          >{{
            'HOME.VACCINES.OPTION_DESCRIPTION.SEARCH_INPUT' | translate
          }}</span
        >
      </div>

      <!--<div
        class="{{
          hasPermissionQuickTest
            ? 'option option--quick-exam'
            : 'option option--quick-exam disabled'
        }}"
        (click)="
          hasPermissionQuickTest
            ? onSelectedOptionAndRedirect(homeOptionsEnum.QUICK_TEST)
            : false
        "
      >
        <img
          class="image"
          src="{{
            hasPermissionQuickTest
              ? './assets/images/testeRapido.svg'
              : './assets/images/quick-test-disabled.png'
          }}"
          alt="icon to represent a covid-19 test"
        />
        <span
          class="{{
            hasPermissionQuickTest ? 'option-title' : 'option-title disabled'
          }}"
          >{{ 'HOME.QUICK_TEST.OPTION_TITLE.SEARCH_INPUT' | translate }}</span
        >
        <span
          class="{{
            hasPermissionQuickTest
              ? 'option-description'
              : 'option-description disabled'
          }}"
          >{{
            'HOME.QUICK_TEST.OPTION_DESCRIPTION.SEARCH_INPUT' | translate
          }}</span
        >
      </div>-->
      <!--<div
        class="{{
          hasPermissionAbsenceControl
            ? 'option option--absenceControl'
            : 'option option--absenceControl disabled'
        }}"
        (click)="
          hasPermissionAbsenceControl
            ? onSelectedOptionAndRedirect(homeOptionsEnum.ABSENCE_CONTROL)
            : false
        "
      >
        <img
          class="image"
          src="{{
            hasPermissionAbsenceControl
              ? './assets/images/option-absence-control.svg'
              : './assets/images/option-absence-control-disabled.png'
          }}"
          alt="magnifying glass over the person icon to represent an employee search"
        />
        <span
          class="{{
            hasPermissionAbsenceControl
              ? 'option-title'
              : 'option-title disabled'
          }}"
          >{{ 'ABSENCE_CONTROL.TITLE' | translate }}</span
        >
        <span
          class="{{
            hasPermissionAbsenceControl
              ? 'option-description'
              : 'option-description disabled'
          }}"
          >{{ 'ABSENCE_CONTROL.HOME_OPTION.DESCRIPTION' | translate }}</span
        >
      </div>-->
      <!--<div
        class="{{
          hasPermissionMonitoringCenter
            ? 'hideMobile option option--monitoringCenter'
            : 'hideMobile option option--monitoringCenter disabled'
        }}"
        (click)="
          hasPermissionMonitoringCenter
            ? onSelectedOptionAndRedirect(homeOptionsEnum.MONITORING_CENTER)
            : false
        "
      >
        <img
          class="image"
          src="{{
            hasPermissionMonitoringCenter
              ? './assets/images/centralDeMonitoramento.svg'
              : './assets/images/centralDeMonitoramentoDisabled.png'
          }}"
          alt="microscópio representando a central de monitoramento"
        />
        <span
          class="{{
            hasPermissionMonitoringCenter
              ? 'option-title'
              : 'option-title disabled'
          }}"
          >{{
            'HOME.MONITORING_CENTER.OPTION_TITLE.SEARCH_INPUT' | translate
          }}</span
        >
        <span
          class="{{
            hasPermissionMonitoringCenter
              ? 'option-description'
              : 'option-description disabled'
          }}"
          >{{
            'HOME.MONITORING_CENTER.OPTION_DESCRIPTION.SEARCH_INPUT' | translate
          }}</span
        >
      </div>-->
    </div>
    <div class="exit" *ngIf="isMobile">
      <button mat-button class="exit-button" (click)="logout()">
        {{ 'LOGOUT_FROM_ACCOUNT.SEARCH_INPUT' | translate }}
      </button>
    </div>
  </div>
</div>
