<div class="container-search-not-found centered empty">
  <div class="centered">
    <mat-icon class="icon" svgIcon="employee_not_found"></mat-icon>
  </div>
  <section class="body">
    <h4 class="empty--title">
      {{ 'SEARCH_NOT_FOUND.TITLE' | translate }}
    </h4>
    <div class="empty--message">
      <span
        >{{ 'SEARCH_NOT_FOUND.MESSAGE' | translate }} &quot;{{
          searchBy
        }}&quot;</span
      >
    </div>
    <div class="empty-tip">
      <ul class="tips">
        <li *ngFor="let tip of tips">
          {{ tip | translate }}
        </li>
      </ul>
    </div>
  </section>
</div>
