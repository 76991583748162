import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class RegionService extends BaseService {
  public uri: string = environment.api;

  private _baseUrlSSOLocation = 'sso-location';

  constructor(http: HttpClient) {
    super(http, null, environment.api);
  }

  public getRegionGrouped(): Observable<any> {
    return this.getRegionGroupedApi().pipe(
      map((regions: any) => {
        return regions.map((region: any) => {
          return {
            id: region.id,
            description: region.description,
            descriptionSsoLocal: region.descriptionSsoLocal,
            state: region.state.toLocaleUpperCase(),
          };
        });
      })
    );
  }

  public getRegionVale(): Observable<any> {
    return this.getRegionApi().pipe(
      map((regions: any) => {
        return regions.map((region: any) => {
          return {
            id: region.id,
            description: region.description,
            descriptionSsoLocal: region.descriptionSsoLocal,
          };
        });
      })
    );
  }

  public getRegionApi(): Observable<any> {
    return this.get<any>(`${this._baseUrlSSOLocation}`);
  }

  public getLocations(): Observable<any> {
    return this.get<any>(`location/tests`);
  }

  public getTestLocationByStateId(stateId: number): Observable<any> {
    return this.get<any>(`location/states/${stateId}/tests`);
  }

  public getStates(): Observable<any> {
    return this.get<any>(`${this._baseUrlSSOLocation}/states`);
  }

  private getRegionGroupedApi(): Observable<any> {
    return this.get<any>(`${this._baseUrlSSOLocation}/grouped`);
  }

  public getSsoLocationByType(employeeTypeId: number): Observable<any> {
    return this.getSsoLocationByTypeCode(employeeTypeId).pipe(
      map((type: any) => {
        return type.map((type: any) => {
          return {
            localSso: type.localSso,
          };
        });
      })
    );
  }

  public getSsoLocationByTypeCode(employeeTypeId: number): Observable<any> {
    return this.get<any>(
      `${this._baseUrlSSOLocation}/${employeeTypeId}/ssoLocation`
    );
  }

  public getHierarchy(parameters: any): Observable<any> {
    return this.get<any>(`hierarchy/hierarchy`, parameters);
  }
}
