import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { ValeAccessCardModule } from './vale-access-card/vale-access-card.module';
import { ValeButtonModule } from './vale-button/vale-button.module';
import { ValeContainerModule } from './vale-container/vale-container.module';
import { ValeDotProfileModule } from './vale-dot-profile/vale-dot-profile.module';
import { ValeDragNDropModule } from './vale-drag-n-drop/vale-drag-n-drop.module';
import { ValeExpansionFilterModule } from './vale-expansion-filter/vale-expansion-filter.module';
import { ValeGenericSearchDropdownModule } from './vale-generic-search-dropdown/vale-generic-search-dropdown.module';
import { ValeIconModule } from './vale-icon/vale-icon.module';
import { ValeImageModule } from './vale-image/vale-image.module';
import { ValeLabelModule } from './vale-label/vale-label.module';
import { ValeLoadingModule } from './vale-loading/vale-loading.module';
import { ValeNavigateModule } from './vale-navigate/vale-navigate.module';
import { ValeRadioButtonSlimModule } from './vale-radio-slim/vale-radio-buttom-slim.module';
import { ValeRadioButtonModule } from './vale-radio/vale-radio-buttom.module';
import { ValeRemovableTagModule } from './vale-removable-tag/vale-removable-tag.module';
import { ValeTimelineModule } from './vale-timeline/vale-timeline.module';
import { ValeUploadModule } from './vale-upload/vale-upload.module';

@NgModule({
  imports: [
    CommonModule,
    ValeRadioButtonModule,
    ValeRadioButtonSlimModule,
    ValeButtonModule,
    ValeIconModule,
    ValeLabelModule,
    ValeRemovableTagModule,
    ValeGenericSearchDropdownModule,
    ValeDotProfileModule,
    ValeContainerModule,
    ValeNavigateModule,
    ValeTimelineModule,
    ValeLoadingModule,
    ValeAccessCardModule,
    ValeUploadModule,
    ValeDragNDropModule,
    ValeExpansionFilterModule,
    ValeImageModule,
  ],
  exports: [
    ValeRadioButtonModule,
    ValeRadioButtonSlimModule,
    ValeButtonModule,
    ValeIconModule,
    ValeLabelModule,
    ValeRemovableTagModule,
    ValeGenericSearchDropdownModule,
    ValeDotProfileModule,
    ValeContainerModule,
    ValeNavigateModule,
    ValeTimelineModule,
    ValeLoadingModule,
    ValeAccessCardModule,
    ValeUploadModule,
    ValeDragNDropModule,
    ValeExpansionFilterModule,
    ValeImageModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeLibModule {}
