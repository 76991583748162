import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ValeNavigateDirective } from './vale-navigate.directive';
@NgModule({
  declarations: [ValeNavigateDirective],
  imports: [CommonModule, FormsModule],
  exports: [ValeNavigateDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeNavigateModule {}
