import { Highlightable } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

import { CustomDropdownService } from '../custom-dropdown.service';
import { CustomSelectComponent } from '../custom-select/custom-select.component';

@Component({
  selector: 'app-custom-select-option',
  templateUrl: './custom-select-option.component.html',
  styleUrls: ['./custom-select-option.component.scss'],
})
export class CustomSelectOptionComponent
  implements OnInit, AfterViewInit, Highlightable {
  @Input()
  public key: any;

  @Input()
  public value: any;

  @Input()
  public checked = false;

  @Input()
  public checkAll = false;

  @Input()
  public icon = '';

  public multiple: boolean;

  @HostBinding('class.selected')
  public get selected(): boolean {
    return this.select.selectedOption === this;
  }

  @HostListener('click', ['$event'])
  public onClick(event: UIEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.select.selectOption(this);
  }

  @HostBinding('class.active')
  public active = false;

  private readonly select: CustomSelectComponent;

  constructor(private readonly dropdownService: CustomDropdownService) {
    this.select = this.dropdownService.getSelect();
    this.multiple = this.dropdownService.getMultiple();
  }

  public ngAfterViewInit(): void {
    if (this.checked) {
      this.onSelect();
    }
  }

  public ngOnInit(): void {
    this.multiple = this.dropdownService.getMultiple();
    this.dropdownService.setCheckAll(this.checkAll);
  }

  public getLabel(): string {
    return this.value;
  }

  public setActiveStyles(): void {
    this.active = true;
  }

  public setInactiveStyles(): void {
    this.active = false;
  }

  public onSelect(): void {
    this.checked = true;
  }

  public onDeselect(): void {
    this.checked = false;
  }

  public isSelected(): boolean {
    return this.select._selectionModel.isSelected(this);
  }

  public toggle(): void {
    return this.select._selectionModel.select(this);
  }
}
