import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ValeFormsVaccineService } from '../../../core/services/vale-forms-vaccine.service';

@Component({
  selector: 'app-vale-image',
  templateUrl: './vale-image.component.html',
  styleUrls: ['./vale-image.component.scss'],
  inputs: ['url'],
})
export class ValeImageComponent implements OnInit {
  public backImage = false;
  public nextImage = false;
  public firstUrlDownload = true;
  public firstIsImage = false;
  public firstExtension = '';
  public secondUrlDownload = false;
  public secondIsImage = false;
  public secondExtension = '';
  public boosterUrlDownload = false;
  public boosterIsImage = false;
  public boosterExtension = '';
  public validExtensions = ['.jpeg', '.jpg', '.png'];
  private _subscriptions = new Subscription();

  @Input() public dose: string;
  @Input() public firstUrl: string;
  @Input() public firstUrlValid: boolean;
  @Input() public secondUrl?: string;
  @Input() public secondUrlValid?: boolean;
  @Input() public boosterUrl?: string;
  @Input() public boosterUrlValid?: boolean;

  @ViewChild('valeimage') public valeimage: ElementRef;

  constructor(
    private _valeFormsVaccineService: ValeFormsVaccineService,
    private renderer: Renderer2
  ) {}

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.firstExtension = (this.firstUrl || '')
      .slice(this.firstUrl.lastIndexOf('.'), this.firstUrl.length)
      .toLowerCase();
    this._verifyExistSecondUrl();
    this._verifyExistBoosterUrl();
  }

  ngAfterViewInit() {
    this.validateUrl();
  }

  public downloadFirstUrl() {
    window.open(this.firstUrl);
  }

  public downloadSecondUrl() {
    window.open(this.secondUrl);
  }

  public downloadBoosterUrl() {
    window.open(this.boosterUrl);
  }

  public validateUrl(): void {
    this._validateFirstUrl();
    this._validateSecondUrl();
    this._validateBoosterUrl();
  }

  public setFirstUrl() {
    this._setStyle(this.firstUrl);
    this.backImage = false;
    this.nextImage = true;
    this.firstUrlDownload = true;
    this.secondUrlDownload = false;
    this.boosterUrlDownload = false;
  }

  public setSecondUrl() {
    this._setStyle(this.secondUrl);
    this.backImage = true;
    this.nextImage = false;
    this.firstUrlDownload = false;
    this.secondUrlDownload = true;
    this.boosterUrlDownload = false;
  }

  public setBoosterUrl() {
    this._setStyle(this.secondUrl);
    this.backImage = true;
    this.nextImage = false;
    this.firstUrlDownload = false;
    this.secondUrlDownload = false;
    this.boosterUrlDownload = true;
  }

  private _verifyExistSecondUrl() {
    if (this.secondUrl) {
      this.secondExtension = (this.secondUrl || '')
        .slice(this.secondUrl.lastIndexOf('.'), this.secondUrl.length)
        .toLowerCase();
      this.nextImage = true;
    }
  }

  private _verifyExistBoosterUrl() {
    if (this.boosterUrl) {
      this.boosterExtension = (this.boosterUrl || '')
        .slice(this.boosterUrl.lastIndexOf('.'), this.boosterUrl.length)
        .toLowerCase();
      this.nextImage = true;
    }
  }

  private _validateFirstUrl() {
    this.firstIsImage =
      this.firstUrlValid && this.validExtensions.includes(this.firstExtension);
    if (this.firstIsImage) {
      this._setStyle(this.firstUrl);
    }
  }

  private _validateSecondUrl() {
    this.secondIsImage =
      this.secondUrlValid &&
      this.validExtensions.includes(this.secondExtension);
  }

  private _validateBoosterUrl() {
    this.boosterIsImage =
      this.boosterUrlValid &&
      this.validExtensions.includes(this.boosterExtension);
  }

  private _setStyle(url: string) {
    this.renderer.setStyle(
      this.valeimage.nativeElement,
      'background',
      `url("${url}")`
    );
    this.renderer.setStyle(
      this.valeimage.nativeElement,
      'backgroundPosition',
      'center'
    );
    this.renderer.setStyle(
      this.valeimage.nativeElement,
      'backgroundSize',
      'cover'
    );
  }
}
