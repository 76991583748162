import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import {
  Injectable,
  Injector,
  OnDestroy,
  TemplateRef,
  Type,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ActionDialogRef } from '../../shared/components/action-dialog/action-dialog-ref';
import { ActionDialogComponent } from '../../shared/components/action-dialog/action-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ActionDialogService implements OnDestroy {
  private _isValidSource = new BehaviorSubject(false);
  private _dataChild = new BehaviorSubject(null);
  private _responseDataChild = new BehaviorSubject(null);

  constructor(private overlay: Overlay, private injector: Injector) {}

  ngOnDestroy(): void {
    this._isValidSource.unsubscribe();
  }

  open<R = any, T = any>(
    content: string | TemplateRef<any> | Type<any>,
    data: T
  ): ActionDialogRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal', 'is-active'],
      backdropClass: 'modal-background',
    });

    const overlayRef = this.overlay.create(configs);

    const myOverlayRef = new ActionDialogRef<R, T>(
      overlayRef,
      content,
      data,
      this._isValidSource
    );

    const injector = this.createInjector(myOverlayRef, this.injector);
    overlayRef.attach(
      new ComponentPortal(ActionDialogComponent, null, injector)
    );

    return myOverlayRef;
  }

  createInjector(ref: ActionDialogRef, inj: Injector): PortalInjector {
    const injectorTokens = new WeakMap([[ActionDialogRef, ref]]);
    return new PortalInjector(inj, injectorTokens);
  }

  public setDisabledConfirmButton(state: boolean): void {
    this._isValidSource.next(state);
  }

  public setDataChild(data: any) {
    this._dataChild.next(data);
  }

  public get dataChild$() {
    return this._dataChild.asObservable();
  }

  public setResponseDataChild(data: any) {
    this._responseDataChild.next(data);
  }

  public get responseDataChild$() {
    return this._responseDataChild.asObservable();
  }

  public get responseDataChild() {
    return this._responseDataChild.getValue();
  }
}
