import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

import { auth } from '../../../environments/environment';
import { UserBusiness } from '../../shared/business/user-business.service';
import { Storage } from '../../shared/util/storage';
import { BaseAuthenticationService } from './base-authentication.service';
import { LogService } from './log.service';

@Injectable()
export class AuthenticationService extends BaseAuthenticationService {
  public _storage: Storage = new Storage();

  constructor(
    oauthService: OAuthService,
    userBusiness: UserBusiness,
    public logService: LogService,
    router: Router
  ) {
    super(oauthService, userBusiness, router);
  }

  public doLogin(): void {
    this.oauthService.configure(auth);
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService
      .tryLogin()
      .then((isLoggedIn) => {
        if (!isLoggedIn || !this.oauthService.hasValidAccessToken()) {
          this.oauthService.initCodeFlow();
        } else {
          this.oauthService
            .loadUserProfile()
            .then((_) => this.updateLoggedUser());
        }
      })
      .catch((error) => {
        this.logService.logWarning(
          'Falha ao executar fluxo de tryLogin',
          error
        );
        window.top.location.href = auth.logoutUrl;
      });
  }

  public async updateLoggedUser() {
    const claim = this.oauthService.getIdentityClaims();
    if (claim) {
      try {
        if (!claim['roles']) {
          this.userBusiness.setUser(false);
          throw new Error('Not Allowed');
        }

        const groupMembershipSplitted = this.getGroupMembership(claim['roles']);

        if (groupMembershipSplitted) {
          const groupArray = groupMembershipSplitted.filter((group) =>
            group.ou.includes('GCM')
          );
          if (groupArray.length === 0) {
            this.userBusiness.setUser(false);
            throw new Error('Not Allowed');
          }
        }

        // obtém os dados do userinfo retornados pelo IAM e transforma as keys do objeto todas em lowercase
        // eslint-disable-next-line dot-notation
        let userObj = {
          userName: claim['name'],
          userIamId: claim['cn'],
          userEmail: claim['email'],
          userCountry: claim['LocationCountry'],
          userGroups: groupMembershipSplitted,
          // eslint-disable-next-line dot-notation
          sub: claim['sub'],
        };

        userObj = this.getUserCountry(userObj);
        this.userBusiness.setUser(userObj);
      } catch (error) {
        this.logService.logWarning(
          'Falha ao buscar dados do usuário logado',
          error
        );
      }
    }
  }
}
