import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { MedicalRecordService } from '../../../core/services/medical-record.service';
import { PatientService } from '../../../core/services/patient.service';
import { RegisterService } from '../../../core/services/register.service';
import { PatientBusiness } from '../../../modules/patient/patient-business.service';
import { PatientReleaseAccessComponent } from '../../../modules/patient/patient-release-access/patient-release-access.component';
import { UserBusiness } from '../../business/user-business.service';
import { OriginVaccineConstant } from '../../constants/origin-vaccine.constant';
import {
  PatientTypeConstant,
  PatientTypeEnum,
} from '../../constants/patient-type.constant';
import { AccessCard } from '../../enums/access-card';
import { HomeOptionsEnum } from '../../enums/home-options.enum';
import { VaccinesEnum } from '../../enums/vaccines.enum';
import { ValeTimelineEnum } from '../../enums/vale-timeline.enum';
import { PatientProtocolModel } from '../../models/patient-protocol.model';
import { PatientModel } from '../../models/patient.model';
import { QuickExamHistoryModel } from '../../models/quick-exam-history.model';
import { UpdateEmployeeDataComponent } from '../update-employee-data/update-employee-data.component';
import { ActionDialogService } from './../../../core/services/action-dialog.service';
import { CardManagementService } from './../../../core/services/card-management.service';
import { PatientCardSituationModel } from './../../models/patient-card-situation.model';
import { UtilHelper } from './../../util/util-helper';

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss'],
})
export class PatientInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public patient: PatientModel;
  @Input() public secondPhaseProtocol: PatientProtocolModel;
  @Input() public selectedOption: number;

  public formContact: FormGroup;
  public formData: FormGroup;
  public patientTypeEnum = PatientTypeEnum;
  public releaseResponse: any;
  public cpfField = false;
  public passportField = false;
  public ssoLocationField = false;
  public managerField = false;
  public managerEmailField = false;
  public managerPhoneField = false;
  public currentAge = 0;

  public patientIsSGC = false;
  public patientIsReleasedSGC = false;
  public patientSGC;
  public listVaccinesGCM = [];
  public patientHistory: QuickExamHistoryModel[] = [];
  public patientProtocolHistory: any[];
  public hasPermissionToReleasePatient: boolean;
  //public hasRelease: boolean;
  public hasComorbidity: boolean;
  public patientCardSituation: PatientCardSituationModel;
  public cardSituationInfo;
  public accessCardEnum = AccessCard;
  public vaccineNumberDoses: number;
  public vaccineDosesTaken: number;
  public isSomeDoseMedicalRestrictionFromGCM: boolean;
  public templateTitle: string;
  public templateText: string;
  public templateUpdateDate: Date;
  public templateInitialTest: Date;
  public templateFinalTest: Date;
  public templateDaysToBlockAccess: number;
  public templateDateText: string;
  public patientComorbidity: any = {};
  public totalComorbidities = 9;
  public updatingTimeline = true;
  public isRedirectToMonitoringCenter = false;
  public isMedicalRecord = false;
  public vaccinationMedicalRestrictionDate: Date;
  public vaccinationSGCDate: Date;
  private _userPermission;
  private _subscriptions = new Subscription();
  private _isFirstAccessToTimeline = true;
  //public isIncreasedRisk = false;

  @Input('updateVaccines') private _updateVaccines: boolean;
  @Input('updateTimeline') private _updateTimeline: boolean;
  @Input('updateCardSituation') private _updateCardSituation: any;

  constructor(
    private _medicalRecordService: MedicalRecordService,
    private _patientService: PatientService,
    private _registerService: RegisterService,
    private _dialog: MatDialog,
    private _translateService: TranslateService,

    private _fb: FormBuilder,
    private _router: Router,
    private _userBusiness: UserBusiness,
    private _cardManagementService: CardManagementService,
    private _actionDialogService: ActionDialogService,
    private _snackBar: MatSnackBar,
    private _patientBusinessService: PatientBusiness
  ) {}

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public ngOnInit(): void {
    this.checkDisplayOfReleaseButton();
    this._getUserPermission();
    this._checkEmployeeHasMissingInformationWhenIsNotDemobilize();
    this._checkIsRedirectToMonitoringCenter();
    this._setIsMedicalRecordFromHomeOption();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.callMethodsFromChanges(changes);
  }

  public callMethodsFromChanges(changes: SimpleChanges): void {
    this._generateContactForm();
    this._generateDataForm();
    this.transformName();
    this.currentAge = this.age;

    this._getPatientInfoEvolutionByEmployeeId(changes);
    this.listVaccinesGCM = [];
    this._getVaccineDosesOfPatient(changes);
    this._getPatientCardSituation(changes);
  }

  public saveComplementaryEmployeeData(): void {
    const employee = {
      Properties: this._actionDialogService.responseDataChild,
    };

    if (this.patient.type == PatientTypeEnum.OUTSOURCED) {
      const sub = this._registerService
        .editOutsourcedRegister(this.patient.gcmid, employee)
        .subscribe(
          (result) => {
            if (result) {
              this._patientBusinessService.getPatientInformation({
                gcmId: this.patient.gcmid,
                id: this.patient.id,
              });
              this._snackBar.open(
                this._translateService.instant(
                  'UPLOAD_EMPLOYEE_DATA.MESSAGE.SUCCESS'
                ),
                this._translateService.instant('GENERAL.CLOSE'),
                {
                  duration: 5000,
                }
              );
            }
          },
          (err) => {
            const message = this._translateService.instant(
              'UPLOAD_EMPLOYEE_DATA.ERROR'
            );
            this._callErrorSnackBar(message);
          }
        );

      this._subscriptions.add(sub);
    } else {
      this._subscriptions.add(
        this._registerService
          .editEmployeeRegister(this.patient.gcmid, employee)
          .subscribe(
            (response: any) => {
              if (response) {
                this._snackBar.open(
                  this._translateService.instant(
                    'UPLOAD_EMPLOYEE_DATA.MESSAGE.SUCCESS'
                  ),
                  this._translateService.instant('GENERAL.CLOSE'),
                  {
                    duration: 5000,
                  }
                );
              }
            },
            (err) => {
              let message = this._translateService.instant(
                'UPLOAD_EMPLOYEE_DATA.ERROR'
              );
              if (err.error && err.error.messages) {
                message = err.error.messages;
              }
              this._callErrorSnackBar(message);
            }
          )
      );
    }
  }

  public transformName(): void {
    if (this.patient.name) {
      const separatedName = this.patient.name.toLowerCase().split(' ');
      this.patient.name = '';
      for (const value of separatedName) {
        const word = this._capitalizeFirstLetter(value);
        this.patient.name = this.patient.name + word;
      }
    }
  }

  public get age(): number {
    if (this.patient.birthDate) {
      const birthDate = this.patient.birthDate;
      const age = UtilHelper.getAge(birthDate);
      return age;
    }
    return 0;
  }

  public goBack(): void {
    let url = 'search';
    if (this.selectedOption === HomeOptionsEnum.MONITORING_CENTER) {
      url = 'new-monitoring-center';
    }
    this._router.navigate([url], {
      state: {
        selectedOption: this.selectedOption,
      },
    });
  }

  public onSelectedOption(): void {
    this._router.navigate([`patient-register/${this.patient.gcmid}/edit`], {
      state: {
        selectedOption: this.selectedOption,
        employeeEdit: this.patient,
        patientEdit: {
          patientType: this.patient.type,
          id: this.patient.gcmid,
        },
      },
    });
  }

  /*public openEmployeeReleaseAccessDialog(): void {
    let loggedUser;

    this._subscriptions.add(
      this._userBusiness.getLoggedUser$.subscribe((result: any) => {
        loggedUser = result.userIamId;
      })
    );

    if (this.releaseResponse) {
      if (this.releaseResponse.user != loggedUser) {
        this._openAccessDialog();
      } else {
        const dialogRef = this._dialog.open(PatientRevokeAccessComponent, {
          disableClose: true,
          data: {
            loggedUser: loggedUser,
            employeeId: this.patient.gcmid,
            releaseResponse: this.releaseResponse,
          },
        });
        this._subscriptions.add(
          dialogRef.afterClosed().subscribe((response) => {
            if (response) {
              this.checkDisplayOfReleaseButton();
              this.cardSituationInfo = this._getCardSituationInfo(
                AccessCard.RED_CARD_GROUP_RISK
              );
            }
          })
        );
      }
    } else {
      this._openAccessDialog();
    }
  }*/

  public checkDisplayOfReleaseButton(): void {
    this._subscriptions.add(
      this._patientService.getRelease(this.patient.gcmid).subscribe(
        (response) => {
          this.releaseResponse = null;
          if (response) {
            this.releaseResponse = response;
            this.patientCardSituation.accessCardId =
              AccessCard.RED_CARD_RELEASE;
            this.patientCardSituation.updateDate = new Date();
            this.cardSituationInfo = this._getCardSituationInfo(
              this.patientCardSituation.accessCardId
            );
          }
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }

  public openCardInfoDialog(content: TemplateRef<any>): void {
    this._setTemplateCardInfo(this.patientCardSituation.accessCardId);
    this._actionDialogService.open(content, {
      confirmButton: true,
      title: 'PATIENT_INFO.PATIENT_CARD_INFO.TITLE',
      src: this.cardSituationInfo.src,
    });
  }

  /*public hasComorbidityOrHasPermissionToReleasePatient(): boolean {
    const examQuarantineDate = new Date(this.patient.examQuarantineDate);
    const today = new Date();
    if (
      (AccessCard.RED_CARD_GROUP_RISK ==
        this.patientCardSituation.accessCardId ||
        AccessCard.RED_CARD_RELEASE ==
          this.patientCardSituation.accessCardId) &&
      this.hasComorbidity &&
      this.hasPermissionToReleasePatient
    ) {
      if (this.patient.examQuarantineDate) {
        return examQuarantineDate < today;
      }
      return true;
    }
    return false;
  }*/

  public getPatientInfoEvolutionByEmployeeId(): void {
    if (this._isFirstAccessToTimeline) {
      this._getPatientInfoEvolutionByPatientId();
    }
  }

  private _getPatientInfoEvolutionByPatientId() {
    const filterEvents = this._filterEventsByHomeOption();
    this.updatingTimeline = true;
    this._subscriptions.add(
      this._medicalRecordService
        .getPatientInfoEvolutionByEmployeeId(this.patient.gcmid, filterEvents)
        .subscribe(
          (results) => {
            this.updatingTimeline = false;
            this._isFirstAccessToTimeline = false;
            if (results) {
              this.patientHistory = results.eventList;
              this.patientProtocolHistory = results.protocolsCycles;
            } else {
              this.patientHistory = [];
              this.patientProtocolHistory = [];
            }
          },
          () => {
            this.updatingTimeline = false;
          }
        )
    );
  }

  private _filterEventsByHomeOption() {
    let filterEvents = [];
    if (this._userBusiness.getHomeOption() === HomeOptionsEnum.QUICK_TEST) {
      filterEvents = [
        ValeTimelineEnum.EXAM_PCR,
        ValeTimelineEnum.EXAM_SEROLOGICAL,
        ValeTimelineEnum.EXAM_TRAG,
      ];
    }
    return filterEvents;
  }

  private _getPatientInfoEvolutionByEmployeeId(changes: SimpleChanges) {
    if (
      (this._isValidPatientChange(changes) ||
        this._isValidUpdateTimeline(changes)) &&
      this.patient &&
      this.patient.gcmid
    ) {
      this._isFirstAccessToTimeline = true;
      this.getPatientInfoEvolutionByEmployeeId();
    }
  }

  private _isValidPatientChange(changes: SimpleChanges) {
    return (
      changes['patient'] &&
      (changes['patient'].firstChange ||
        changes['patient'].currentValue.gcmid !=
          changes['patient'].previousValue.gcmid)
    );
  }

  private _getVaccineDosesOfPatient(changes: SimpleChanges) {
    if (
      this._isValidPatientChange(changes) ||
      this._isValidUpdateVaccine(changes)
    ) {
      this._getPatientInfoEvolutionByPatientId();
      this._subscriptions.add(
        this._patientService
          .getVaccinesPatientByGcmId(this.patient.gcmid)
          .subscribe((results) => {
            this.isSomeDoseMedicalRestrictionFromGCM = this._isSomeDoseMedicalRestrictionFromGCM(
              results
            );
            this.vaccineDosesTaken = 0;
            this.patientIsReleasedSGC = false;
            this.patientIsSGC = false;
            this.listVaccinesGCM = [];
            if (results && results.length > 0) {
              this._setSGCType(results);
              this.vaccineNumberDoses = results[0].numberDoses;
              results.forEach((element) => {
                if (
                  element.origin != OriginVaccineConstant.SGC &&
                  !element.isBoosterVaccine
                ) {
                  this.listVaccinesGCM.push(element);
                }
              });
              this.vaccineDosesTaken = this.listVaccinesGCM.length;
            }
          })
      );
    }
  }
  private _isSomeDoseMedicalRestrictionFromGCM(vaccines: any): boolean {
    for (const vaccine in vaccines) {
      if (
        vaccines[vaccine].vaccineInformationId ===
          VaccinesEnum.RESTRICAO_MEDICA &&
        vaccines[vaccine].origin !== OriginVaccineConstant.SGC
      ) {
        this.vaccinationMedicalRestrictionDate =
          vaccines[vaccine].vaccinationDate;

        return true;
      }
    }
    return false;
  }

  private _setSGCType(vaccines: any): void {
    for (const vaccine in vaccines) {
      if (
        vaccines[vaccine].origin == OriginVaccineConstant.SGC &&
        !vaccines[vaccine].isBoosterVaccine &&
        !this.patientIsReleasedSGC &&
        !this.patientIsSGC
      ) {
        this.vaccinationSGCDate = vaccines[vaccine].vaccinationDate;
        if (
          vaccines[vaccine].vaccineInformationId ==
          VaccinesEnum.RESTRICAO_MEDICA
        ) {
          this.patientIsSGC = false;
          this.patientIsReleasedSGC = true;
        } else if (!this.patientIsReleasedSGC) {
          this.patientIsSGC = true;
          this.patientIsReleasedSGC = false;
        } else {
          this.patientIsReleasedSGC = false;
          this.patientIsSGC = false;
        }
      }
    }
  }

  private _verifyEmployeeTypeAndSetModalFormFields() {
    if (
      this.patient.type != PatientTypeConstant.OUTSOURCED &&
      this.patient.type != PatientTypeConstant.VALE
    ) {
      if (this.patient.id == null) {
        this.cpfField = true;
        this.passportField = false;
        this.managerField = false;
        this.managerEmailField = false;
        this.managerPhoneField = false;
        this.ssoLocationField = false;
      }
    } else if (this.patient.type == PatientTypeConstant.VALE) {
      this.passportField = false;
      this.ssoLocationField = false;

      if (!this.patient.id) {
        this.cpfField = true;
      }
      if (!this.patient.manager) {
        this.managerField = true;
      }
      if (!this.patient.managerEmail) {
        this.managerEmailField = true;
      }
      if (!this.patient.contact.managerPhone) {
        this.managerPhoneField = true;
      }
    } else {
      if (!this.patient.id) {
        this.cpfField = true;
        this.passportField = true;
      }
      if (!this.patient.manager) {
        this.managerField = true;
      }
      if (!this.patient.managerEmail) {
        this.managerEmailField = true;
      }
      if (!this.patient.contact.managerPhone) {
        this.managerPhoneField = true;
      }
      if (!this.patient.ssoLocation) {
        this.ssoLocationField = true;
      }
    }
  }

  private _openModalForMissingEmployeeData() {
    this._actionDialogService.setDataChild({
      managerField: this.managerField,
      cpfField: this.cpfField,
      passportField: this.passportField,
      ssoLocationField: this.ssoLocationField,
      managerEmailField: this.managerEmailField,
      managerPhoneField: this.managerPhoneField,
    });
    const dialogRef = this._actionDialogService.open(
      UpdateEmployeeDataComponent,
      {
        confirmButton: false,
        title: 'Usuário com pendências no cadastro',
        src: './assets/images/clipboard.svg',
      }
    );
    this._subscriptions.add(
      dialogRef.afterClosed$.subscribe((res: any) => {
        if (res && res.data) {
          this.saveComplementaryEmployeeData();
        }
      })
    );
  }

  private _callErrorSnackBar(message: string) {
    this._snackBar.open(
      message,
      this._translateService.instant('GENERAL.CLOSE'),
      {
        duration: 5000,
      }
    );
  }

  private _openAccessDialog() {
    const dialogRef = this._dialog.open(PatientReleaseAccessComponent, {
      disableClose: true,
      data: {
        employeeId: this.patient.gcmid,
        releaseResponse: this.releaseResponse,
      },
    });
    this._subscriptions.add(
      dialogRef.afterClosed().subscribe((response) => {
        if (response) {
          this.checkDisplayOfReleaseButton();
        }
      })
    );
  }

  private _capitalizeFirstLetter(nome: string) {
    return nome.charAt(0).toUpperCase() + nome.slice(1) + ' ';
  }

  private _generateDataForm() {
    switch (this.patient.type) {
      case PatientTypeEnum.DEPENDENT:
        this.formData = this._fb.group({
          id: [this.patient.id],
          socialName: [this.patient.socialName],
          idCard: [this.patient.idCard],
          dependentNameHolder: [this.patient.holderName],
        });
        break;
      case PatientTypeEnum.OUTSOURCED:
        this.formData = this._fb.group({
          id: [this.patient.id],
          socialName: [this.patient.socialName],
          registration: [this.patient.registration],
          ssoLocation: [this.patient.ssoLocation],
          location: [this.patient.location],
          company: [this.patient.company],
          manager: [this.patient.manager],
        });
        break;
      case PatientTypeEnum.RETIRED:
        this.formData = this._fb.group({
          id: [this.patient.id],
          socialName: [this.patient.socialName],
          idCard: [this.patient.idCard],
        });
        break;
      case PatientTypeEnum.VALE:
        this.formData = this._fb.group({
          id: [this.patient.id],
          socialName: [this.patient.socialName],
          registration: [this.patient.registration],
          location: [this.patient.location],
          ssoLocation: [this.patient.ssoLocation],
          director: [this.patient.director],
          role: [this.patient.role],
          manager: [this.patient.manager],
        });
        break;
    }
  }

  private _generateContactForm() {
    switch (this.patient.type) {
      case PatientTypeEnum.DEPENDENT:
        this.formContact = this._fb.group({
          personalPhone: [this.patient.contact.personalPhone],
          alternativePhone: [this.patient.contact.alternativePhone],
          holderPhone: [this.patient.contact.holderPhone],
        });
        break;
      case PatientTypeEnum.OUTSOURCED:
      case PatientTypeEnum.VALE:
        this.formContact = this._fb.group({
          personalPhone: [this.patient.contact.personalPhone],
          alternativePhone: [this.patient.contact.alternativePhone],
          checklistPhone: [this.patient.contact.checklistPhone],
          managerPhone: [this.patient.contact.managerPhone],
        });
        break;
      case PatientTypeEnum.RETIRED:
        this.formContact = this._fb.group({
          personalPhone: [this.patient.contact.personalPhone],
          alternativePhone: [this.patient.contact.alternativePhone],
        });
        break;
    }
  }

  private _getUserPermission(): void {
    this._subscriptions.add(
      this._userBusiness.userPermission$.subscribe((permissions: any) => {
        this._userPermission = permissions;
        this.hasPermissionToReleasePatient = this._userPermission.hasPermissionMedicalRecord;
      })
    );
  }

  private _getCardSituationInfo(accessCardId: number) {
    const alt =
      'imagem que funciona como botão e representa um card do funcionário';
    switch (accessCardId) {
      case AccessCard.RED_CARD_SYMPTOMATIC:
      case AccessCard.RED_CARD_VACCINE:
        return { src: `./assets/images/patient-red-card.svg`, alt: alt };
      case AccessCard.RED_CARD_QUARANTINE:
      case AccessCard.RED_CARD_BY_CONTACT_QUARANTINE:
      case AccessCard.RED_CARD_TEST_POSITIVE_QUARANTINE:
      case AccessCard.RED_CARD_GROUP_RISK:
      case AccessCard.GREEN_CARD:
      case AccessCard.GREEN_CARD_RETEST:
        return { src: `./assets/images/patient-green-card.svg`, alt: alt };

      default:
        return {
          src: `./assets/images/${this._getNameImageCard(accessCardId)}`,
          alt: alt,
        };
    }
  }

  private _getNameImageCard(accessCardId: number) {
    const cardName = (AccessCard.toString(accessCardId) || '')
      .toLowerCase()
      .replace(/_/gi, '-');
    return `patient-${cardName}.svg`;
  }

  private _setTemplateCardInfo(accessCardId: number | AccessCard) {
    this.templateTitle = `PATIENT_INFO.PATIENT_CARD_INFO.${AccessCard.toString(
      accessCardId
    )}.TITLE`;
    this.templateText = `PATIENT_INFO.PATIENT_CARD_INFO.${AccessCard.toString(
      accessCardId
    )}.CONTENT`;
    this.templateDateText = `PATIENT_INFO.PATIENT_CARD_INFO.${AccessCard.toString(
      accessCardId
    )}.DATE_TEXT`;
    this.templateUpdateDate = this.patientCardSituation.updateDate;
    this.templateFinalTest = this.patientCardSituation.finalTest;
    let diffDaysToBlock =
      (new Date(this.templateFinalTest).getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24);
    diffDaysToBlock = diffDaysToBlock > 0 ? diffDaysToBlock : 0;
    this.templateDaysToBlockAccess = Math.round(diffDaysToBlock);
  }

  private _getPatientCardSituation(changes: SimpleChanges) {
    if (
      this._isValidPatientChange(changes) ||
      this._isValidUpdateCardSituation(changes)
    ) {
      this._subscriptions.add(
        this._cardManagementService
          .patientCardSituation(this.patient.gcmid, this.patient.type)
          .subscribe((patientCardSituation: PatientCardSituationModel) => {
            this.patientCardSituation = patientCardSituation;
            this.cardSituationInfo = null;
            this.patientComorbidity = null;
            this.hasComorbidity = false;
            //this.hasRelease = false;
            //this.isIncreasedRisk = false;
            if (this.patientCardSituation) {
              this.cardSituationInfo = this._getCardSituationInfo(
                this.patientCardSituation.accessCardId
              );
              this.checkDisplayOfReleaseButton();
              this._handleComorbiditiesByPatient(changes);
            }
          })
      );
    }
  }

  private _handleComorbiditiesByPatient(changes: SimpleChanges) {
    this.patientComorbidity = null;
    this.hasComorbidity = false;
    //this.hasRelease = false;
    //this.isIncreasedRisk = false;
    if (
      changes['_updateCardSituation'] &&
      changes['_updateCardSituation'].currentValue &&
      changes['_updateCardSituation'].currentValue.value
    ) {
      this.patientComorbidity =
        changes['_updateCardSituation'].currentValue.value;
      /*this.patientComorbidity.comorbidities.comorbidities.forEach((element) => {
        if (
          element.id == ComorbidityEnum.GESTANTES_E_LACTANTES ||
          element.id == ComorbidityEnum.IMUNOSSUPRIMIDOS
        ) {
          this.isIncreasedRisk = true;
        }
      });*/
      this.hasComorbidity = !!(
        this.patientComorbidity &&
        (this.patientComorbidity.comorbidities.comorbidities || []).length
      );
      /*if (this.patientComorbidity.comorbidities.released) {
        this.hasRelease = true;
      }*/
    } else {
      this._getPatientComorbiditiesByGcmId();
    }
  }

  private _isValidUpdateTimeline(changes: SimpleChanges) {
    return (
      changes['_updateTimeline'] &&
      (changes['_updateTimeline'].firstChange ||
        changes['_updateTimeline'].currentValue !=
          changes['_updateTimeline'].previousValue)
    );
  }

  private _isValidUpdateVaccine(changes: SimpleChanges) {
    return (
      changes['_updateVaccines'] &&
      (changes['_updateVaccines'].firstChange ||
        changes['_updateVaccines'].currentValue !=
          changes['_updateVaccines'].previousValue)
    );
  }

  private _isValidUpdateCardSituation(changes: SimpleChanges) {
    return (
      changes['_updateCardSituation'] &&
      (changes['_updateCardSituation'].firstChange ||
        changes['_updateCardSituation'].currentValue !=
          changes['_updateCardSituation'].previousValue)
    );
  }

  private _getPatientComorbiditiesByGcmId() {
    const patientId = this.patient.gcmid;
    if (this.patient.gcmid) {
      const comorbidities = this._patientService
        .getComorbiditiesByEmployeeId(patientId)
        .subscribe((response) => {
          /*if (response.released) {
            this.hasRelease = true;
          }*/
          /*response.comorbidities.forEach((element) => {
            if (
              element.id == ComorbidityEnum.GESTANTES_E_LACTANTES ||
              element.id == ComorbidityEnum.IMUNOSSUPRIMIDOS
            ) {
              this.isIncreasedRisk = true;
            }
          });*/
          this.patientComorbidity = response;
          this.hasComorbidity = !!((response && response.comorbidities) || [])
            .length;
        });
      this._subscriptions.add(comorbidities);
    }
  }

  private _checkEmployeeHasMissingInformationWhenIsNotDemobilize() {
    if (!this.patient.isDemobilize) {
      this._verifyEmployeeTypeAndSetModalFormFields();
      if (
        (this.managerField ||
          this.cpfField ||
          this.passportField ||
          this.ssoLocationField ||
          this.managerEmailField ||
          this.managerPhoneField) &&
        this.selectedOption == HomeOptionsEnum.MEDICAL_RECORD
      ) {
        this._openModalForMissingEmployeeData();
      }
    }
  }

  private _checkIsRedirectToMonitoringCenter() {
    this._subscriptions.add(
      this._userBusiness.homeOption$.subscribe((homeOption) => {
        this.isRedirectToMonitoringCenter =
          HomeOptionsEnum.MONITORING_CENTER === homeOption;
      })
    );
  }

  private _setIsMedicalRecordFromHomeOption() {
    this.isMedicalRecord =
      this._userBusiness.getHomeOption() === HomeOptionsEnum.MEDICAL_RECORD;
  }
}
