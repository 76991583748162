import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortalDirective } from '@angular/cdk/portal';
import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ValeGenericSearchDropdownService } from './vale-generic-search-dropdown.service';

@Component({
  selector: 'vale-generic-search-dropdown',
  template: ` <ng-template cdk-portal>
    <ng-content></ng-content>
  </ng-template>`,
})
export class ValeGenericSearchDropdownComponent implements OnDestroy {
  @Input()
  public reference: HTMLElement;

  @ViewChild(TemplatePortalDirective, { static: true })
  public contentTemplate: TemplatePortalDirective;

  protected overlayRef: OverlayRef;

  public showing = false;
  private _subscriptions = new Subscription();
  private _hasBackdrop = true;

  constructor(
    protected overlay: Overlay,
    private _valeGenericSearchDropdownService: ValeGenericSearchDropdownService
  ) {}
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public show(): void {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(this.contentTemplate);
    this.syncWidth();
    this._subscriptions.add(
      this.overlayRef.backdropClick().subscribe(() => this.hide())
    );
    this.showing = true;
  }

  public hide(): void {
    this.showing = false;
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
    this.reference.focus();
    this._valeGenericSearchDropdownService.resetSearch();
  }

  @HostListener('window:resize')
  public onWinResize(): void {
    this.syncWidth();
  }

  public setClickOutsideDropdown(hasBackdrop: boolean): void {
    this._hasBackdrop = hasBackdrop;
  }

  protected getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.reference)
      .withPush(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ])
      .withDefaultOffsetY(8);

    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    return new OverlayConfig({
      positionStrategy: positionStrategy,
      scrollStrategy: scrollStrategy,
      hasBackdrop: this._hasBackdrop,
      backdropClass: 'cdk-overlay-transparent-backdrop-vale-generic-search',
    });
  }

  private syncWidth() {
    if (!this.overlayRef) {
      return;
    }

    const refRect = this.reference.getBoundingClientRect();
    this.overlayRef.updateSize({ width: refRect.width });
  }
}
