import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ValeInputDirective } from './directive/vale-input.directive';
import { ValeLabelComponent } from './vale-label.component';

@NgModule({
  declarations: [ValeLabelComponent, ValeInputDirective],
  exports: [ValeLabelComponent, ValeInputDirective],
  imports: [CommonModule],
})
export class ValeLabelModule {}
