import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from './../../../environments/environment';
import { ApplicationInsightService } from './application-insights.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(private applicationInsightService: ApplicationInsightService) {}

  public logErrorOnApplicationInsight(error: Error): void {
    this.applicationInsightService.logError(error);
  }

  public logError(error: Error | HttpErrorResponse): void {
    if (!environment.production) {
      console.log(error);
    }
  }

  public logWarning(message: string, error: Error | HttpErrorResponse): void {
    this.applicationInsightService.logTrace(message, { error });
  }
}
