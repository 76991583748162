import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';

import { ReturnDialogButton } from '../../shared/components/return-dialog/return-dialog-button.model';
import { ReturnDialogComponent } from '../../shared/components/return-dialog/return-dialog.component';
import { ReturnDialog } from '../../shared/components/return-dialog/return-dialog.model';
import { UtilHelper } from '../../shared/util/util-helper';
import { RegisterService } from './register.service';

@Injectable()
export class PatientRegisterMdmService implements OnDestroy {
  private _subscriptions = new Subscription();
  constructor(
    private _registerService: RegisterService,
    private _dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public getPatientProfileFromMdm(code: string): Observable<any> {
    return this._registerService.getPatientProfileFromMdm(code);
  }

  public getPatientProfileFromMdmHandleResponse$(
    code: string
  ): Observable<any> {
    let message = `Não foi possível obter os dados da matrícula ${code} informada no sistema Vale!`;
    return new Observable((subscriber) => {
      this._subscriptions.add(
        this._registerService.getEmployeeGCMProfile(code).subscribe(
          (result) => {
            if (result.type) {
              subscriber.next(result);
              subscriber.complete();
            } else {
              this._callErrorReturnDialog(message);
              subscriber.error(null);
              subscriber.complete();
            }
          },
          (error) => {
            if (error && error.status != 404) {
              message = UtilHelper.getMessagesApiError(error);
            }
            this._callErrorReturnDialog(message);
            // subscriber.next(null);
            subscriber.error(null);
            subscriber.complete();
          }
        )
      );
    });
  }

  private _callErrorReturnDialog(message: string) {
    const dialogRef = this._dialog;
    const buttons = new Array<ReturnDialogButton>(
      new ReturnDialogButton({
        name: 'Tente Novamente', // [TO DO] - TRADUZIR
        className:
          'button-classic button-classic--complete button-classic--background-blue',
        action: () => {
          dialogRef.closeAll();
        },
      })
    );
    this._dialog.open(ReturnDialogComponent, {
      data: new ReturnDialog({
        actionType: 'error',
        title: 'Erro ao validar matrícula', // [TO DO] - TRADUZIR
        message: message,
        path: './assets/images/error.svg',
        buttons: buttons,
        closeActionButton: () => {
          dialogRef.closeAll();
        },
      }),
    });
  }
}
