import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { ValeButtonModule } from '../vale-button/vale-button.module';
import { ValeImageComponent } from './vale-image.component';
import { FocusOutDirective } from './vale-image.directive';

@NgModule({
  declarations: [ValeImageComponent, FocusOutDirective],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    ValeButtonModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [ValeImageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeImageModule {}
