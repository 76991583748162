import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class UserDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if (
      typeof value === 'string' &&
      value.indexOf('/') > -1 &&
      value.length == 10
    ) {
      if (this.isValidDate(value)) {
        const str = value.split('/');
        const year = Number(str[2]);
        const month = Number(str[1]) - 1;
        const date = Number(str[0]);
        return new Date(year, month, date);
      }
    } else if (!value) {
      return null;
    }
    return new Date(undefined);
  }

  private isValidDate(date: string) {
    const regex = /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/g;
    return date.match(regex);
  }
}
