<div class="container-patient-info">
  <div class="patient-info">
    <div
      class="patient-info-buttons {{
        !isRedirectToMonitoringCenter ? 'align-flex-end' : ''
      }}"
    >
      <div *ngIf="isRedirectToMonitoringCenter">
        <button app-vale-button class="go-back-arrow" (click)="goBack()">
          <div class="icon">
            <img
              src="../../../../assets/images/icons/left-arrow.svg"
              alt="left-arrow"
            />
          </div>
          <span class="go-back-text">
            {{ 'GENERAL.GO_BACK' | translate }}
          </span>
        </button>
      </div>
      <div class="patient-edit">
        <button
          app-vale-button
          class="edit-button"
          (click)="onSelectedOption()"
          [matTooltip]="'TOOLTIPS.EDIT_BUTTON' | translate"
        >
          <img
            src="../../../../assets/icons/edit-profile.svg"
            alt="edit-profile"
            class="edit-icon"
          />
          <span class="">{{ 'PATIENT_INFO.EDIT_DATA' | translate }}</span>
        </button>
      </div>
    </div>
    <div class="patient-info-gcm">
      <div *ngIf="secondPhaseProtocol?.protocol">
        <div *ngIf="secondPhaseProtocol.protocol != 5">
          <div class="gcm-protocol">
            <img
              alt="secondPhaseProtocol"
              *ngIf="secondPhaseProtocol.protocol == 1"
              src="../../../../assets/images/icons/protocol1.svg"
              [matTooltip]="'TOOLTIPS.PROTOCOL_1' | translate"
            />
            <img
              alt="secondPhaseProtocol"
              *ngIf="secondPhaseProtocol.protocol == 2"
              src="../../../../assets/images/icons/protocol2.svg"
              [matTooltip]="'TOOLTIPS.PROTOCOL_2' | translate"
            />
            <img
              alt="secondPhaseProtocol"
              *ngIf="secondPhaseProtocol.protocol == 3"
              src="../../../../assets/images/icons/protocol3.svg"
              [matTooltip]="'TOOLTIPS.PROTOCOL_3' | translate"
            />
            <img
              alt="secondPhaseProtocol"
              *ngIf="secondPhaseProtocol.protocol == 4"
              src="../../../../assets/images/icons/protocol4.svg"
              [matTooltip]="'TOOLTIPS.PROTOCOL_4' | translate"
            />
          </div>
        </div>
      </div>
      <div
        class="gcm-patient-type"
        [matTooltip]="'TOOLTIPS.PROFILE_PATIENT' | translate"
      >
        <app-vale-dot-profile [profile]="patient.type"></app-vale-dot-profile>
      </div>
      <div
        class="gcm-id"
        *ngIf="patient.gcmid"
        [matTooltip]="'TOOLTIPS.ID_PATIENT' | translate"
      >
        @{{ patient.gcmid }}
      </div>
      <div *ngIf="patient.isDemobilize" class="demobilized-img">
        <img
          src="./assets/images/mobilize-eye.svg"
          alt="imagem de olho cortado representando que o colaborador está desmobilizado"
          [matTooltip]="'TOOLTIPS.DEMOBILIZED' | translate"
        />
      </div>
    </div>
    <div class="patient-info-container">
      <div class="info">
        <div class="patient-info-data">
          <div class="patient-info-name">
            {{ patient.name }}
          </div>
          <div class="basic-info">
            <div class="years-old">
              <span *ngIf="patient.birthDate">{{ currentAge }} </span
              ><span>{{
                (currentAge == 0
                  ? patient.birthDate
                    ? 'PATIENT_REGISTER.INFO.YEAR'
                    : 'PATIENT_REGISTER.INFO.WITHOUT_BIRTHDATE'
                  : 'PATIENT_REGISTER.INFO.YEARS'
                ) | translate
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="patient-info-card">
        <img
          alt="ícone card situation"
          *ngIf="cardSituationInfo"
          [ngClass]="
            patient.isDemobilize
              ? 'temporary-release-card-disabled'
              : 'temporary-release-card'
          "
          [src]="cardSituationInfo.src"
          [alt]="cardSituationInfo.alt"
          (click)="openCardInfoDialog(containerPatientInfoTemplate)"
          [matTooltip]="'TOOLTIPS.CARD_PATIENT' | translate"
        />
      </div>
    </div>
    <div class="patient-basic-status">
      <!--<div *ngIf="isIncreasedRisk && !hasRelease" class="accessibility">
        <mat-icon
          svgIcon="accessibility-red"
          [matTooltip]="'TOOLTIPS.GRA_II' | translate"
          class="accessibility-icon"
        ></mat-icon>
      </div>-->
      <div *ngIf="hasComorbidity" class="accessibility">
        <mat-icon
          svgIcon="accessibility-green"
          [matTooltip]="'TOOLTIPS.GRA_I' | translate"
          class="accessibility-icon"
        ></mat-icon>
      </div>
      <!--<div *ngIf="isIncreasedRisk && hasRelease" class="accessibility">
        <mat-icon
          svgIcon="accessibility-blue"
          [matTooltip]="'TOOLTIPS.GRA_II_RELEASE' | translate"
          class="accessibility-icon"
        ></mat-icon>
      </div>-->
      <div
        *ngIf="
          (vaccineDosesTaken && !isSomeDoseMedicalRestrictionFromGCM) ||
          patientIsSGC ||
          patientIsReleasedSGC
        "
        class="info-vaccine"
      >
        <img src="../../../../assets/images/icons/vaccine.svg" alt="vaccine" />
        <span class="vaccinated" *ngIf="!patientIsSGC && !patientIsReleasedSGC">
          {{ 'PATIENT_INFO.VACCINATED' | translate }} {{ vaccineDosesTaken }}/{{
            vaccineNumberDoses
          }}
        </span>
        <span
          class="vaccinated"
          [matTooltip]="vaccinationSGCDate | date: 'dd/MM/yyyy'"
          *ngIf="patientIsSGC"
        >
          {{ 'PATIENT_INFO.VACCINATED_SGC' | translate }}
        </span>
        <span
          class="vaccinated"
          [matTooltip]="vaccinationSGCDate | date: 'dd/MM/yyyy'"
          *ngIf="patientIsReleasedSGC"
        >
          {{ 'PATIENT_INFO.RELEASED_SGC' | translate }}
        </span>
      </div>
      <div
        *ngIf="vaccineDosesTaken && isSomeDoseMedicalRestrictionFromGCM"
        class="info-vaccine"
      >
        <img src="../../../../assets/images/icons/vaccine.svg" alt="vaccine" />
        <span
          class="vaccinated"
          [matTooltip]="vaccinationMedicalRestrictionDate | date: 'dd/MM/yyyy'"
        >
          {{ 'PATIENT_INFO.RELEASED' | translate }}
        </span>
      </div>
      <div
        class="patient-info-status"
        *ngIf="selectedOption == 1 || selectedOption == 4"
        [matTooltip]="patient?.lastStatusUpdateDate | date: 'dd/MM/yyyy'"
      >
        <div class="status" *ngIf="patient.status?.id == 1">
          <img
            src="../../../../assets/images/icons/black-warning.svg"
            alt="black-warning"
          />
          <span class="status-text status-text--black">
            {{ patient.status?.description }}
          </span>
        </div>
        <div
          class="status"
          *ngIf="
            patient.status?.id == 2 ||
            patient.status?.id == 3 ||
            patient.status?.id == 4
          "
        >
          <img
            src="../../../../assets/images/icons/red-warning.svg"
            alt="red-warning"
          />
          <span class="status-text status-text--red">
            {{ patient.status?.description }}
          </span>
        </div>
        <div
          class="status"
          *ngIf="patient.status?.id == 5 || patient.status?.id == 6"
        >
          <img
            src="../../../../assets/images/icons/yellow-warning.svg"
            alt="yellow-warning"
          />
          <span class="status-text status-text--yellow">
            {{ patient.status?.description }}
          </span>
        </div>
        <div
          class="status"
          *ngIf="
            patient.status?.id == 7 ||
            patient.status?.id == 8 ||
            patient.status?.id == 9
          "
        >
          <img
            src="../../../../assets/images/icons/green-check.svg"
            alt="green-check"
          />
          <span class="status-text status-text--green">
            {{ patient.status?.description }}
          </span>
        </div>
        <div class="status" *ngIf="patient.status?.id == 10">
          <img
            src="../../../../assets/images/icons/blue-check.svg"
            alt="blue-check"
          />
          <span class="status-text status-text--blue">
            {{ patient.status?.description }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="patient-data">
    <mat-tab-group dynamicHeight>
      <mat-tab #dataTab>
        <ng-template mat-tab-label>
          <div [matTooltip]="'TOOLTIPS.DATA_PATIENT' | translate">
            <img
              class="tab-icon"
              [src]="
                dataTab.isActive
                  ? '../../../../assets/images/icons/patient-activated.svg'
                  : '../../../../assets/images/icons/patient-disabled.svg'
              "
              alt="icon tab patient"
            />
            <span
              [ngClass]="
                dataTab.isActive
                  ? 'patient-data-label patient-data-label--activated'
                  : 'patient-data-label patient-data-label--disabled'
              "
            >
              {{ 'PATIENT_INFO.PATIENT_DATA' | translate }}
            </span>
          </div>
        </ng-template>
        <div class="patient-form">
          <app-patient-info-vale-data
            [formData]="formData"
            *ngIf="patient.type == patientTypeEnum.VALE"
          >
          </app-patient-info-vale-data>
          <app-patient-info-retired-data
            [formData]="formData"
            *ngIf="patient.type == patientTypeEnum.RETIRED"
          >
          </app-patient-info-retired-data>
          <app-patient-info-outsourced-data
            [formData]="formData"
            *ngIf="patient.type == patientTypeEnum.OUTSOURCED"
          >
          </app-patient-info-outsourced-data>
          <app-patient-info-dependent-data
            [formData]="formData"
            *ngIf="patient.type == patientTypeEnum.DEPENDENT"
          >
          </app-patient-info-dependent-data>
          <app-patient-info-vale-contact
            [formContact]="formContact"
            *ngIf="patient.type == patientTypeEnum.VALE"
          >
          </app-patient-info-vale-contact>
          <app-patient-info-dependent-contact
            [formContact]="formContact"
            *ngIf="patient.type == patientTypeEnum.DEPENDENT"
          >
          </app-patient-info-dependent-contact>
          <app-patient-info-outsourced-contact
            [formContact]="formContact"
            *ngIf="patient.type == patientTypeEnum.OUTSOURCED"
          ></app-patient-info-outsourced-contact>
          <app-patient-info-retired-contact
            [formContact]="formContact"
            *ngIf="patient.type == patientTypeEnum.RETIRED"
          >
          </app-patient-info-retired-contact>
        </div>
      </mat-tab>

      <mat-tab #historyTab>
        <ng-template mat-tab-label>
          <div
            [matTooltip]="'TOOLTIPS.EVOLUTION_PATIENT' | translate"
            (click)="getPatientInfoEvolutionByEmployeeId()"
          >
            <img
              class="tab-icon"
              [src]="
                historyTab.isActive
                  ? '../../../../assets/images/icons/evolution-activated.svg'
                  : '../../../../assets/images/icons/evolution-disabled.svg'
              "
              alt="history-activated"
            />
            <span
              [ngClass]="
                historyTab.isActive
                  ? 'patient-data-label patient-data-label--activated'
                  : 'patient-data-label patient-data-label--disabled'
              "
            >
              {{ 'PATIENT_INFO.PATIENT_EVOLUTION' | translate }}
            </span>
          </div>
        </ng-template>
        <div class="patient-form">
          <app-patient-info-history
            *ngIf="patientHistory?.length > 0"
            [patientHistory]="patientHistory"
            [updatingTimeline]="updatingTimeline"
          ></app-patient-info-history>
          <div *ngIf="!patientHistory?.length">
            <img
              class="empty-state-img"
              src="./assets/images/employee_not_found.svg"
              alt="imagem de funcionários não encontrados"
            />
            <span class="empty-state-text">{{
              'PATIENT_INFO.NO_PATIENT_EVOLUTION' | translate
            }}</span>
          </div>
        </div>
      </mat-tab>
      <mat-tab #checklistTab *ngIf="isMedicalRecord">
        <ng-template mat-tab-label>
          <div [matTooltip]="'TOOLTIPS.CHECKLISTS_PATIENT' | translate">
            <img
              class="tab-icon"
              [src]="
                checklistTab.isActive
                  ? '../../../../assets/images/icons/checklists-enable.svg'
                  : '../../../../assets/images/icons/checklists-disable.svg'
              "
              alt="contact-activated"
            />
            <span
              [ngClass]="
                checklistTab.isActive
                  ? 'patient-data-label patient-data-label--activated'
                  : 'patient-data-label patient-data-label--disabled'
              "
            >
              {{ 'PATIENT_INFO.PATIENT_CHECKLIST' | translate }}
            </span>
          </div>
        </ng-template>
        <div class="patient-form">
          <app-patient-info-cheklist> </app-patient-info-cheklist>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #containerPatientInfoTemplate>
  <div class="container-patient-info-template">
    <section class="header">
      <span class="title">{{ templateTitle | translate }}</span>
    </section>
    <section class="body">
      <span
        class="text"
        *ngIf="
          patientCardSituation.accessCardId !==
            accessCardEnum.RED_CARD_LATE_TEST &&
          patientCardSituation.accessCardId !== accessCardEnum.GREEN_CARD_RETEST
        "
        >{{ templateText | translate }}</span
      >
    </section>
    <section class="footer" *ngIf="templateFinalTest">
      <span
        class="text"
        *ngIf="patientCardSituation.accessCardId == accessCardEnum.YELLOW_CARD"
        >{{
          'PATIENT_INFO.PATIENT_CARD_INFO.YELLOW_CARD.BLOCKED_ACCESS'
            | translate: { days: templateDaysToBlockAccess }
        }}
      </span>
      <div
        *ngIf="
          patientCardSituation.accessCardId ==
            accessCardEnum.RED_CARD_LATE_TEST ||
          patientCardSituation.accessCardId == accessCardEnum.GREEN_CARD_RETEST
        "
      >
        <span class="text"
          >{{ templateDateText | translate }}
          {{ templateFinalTest | date: 'dd/MM/yyyy' }}</span
        >
      </div>
    </section>
    <section class="footer" *ngIf="templateUpdateDate">
      <span class="text"
        >{{ 'PATIENT_INFO.PATIENT_CARD_INFO.UPDATE_DATE' | translate }}
        {{ templateUpdateDate | date: 'dd/MM/yyyy' }}</span
      >
    </section>
    <section class="warning">
      <span class="note">{{
        'PATIENT_INFO.PATIENT_CARD_INFO.CARD_WARNING' | translate
      }}</span>
    </section>
  </div>
</ng-template>
