<div class="timeline" *ngFor="let timeline of data">
  <div class="year-paralaxx">
    <div class="rotation-paralaxx">{{ timeline.year }}</div>
  </div>
  <ul class="main">
    <ng-container *ngFor="let event of timeline?.data; let i = index">
      <li class="time main-li" *ngIf="event.isFirst">
        <div class="content">
          <span class="title-date">
            {{ event.eventDateFormated }}
          </span>
        </div>
      </li>
      <li
        [ngSwitch]="event.eventType"
        [ngClass]="
          event.eventType == valeTimelineEnum.VACCINES ||
          event.eventType == valeTimelineEnum.COMORBIDITY
            ? 'main-li title-clickable'
            : 'main-li'
        "
      >
        <div>
          <div *ngSwitchCase="valeTimelineEnum.HOSPITALIZATION">
            <div class="container-header">
              <div>
                <span class="title">{{
                  'TIMELINE.HOSPITALIZATION' | translate
                }}</span>
              </div>
            </div>
            <div class="body">
              <div>{{ event.hospitalName }} - {{ event.state }}</div>
              <div *ngIf="event.transferenceDate" class="note-container">
                <span class="note-border-primary">
                  {{ 'TIMELINE.TRANSFER' | translate }}
                  {{ event.transferenceDate }}
                </span>
              </div>
            </div>
          </div>

          <div *ngSwitchCase="valeTimelineEnum.DISCHARGE">
            <div class="container-header">
              <div>
                <span class="title">{{
                  'TIMELINE.DISCHARGE' | translate
                }}</span>
              </div>
            </div>
            <div class="body">
              <div>{{ event.hospitalName }}</div>
            </div>
          </div>

          <div *ngSwitchCase="valeTimelineEnum.SYMPTOM">
            <div class="container-header">
              <div>
                <span class="title">{{ 'TIMELINE.SYMPTOM' | translate }}</span>
              </div>
            </div>
            <div class="body">
              <ul *ngIf="event?.symptomList">
                <li *ngFor="let name of event.symptomList">{{ name }}</li>
              </ul>
            </div>
          </div>

          <div
            *ngSwitchCase="valeTimelineEnum.COMORBIDITY"
            (click)="openModule('comorbidities')"
            class="title-clickable"
          >
            <div class="container-header">
              <div>
                <span class="title">{{
                  'TIMELINE.COMORBIDITY' | translate
                }}</span>
              </div>
            </div>
            <div class="body">
              <ul *ngIf="event?.comorbidityList">
                <li *ngFor="let name of event?.comorbidityList">
                  {{ name }}
                </li>
              </ul>
              <div *ngIf="event.medicalNote">{{ event.medicalNote }}</div>
            </div>
          </div>

          <div *ngSwitchCase="valeTimelineEnum.NOTE">
            <div class="container-header">
              <div>
                <span class="title">{{ 'TIMELINE.NOTE' | translate }}</span>
              </div>
            </div>
            <div class="body">
              <div>{{ event.note }}</div>
            </div>
          </div>

          <div *ngSwitchCase="valeTimelineEnum.EXAM_TRAG">
            <div class="container-header">
              <div>
                <span
                  [ngClass]="{
                    title: event.cycle != 0,
                    'title-disable': event.cycle == 0
                  }"
                  >{{ 'TIMELINE.QUICK_EXAM_TRAG' | translate }}</span
                >
              </div>
            </div>
            <div class="body">
              <div [ngClass]="{ outofCycle: event.cycle == 0 }">
                {{
                  (event?.resultTest
                    ? 'TIMELINE.QUICK_EXAM_RESULT.POSITIVE'
                    : 'TIMELINE.QUICK_EXAM_RESULT.NEGATIVE'
                  ) | translate
                }}
              </div>
              <div>
                <span class="test-time">
                  ({{ 'TIMELINE.TIME' | translate }}{{ event.timeTestTrag }})
                </span>
              </div>
              <div *ngIf="event.cycle == 0" class="cycle">
                <div>
                  <span class="note-border">{{
                    'TIMELINE.TEST_DISREGARDED' | translate
                  }}</span>
                </div>
                <div>
                  <div class="note">
                    {{ 'TIMELINE.TEST_LESS_THAN_6_DAYS' | translate }}
                  </div>
                </div>
              </div>
              <div class="test-note" *ngIf="event.note">
                <ul>
                  <li class="title">
                    <span>{{ 'TIMELINE.EXAM_NOTE' | translate }}</span>
                  </li>
                </ul>
                {{ event.note }}
              </div>
            </div>
          </div>

          <div *ngSwitchCase="valeTimelineEnum.TRAG_TEST_EXTERNAL">
            <div class="container-header">
              <div>
                <span class="title">{{
                  'TIMELINE.QUICK_EXAM_TRAG' | translate
                }}</span>
              </div>
            </div>
            <div class="body">
              <div>
                {{
                  (event?.resultTest
                    ? 'TIMELINE.QUICK_EXAM_RESULT.POSITIVE'
                    : 'TIMELINE.QUICK_EXAM_RESULT.NEGATIVE'
                  ) | translate
                }}
              </div>
            </div>
            <div class="external">
              <span>{{
                'TIMELINE.TEST_EXTERNAL_INFO.TEST_TRAG_EXTERNAL' | translate
              }}</span>
            </div>
            <div class="externo">
              <span>{{
                'TIMELINE.TEST_EXTERNAL_INFO.TEST_TRAG_EXTERNAL_INFO'
                  | translate
              }}</span>
            </div>
            <div class="body">
              <div class="test-note" *ngIf="event.note">
                <ul>
                  <li class="title">
                    <span>{{ 'TIMELINE.EXAM_NOTE' | translate }}</span>
                  </li>
                </ul>
                {{ event.note }}
              </div>
            </div>
          </div>

          <div *ngSwitchCase="valeTimelineEnum.EXAM_SEROLOGICAL">
            <div class="container-header">
              <div>
                <span class="title">{{
                  'TIMELINE.QUICK_EXAM_SOROLOGICOL' | translate
                }}</span>
              </div>
            </div>
            <div [ngSwitch]="event.resultTest" class="body">
              <div
                *ngSwitchCase="quickExamResultEnum.IGM_POSITIVE_IGG_POSITIVE"
              >
                <div>
                  <span>{{
                    'TIMELINE.QUICK_EXAM_RESULT.IGM_REAGENT_RESULT' | translate
                  }}</span>
                </div>
                <div>
                  <span>{{
                    'TIMELINE.QUICK_EXAM_RESULT.IGG_REAGENT_RESULT' | translate
                  }}</span>
                </div>
              </div>
              <div
                *ngSwitchCase="quickExamResultEnum.IGM_POSITIVE_IGG_NEGATIVE"
              >
                <div>
                  <span>{{
                    'TIMELINE.QUICK_EXAM_RESULT.IGM_REAGENT_RESULT' | translate
                  }}</span>
                </div>
                <div>
                  <span>{{
                    'TIMELINE.QUICK_EXAM_RESULT.IGG_NOT_REAGENT_RESULT'
                      | translate
                  }}</span>
                </div>
              </div>
              <div
                *ngSwitchCase="quickExamResultEnum.IGM_NEGATIVE_IGG_POSITIVE"
              >
                <div>
                  <span>{{
                    'TIMELINE.QUICK_EXAM_RESULT.IGM_NOT_REAGENT_RESULT'
                      | translate
                  }}</span>
                </div>
                <div>
                  <span>{{
                    'TIMELINE.QUICK_EXAM_RESULT.IGG_REAGENT_RESULT' | translate
                  }}</span>
                </div>
              </div>
              <div
                *ngSwitchCase="quickExamResultEnum.IGM_NEGATIVE_IGG_NEGATIVE"
              >
                <div>
                  <span>{{
                    'TIMELINE.QUICK_EXAM_RESULT.IGM_NOT_REAGENT_RESULT'
                      | translate
                  }}</span>
                </div>
                <div>
                  <span>{{
                    'TIMELINE.QUICK_EXAM_RESULT.IGG_NOT_REAGENT_RESULT'
                      | translate
                  }}</span>
                </div>
              </div>
              <div *ngSwitchCase="quickExamResultEnum.UNREACTIVE">
                <div>
                  <span>{{
                    'TIMELINE.QUICK_EXAM_RESULT.IGM_NOT_REAGENT_RESULT'
                      | translate
                  }}</span>
                </div>
                <div>
                  <span>{{
                    'TIMELINE.QUICK_EXAM_RESULT.IGG_NOT_REAGENT_RESULT'
                      | translate
                  }}</span>
                </div>
              </div>
              <div
                *ngSwitchCase="quickExamResultEnum.REACTIVE_WITHOUT_DISTICTION"
              >
                <div>
                  <span>{{
                    'TIMELINE.QUICK_EXAM_RESULT.IGM_NOT_REAGENT_RESULT'
                      | translate
                  }}</span>
                </div>
                <div>
                  <span>{{
                    'TIMELINE.QUICK_EXAM_RESULT.IGG_NOT_REAGENT_RESULT'
                      | translate
                  }}</span>
                </div>
              </div>
              <div class="test-note" *ngIf="event.note">
                <ul>
                  <li class="title">
                    <span>{{ 'TIMELINE.EXAM_NOTE' | translate }}</span>
                  </li>
                </ul>
                {{ event.note }}
              </div>
            </div>
          </div>

          <div *ngSwitchCase="valeTimelineEnum.EXAM_PCR">
            <div class="container-header">
              <div>
                <span class="title">{{ 'TIMELINE.EXAM_PCR' | translate }}</span>
              </div>
              <div>
                <span class="subtitle">{{
                  'TIMELINE.QUICK_EXAM_RESULT.REALIZED_EXAM' | translate
                }}</span>
              </div>
            </div>
            <div *ngIf="event.resultTest !== null">
              <div class="body">
                {{
                  (event?.resultTest === 1
                    ? 'TIMELINE.QUICK_EXAM_RESULT.POSITIVE'
                    : event?.resultTest === 2
                    ? 'TIMELINE.QUICK_EXAM_RESULT.INCONCLUSIVE'
                    : 'TIMELINE.QUICK_EXAM_RESULT.NEGATIVE'
                  ) | translate
                }}
              </div>
              <div>
                <span class="test-time">
                  ({{ 'TIMELINE.TIME' | translate }}{{ event.timeTestPCR }})
                </span>
              </div>
              <span class="subtitle">
                ({{
                  'TIMELINE.QUICK_EXAM_RESULT.RESULT_EXAM_DATE' | translate
                }}
                {{ event.labExamResultDateFormated }})</span
              >
              <div class="body">
                <div *ngIf="event.stopQuarentinePCR === true">
                  <div class="stop-quarentine">
                    {{ 'TIMELINE.STOP_QUARENTINE' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngSwitchCase="valeTimelineEnum.VACCINES"
            (click)="openModule('vaccines')"
            class="title-clickable"
          >
            <div class="container-header">
              <div>
                <span class="title title-clickable">{{
                  'TIMELINE.VACCINED' | translate
                }}</span>
              </div>
            </div>
            <div
              class="body"
              *ngIf="event.vaccineId != vaccinesEnum.RESTRICAO_MEDICA"
            >
              <div class="vaccine">
                {{ 'TIMELINE.TOOK_VACCINE' | translate }}
                <strong *ngIf="event.vaccineId != vaccinesEnum.OTHER_VACCINE">{{
                  event?.vaccineName
                }}</strong>
                <strong *ngIf="event.vaccineId == vaccinesEnum.OTHER_VACCINE">{{
                  event?.vaccineInformationNote
                }}</strong>
              </div>
              <ul>
                <li *ngIf="event?.isBoosterVaccine">
                  {{ 'TIMELINE.BOOSTER_DOSE_VACCINE' | translate }}
                </li>
                <li *ngIf="!event?.isBoosterVaccine">
                  {{ 'TIMELINE.DOSE_VACCINE' | translate }}
                  {{ event.doseVaccine }}/{{ event?.numberDoses }}
                </li>
              </ul>
              <div
                *ngIf="event.eventDate > event.createdDate"
                class="note-container"
              >
                <span class="note-border-primary">
                  {{ 'TIMELINE.REMOVED_VACCINE' | translate }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="body"
            *ngIf="
              event.vaccineId == vaccinesEnum.RESTRICAO_MEDICA &&
              event.eventDate > event.createdDate
            "
          >
            <div class="note-container">
              <span class="note-border-primary">
                {{ 'TIMELINE.RELEASED_FROM_VACCINE_REMOVED' | translate }}
              </span>
            </div>
          </div>
          <div
            class="body"
            *ngIf="
              event.vaccineId == vaccinesEnum.RESTRICAO_MEDICA &&
              event.createdDate == event.eventDate
            "
          >
            <div class="note-container">
              <span class="note-border-primary">
                {{
                  'TIMELINE.COLABORATOR_RELEASED_FROM_VACCINE' | translate
                }}</span
              >
            </div>
          </div>

          <div *ngSwitchCase="valeTimelineEnum.COMPULSORY_NOTIFICATION">
            <div class="container-header">
              <div>
                <span class="title">{{
                  'TIMELINE.COMPULSORY_NOTIFICATION' | translate
                }}</span>
              </div>
            </div>
            <div class="body">
              <div>
                {{ 'TIMELINE.COMPULSORY_NOTIFICATION_DESCRIPTION' | translate }}
              </div>
              <ul>
                <li>
                  {{ event.numberCompulsoryNotification }}
                </li>
              </ul>
            </div>
          </div>

          <div *ngSwitchCase="valeTimelineEnum.DEATH">
            <div class="container-header">
              <div>
                <span class="title">{{ 'TIMELINE.DEATH' | translate }}</span>
              </div>
            </div>
            <div class="body">
              <div>{{ 'TIMELINE.CID' | translate }} {{ event.cid }}</div>
            </div>
          </div>

          <div class="footer">
            <div class="user">
              <div
                *ngIf="
                  (event?.eventType == valeTimelineEnum.VACCINES &&
                    event.origin != integrationSGC) ||
                  event.eventType != valeTimelineEnum.VACCINES
                "
                class="name"
              >
                <span>{{ event?.userFullName }}</span>
              </div>
              <div class="code">
                <span>{{ event?.user }}</span>
              </div>
            </div>
            <div class="date">
              <span
                *ngIf="
                  (event?.eventType == valeTimelineEnum.VACCINES &&
                    event.origin != integrationSGC) ||
                  event.eventType != valeTimelineEnum.VACCINES
                "
                >{{ 'TIMELINE.ATTENDANCE' | translate }}
                {{ event.createdDateFormated }}</span
              >
              <span
                *ngIf="
                  event?.eventType == valeTimelineEnum.VACCINES &&
                  event.origin == integrationSGC
                "
                >{{ 'TIMELINE.INTEGRATION' | translate }}
                {{ event.createdDateFormated }}</span
              >
            </div>
          </div>
        </div>
      </li>
      <div class="divider" *ngIf="i < timeline?.data?.length - 1"></div>
    </ng-container>
  </ul>
</div>
