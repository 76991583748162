import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

import { CustomDropdownComponent } from '../custom-dropdown.component';
import { CustomDropdownService } from '../custom-dropdown.service';
import { CustomSelectOptionComponent } from '../custom-select-option/custom-select-option.component';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true,
    },
    CustomDropdownService,
  ],
})
export class CustomSelectComponent
  implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {
  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public selected: any;

  @Input()
  public required = false;

  @Input()
  public disabled = false;

  @Input()
  public fieldName: string;

  @Input()
  public multiple = false;

  @Input()
  public markAll = false;

  @Input()
  public currency = false;

  @Input() public inUse = false;

  @Input() withIcon = false;

  @Input() icon = '';

  @ViewChild('input', { static: true })
  public input: ElementRef;

  @ViewChild(CustomDropdownComponent, { static: true })
  public dropdown: CustomDropdownComponent;

  @ContentChildren(CustomSelectOptionComponent)
  public options: QueryList<CustomSelectOptionComponent>;

  public _selectionModel: SelectionModel<CustomSelectOptionComponent>;

  private keyManager: ActiveDescendantKeyManager<CustomSelectOptionComponent>;

  private filteredOptions: any[];

  constructor(private readonly dropdownService: CustomDropdownService) {
    this.dropdownService.register(this);
  }

  public ngOnChanges(): void {
    if (this.options) {
      this.markAllOptions();
    }
  }

  public ngOnInit(): void {
    this.filteredOptions = [];
    this.dropdownService.register(this);
    this._selectionModel = new SelectionModel(
      this.dropdownService.getMultiple(),
      []
    );
  }

  public selectedOption: CustomSelectOptionComponent;

  public displayText: string;

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.selectedOption = this.options
        .toArray()
        .find((option) => option.key === this.selected);

      if (this.withIcon) {
        this.icon = this.selectedOption.icon;
      }

      this.displayText = '';
      // this.displayText = this.selectedOption ? this.selectedOption.value : '';
      this.keyManager = new ActiveDescendantKeyManager(this.options)
        .withHorizontalOrientation('ltr')
        .withVerticalOrientation()
        .withWrap();
      this.markAllOptions();
    });
  }

  public showDropdown(): void {
    this.dropdown.show();

    if (!this.options.length) {
      return;
    }

    if (!this.multiple) {
      this.selected
        ? this.keyManager.setActiveItem(this.selectedOption)
        : this.keyManager.setFirstItemActive();
    }
  }

  public onDropMenuIconClick(event: UIEvent): void {
    event.stopPropagation();
    setTimeout(() => {
      this.input.nativeElement.focus();
      this.input.nativeElement.click();
    }, 10);
  }

  public setInUseState(inUse: boolean): void {
    this.inUse = inUse;
  }

  public selectOption(option: CustomSelectOptionComponent): void {
    this.keyManager.setActiveItem(option);
    this.selected = option.key;
    this.selectedOption = option;

    if (this.multiple) {
      this.selectOptionMultiple(option);
    } else {
      this.displayText = this.selectedOption ? this.selectedOption.value : '';
      this.hideDropdown();
      this.input.nativeElement.focus();
    }
    this.onChange();
  }

  public displayString(): void {
    this.displayText = '';
    this.displayText = this.fieldName;
    this.selected = this._selectionModel.selected
      .filter((item) => !item.checkAll)
      .map((item) => item.key);
  }

  public hideDropdown(): void {
    this.dropdown.hide();
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (
      ['Enter', ' ', 'ArrowDown', 'Down', 'ArrowUp', 'Up'].indexOf(event.key) >
      -1
    ) {
      if (!this.dropdown.showing) {
        this.showDropdown();
        return;
      }

      if (!this.options.length) {
        event.preventDefault();
        return;
      }
    }

    if (event.key === 'Enter' || event.key === ' ') {
      this.selectedOption = this.keyManager.activeItem;
      this.selectOption(this.selectedOption);
    } else if (event.key === 'Escape' || event.key === 'Esc') {
      this.dropdown.showing && this.hideDropdown();
    } else if (
      [
        'ArrowUp',
        'Up',
        'ArrowDown',
        'Down',
        'ArrowRight',
        'Right',
        'ArrowLeft',
        'Left',
      ].indexOf(event.key) > -1
    ) {
      this.keyManager.onKeydown(event);
    } else if (
      event.key === 'PageUp' ||
      event.key === 'PageDown' ||
      event.key === 'Tab'
    ) {
      this.dropdown.showing && event.preventDefault();
    }
  }

  public markAllOptions(): void {
    if (this.options) {
      const _options = this.options.toArray();

      const selected = _options.find((option) => option.key === this.selected);
      this.icon = this.withIcon ? (selected ? selected.icon : null) : this.icon;

      if (this.selected) {
        if (Array.isArray(this.selected)) {
          this.selected.forEach((item: any) => {
            const opt = _options.find((obj: any) => _.isEqual(item, obj.key));
            this._selectionModel.select(opt);
          });
        } else {
          const opt = _options.find((obj: any) =>
            _.isEqual(this.selected, obj.key)
          );
          this._selectionModel.select(opt);
        }
      } else if (this.markAll && this.multiple) {
        _options.forEach((item) => {
          this._selectionModel.select(item);
        });
      } else {
        const item = _options.find((item) => item.checkAll);
        if (item && this.selected) {
          this._selectionModel.select(item);
        }
      }

      if (this._selectionModel.selected) {
        this.displayString();
      }
    }
  }

  public onChangeFn = (_: any): void => {};

  public onTouchedFn = (): void => {};

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    if (this.selected != [] || !this.selected) {
      this.selected = obj;
      this.displayText = '';
      this._selectionModel.clear();
      this.markAllOptions();
    }
  }

  public onTouched(): void {
    this.onTouchedFn();
  }

  public onChange(): void {
    this.onChangeFn(this.selected);
  }

  private selectOptionMultiple(option: CustomSelectOptionComponent) {
    const _options = this.options.toArray();
    if (option.checkAll) {
      if (this._selectionModel.selected.length === _options.length) {
        this._selectionModel.clear();
      } else {
        _options.forEach((item) => {
          this._selectionModel.select(item);
        });
      }
    } else {
      let checkAll = this._selectionModel.selected.find(
        (item) => item.checkAll
      );
      if (checkAll) {
        this._selectionModel.deselect(checkAll);
      }

      if (this._selectionModel.isSelected(this.selectedOption)) {
        this._selectionModel.deselect(this.selectedOption);
      } else {
        this._selectionModel.select(this.selectedOption);
        checkAll = _options.find((item) => item.checkAll);
        if (
          checkAll &&
          this._selectionModel.selected.length === _options.length - 1
        ) {
          this._selectionModel.select(checkAll);
        }
      }
    }
    this.displayString();
  }
}
