import { Component, Input, OnInit } from '@angular/core';

import { TopicsModel } from '../../../../../models/topics-model';

@Component({
  selector: 'app-help-center-topics-child',
  templateUrl: './help-center-topics-child.component.html',
  styleUrls: ['./help-center-topics-child.component.scss'],
})
export class HelpCenterTopicsChildComponent implements OnInit {
  @Input() public topics: TopicsModel;

  constructor() {}

  ngOnInit() {}
}
