import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { TestLocationModel } from '../models/test-location.model';

export class AutoCompleteValidator {
  public static validateTestLocation(
    validFields: TestLocationModel[]
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        validFields !== undefined &&
        control.value != null &&
        control.value.length > 0
      ) {
        const upperCaseNames = validFields.map((value) => {
          return value && value.name ? value.name.toUpperCase() : '';
        });

        const fieldIndex = upperCaseNames.indexOf(control.value.toUpperCase());

        if (fieldIndex === -1) {
          return { validateTestLocation: true };
        }
      }
      return null;
    };
  }

  public static validateLocation(
    validFields: TestLocationModel[]
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (validFields !== undefined && control.value != null) {
        if (typeof control.value !== 'object') {
          return { validateTestLocation: true };
        }
      }
      return null;
    };
  }

  public static validateSsoLocation(validFields: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        validFields !== undefined &&
        control.value != null &&
        control.value.length > 0
      ) {
        const upperCaseLocations = validFields.map((value) => {
          return value.toUpperCase();
        });

        const fieldIndex = upperCaseLocations.indexOf(
          control.value.toUpperCase()
        );

        if (fieldIndex === -1) {
          return { validateTestLocation: true };
        }
      }
      return null;
    };
  }
}
