import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OAuthService } from 'angular-oauth2-oidc';
import * as moment from 'moment';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { auth, environment } from '../../../environments/environment';
import { ReturnDialogButton } from '../../shared/components/return-dialog/return-dialog-button.model';
import { ReturnDialogComponent } from '../../shared/components/return-dialog/return-dialog.component';
import { ReturnDialog } from '../../shared/components/return-dialog/return-dialog.model';
import { Extensions } from '../../shared/extensions';
import { AuthenticationService } from '../services/authentication.service';
import { LogService } from '../services/log.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private spinner: SpinnerVisibilityService,
    private authenticationService: AuthenticationService,
    private oauthService: OAuthService,
    private logService: LogService
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let culture = localStorage.getItem(Extensions.STORAGE_KEYS.CULTURE);
    if (!culture) {
      culture = navigator.language;
      localStorage.setItem(Extensions.STORAGE_KEYS.CULTURE, culture);
    }

    let authReq;
    const byPassUser = localStorage.getItem('bypass_login_user') || null;
    if (!environment.production && byPassUser) {
      authReq = req.clone({
        headers: req.headers
          .set('Accept-Language', culture.replace(/"/g, ''))
          .set('x-bypass-auth', byPassUser),
      });
    } else {
      localStorage.setItem('bypass_login_user', '');
      var idToken = localStorage.getItem('id_token') || '';
      authReq = req.clone({
        headers: req.headers
          .set('Accept-Language', culture.replace(/"/g, ''))
          .set('x-id-token', idToken),
      });
    }

    if (req.method === 'POST' || req.method === 'PUT') {
      this.shiftDates(req.body);
    }

    return next.handle(authReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // ..
          }
        },
        (err: any) => {
          this.spinner.hide();
          // console.log('err', err);
          if (err instanceof HttpErrorResponse) {
            if (
              req.url.includes(auth.userinfoEndpoint) ||
              (req.url.includes(auth.tokenEndpoint) &&
                !this.isRefreshTokenRequest(req.body))
            ) {
              this.callErrorReturnDialog(err);
            } else if (
              req.url.includes(environment.api) &&
              err.status === 401
            ) {
              this.oauthService
                .refreshToken()
                .then(() => next.handle(authReq))
                .catch((error) => {
                  this.logService.logWarning(
                    'Falha ao tentar refreshToken apos erro 401',
                    error
                  );
                  this.authenticationService.doLogout();
                  window.location.href = auth.logoutUrl;
                });
            } else if (err.status === 0 || err.status === 500) {
              this._snackBar.open('Erro desconhecido', 'FECHAR', {
                duration: 4000,
              });
            }
          }
        }
      )
    );
  }

  private shiftDates(body) {
    if (body == null || body == undefined || !body) {
      return body;
    }

    if (typeof body != 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
        this.convertToDateAndSetProperty(body, key, value);
      } else if (typeof value == 'object') {
        // eslint-disable-next-line import/namespace
        if (moment.isMoment(value)) {
          const _value = value.toDate();
          this.convertToDateAndSetProperty(body, key, _value);
        } else {
          this.shiftDates(value);
        }
      }
    }
  }

  private convertToDateAndSetProperty(obj, key, value) {
    obj[key] = new Date(
      Date.UTC(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        value.getHours(),
        value.getMinutes(),
        value.getSeconds()
      )
    )
      .toJSON()
      .replace('Z', '');
  }

  private isRefreshTokenRequest(body: any) {
    let isRefresh = false;
    if (body && body.map) {
      body.map.forEach((x) => {
        if (x && x[0] == 'refresh_token') {
          isRefresh = true;
        }
      });
    }

    return isRefresh;
  }

  private callErrorReturnDialog(err: HttpErrorResponse) {
    const text1 = 'Para entrar no GCM, ';
    const text2 = 'desconecte do IAM'.bold();
    const text3 = ' e tente novamente.';
    const defaultMessage = text1 + text2 + text3;

    const dialogRef = this.dialog;
    const buttons = new Array<ReturnDialogButton>(
      new ReturnDialogButton({
        name: 'Desconectar do IAM',
        className: 'button-classic button-classic--background-red ',
        action: () => {
          dialogRef.closeAll();
        },
      })
    );

    this.logService.logWarning(
      'Tela de falha do login no IAM exibida para o usuario.',
      err
    );

    const modalInstance = this.dialog.open(ReturnDialogComponent, {
      data: new ReturnDialog({
        actionType: 'error',
        title: 'Não foi possível concluir o login no IAM',
        message: defaultMessage,
        buttons: buttons,
        path: './assets/images/error.svg',
        closeActionButton: () => {
          dialogRef.closeAll();
        },
      }),
      disableClose: true,
    });

    modalInstance.afterClosed().subscribe(() => {
      this.authenticationService.doLogout();
      window.location.href = auth.logoutUrl;
    });
  }
}
