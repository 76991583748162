import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-vale-radio-button',
  templateUrl: './vale-radio-button.component.html',
  styleUrls: ['./vale-radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValeRadioButtonComponent
  implements AfterViewInit, AfterViewChecked {
  @Input() public disabled = false;
  @Input() public checked = false;
  @Input() public value: any;
  public control: any;
  public name: string;

  @HostBinding('class.container-vale-radio')
  public setClass(): boolean {
    return true;
  }

  @HostListener('click')
  public onclick(): void {
    if (this.control && !this.disabled) {
      this.control.setValue(this.value);
    }
  }

  constructor(private _cdr: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    if (this.control && this.disabled) {
      this.control.setDisabledState(this.disabled);
    }
  }

  public ngAfterViewChecked(): void {
    if (this.control) {
      this.checked = this.control.value == this.value;
      if (this.disabled) {
        this.control.setDisabledState(this.disabled);
      }
    }
    this._cdr.detectChanges();
  }
}
