<div class="container-bypass-login">
  <mat-card>
    <div class="header">Bypass IAM</div>
    <form [formGroup]="userForm" class="container-form">
      <div class="container-info-user">
        <div>
          <span>Informações do usuário</span>
        </div>
        <div class="two-columns">
          <mat-form-field appearance="outline" floatLabel="never">
            <input matInput formControlName="code" placeholder="Iam id" />
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="never">
            <input matInput formControlName="name" placeholder="Nome" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" floatLabel="never">
            <input matInput formControlName="email" placeholder="Email" />
          </mat-form-field>
        </div>
        <div></div>
        <div class="two-columns">
          <mat-form-field appearance="outline" floatLabel="never">
            <mat-select formControlName="country" placeholder="País">
              <mat-option
                *ngFor="let country of userCountries"
                [value]="country.code"
              >
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="never">
            <mat-select
              multiple
              formControlName="roles"
              placeholder="Permissão"
            >
              <mat-option *ngFor="let role of roles" [value]="role.code">
                {{ role.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="addAllButtom">
            <button app-vale-stroked-button (click)="selectAll()">Todos</button>
          </div>
        </div>
      </div>
      <div class="container-info-groups">
        <div class="two-columns">
          <div>
            <span>Adicionar acesso</span>
          </div>
          <div>
            <button
              app-vale-stroked-button
              class="circle-button"
              (click)="addGroup()"
            >
              +
            </button>
          </div>
        </div>
        <div class="container-groups">
          <ng-container formArrayName="groups">
            <div
              *ngFor="let _ of groups.controls; index as i"
              class="two-columns"
            >
              <ng-container class="" [formGroupName]="i">
                <mat-form-field appearance="outline" floatLabel="never">
                  <mat-select formControlName="tool" placeholder="Aplicação">
                    <mat-option *ngFor="let tool of tools" [value]="tool.code">
                      {{ tool.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="never">
                  <mat-select formControlName="country" placeholder="País">
                    <mat-option
                      *ngFor="let country of countries"
                      [value]="country.code"
                    >
                      {{ country.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div>
                  <button
                    app-vale-stroked-button
                    class="circle-button"
                    color="danger"
                    (click)="removeGroup(i)"
                  >
                    X
                  </button>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
    <div class="footer">
      <button
        app-vale-stroked-button
        [disabled]="userForm.invalid"
        class="send-button"
        (click)="doLogin()"
      >
        Enviar
      </button>
    </div>
  </mat-card>
</div>
