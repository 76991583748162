import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthenticationService } from '../../core/services/authentication.service';
import { UserBusiness } from '../../shared/business/user-business.service';
import { HomeOptionsEnum } from '../../shared/enums/home-options.enum';
import { BreakpointObserverService } from './../../shared/services/breakpoint-observer.service';
import { CsatDialogComponent } from './components/csat-dialog/csat-dialog.component';
import { ReleaseNotesDialogComponent } from './components/release-notes-dialog/release-notes-dialog.component';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public selectedOption: HomeOptionsEnum;
  public isMobile: boolean;
  public userName: string;
  public hasPermissionAbsenceControl = false;
  public hasPermissionMedicalRecord = false;
  public hasPermissionQuickTest = false;
  public hasPermissionMonitoringCenter = false;
  public hasPermissionCardManagement = false;
  public hasOnlyPermissionUraAccessCard = false;
  public hasPermissionUraAccessCard = false;
  public homeOptionsEnum = HomeOptionsEnum;
  private _subscriptions = new Subscription();

  constructor(
    private router: Router,
    private _breakpointObserverService: BreakpointObserverService,
    public userBusiness: UserBusiness,
    private authenticationService: AuthenticationService,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private _homeService: HomeService
  ) {}

  public ngOnInit(): void {
    this._subscriptions.add(
      this._breakpointObserverService.deviceConfig$.subscribe((device) => {
        this.isMobile = device.isMobile || device.isTablet;
      })
    );
    this._subscriptions.add(
      this.userBusiness.getLoggedUser$.subscribe((result: any) => {
        this.setUserName(result);
      })
    );
    this._setUserPermissions();
    if (!this.isMobile) {
      this._postDialogs();
    }
  }

  private _postDialogs() {
    const sub = this._homeService.postRelease().subscribe((response: any) => {
      if (response.activeRelease) {
        this.expandReleaseNotes();
      }
      if (response.activeCsat) {
        this.expandCsatDialog();
      }
    });

    this._subscriptions.add(sub);
  }

  public expandReleaseNotes(): void {
    this.dialog.open(ReleaseNotesDialogComponent, {
      maxWidth: '794px',
      disableClose: true,
      panelClass: 'modal-release-notes',
    });
  }

  public expandCsatDialog(): void {
    this.dialog.open(CsatDialogComponent, {
      maxWidth: '750px',
      disableClose: true,
    });
  }

  public ngAfterViewChecked(): void {
    const subscription = this.userBusiness.getLoggedUser$.subscribe(
      (result: any) => {
        this.setUserName(result);
      }
    );
    this.cdRef.detectChanges();

    subscription.unsubscribe();
  }

  private setUserName(result: any) {
    if (result) {
      this.userName = result.userName.split(' ')[0];
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public onSelectedOptionAndRedirect(selectedOption: HomeOptionsEnum): void {
    this.userBusiness.setHomeOption(selectedOption);
    const urlToRedirect = this._getUrlToRedirect(selectedOption);
    this.router.navigate([urlToRedirect], {
      queryParams: {
        selectedOption: selectedOption,
      },
    });
  }

  public logout(): void {
    this.authenticationService.doLogout();
    localStorage.clear();
  }

  private _getUrlToRedirect(selectedOption: HomeOptionsEnum): string {
    switch (selectedOption) {
      case HomeOptionsEnum.MONITORING_CENTER:
        return 'new-monitoring-center';
      case HomeOptionsEnum.ABSENCE_CONTROL:
        return 'absence-control';
      case HomeOptionsEnum.ACCESS_CARD:
        return 'card-management/manage-card';
      case HomeOptionsEnum.VALE_FORMS_VACCINES:
        return 'vale-forms-vaccines';
      default:
        return 'search';
    }
  }

  private _setUserPermissions() {
    this._subscriptions.add(
      this.userBusiness.userPermission$.subscribe((roles: any) => {
        this._mapperPermissions(roles);
        if (this.hasOnlyPermissionUraAccessCard) {
          this.redirectToUraAccessCard();
        }
      })
    );
  }

  private _mapperPermissions(roles: any) {
    this.hasPermissionAbsenceControl = roles.hasPermissionAbsenceControl;
    this.hasPermissionMedicalRecord = roles.hasPermissionMedicalRecord;
    this.hasPermissionMonitoringCenter = roles.hasPermissionMonitoringCenter;
    this.hasPermissionMonitoringCenter = roles.hasPermissionQuickTest;
    this.hasPermissionCardManagement = roles.hasPermissionCardManagement;
    this.hasPermissionUraAccessCard = roles.hasPermissionUraAccessCard;
    this.hasPermissionQuickTest = roles.hasPermissionQuickTest;
    this.hasOnlyPermissionUraAccessCard = roles.hasOnlyPermissionUraAccessCard;
  }

  private redirectToUraAccessCard(): void {
    this.router.navigate(['ura-access-card'], {
      queryParams: {
        selectedOption: 1,
      },
    });
  }
}
