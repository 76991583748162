import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Extensions } from '../../shared/extensions';
import { PatientValeFormsVaccines } from '../../shared/models/patient-vale-forms-vaccines.model';
import {
  InvalidateVaccinationRercord,
  VaccinationRercord,
} from '../../shared/models/vaccination-record.inteface';
import { ValeFormsVaccineFilter } from '../../shared/models/vale-forms-vaccine-filter.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ValeFormsVaccineService extends BaseService {
  private readonly _idiom;
  private readonly _baseUrl = 'vale-forms-vaccine';

  constructor(httpClient: HttpClient) {
    super(httpClient, null, environment.api);
    this._idiom = localStorage.getItem(Extensions.STORAGE_KEYS.CULTURE);
    if (this._idiom === 'pt' || this._idiom === 'pt-MZ') {
      this._idiom = 'pt-BR';
    }
  }

  public getInvalidateReasonsVaccine(countryId: number): Observable<any> {
    return this.get<any>(`${this._baseUrl}/invalidate-reasons-vaccines/`, {
      countryId: countryId,
    });
  }

  public getPatientValeFormsVaccinesByFilter(
    valeFormsVaccineFilter: ValeFormsVaccineFilter
  ): Observable<PatientValeFormsVaccines[]> {
    return this.get<PatientValeFormsVaccines[]>(
      `${this._baseUrl}/patient-vaccines`,
      valeFormsVaccineFilter
    );
  }

  public getValidateUrlImg(url: string): Observable<any> {
    return this.get<any>(`${this._baseUrl}/validateUrlImg/`, {
      url: url,
    });
  }

  public getVaccineInformationById(
    id: number,
    status: number
  ): Observable<any> {
    return this.get<any>(`${this._baseUrl}/patient-vaccines/${id}/${status}`);
  }

  public invalidateVaccinationRecord(
    vaccinationRecord: InvalidateVaccinationRercord
  ): Observable<any> {
    const {
      patientId,
      vaccinationRecordsId,
      dose,
      reasons,
      isBoosterVaccine,
    } = vaccinationRecord;
    return this.post<any>(
      `${this._baseUrl}/patient-vaccines/${patientId}/vaccination-record/${vaccinationRecordsId}/doses/${dose}/invalidate/${isBoosterVaccine}`,
      reasons
    );
  }

  public validateVaccinationRecord(
    vaccinationRecord: VaccinationRercord
  ): Observable<any> {
    const { patientId, vaccinationRecordsId, dose } = vaccinationRecord;
    return this.post<any>(
      `${this._baseUrl}/patient-vaccines/${patientId}/vaccination-record/${vaccinationRecordsId}/doses/${dose}/validate`
    );
  }

  public getWorkLocationComplexByStateId(stateId: number): Observable<any> {
    return this.get<any>(`${this._baseUrl}/states/${stateId}/work-locations`);
  }

  public getHealthUnitByWorkLocationComplexId(
    workLocationComplexId: number,
    stateId: number
  ): Observable<any> {
    return this.get<any>(
      `${this._baseUrl}/states/${stateId}/work-locations/${workLocationComplexId}/healt-units`
    );
  }

  public revalidateVaccinationRecord(vaccinationRecordsId: any, dose: any) {
    return this.post<any>(
      `${this._baseUrl}/patient-vaccines/revalidate-invalid-record/${vaccinationRecordsId}/${dose}`
    );
  }
}
