<div class="container-release-notes-dialog">
  <div class="header">
    <div class="title">{{ 'RELEASE_NOTES.TITLE_DIALOG' | translate }}</div>
  </div>
  <div class="body">
    <div class="container">
      <div *ngIf="imageRoute">
        <div class="image">
          <img
            src="./assets/images/release-notes/{{ imageRoute }}"
            alt="release notes"
          />
        </div>
      </div>
      <div *ngIf="!imageRoute">
        <div class="image" *ngIf="countryId == countryEnum.BRAZIL">
          <img
            src="./assets/images/release-notes/release-notes-br-20220502.svg"
            alt="release notes"
          />
        </div>
        <div class="image" *ngIf="countryId == countryEnum.MOZAMBIQUE">
          <img
            src="./assets/images/release-notes/release-notes-mzb-20220502.svg"
            alt="release notes"
          />
        </div>
      </div>
      <div class="divisor"></div>
      <div class="button">
        <button app-vale-flat-button (click)="closeModal()">
          {{ 'RELEASE_NOTES.BUTTON_CLOSE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
