import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { auth, environment } from './../environments/environment';
import { AuthenticationService } from './core/services/authentication.service';
import { BypassLoginAuthService } from './modules/bypass-login/bypass-login-auth.service';
import { UserBusiness } from './shared/business/user-business.service';
import { AppConstant } from './shared/constants/app.constant';
import { BreakpointObserverService } from './shared/services/breakpoint-observer.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  public isLoggedIn = false;
  public isDenied = false;
  public isMobile = false;
  public isTablet = false;
  public isIE: boolean;
  public watch$: any;
  public isBypassLogin = false;
  public tokenEndpoint: string = auth.tokenEndpoint;
  public hasOnlyPermissionUraAccessCard = false;
  @Input() public helpCenterIsOpened = false;
  @Input() public helpCenterCanClose = false;
  @ViewChild('containerValeTheme', { static: true })
  public $container;

  private _subscriptions = new Subscription();

  constructor(
    private authenticationService: AuthenticationService,
    private userBusiness: UserBusiness,
    public breakpointObserver: BreakpointObserver,
    private router: Router,
    private bypassLoginAuthService: BypassLoginAuthService,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _cdRef: ChangeDetectorRef,
    private _breakpointsObserverService: BreakpointObserverService
  ) {
    // (window as any).clarityMicrosoft(
    //   window,
    //   document,
    //   'clarity',
    //   'script',
    //   `${environment.clarity}`
    // );

    (window as any).ga('create', `${environment.GAcontainer}`, 'auto');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        (window as any).ga('set', 'page', event.urlAfterRedirects);
        (window as any).ga('send', 'pageview');
      }
    });
    if (!environment.production) {
      const bypassUser = localStorage.getItem('bypass_login_user');
      if (location.pathname === '/bypass-login') {
        this.isBypassLogin = true;
      } else if (bypassUser) {
        this.isBypassLogin = true;
        this.bypassLoginAuthService.updateLoggedBypassLoginUser(
          JSON.parse(bypassUser)
        );
      } else {
        this.isBypassLogin = false;
        localStorage.setItem('bypass_login_user', '');
        this.authenticationService.doLogin();
      }
    } else {
      this.isBypassLogin = false;
      localStorage.setItem('bypass_login_user', '');
      this.authenticationService.doLogin();
    }
    AppConstant.iconNamesSvg.forEach((name: string) => {
      this._matIconRegistry.addSvgIcon(
        name,
        this._domSanitizer.bypassSecurityTrustResourceUrl(
          `../assets/images/${name}.svg`
        )
      );
    });
  }
  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  public clickOutsideModal(): void {
    this.helpCenterIsOpened = false;
  }

  public ngOnInit(): void {
    this.isIE = this.IsIE();
    this._subscriptions.add(
      this.userBusiness.getLoggedUser$.subscribe((result) => {
        this.userBusiness.setUserCountry();
        if (result != null && result.userName) {
          this.isLoggedIn = true;
        } else if (result != null && result === false) {
          this.isDenied = true;
          this.router.navigateByUrl('profile-denied');
        }
      })
    );

    this._subscriptions.add(
      this._breakpointsObserverService.deviceConfig$.subscribe((device) => {
        this.isMobile = device.isMobile;
        this.isTablet = device.isTablet;
      })
    );

    this._setHasOnlyPermissionUraAccessCardByUserPermissions();
  }

  public getFiltered() {
    return [
      this.tokenEndpoint,
      /\/api\/events\/employee\/[0-9]*\/evolution-event/,
    ];
  }

  public IsIE() {
    const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); // IE 11

    return msie > 0 || trident > 0;
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public openHelpCenter() {
    this.helpCenterIsOpened = !this.helpCenterIsOpened;
  }

  private _setHasOnlyPermissionUraAccessCardByUserPermissions() {
    this._subscriptions.add(
      this.userBusiness.userPermission$.subscribe((permisions) => {
        this.hasOnlyPermissionUraAccessCard =
          permisions.hasOnlyPermissionUraAccessCard;
      })
    );
  }
}
