import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { EventEmitter } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { ValeGenericSearchDropdownComponent } from './vale-generic-search-dropdown.component';
import { ValeGenericSearchDropdownService } from './vale-generic-search-dropdown.service';
import { ValeGenericSearchOptionComponent } from './vale-generic-search-option.component';

@Component({
  selector: 'vale-generic-search',
  templateUrl: './vale-generic-search-dropdown.componet.html',
  styleUrls: ['./_vale-generic-search-dropdown.componet.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValeGenericSearchComponent),
      multi: true,
    },
    ValeGenericSearchDropdownService,
  ],
})
export class ValeGenericSearchComponent
  implements OnInit, ControlValueAccessor {
  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public selected: string;

  @Input()
  public required = false;

  @Input()
  public disabled = false;

  @ViewChild('input', { static: true })
  public input: ElementRef;

  @ViewChild(ValeGenericSearchDropdownComponent, { static: true })
  public dropdown: ValeGenericSearchDropdownComponent;

  @ContentChildren(ValeGenericSearchOptionComponent)
  public options: QueryList<ValeGenericSearchOptionComponent>;

  public selectedOption: ValeGenericSearchOptionComponent;

  public displayText: string;

  public onChangeFn = (_: any) => {};

  public onTouchedFn = () => {};

  public clearResultSearch = false;

  @Input()
  public color = 'primary';

  public isPrimary = true;

  public searchBy = new FormControl(null);

  @Input('closeTitle')
  public closeTitle = 'Limpar';

  @Input('searchTitle')
  public searchTitle = 'Pesquisar';

  @Output('changeSearch')
  private _changeSearch = new EventEmitter();

  @Output('selectionChange') private _selectionChange = new EventEmitter();

  private keyManager: ActiveDescendantKeyManager<ValeGenericSearchOptionComponent>;

  private _subscriptions = new Subscription();

  @Input('closeClickOutsideDropdown')
  private _clickOutsideDropdown = true;

  @Input('focus')
  public focus = false;

  @Input()
  public searchValue = '';

  constructor(private dropdownService: ValeGenericSearchDropdownService) {
    this.dropdownService.register(this);
  }

  ngOnInit(): void {
    //this.searchBy.setValue('asd');
    this.dropdown.setClickOutsideDropdown(this._clickOutsideDropdown);
    this.isPrimary = this.color === 'primary';
    setTimeout(() => {
      if (this.focus) {
        this.input.nativeElement.focus();
      }
      this.selectedOption = this.options
        .toArray()
        .find((option) => option.value === this.selected);
      this.displayText = this.selectedOption ? this.selectedOption.value : '';
      this.keyManager = new ActiveDescendantKeyManager(this.options)
        .withHorizontalOrientation('ltr')
        .withVerticalOrientation()
        .withWrap();

      this._subscriptions.add(
        this.options.changes.subscribe(
          (options: QueryList<ValeGenericSearchOptionComponent>) => {
            if (options) {
              const _options = this.options.toArray();
              this.selectedOption = this.options
                .toArray()
                .find((option) => option.value === this.selected);
              this.displayText = this.selectedOption
                ? this.selectedOption.value
                : '';
              this.keyManager = new ActiveDescendantKeyManager(options)
                .withHorizontalOrientation('ltr')
                .withVerticalOrientation()
                .withWrap();
              this.keyManager.setFirstItemActive();
              if (_options.length) {
                this.showDropdown();
              }
            }
          }
        )
      );
    });
  }
  ngOnChanges(): void {
    this.searchBy.setValue(this.searchValue);
  }

  public showDropdown(): void {
    this.dropdown.show();
    this.clearResultSearch = true;

    if (!this.options.length) {
      return;
    }

    this.selected
      ? this.keyManager.setActiveItem(this.selectedOption)
      : this.keyManager.setFirstItemActive();
  }

  public hideDropdown(): void {
    this.dropdown.hide();
  }

  public selectOption(option: ValeGenericSearchOptionComponent): void {
    this.keyManager.setActiveItem(option);
    this.selected = option.value;
    this.selectedOption = option;
    this.displayText = this.selectedOption ? this.selectedOption.value : '';
    this._clearReset();
    this.hideDropdown();
    this.onChange();
  }

  public close(): void {
    this._clearReset();
    this.onChange();
  }

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    this.selected = obj;
  }

  public onTouched(): void {
    this.onTouchedFn();
  }

  public onChange(): void {
    this.onChangeFn(this.selected);
    this._selectionChangeEventEmitter();
  }

  public sendEventEmitter(): void {
    this.hideDropdown();
    const search = this.searchBy.value
      ? {
          searchBy: this.searchBy.value,
        }
      : null;
    this._changeSearch.emit(search);
  }
  public isClearResultSearch(): boolean {
    return this.clearResultSearch;
  }

  public resetSearch(): void {
    this._changeSearch.emit(null);
  }

  private _selectionChangeEventEmitter() {
    this._selectionChange.emit(this.selected);
    this.searchBy.reset();
  }

  private _clearReset() {
    this.clearResultSearch = false;
    this.searchBy.reset();
  }
}
