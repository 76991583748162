import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { DialogService } from '../../services/dialog.service';

@Directive({
  selector: '[appValeNavigate]',
})
export class ValeNavigateDirective {
  @HostListener('click', ['$event'])
  public onClick(): void {
    this.execute();
  }

  @Input('url')
  private _url = '/';

  @Input('state')
  private _state;

  @Input('showDialog')
  private _hasModification;

  @Output('selectionChange')
  private _selectionChangeEvent = new EventEmitter(null);

  private _subscriptions = new Subscription();

  constructor(private _router: Router, private _dialogService: DialogService) {}

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  ngOnInit(): void {}

  public execute(): void {
    if (this._hasModification) {
      const dialogRef = this._dialogService.openGoBackDialog();
      this._subscriptions.add(
        dialogRef.afterClosed().subscribe((response) => {
          this._selectionChangeEvent.emit(response);
          if (response && response !== 'DEFAULT_CLOSE') {
            this.redirectTo();
          }
        })
      );
    } else {
      this.redirectTo();
    }
  }

  public redirectTo(): void {
    this._router.navigate([this._url], { state: this._state });
  }
}
