import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BaseService } from '../../core/services/base.service';
import { Extensions } from '../../shared/extensions';

@Injectable()
export class HomeService extends BaseService {
  private _navigations: BehaviorSubject<any>;
  private readonly _idiom;
  private readonly _baseUrl = 'release-notes';

  get navigations() {
    return this._navigations.asObservable();
  }

  constructor(httpClient: HttpClient) {
    super(httpClient, null, environment.api);
    this._idiom = localStorage.getItem(Extensions.STORAGE_KEYS.CULTURE);
    if (this._idiom === 'pt' || this._idiom === 'pt-MZ') {
      this._idiom = 'pt-BR';
    }
    this._navigations = new BehaviorSubject<any>([]);
  }

  public postRelease(): Observable<any> {
    return this.post<boolean>(`${this._baseUrl}/user-release`);
  }

  public getRelease(): Observable<any> {
    return this.post<boolean>(`${this._baseUrl}/release`);
  }

  public updateCsatFlag(): Observable<any> {
    return this.post<boolean>(`${this._baseUrl}/csat`);
  }
}
