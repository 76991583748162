import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthenticationService } from '../../../core/services/authentication.service';
import { UserBusiness } from '../../business/user-business.service';
import { HomeOptionsEnum } from '../../enums/home-options.enum';
import { EmployeeModel } from '../../models';
import { Storage } from '../../util/storage';
import { BreakpointObserverService } from './../../services/breakpoint-observer.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public userName: string;
  public employee: EmployeeModel;
  public userInitialsName: string;
  public _storage: Storage = new Storage();
  public watch$: Subscription;
  public showingSearch = false;
  private _subscriptions = new Subscription();
  public homeOptionsEnum = HomeOptionsEnum;
  public isMobile = false;
  public hasOnlyPermissionUraAccessCard = false;

  constructor(
    public userBusiness: UserBusiness,
    private authenticationService: AuthenticationService,
    private _router: Router,
    private _breakpointsObserverService: BreakpointObserverService
  ) {
    this._subscriptions.add(
      this._router.events
        .pipe(filter((e) => e instanceof NavigationStart))
        .subscribe((navigation: any) => {
          this.showingSearch = false;
          if (navigation && navigation.url) {
            const urlSegments = navigation.url.split('/');
            const url = (urlSegments || []).length > 1 ? urlSegments[1] : '';
            this.showingSearch =
              ['quick-exam', 'patient'].includes(url) &&
              userBusiness.getHomeOption() !==
                HomeOptionsEnum.MONITORING_CENTER;
          }
        })
    );
  }

  public ngOnInit(): void {
    this._subscriptions.add(
      this._breakpointsObserverService.deviceConfig$.subscribe((device) => {
        this.isMobile = device.isMobile;
      })
    );

    this.checkTokenVersion();
    this.watch$ = this.userBusiness.getLoggedUser$.subscribe((result: any) => {
      this.userName = result.userName;
      this.getUserName();
    });
    this._subscriptions.add(
      this.userBusiness.userPermission$.subscribe((permissions) => {
        this.hasOnlyPermissionUraAccessCard =
          permissions.hasOnlyPermissionUraAccessCard;
      })
    );
  }

  public ngOnDestroy(): void {
    this.watch$.unsubscribe();
  }

  public logout(): void {
    this.authenticationService.doLogout();
  }

  private _deleteSessions(): void {
    this._storage.delSession('MonitoringCenterFormValue');
    this._storage.delSession('MonitoringCenterFilter');
    this._storage.delSession('VaccineFormValue');
  }

  public redirectToHomePage(): void {
    this._deleteSessions();
    this._router.navigate(['/']);
  }

  public showSearch(): boolean {
    return this.showingSearch && !this.isMobile;
  }

  private getUserName() {
    const nomeSeparado = this.userName.split(' ');
    this.userInitialsName = nomeSeparado[0].substring(0, 1);
    if (nomeSeparado[1]) {
      this.userInitialsName += nomeSeparado[1].substring(0, 1);
    }
    if (this.userInitialsName) {
      this.userInitialsName = this.userInitialsName.toUpperCase();
    }
  }

  private checkTokenVersion() {
    const currentVersion = parseInt(localStorage.getItem('expires_at'), 10);
    if (currentVersion) {
      if (new Date() > new Date(currentVersion)) {
        this.logout();
      }
    } else {
      this.logout();
    }
  }

  public onSelectedOptionAndRedirect(selectedOption: HomeOptionsEnum): void {
    this.userBusiness.setHomeOption(selectedOption);
    const urlToRedirect = this._getUrlToRedirect(selectedOption);
    this._router.navigate([urlToRedirect], {
      queryParams: {
        selectedOption: selectedOption,
      },
    });
  }

  private _getUrlToRedirect(selectedOption: HomeOptionsEnum): string {
    switch (selectedOption) {
      case HomeOptionsEnum.MONITORING_CENTER:
        return 'new-monitoring-center';
      case HomeOptionsEnum.ABSENCE_CONTROL:
        return 'absence-control';
      case HomeOptionsEnum.ACCESS_CARD:
        return 'card-management/manage-card';
      case HomeOptionsEnum.ADMIN_CENTER:
        return 'admin-center/init';
      case HomeOptionsEnum.VALE_FORMS_VACCINES:
        return 'vale-forms-vaccines';
      default:
        return 'search';
    }
  }
}
