<form class="input-wrapper">
  <div class="input" [class.required]="required" #dropreference>
    <div class="container-input-button">
      <div class="container-input-button container-input-reset">
        <input
          #input
          [placeholder]="placeholder | translate"
          [disabled]="disabled"
          [formControl]="searchBy"
          (blur)="onTouched()"
          autocomplete="off"
        />
        <mat-icon
          *ngIf="isClearResultSearch()"
          (click)="close()"
          class="button-reset"
          [matTooltip]="closeTitle | translate"
          >close</mat-icon
        >
      </div>
      <div>
        <button
          app-vale-flat-button
          (click)="sendEventEmitter();"
          color="{{isPrimary ? 'primary' : 'gray'}}"
          class="button-search"
          [matTooltip]="searchTitle | translate"
        >
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
    <vale-generic-search-dropdown [reference]="dropreference" #dropdownComp>
      <div class="dropdown-options-container">
        <ng-content select="vale-generic-search-option"></ng-content>
      </div>
    </vale-generic-search-dropdown>
  </div>
</form>
