import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[valeSuffix]',
})
export class ValeSuffixDirective {
  @HostBinding('style.right') public side = 0;

  @HostBinding('class.icon-field') public size = true;

  constructor() {}
}
