import { Inject } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

import { PatientService } from '../../../core/services/patient.service';
@Component({
  selector: 'app-patient-revoke-access',
  templateUrl: './patient-revoke-access.component.html',
  styleUrls: ['./patient-revoke-access.component.scss'],
})
export class PatientRevokeAccessComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public initialDate: any;
  public finalDate: any;
  public equalDates: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PatientRevokeAccessComponent>,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public patientService: PatientService
  ) {}

  ngOnInit() {
    if (this.data.releaseResponse) {
      this.initialDate = this.data.releaseResponse.initialDate;
      this.finalDate = this.data.releaseResponse.finalDate;
      this.equalDates = this.compareDates();
    }
  }
  public compareDates() {
    return this.initialDate.substring(0, 10) == this.finalDate.substring(0, 10);
  }
  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  public revokeAccess() {
    this.subscriptions.add(
      this.patientService.revoke(this.data.employeeId).subscribe(
        (response) => {
          this.snackBar.open(' Liberação revogada com sucesso', 'Fechar', {
            duration: 5000,
          });
          this.closeDialog();
        },
        (err) => {
          this.snackBar.open(
            'Não foi possível revogar a liberação. Tente novamente',
            'Fechar',
            {
              duration: 5000,
            }
          );
        }
      )
    );
  }

  public closeDialog() {
    this.dialogRef.close(true);
  }
}
