import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { ValeRemovableTagComponent } from './vale-removable-tag.component';

@NgModule({
  declarations: [ValeRemovableTagComponent],
  imports: [CommonModule, FormsModule, MatIconModule],
  exports: [ValeRemovableTagComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeRemovableTagModule {}
