import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ValeButtonComponent } from './vale-button.component';

@NgModule({
  declarations: [ValeButtonComponent],
  imports: [CommonModule, MatIconModule],
  exports: [ValeButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ValeButtonModule {}
