import { Component, Input, OnInit } from '@angular/core';

import { AccessCard } from '../../enums/access-card';

@Component({
  selector: 'app-vale-access-card',
  templateUrl: './vale-access-card.component.html',
  styleUrls: ['./vale-access-card.component.scss'],
})
export class ValeAccessCardComponent implements OnInit {
  @Input('accessCard') public cardId;
  @Input('showInfo') public showInfo = false;
  @Input('cardName') public cardName;
  @Input('cardReason') public cardReason;

  public card;

  constructor() {}

  ngOnInit(): void {
    this.card = this._getCardSituationInfo(this.cardId);

    if (this.cardName) {
      this.card = { ...this.card, name: this.cardName };
    }

    if (this.cardReason) {
      this.card = { ...this.card, message: this.cardReason };
    }
  }

  private _getCardSituationInfo(accessCardId: number) {
    const alt = 'Representa o cartão de acesso do funcionário';
    switch (accessCardId) {
      case AccessCard.RED_CARD_LATE_TEST:
        return {
          name: 'Card Vermelho',
          message: 'Atraso no teste',
          src: `./assets/images/patient-red-card-late-test.svg`,
          alt: alt,
        };
      case AccessCard.RED_CARD_GROUP_RISK:
      case AccessCard.RED_CARD_SYMPTOMATIC:
      case AccessCard.RED_CARD_QUARANTINE:
      case AccessCard.RED_CARD_BY_CONTACT_QUARANTINE:
      case AccessCard.RED_CARD_TEST_POSITIVE_QUARANTINE:
        return {
          name: 'Card Vermelho',
          message: '',
          src: `./assets/images/patient-red-card.svg`,
          alt: alt,
        };
      case AccessCard.GREEN_CARD:
      case AccessCard.GREEN_CARD_RETEST:
        return {
          name: 'Card Verde',
          message: '',
          src: `./assets/images/patient-green-card.svg`,
          alt: alt,
        };
      case AccessCard.BLUE_CARD_FREQUENT:
        return {
          name: 'Card Azul - Frequent',
          message: '',
          src: `./assets/images/patient-blue-card-frequent.svg`,
          alt: alt,
        };
      case AccessCard.BLUE_CARD_EVENTUAL:
        return {
          name: 'Card Azul - Eventual',
          message: '',
          src: `./assets/images/patient-blue-card-eventual.svg`,
          alt: alt,
        };
      case AccessCard.BLUE_CARD_AND_RED:
        return {
          name: 'Card Azul + Vermelho',
          message: '',
          src: `./assets/images/patient-blue-card-red-card.svg`,
          alt: alt,
        };
      case AccessCard.BLUE_CARD_AND_YELLOW:
        return {
          name: 'Card Azul + Amarelo',
          message: '',
          src: `./assets/images/patient-blue-card-yellow-card.svg`,
          alt: alt,
        };
      case AccessCard.YELLOW_CARD:
        return {
          name: 'Card Amarelo',
          message: 'Realizar o teste',
          src: `./assets/images/patient-yellow-card.svg`,
          alt: alt,
        };

      default:
        return {
          name: 'Sem card',
          message: '',
          src: `./assets/images/patient-without-card.svg`,
          alt: alt,
        };
    }
  }

  private _getNameImageCard(accessCardId: number) {
    const cardName = (AccessCard.toString(accessCardId) || '')
      .toLowerCase()
      .replace(/_/gi, '-');
    return `patient-${cardName}.svg`;
  }
}
