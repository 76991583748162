import { HostListener } from '@angular/core';
import { Directive } from '@angular/core';

@Directive({
  selector: '[is-over-directive]',
})
export class FocusOutDirective {
  @HostListener('mouseenter', ['$event'])
  public onEnter(event: any): void {}
  @HostListener('mouseleave', ['$event'])
  public onLeave(event: any): void {
    const container = document.getElementById('vale-image');
    Object.assign(container.style, {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    });
  }

  @HostListener('mousemove', ['$event'])
  public onMouseMove(event: any): void {
    const container = document.getElementById('vale-image');
    const boxWidth = container.clientWidth;
    const boxHeight = container.clientHeight;
    const rect = (container as HTMLImageElement).getBoundingClientRect();
    const xPos = event.clientX - rect.left;
    const yPos = event.clientY - rect.top;
    const xPercent = xPos / (boxWidth / 100) + '%';
    const yPercent = yPos / (boxHeight / 100) + '%';
    Object.assign(container.style, {
      backgroundPosition: xPercent + ' ' + yPercent,
      backgroundSize: 800 + 'px',
    });
  }
}
