import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightService {
  public env: any = environment;
  public appInsights: ApplicationInsights;
  constructor() {
    if (this.env.applicationInsights.isActive) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.env.applicationInsights.instrumentationKey,
        },
      });
      this.appInsights.loadAppInsights();
      this.appInsights.trackPageView();
    }
  }

  public logError(error: Error): void {
    this.appInsights.trackException({ exception: error });
  }

  public logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  public logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ): void {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  public logTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
